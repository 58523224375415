import { getData, postData } from "./common.service";

const CORE_API = process.env.REACT_APP_CORE_API;

export const startConversation = async (payload: any, signal?: AbortSignal) => {
  const url = "/dharti?path=get_start_conversation_id";
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    signal,
    body: payload,
  })
}

export const sendFirstMessage = async (id: any, payload: any, signal?: AbortSignal) => {
  const url = `/dharti?path=start_conversation/${id}`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    signal,
    body: payload,
    isBody: true
  })
}

export const sendMessageToBot = async (id: any, payload: any, signal?: AbortSignal) => {
  const url = `/dharti?path=send_message/${id}`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    signal,
    body: payload,
    isBody: true
  })
}

export const checkConversation = async (id: any) => {
  const url = `/dharti?path=is_conversation/${id}`;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
  })
}
export async function getDhartiChatsCount(
  fromDate?: Date,
  endDate?: Date,
  filters: any[] = []
) {

  const qs: any = {
    where: { and: [] }
  };

  if (fromDate && endDate) {
    qs.where.and.push(
      { expiry: { lte: endDate } },
      { expiry: { gte: fromDate } }
    );
  }

  if (filters.length) {
    qs.where.and.push(...filters);
  }
  
  let url = `/conversations/count?where=${JSON.stringify({ and: qs.where.and })}`;

  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}


export async function getChatsByPagination(
  fromDate?: Date,
  endDate?: Date,
  filters: any[] = []
) {
  const qs: any = {
    order: ["expiry DESC"],
    where: { and: [] }
  };

  if (fromDate && endDate) {
    qs.where.and.push(
      { expiry: { lte: endDate } },
      { expiry: { gte: fromDate } }
    );
  }

  if (filters.length) {
    qs.where.and.push(...filters);
  }

  return getData('/conversations?filter=' + JSON.stringify(qs), {
    basePath: CORE_API,
    sendTokenInHeader: true
  });
}



export async function getChatsByFarmerId(
  farmerId: string,
  fromDate: Date,
  endDate: Date
) {
  return getData('/conversations?filter=' + JSON.stringify({
    where: {

      and: [{ farmer_id: farmerId, expiry: { lte: endDate } }, { expiry: { gte: fromDate } }],
    },
    order: ["expiry DESC"],
  }), {
    basePath: CORE_API,
    sendTokenInHeader: true
  })
}