import { CORE_API } from "../constants/api";
import { getData, postData, deleteData } from "./common.service";


export const getLocale = async (filter?: any) => {
  let url = "/locales";
  
  if (filter) {
    url += `?filter=${JSON.stringify(filter)}`;
  }

  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  });
}

export const getAllLocale = async (filter?: any) => {
  let url = "/locale-list";
  
  if (filter) {
    url += `?filter=${JSON.stringify(filter)}`;
  }

  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  });
}

export const getLocaleById = async (id: string) => {
  const url = "/locales/"+id;
  return getData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API
  })
}

export const postLocale = async (payload: any) => {
  const url = `/locales`;
  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    body: payload
  })
}

export async function putLocale(payload: any, id: any) {
  const url = `/locales/${id}`;

  return postData(url, {
    sendTokenInHeader: true,
    basePath: CORE_API,
    usePutMethod: true,
    body: payload
  })
}

export async function deleteLocale(id: any) {
  const url = `/locales/${id}`

  return deleteData(url, {
    basePath: CORE_API,
    sendTokenInHeader: true,
    useDeleteMethod: true
  })
}

