import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { Typography } from '@mui/material';

interface LoaderProps {
    message?: string; // here msg is optional
    color?: 'success' | 'inherit' | 'primary' | 'secondary' | 'error' | 'info' | 'warning';  
}

export default function Loader({ message, color = "success" }: LoaderProps) {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
            <CircularProgress color={color} />
            <Typography variant="h6" sx={{ }} color={color}>
                {message }   
            </Typography>
        </Box>
    );
}