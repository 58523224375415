import {
  Grid,
  IconButton,
  Typography,
  Tab,
  Tabs,
  Modal,
  TextField,
} from "@mui/material";
import { AddCircleOutline, ArrowBack } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  postNutrient,
  updateNutrient,
  deleteNutrient,
  getSupportedRootStocks,
  getNutrientPetioleStage,
} from "../services/plot.service";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from "@mui/icons-material/Cancel";
import { generateOptions, isArrayWithLength } from "../utils/helper";
import { getCropOptions, getCropVarieties } from "src/services/crop.service";
import Select, { MultiValue, SingleValue } from "react-select";
import { getAllFertilizers } from "src/services/plot.service";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ToastAlert } from "src/components";
import LocaleDropdown from "src/components/LocaleDropdown";
import { LocationFilter } from "src/components/Filters";
import { getSoilStandard } from "src/services/soilStandard.service";

interface Props {
  selectedModalData?: any;
  setOpenModal?: any;
  appendGrid?: any;
}

const DropdownProps = {
  isClearable: true,
  isSearchable: true,
  placeholder: "- select -",
  styles: {
    container: (baseStyles: any) => ({
      ...baseStyles,
      width: "95%",
      padding: 2,
      fontSize: 16,
      margin: "3px 0",
      marginBottom: 11,
      marginTop: 6,
    }),
  },
};

const PlantAge: React.FC<any> = ({
  title,
  type,
  addPlantAgeData,
  selectedModalData,
  content,
  updatePlantData,
  deletePlantAgeData,
}) => {
  return (
    <Grid width={"44%"}>
      <Grid
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"85%"}
      >
        <Typography>{title}</Typography>
        <IconButton
          onClick={(e) => {
            addPlantAgeData(type, selectedModalData);
          }}
        >
          <AddCircleOutline />
        </IconButton>
      </Grid>
      <Grid container spacing={2}>
        {content?.[selectedModalData]?.standardDose?.[type]?.map(
          (n: any, index: number) => (
            <Grid item xs={12} key={index}>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={3}>
                  <TextField
                    label="Min"
                    type="number"
                    value={n?.min}
                    onChange={(e) =>
                      updatePlantData(
                        selectedModalData,
                        index,
                        type,
                        "min",
                        Number(e.target.value)
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Max"
                    type="number"
                    value={n?.max}
                    onChange={(e) =>
                      updatePlantData(
                        selectedModalData,
                        index,
                        type,
                        "max",
                        Number(e.target.value)
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    label="Value"
                    type="number"
                    value={n?.value}
                    onChange={(e) =>
                      updatePlantData(
                        selectedModalData,
                        index,
                        type,
                        "value",
                        Number(e.target.value)
                      )
                    }
                  />
                </Grid>
                <Grid item xs={3}>
                  <IconButton
                    onClick={(e) =>
                      deletePlantAgeData(selectedModalData, index, type)
                    }
                    color="error"
                    aria-label="delete"
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>
          )
        )}
      </Grid>
    </Grid>
  );
};

const NutrientDetails: React.FC<Props> = (props: Props): JSX.Element => {
  const res: any = props?.selectedModalData;
  const [content, setContent] = useState<any>(res || {});
  const [petioleAnalysisStageId, setpetioleAnalysisStageId] = useState<
    SingleValue<any>
  >([]);
  const [petioleOptions, setPetioleOptions] = useState<any>();
  const [cropVarietyId, setcropVarietyId] = useState<MultiValue<any>>([]);
  const [rootStockId, setrootStockId] = useState<MultiValue<any>>([]);
  const [rootStockOptions, setrootStockOptions] = useState<any>();
  const [soilTypeId, setsoilTypeId] = useState<MultiValue<any>>([]);
  const [cropId, setcropId] = useState<any>(res?.cropId || "");
  const [cropOptions, setcropOptions] = useState<any>();
  const [cropVarietyOptions, setcropVarietyOptions] = useState<any>();
  const [seasonId, setseasonId] = useState<any>(res?.seasonId || "");
  const [notes, setNotes] = useState<MultiValue<any>>([]);
  const [chemicalOptions, setChemicalOptions] = useState<any>();
  const [render, setrender] = useState<boolean>(false);
  const [NewEle, setNewEle] = useState<any>("");
  const [newDistribution, setnewDistribution] = useState<any>("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState<any>();
  const [tabIndex, setTabIndex] = useState(-1);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [enableCopy, setEnableCopy] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [soilData, setSoilData] = useState<any>(null);

  const toIgnore = [
    "petioleAnalysisStageId",
    "id",
    "seasonId",
    "cropId",
    "cropVarietyId",
    "rootStockId",
    "soilTypeId",
    "note",
    "created_date",
    "notes",
    "stages",
    "distribution",
    "maxConcentration",
  ];

  const filteredKeys = Object.keys(content).filter(
    (key) => !toIgnore.includes(key)
  );

  const handleChange = (event: any, newValue: any) => {
    setOpenModal(false);
    setSelectedModalData(event);
    setTabIndex(newValue);
    setOpenModal(true);
  };

  const soilOptions = [
    { label: "Black Soil", value: 1 },
    { label: "Sandy Loam", value: 2 },
    { label: "Clay Loam", value: 3 },
    { label: "Loamy Sand", value: 4 },
    { label: "Fine Sandy Loam", value: 2 },
    { label: "Clay", value: 6 },
  ];

  const addcontent = async () => {
    if (NewEle.length > 0) {
      let newcontent = content;
      const obj = {
        foliarDoseRatio: {
          value: 0,
        },
        standardDose: {
          unit: "%",
          value: 0,
        },
        nutrientIndex: {
          formula: "1",
          value: 0,
        },
        fertigationDoseRatio: {
          value: 0,
        },
        distribution: {
          D001: {
            foliar: 0,
            fertigation: 0,
            soilApplication: 0,
          },
          D010: {
            foliar: 0,
            fertigation: 0,
            soilApplication: 0,
          },
          D011: {
            foliar: 0,
            fertigation: 0,
            soilApplication: 0,
          },
          D101: {
            foliar: 0,
            fertigation: 0,
            soilApplication: 0,
          },
          D110: {
            foliar: 0,
            fertigation: 0,
            soilApplication: 0,
          },
          D111: {
            foliar: 0,
            fertigation: 0,
            soilApplication: 0,
          },
        },
        sourceIds: [],
      };
      newcontent = { [NewEle]: obj, ...newcontent };
      setContent(newcontent);
      setrender(!render);
      handleChange(NewEle, 0);
    }
  };
  const unitOptions = [
    { value: "%", label: "%" },
    { value: "ppm", label: "ppm" },
    { value: "ml/lit", label: "ml/lit" },
    { value: "kg/ha", label: "kg/ha" },
    { value: "kg/acre", label: "kg/acre" },
    { value: "kg/plant", label: "kg/plant" },
    { value: 'lit/acre', label: 'lit/acre' },
  ];

  const addDistribution = async (i: any) => {
    let newcontentdist = content?.[i]?.distribution;
    if (newDistribution.length === 4) {
      const obj = {
        foliar: 0,
        fertigation: 0,
        soilApplication: 0,
      };
      newcontentdist = { [newDistribution]: obj, ...newcontentdist };
      delete content?.[i]?.distribution;
      content[i].distribution = newcontentdist;
      setContent(content);
      setnewDistribution("");
      alert("Item added in Distribution");
      setrender(!render);
    }
  };

  const addPlantAgeData = async (type: string, i: any) => {
    let newcontentdata = content?.[i]?.standardDose[type] || [];
    const obj = {
      min: Infinity,
      max: Infinity,
      value: Infinity,
    };
    newcontentdata = [...newcontentdata, obj];
    delete content?.[i]?.standardDose[type];
    content[i].standardDose[type] = newcontentdata;
    setContent(content);
    setrender(!render);
  };

  const deletePlantAgeData = (i: any, index: number, type: string) => {
    let newcontentdata = content?.[i]?.standardDose[type] || [];
    const updatedData = newcontentdata?.filter((_: any, i: any) => i !== index);
    content[i].standardDose[type] = updatedData;
    setContent(content);
    setrender(!render);
  };

  const updatePlantData = (
    i: any,
    index: number,
    type: string,
    field: string,
    value: number
  ) => {
    let newcontentdata = content?.[i]?.standardDose[type] || [];
    const updatedNutrients = newcontentdata?.map((n: any, ind: any) =>
      ind === index ? { ...n, [field]: value } : n
    );
    content[i].standardDose[type] = updatedNutrients;
    setContent(content);
    setrender(!render);
  };

  const updateSoilDose = (
    i: any,
    type: string,
    field: string,
    value: any
  ) => {
    let newcontentdata = {...content?.[i]?.standardDose[type], [field]: value}
    content[i].standardDose[type] = newcontentdata;
    setContent(content);
    setrender(!render);
  };

  const deleteContentElement = (i: any) => {
    delete content[i];
    setTabIndex(0);
    setSelectedModalData(
      Object.keys(content).filter((key) => !toIgnore.includes(key))[0]
    );
    setrender(!render);
  };

  const deleteDistElement = (i: any, key: any) => {
    let newcontentdist = content?.[i]?.distribution;
    delete newcontentdist?.[key];
    delete content?.[i]?.distribution;
    content[i].distribution = newcontentdist;
    setContent(content);
    setrender(!render);
  };

  const changeElementValue = (f: any, s: any, t: any, val: any) => {
    if (t === "minOptimal" || t === "maxOptimal") {
      if (!Number.isNaN(val)) {
        if (!content[f][s]) {
          let obj = {
            [t]: Number(val),
          };
          content[f] = { [s]: obj, ...content[f] };
        } else {
          content[f][s][t] = Number(val);
        }
      } else {
        alert("Please Enter Numerical Value");
      }
    } else {
      if (!content[f][s]) {
        let obj = {
          [t]: val,
        };
        content[f] = { [s]: obj, ...content[f] };
      } else {
        content[f][s][t] = val;
      }
    }
    setContent(content);
    setrender(!render);
  };

  const changeDistributionVal = (f: any, s: any, t: any, val: any) => {
    const sumOfKeys: any = Object.keys(content[f].distribution[s]).reduce(
      (accumulator: any, currentKey: any) => {
        if (currentKey !== t) {
          return accumulator + content[f].distribution[s][currentKey];
        }
        return accumulator;
      },
      0
    );
    sumOfKeys + Number(val) <= 1
      ? (content[f].distribution[s][t] = Number(val))
      : (content[f].distribution[s][t] = 0);
    setContent(content);
    setrender(!render);
  };

  const changeSourceValue = (i: any, val: any) => {
    let valArr = val.split(",");
    valArr = valArr?.map((i: any) => i.trim());
    content[i].sourceIds = valArr;
    setContent(content);
    setrender(!render);
  };

  const cleanArray = (arr: any[]): any[] => {
    return arr.map((item: any) => {
      if (typeof item === "object" && item !== null) {
        const cleanedItem = Object.fromEntries(
          Object.entries(item)
            .filter(
              ([key, value]: any) => value !== Infinity && value !== -Infinity
            )
            .map(([key, value]: any) => {
              if (key === "value" && value > 1) {
                return [key, value / 100];
              }
              return [key, value];
            })
        );
        return cleanedItem;
      }
      return item;
    });
  };

  const onSubmit = () => {
    if (cropId) {
      if (window.confirm("Do you want to submit?")) {
        let cropVarietyArr = cropVarietyId?.map(
          (variety: any) => variety.value
        );
        let rootstockArr = rootStockId?.map((i: any) => i.value);
        let soilArr = soilTypeId?.map((soil: any) => String(soil.value));
        let notesArr = notes?.map((note: any) => note?.value || note);

        content.petioleAnalysisStageId = isArrayWithLength(
          petioleAnalysisStageId
        )
          ? petioleAnalysisStageId[0]?.value
          : petioleAnalysisStageId?.value;
        content.cropVarietyId = cropVarietyArr;
        content.rootStockId = rootstockArr;
        content.soilTypeId = soilArr;
        content.cropId = cropId;
        content.seasonId = seasonId;
        content.notes = notesArr;
        setContent(content);

        let finalContent = content;


        Object.keys(finalContent)?.map((i: any, index: Number) => {
          const toIgnore = [
            "petioleAnalysisStageId",
            "id",
            "seasonId",
            "cropId",
            "cropVarietyId",
            "rootStockId",
            "soilTypeId",
            "note",
            "created_date",
            "notes",
          ];
          if (!toIgnore.includes(i)) {
            Object.keys(finalContent?.[i])?.map((typ) => {
              const Ignore = [
                "sourceIds",
                "distribution",
                "nutrientIndex",
                "standardDose",
              ];
              if (!Ignore.includes(typ) && typ !== "stcrStandard") {
                finalContent[i][typ].value =
                  Number(finalContent?.[i]?.[typ]?.value) || 0;
                finalContent[i][typ].unit =
                  finalContent?.[i]?.[typ]?.unit || "%";
              } else if (typ === "stcrStandard") {
                if (finalContent?.[i]?.[typ]?.constant !== undefined)
                  finalContent[i][typ].constant = Number(
                    finalContent?.[i]?.[typ]?.constant
                  );
                if (finalContent?.[i]?.[typ]?.default !== undefined)
                  finalContent[i][typ].default = Number(
                    finalContent?.[i]?.[typ]?.default
                  );
                if (finalContent?.[i]?.[typ]?.standard !== undefined)
                  finalContent[i][typ].standard = Number(
                    finalContent?.[i]?.[typ]?.standard
                  );
                if (finalContent?.[i]?.[typ]?.yield !== undefined)
                  finalContent[i][typ].yield = Number(
                    finalContent?.[i]?.[typ]?.yield
                  );
              } else if (typ === "distribution") {
                Object.keys(finalContent?.[i]?.distribution)?.map((name) => {
                  if (finalContent?.[i]?.[typ]?.[name]?.foliar === 0)
                    delete finalContent?.[i]?.[typ]?.[name]?.foliar;
                  if (finalContent?.[i]?.[typ]?.[name]?.soilApplication === 0)
                    delete finalContent?.[i]?.[typ]?.[name]?.soilApplication;
                  if (finalContent?.[i]?.[typ]?.[name]?.fertigation === 0)
                    delete finalContent?.[i]?.[typ]?.[name]?.fertigation;
                });
              } else if (typ === "standardDose") {
                finalContent[i][typ].value =
                  Number(finalContent?.[i]?.[typ]?.value) || 0;
                finalContent[i][typ].unit =
                  finalContent?.[i]?.[typ]?.unit || "%";
                if (!!finalContent?.[i]?.[typ]?.nutrientWise?.length) {
                  finalContent[i][typ].nutrientWise = cleanArray(
                    finalContent?.[i]?.[typ]?.nutrientWise
                  );
                }
                if (!!finalContent?.[i]?.[typ]?.yearWise?.length) {
                  finalContent[i][typ].yearWise = cleanArray(
                    finalContent?.[i]?.[typ]?.yearWise
                  );
                }
              }
            });
          }
        });
        if (finalContent?.id) {
          updateNutrient(finalContent).then((res: any) => {
            if (res.message === "Success") {
              alert("Updated");
              props?.appendGrid(1);
            } else alert("Some Error Occured");
          });
        } else {
          postNutrient(finalContent).then((res: any) => {
            if (res.id) {
              alert("Nutrient Config added");
              props?.appendGrid(1);
            } else alert("Some Error Ocuured");
          });
        }
      }
    } else alert("No such chemical");

    props.setOpenModal(false);
  };

  const onDelete = () => {
    if (content.id) {
      if (window.confirm("Do you want to delete?")) {
        if (content.id) {
          deleteNutrient(content.id).then((res: any) => {
            if (res.message === "Success") {
              alert("Configuration Deleted");
              props?.appendGrid(1);
            } else alert("Some Error Occured");
          });
        } else alert("Some Error Occured");
      }
    } else alert("No such Configuration");
    props.setOpenModal(false);
  };

  useEffect(() => {
    getCropOptions().then((res: any) => {
      setcropOptions(
        [
          {
            label: "--select--",
            value: "--select--",
          },
        ].concat(generateOptions(res, "id", "cropName") as any)
      );
    });
    getAllFertilizers().then((res: any) => {
      setChemicalOptions(generateOptions(res, "id", "name") as any);
    });
  }, []);

  useEffect(() => {
    if (cropId.length > 0) {
      getCropVarieties(cropId).then((res: any) => {
        setcropVarietyOptions(generateOptions(res, "id", "name") as any);
      });
      getSupportedRootStocks(cropId).then((res: any) => {
        setrootStockOptions(generateOptions(res, "id", "name") as any);
      });
      getNutrientPetioleStage(cropId).then((res: any) => {
        setPetioleOptions(generateOptions(res, "stageId", "name") as any);
      });
    }
  }, [cropId]);

  useEffect(() => {
    if (
      props?.selectedModalData?.cropVarietyId &&
      isArrayWithLength(props?.selectedModalData?.cropVarietyId) &&
      cropVarietyOptions
    ) {
      setcropVarietyId(
        cropVarietyOptions?.filter((variety: any) =>
          props?.selectedModalData?.cropVarietyId.includes(variety.value)
        )
      );
    }
    if (
      props?.selectedModalData?.rootStockId &&
      isArrayWithLength(props?.selectedModalData?.rootStockId) &&
      rootStockOptions
    ) {
      setrootStockId(
        rootStockOptions?.filter((rootStock: any) =>
          props?.selectedModalData?.rootStockId.includes(rootStock.value)
        )
      );
    }
    if (props?.selectedModalData?.petioleAnalysisStageId && petioleOptions) {
      setpetioleAnalysisStageId(
        petioleOptions?.filter(
          (petiole: any) =>
            props?.selectedModalData?.petioleAnalysisStageId === petiole.value
        )
      );
    }
    if (
      props?.selectedModalData?.soilTypeId &&
      isArrayWithLength(props?.selectedModalData?.soilTypeId)
    ) {
      setsoilTypeId(
        soilOptions?.filter((soil: any) =>
          props?.selectedModalData?.soilTypeId.includes(soil.value)
        )
      );
    }
    setNotes(
      [
        "foliarNote",
        "potassiumNitrateNote",
        "calciumNitrateNote",
        ...(props.selectedModalData?.notes || []),
      ]?.filter((item, index, array) => array.indexOf(item) === index)
    );
    setEnableCopy(true);
  }, [cropVarietyOptions, rootStockOptions, petioleOptions]);

  useEffect(() => {
    async function fetchData() {
      const response = await getSoilStandard(selectedRegion);
      if (response[0]?.id) {
        if (!!filteredKeys.length) {
          response[0]?.nutrients?.map((nutrient: any) => {
            if (content?.[nutrient?.element]) {
              content[nutrient?.element].soilStandard.minOptimal =
                nutrient?.min;
              content[nutrient?.element].soilStandard.maxOptimal =
                nutrient?.max;
              content[nutrient?.element].soilStandard.value = nutrient?.value;
              content[nutrient?.element].soilStandard.unit = nutrient?.unit;
            }
          });
        } else {
          let newcontent = content;
          response[0]?.nutrients?.map((nutrient: any) => {
            const obj = {
              foliarDoseRatio: {
                value: 0,
              },
              standardDose: {
                unit: "%",
                value: 0,
              },
              nutrientIndex: {
                formula: "1",
                value: 0,
              },
              fertigationDoseRatio: {
                value: 0,
              },
              soilStandard: {
                minOptimal: nutrient?.min,
                maxOptimal: nutrient?.max,
                value: nutrient?.value,
                unit: nutrient?.unit,
              },
              distribution: {
                D001: {
                  foliar: 0,
                  fertigation: 0,
                  soilApplication: 0,
                },
                D010: {
                  foliar: 0,
                  fertigation: 0,
                  soilApplication: 0,
                },
                D011: {
                  foliar: 0,
                  fertigation: 0,
                  soilApplication: 0,
                },
                D101: {
                  foliar: 0,
                  fertigation: 0,
                  soilApplication: 0,
                },
                D110: {
                  foliar: 0,
                  fertigation: 0,
                  soilApplication: 0,
                },
                D111: {
                  foliar: 0,
                  fertigation: 0,
                  soilApplication: 0,
                },
              },
              sourceIds: [],
            };
            newcontent = { [nutrient?.element]: obj, ...newcontent };
          });
          setContent(newcontent);
        }
        alert("Data Imported");
      } else {
        alert("No soil standard found for selected region.");
      }
    }
    if (!!selectedRegion?.length) {
      fetchData();
      setIsImporting(false);
    }
  }, [selectedRegion]);

  const navigate = useNavigate();

  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid
        p={2}
        borderRadius={20}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton
            onClick={() =>
              props?.setOpenModal
                ? props.setOpenModal(false)
                : navigate("/nutrient")
            }
          >
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Nutrient Configuration
          </Typography>
        </Grid>
        {content?.id && enableCopy && (
          <IconButton
            style={{ marginLeft: 20, marginRight: 25 }}
            onClick={() => {
              const updatedContent = { ...content };
              delete updatedContent.id;
              setContent(updatedContent);
              setOpenToast(true);
            }}
          >
            <ContentCopyIcon />
            <Typography marginLeft={0.8} fontSize={18}>
              Copy
            </Typography>
          </IconButton>
        )}
      </Grid>
      <ToastAlert
        open={openToast}
        setOpen={setOpenToast}
        severity={"info"}
        message={"Content Copied"}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 30,
        }}
      >
        <form>
          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Crop
          </label>
          <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
            *
          </label>
          <select
            onChange={(e) => {
              setcropId(e.target.value);
            }}
            style={{
              width: "97%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
            value={cropId}
          >
            {cropOptions?.map(({ value, label }: any, index: any) => (
              <option value={value}>{label}</option>
            ))}
          </select>
          <br />

          <h3 style={{ margin: 5 }}>Crop Variety</h3>
          <Select
            options={cropVarietyOptions}
            onChange={(selected: MultiValue<any>) => {
              setcropVarietyId(selected);
            }}
            isMulti
            value={cropVarietyId}
            {...DropdownProps}
          />

          <h3 style={{ margin: 5 }}>RootStock</h3>
          <Select
            options={rootStockOptions}
            onChange={(selected: MultiValue<any>) => {
              setrootStockId(selected);
            }}
            isMulti
            value={rootStockId}
            {...DropdownProps}
          />

          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Type
          </label>
          <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
            *
          </label>
          <Select
            options={petioleOptions}
            onChange={(selected: SingleValue<any>) => {
              setpetioleAnalysisStageId(selected);
            }}
            value={petioleAnalysisStageId}
            {...DropdownProps}
          />

          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Season
          </label>
          <input
            name="fertilizerName"
            onChange={(e) => setseasonId(e.target.value)}
            value={seasonId}
            type="text"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
          ></input>
          <br />

          <h3 style={{ margin: 5 }}>Soil Type</h3>
          <Select
            options={soilOptions}
            onChange={(selected: MultiValue<any>) => {
              setsoilTypeId(selected);
            }}
            value={soilTypeId}
            isMulti
            {...DropdownProps}
          />

          <LocaleDropdown
            value={notes}
            title="Notes"
            titleStyle={{ fontSize: 19, fontWeight: "bold", width: "15%" }}
            onChange={(selected: MultiValue<any>) => {
              setNotes(selected);
            }}
            isMulti={true}
            containerStyle={{ width: "99%" }}
          />

          <Grid style={{}}>
            <label
              style={{
                fontSize: 20,
                fontWeight: "500",
                margin: 8,
                marginRight: "70%",
                marginTop: 20,
              }}
            >
              Element
            </label>
            <Grid
              display={"flex"}
              justifyContent={"space-between"}
              style={{ marginBottom: 28, marginTop: 8 }}
            >
              <input
                type="text"
                value={NewEle}
                placeholder="Element Name"
                onChange={(e) => {
                  setNewEle(e.target.value);
                }}
                style={{
                  width: "60%",
                  padding: "8px",
                  fontSize: 18,
                  paddingTop: "10px",
                  marginTop: 12,
                }}
              ></input>
              <br />

              <input
                type="button"
                value="Add Element"
                onClick={addcontent}
                style={{
                  width: "15%",
                  padding: "8px",
                  fontSize: 18,
                  paddingTop: "10px",
                  alignSelf: "center",
                  marginTop: 7,
                }}
              ></input>
              <input
                type="button"
                value="Import Soil Standard"
                onClick={() => setIsImporting(true)}
                style={{
                  width: "20%",
                  padding: "8px",
                  fontSize: 18,
                  paddingTop: "10px",
                  alignSelf: "center",
                  marginTop: 7,
                }}
              ></input>

              <br />
            </Grid>
            <div style={{ display: "flex", width: 1200 }}>
              <Tabs value={tabIndex} variant="scrollable">
                {filteredKeys.map((key, index) => (
                  <Tab
                    key={`${key}-${index}`}
                    label={key}
                    onClick={() => handleChange(key, index)}
                  />
                ))}
              </Tabs>
            </div>
          </Grid>
          {openModal && selectedModalData && (
            <Grid
              style={{
                backgroundColor: "#fff",
                padding: 12,
                margin: 5,
              }}
              boxShadow={4}
              borderRadius={4}
            >
              <Grid key={selectedModalData}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <label
                    style={{ fontSize: 22, fontWeight: "bold", margin: "15px" }}
                  >
                    {selectedModalData}
                  </label>
                  <IconButton
                    onClick={() => deleteContentElement(selectedModalData)}
                  >
                    <DeleteIcon style={{ color: "grey" }} fontSize="small" />
                  </IconButton>
                </div>
                <Typography
                  style={{ marginTop: 12, marginBottom: 15, fontWeight: "500" }}
                >
                  Petiole Standard
                </Typography>
                <Grid>
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    <Grid
                      display={"flex"}
                      justifyContent={"space-around"}
                      width={"50%"}
                    >
                      <label style={{ marginTop: 10 }}>Value</label>
                      <input
                        value={
                          content?.[selectedModalData]?.petioleStandard?.value
                        }
                        onChange={(e) => {
                          changeElementValue(
                            selectedModalData,
                            "petioleStandard",
                            "value",
                            e.target.value
                          );
                        }}
                        defaultValue={0}
                        type="text"
                        style={{
                          width: "60%",
                          padding: "10px",
                          fontSize: 18,
                          paddingTop: "15px",
                          margin: "3px",
                          marginBottom: 11,
                        }}
                      ></input>
                    </Grid>
                    <Grid
                      display={"flex"}
                      justifyContent={"space-around"}
                      width={"50%"}
                    >
                      <label style={{ marginTop: 10 }}>Unit</label>
                      <select
                        onChange={(e) => {
                          changeElementValue(
                            selectedModalData,
                            "petioleStandard",
                            "unit",
                            e.target.value
                          );
                        }}
                        style={{
                          width: "62%",
                          padding: "10px",
                          fontSize: 18,
                          paddingTop: "15px",
                          margin: "3px",
                          marginBottom: 11,
                        }}
                        value={
                          content?.[selectedModalData]?.petioleStandard?.unit
                        }
                      >
                        {unitOptions.map(({ value, label }, index) => (
                          <option value={value}>{label}</option>
                        ))}
                      </select>
                    </Grid>
                  </Grid>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    marginLeft={-2}
                  >
                    <Grid
                      display={"flex"}
                      justifyContent={"space-around"}
                      width={"50%"}
                    >
                      <label style={{ marginTop: 10 }}>Min Optimal</label>
                      <input
                        value={
                          content?.[selectedModalData]?.petioleStandard
                            ?.minOptimal
                        }
                        onChange={(e) => {
                          changeElementValue(
                            selectedModalData,
                            "petioleStandard",
                            "minOptimal",
                            e.target.value
                          );
                        }}
                        type="number"
                        style={{
                          width: "60%",
                          padding: "10px",
                          fontSize: 18,
                          paddingTop: "15px",
                          margin: "3px",
                          marginBottom: 11,
                        }}
                      ></input>
                    </Grid>
                    <Grid
                      display={"flex"}
                      justifyContent={"space-around"}
                      width={"50%"}
                    >
                      <label style={{ marginTop: 10 }}>Max Optimal</label>
                      <input
                        value={
                          content?.[selectedModalData]?.petioleStandard
                            ?.maxOptimal
                        }
                        onChange={(e) => {
                          changeElementValue(
                            selectedModalData,
                            "petioleStandard",
                            "maxOptimal",
                            e.target.value
                          );
                        }}
                        type="number"
                        style={{
                          width: "60%",
                          padding: "10px",
                          fontSize: 18,
                          paddingTop: "15px",
                          margin: "3px",
                          marginBottom: 11,
                        }}
                      ></input>
                    </Grid>
                  </Grid>
                </Grid>
                <Typography
                  style={{ marginTop: 12, marginBottom: 15, fontWeight: "500" }}
                >
                  STCR Standard
                </Typography>
                <Grid>
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    <Grid
                      display={"flex"}
                      justifyContent={"space-around"}
                      width={"50%"}
                    >
                      <label style={{ marginTop: 10 }}>Constant</label>
                      <input
                        value={
                          content?.[selectedModalData]?.stcrStandard?.constant
                        }
                        onChange={(e) => {
                          changeElementValue(
                            selectedModalData,
                            "stcrStandard",
                            "constant",
                            e.target.value
                          );
                        }}
                        type="text"
                        style={{
                          width: "60%",
                          padding: "10px",
                          fontSize: 18,
                          paddingTop: "15px",
                          margin: "3px",
                          marginBottom: 11,
                        }}
                      ></input>
                    </Grid>
                    <Grid
                      display={"flex"}
                      justifyContent={"space-around"}
                      width={"50%"}
                    >
                      <label style={{ marginTop: 10 }}>Yield</label>
                      <input
                        value={
                          content?.[selectedModalData]?.stcrStandard?.yield
                        }
                        onChange={(e) => {
                          changeElementValue(
                            selectedModalData,
                            "stcrStandard",
                            "yield",
                            e.target.value
                          );
                        }}
                        type="text"
                        style={{
                          width: "60%",
                          padding: "10px",
                          fontSize: 18,
                          paddingTop: "15px",
                          margin: "3px",
                          marginBottom: 11,
                        }}
                      ></input>
                    </Grid>
                  </Grid>
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    <Grid
                      display={"flex"}
                      justifyContent={"space-around"}
                      width={"50%"}
                    >
                      <label style={{ marginTop: 10 }}>Standard</label>
                      <input
                        value={
                          content?.[selectedModalData]?.stcrStandard?.standard
                        }
                        onChange={(e) => {
                          changeElementValue(
                            selectedModalData,
                            "stcrStandard",
                            "standard",
                            e.target.value
                          );
                        }}
                        type="text"
                        style={{
                          width: "60%",
                          padding: "10px",
                          fontSize: 18,
                          paddingTop: "15px",
                          margin: "3px",
                          marginBottom: 11,
                        }}
                      ></input>
                    </Grid>
                    <Grid
                      display={"flex"}
                      justifyContent={"space-around"}
                      width={"50%"}
                    >
                      <label style={{ marginTop: 10 }}>Default</label>
                      <input
                        value={
                          content?.[selectedModalData]?.stcrStandard?.default
                        }
                        onChange={(e) => {
                          changeElementValue(
                            selectedModalData,
                            "stcrStandard",
                            "default",
                            e.target.value
                          );
                        }}
                        type="text"
                        style={{
                          width: "60%",
                          padding: "10px",
                          fontSize: 18,
                          paddingTop: "15px",
                          margin: "3px",
                          marginBottom: 11,
                        }}
                      ></input>
                    </Grid>
                  </Grid>
                </Grid>
                <Typography
                  style={{ marginTop: 12, marginBottom: 15, fontWeight: "500" }}
                >
                  Soil Standard
                </Typography>
                <Grid>
                  <Grid display={"flex"} justifyContent={"space-between"}>
                    <Grid
                      display={"flex"}
                      justifyContent={"space-around"}
                      width={"50%"}
                    >
                      <label style={{ marginTop: 10 }}>Value</label>
                      <input
                        value={
                          content?.[selectedModalData]?.soilStandard?.value
                        }
                        onChange={(e) => {
                          changeElementValue(
                            selectedModalData,
                            "soilStandard",
                            "value",
                            e.target.value
                          );
                        }}
                        defaultValue={0}
                        type="text"
                        style={{
                          width: "60%",
                          padding: "10px",
                          fontSize: 18,
                          paddingTop: "15px",
                          margin: "3px",
                          marginBottom: 11,
                        }}
                      ></input>
                    </Grid>
                    <Grid
                      display={"flex"}
                      justifyContent={"space-around"}
                      width={"50%"}
                    >
                      <label style={{ marginTop: 10 }}>Unit</label>
                      <select
                        value={content?.[selectedModalData]?.soilStandard?.unit}
                        onChange={(e) => {
                          changeElementValue(
                            selectedModalData,
                            "soilStandard",
                            "unit",
                            e.target.value
                          );
                        }}
                        style={{
                          width: "62%",
                          padding: "10px",
                          fontSize: 18,
                          paddingTop: "15px",
                          margin: "3px",
                          marginBottom: 11,
                        }}
                      >
                        {unitOptions.map(({ value, label }, index) => (
                          <option value={value}>{label}</option>
                        ))}
                      </select>
                    </Grid>
                  </Grid>

                  <Grid
                    display={"flex"}
                    justifyContent={"space-between"}
                    marginLeft={-2}
                  >
                    <Grid
                      display={"flex"}
                      justifyContent={"space-around"}
                      width={"50%"}
                    >
                      <label style={{ marginTop: 10 }}>Min Optimal</label>
                      <input
                        value={
                          content?.[selectedModalData]?.soilStandard?.minOptimal
                        }
                        onChange={(e) => {
                          changeElementValue(
                            selectedModalData,
                            "soilStandard",
                            "minOptimal",
                            e.target.value
                          );
                        }}
                        type="number"
                        style={{
                          width: "60%",
                          padding: "10px",
                          fontSize: 18,
                          paddingTop: "15px",
                          margin: "3px",
                          marginBottom: 11,
                        }}
                      ></input>
                    </Grid>
                    <Grid
                      display={"flex"}
                      justifyContent={"space-around"}
                      width={"50%"}
                    >
                      <label style={{ marginTop: 10 }}>Max Optimal</label>
                      <input
                        value={
                          content?.[selectedModalData]?.soilStandard?.maxOptimal
                        }
                        onChange={(e) => {
                          changeElementValue(
                            selectedModalData,
                            "soilStandard",
                            "maxOptimal",
                            e.target.value
                          );
                        }}
                        type="number"
                        style={{
                          width: "60%",
                          padding: "10px",
                          fontSize: 18,
                          paddingTop: "15px",
                          margin: "3px",
                          marginBottom: 11,
                        }}
                      ></input>
                    </Grid>
                  </Grid>
                </Grid>
                <Typography
                  style={{ marginTop: 12, marginBottom: 15, fontWeight: "500" }}
                >
                  Nutrient Index
                </Typography>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-around"}
                    width={"50%"}
                  >
                    <label style={{ marginTop: 10 }}>Formula</label>
                    <input
                      value={
                        content?.[selectedModalData]?.nutrientIndex?.formula
                      }
                      onChange={(e) => {
                        changeElementValue(
                          selectedModalData,
                          "nutrientIndex",
                          "formula",
                          e.target.value
                        );
                      }}
                      type="text"
                      style={{
                        width: "60%",
                        padding: "10px",
                        fontSize: 18,
                        paddingTop: "15px",
                        margin: "3px",
                        marginBottom: 11,
                      }}
                    ></input>
                  </Grid>
                </Grid>
                <Typography
                  style={{ marginTop: 12, marginBottom: 15, fontWeight: "500" }}
                >
                  Standard Dose
                </Typography>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-around"}
                    width={"50%"}
                  >
                    <label style={{ marginTop: 10 }}>Value</label>
                    <input
                      value={content?.[selectedModalData]?.standardDose?.value}
                      onChange={(e) => {
                        changeElementValue(
                          selectedModalData,
                          "standardDose",
                          "value",
                          e.target.value
                        );
                      }}
                      type="text"
                      style={{
                        width: "60%",
                        padding: "10px",
                        fontSize: 18,
                        paddingTop: "15px",
                        margin: "3px",
                        marginBottom: 11,
                      }}
                    ></input>
                  </Grid>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-around"}
                    width={"50%"}
                  >
                    <label style={{ marginTop: 10 }}>Unit</label>
                    <select
                      value={content?.[selectedModalData]?.standardDose?.unit}
                      onChange={(e) => {
                        changeElementValue(
                          selectedModalData,
                          "standardDose",
                          "unit",
                          e.target.value
                        );
                      }}
                      style={{
                        width: "62%",
                        padding: "10px",
                        fontSize: 18,
                        paddingTop: "15px",
                        margin: "3px",
                        marginBottom: 11,
                      }}
                    >
                      {unitOptions.map(({ value, label }, index) => (
                        <option value={value}>{label}</option>
                      ))}
                    </select>
                  </Grid>
                </Grid>
                  <Grid boxShadow={1} borderRadius={8} p={4} m={4}>
                    <Grid px={4}>
                      <Typography
                        style={{
                          fontWeight: "500",
                        }}
                      >
                        Fixed Soil Dose
                      </Typography>
                      <Grid display={"flex"} justifyContent={"space-between"}>
                        <Grid
                          display={"flex"}
                          justifyContent={"space-around"}
                          width={"50%"}
                        >
                          <label style={{ marginTop: 10 }}>Value</label>
                          <input
                            value={
                              content?.[selectedModalData]?.standardDose
                                ?.soilDose?.value
                            }
                            onChange={(e) => {
                              updateSoilDose(
                                selectedModalData,
                                "soilDose",
                                "value",
                                Number(e.target.value)
                              );
                            }}
                            min={0}
                            type="number"
                            style={{
                              width: "60%",
                              padding: "10px",
                              fontSize: 18,
                              paddingTop: "15px",
                              margin: "3px",
                              marginBottom: 11,
                            }}
                          ></input>
                        </Grid>
                        <Grid
                          display={"flex"}
                          justifyContent={"space-around"}
                          width={"50%"}
                        >
                          <label style={{ marginTop: 10 }}>Unit</label>
                          <select
                            value={
                              content?.[selectedModalData]?.standardDose
                                ?.soilDose?.unit
                            }
                            onChange={(e) => {
                              updateSoilDose(
                                selectedModalData,
                                "soilDose",
                                "unit",
                                e.target.value
                              );
                            }}
                            style={{
                              width: "62%",
                              padding: "10px",
                              fontSize: 18,
                              paddingTop: "15px",
                              margin: "3px",
                              marginBottom: 11,
                            }}
                          >
                            {unitOptions.map(({ value, label }, index) => (
                              <option value={value}>{label}</option>
                            ))}
                          </select>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-evenly"}
                      borderRadius={4}
                      padding={5}
                    >
                      <PlantAge
                        title={"Nutrient Wise"}
                        type={"nutrientWise"}
                        addPlantAgeData={addPlantAgeData}
                        deletePlantAgeData={deletePlantAgeData}
                        content={content}
                        selectedModalData={selectedModalData}
                        updatePlantData={updatePlantData}
                      />
                      <PlantAge
                        title={"Year Wise"}
                        type={"yearWise"}
                        addPlantAgeData={addPlantAgeData}
                        deletePlantAgeData={deletePlantAgeData}
                        content={content}
                        selectedModalData={selectedModalData}
                        updatePlantData={updatePlantData}
                      />
                    </Grid>
                  </Grid>
                
                <Typography
                  style={{ marginTop: 12, marginBottom: 15, fontWeight: "500" }}
                >
                  Foliar and Fertigation Dose Ratio
                </Typography>
                <Grid display={"flex"} justifyContent={"space-between"}>
                  <Grid
                    display={"flex"}
                    justifyContent={"space-around"}
                    width={"50%"}
                  >
                    <label style={{ marginTop: 10 }}>Foliar</label>
                    <input
                      value={
                        content?.[selectedModalData]?.foliarDoseRatio?.value
                      }
                      onChange={(e) => {
                        changeElementValue(
                          selectedModalData,
                          "foliarDoseRatio",
                          "value",
                          e.target.value
                        );
                      }}
                      type="text"
                      style={{
                        width: "60%",
                        padding: "10px",
                        fontSize: 18,
                        paddingTop: "15px",
                        margin: "3px",
                        marginBottom: 11,
                      }}
                    ></input>
                  </Grid>

                  <Grid
                    display={"flex"}
                    justifyContent={"space-around"}
                    width={"50%"}
                  >
                    <label style={{ marginTop: 10 }}>Fertigation</label>
                    <input
                      value={
                        content?.[selectedModalData]?.fertigationDoseRatio
                          ?.value
                      }
                      onChange={(e) => {
                        changeElementValue(
                          selectedModalData,
                          "fertigationDoseRatio",
                          "value",
                          e.target.value
                        );
                      }}
                      type="text"
                      style={{
                        width: "60%",
                        padding: "10px",
                        fontSize: 18,
                        paddingTop: "15px",
                        margin: "3px",
                        marginBottom: 11,
                      }}
                    ></input>
                  </Grid>
                </Grid>

                <Grid
                  display={"flex"}
                  justifyContent={"space-between"}
                  marginBottom={5}
                  marginTop={5}
                >
                  <Typography
                    style={{
                      marginTop: 12,
                      marginBottom: 15,
                      fontWeight: "500",
                    }}
                  >
                    Distribution
                  </Typography>
                  <Grid display={"flex"}>
                    <input
                      type="text"
                      value={newDistribution}
                      maxLength={4}
                      placeholder="Distribution Name"
                      onChange={(e) => {
                        setnewDistribution(e.target.value);
                      }}
                      style={{
                        width: "40%",
                        marginRight: 17,
                        padding: "8px",
                        fontSize: 18,
                        paddingTop: "10px",
                        marginTop: 7,
                      }}
                    ></input>
                    <br />

                    <input
                      type="button"
                      value="Add Distribution"
                      onClick={() => addDistribution(selectedModalData)}
                      style={{
                        width: "40%",
                        padding: "8px",
                        fontSize: 18,
                        paddingTop: "10px",
                        alignSelf: "center",
                        marginTop: 7,
                      }}
                    ></input>
                    <br />
                  </Grid>
                </Grid>
                <>
                  {content?.[selectedModalData]?.distribution &&
                    Object.keys(
                      content?.[selectedModalData]?.distribution
                    )?.map((key: any) => {
                      const foliarDistribution =
                        content?.[selectedModalData]?.distribution?.[key]
                          ?.foliar ?? null;
                      const fertigationDistribution =
                        content?.[selectedModalData]?.distribution?.[key]
                          ?.fertigation ?? null;
                      const soilApplicationDistribution =
                        content?.[selectedModalData]?.distribution?.[key]
                          ?.soilApplication ?? null;

                      return (
                        <Grid key={key + selectedModalData}>
                          <Typography>{key}</Typography>
                          <Grid display={"flex"}>
                            <Grid
                              display={"flex"}
                              justifyContent={"space-around"}
                              width={"30%"}
                            >
                              <label style={{ marginTop: 10 }}>Foliar</label>
                              <input
                                onChange={(e) => {
                                  changeDistributionVal(
                                    selectedModalData,
                                    key,
                                    "foliar",
                                    e.target.value
                                  );
                                }}
                                min={0}
                                value={foliarDistribution}
                                type="number"
                                style={{
                                  width: "20%",
                                  padding: "10px",
                                  fontSize: 18,
                                  paddingTop: "15px",
                                  margin: "3px",
                                  marginBottom: 11,
                                }}
                              ></input>
                            </Grid>
                            <Grid
                              display={"flex"}
                              justifyContent={"space-around"}
                              width={"30%"}
                            >
                              <label style={{ marginTop: 10 }}>
                                Fertigation
                              </label>
                              <input
                                onChange={(e) => {
                                  changeDistributionVal(
                                    selectedModalData,
                                    key,
                                    "fertigation",
                                    e.target.value
                                  );
                                }}
                                min={0}
                                value={fertigationDistribution}
                                type="number"
                                style={{
                                  width: "20%",
                                  padding: "10px",
                                  fontSize: 18,
                                  paddingTop: "15px",
                                  margin: "3px",
                                  marginBottom: 11,
                                }}
                              ></input>
                            </Grid>
                            <Grid
                              display={"flex"}
                              justifyContent={"space-around"}
                              width={"30%"}
                            >
                              <label style={{ marginTop: 10 }}>
                                Soil Application
                              </label>
                              <input
                                onChange={(e) => {
                                  changeDistributionVal(
                                    selectedModalData,
                                    key,
                                    "soilApplication",
                                    e.target.value
                                  );
                                }}
                                min={0}
                                value={soilApplicationDistribution}
                                type="number"
                                style={{
                                  width: "20%",
                                  padding: "10px",
                                  fontSize: 18,
                                  paddingTop: "15px",
                                  margin: "3px",
                                  marginBottom: 11,
                                }}
                              ></input>
                            </Grid>
                            <IconButton
                              onClick={() => {
                                deleteDistElement(selectedModalData, key);
                              }}
                            >
                              <CancelIcon color="error" style={{}} />
                            </IconButton>
                            <br />
                          </Grid>
                        </Grid>
                      );
                    })}
                </>

                <Typography
                  style={{ marginTop: 12, marginBottom: 15, fontWeight: "500" }}
                >
                  Fertigation Sources
                </Typography>
                <Select
                  options={chemicalOptions}
                  onChange={(selected: MultiValue<any>) => {
                    changeSourceValue(
                      selectedModalData,
                      selected?.map((item) => item.value).join(",")
                    );
                  }}
                  isMulti
                  value={chemicalOptions?.filter((chem: any) =>
                    content?.[selectedModalData]?.sourceIds?.includes(
                      chem?.value
                    )
                  )}
                  {...DropdownProps}
                />
              </Grid>
            </Grid>
          )}
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            style={{ marginTop: 55 }}
          >
            <input
              type="button"
              value="Submit"
              onClick={onSubmit}
              style={{
                width: "45%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
              }}
            />
            <input
              type="button"
              value="Delete"
              onClick={onDelete}
              style={{
                width: "45%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
              }}
            />
          </Grid>
        </form>
      </div>

      <Modal
        open={isImporting}
        onClose={() => setIsImporting(false)}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          overflow: "auto",
        }}
      >
        <Grid
          container
          my={1}
          boxShadow={2}
          borderRadius={4}
          padding={2}
          sx={{
            width: "20%",
            maxWidth: "600px",
            backgroundColor: "#fff",
          }}
        >
          <LocationFilter
            dropdowns={["state"]}
            onChange={(e) => setSelectedRegion(e?.state)}
            defaultBackgroundColor="white"
            hideTitle
          />
        </Grid>
      </Modal>
    </Grid>
  );
};

export default NutrientDetails;
