import { Grid, IconButton, Typography, Button } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { useNavigate } from "react-router";
import Select, { SingleValue } from "react-select";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import {
  getSoilStandard,
  patchSoilStandard,
  postSoilStandard,
  deleteSoilStandard,
} from "src/services/soilStandard.service";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { ToastAlert } from "src/components";
import { LocationFilter } from "src/components/Filters";

const SoilStandard: React.FC = (): JSX.Element => {
  const [isError, setisError] = useState(false);
  const [isCopy, setisCopy] = useState(false);
  const [soilData, setSoilData] = useState<any>(null);
  const [selectedRegion, setSelectedRegion] = useState<any>(null);
  const [openToast, setOpenToast] = useState<boolean>(false);

  const navigate = useNavigate();

  const defaultNutrients: any[] = [
    {
      element: "N",
      value: 0,
      unit: "ppm",
    },
    {
      element: "P",
      value: 0,
      unit: "ppm",
    },
    {
      element: "K",
      value: 0,
      unit: "ppm",
    },
    {
      element: "Ca",
      value: 0,
      unit: "ppm",
    },
    {
      element: "Mg",
      value: 0,
      unit: "ppm",
    },
    {
      element: "S",
      value: 0,
      unit: "ppm",
    },
    {
      element: "Fe",
      value: 0,
      unit: "ppm",
    },
    {
      element: "Mn",
      value: 0,
      unit: "ppm",
    },
    {
      element: "B",
      value: 0,
      unit: "ppm",
    },
    {
      element: "Zn",
      value: 0,
      unit: "ppm",
    },
    {
      element: "Cu",
      value: 0,
      unit: "ppm",
    },
  ];

  const onReset = () => {
    setisError(false);
    setSoilData(null);
  };

  const onSubmit = async () => {
    setisError(false);

    if (selectedRegion.length === 0) {
      alert("Select a state");
      return;
    }

    try {
      let res = await getSoilStandard(selectedRegion);

      if (res[0]?.id) {
        setSoilData(res[0]);
      } else {
        setisError(true);
        setSoilData(null);
      }
    } catch (err) {
      console.log(err);
      setisError(true);
      setSoilData(null);
    }
  };
  const unitOptions = [
    { value: "%", label: "%" },
    { value: "ppm", label: "ppm" },
    { value: "ml/lit", label: "ml/lit" },
    { value: "kg/ha", label: "kg/ha" },
    { value: "kg/acre", label: "kg/acre" },
    { value: "kg/plant", label: "kg/plant" },
    { value: 'lit/acre', label: 'lit/acre' },
  ];
  useEffect(() => {
    if (!isCopy) {
      if (selectedRegion?.length) onSubmit();
      else {
        onReset();
      }
    }
  }, [selectedRegion]);

  const finalSubmit = () => {
    if (window.confirm("Do you want to submit?")) {
      setisCopy(false);
      const filteredNutrient: any = soilData?.nutrients?.filter(
        (nutrient: any) => nutrient.value > 0
      );
      if (soilData?.id) {
        patchSoilStandard(
          { region: selectedRegion, nutrients: filteredNutrient },
          soilData?.id
        ).then((res: any) => {
          if (res.message === "Success") {
            alert("Item updated");
            onSubmit();
          } else alert("Some Error Ocuured");
        });
      } else {
        postSoilStandard({
          region: selectedRegion,
          nutrients: filteredNutrient,
        }).then((res: any) => {
          if (res?.id) {
            alert("Item added");
            onSubmit();
          } else alert("Some Error Ocuured");
        });
      }
    }
  };

  const onDelete = async () => {
    if (soilData?.id) {
      if (window.confirm("Do you want to delete?")) {
        deleteSoilStandard(soilData.id).then((res: any) => {
          if (res.message === "Success") {
            alert("Item deleted");
            onSubmit();
          } else alert("Some Error Ocuured");
        });
      }
    } else alert("Some Error Occured");
  };

  const addData = async (type: string, value: any, index: number) => {
    let nutrients: any = soilData?.nutrients;
    nutrients[index][type] = value;
    setSoilData({ ...soilData, nutrients: nutrients });
  };

  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Grid
        p={2}
        borderRadius={20}
        display={"flex"}
        justifyContent={"space-between"}
        marginBottom={3}
        width="100%"
      >
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton onClick={() => navigate("/")}>
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Soil Standard
          </Typography>
        </Grid>
        {soilData?.id && (
          <IconButton
            style={{ marginRight: 25 }}
            onClick={() => {
              setisCopy(true);
              delete soilData.id;
              setOpenToast(true);
            }}
          >
            <ContentCopyIcon />
            <Typography marginLeft={0.8} fontSize={18}>
              Copy
            </Typography>
          </IconButton>
        )}
      </Grid>
      <ToastAlert
        open={openToast}
        setOpen={setOpenToast}
        severity={"info"}
        message={"Content Copied"}
      />
      <Grid
        width="95%"
        mb={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <div style={{ padding: "5px" }}>
          <LocationFilter
            dropdowns={["state"]}
            onChange={(e) => setSelectedRegion(e?.state)}
            defaultBackgroundColor="white"
            hideTitle
          />
        </div>
        <Grid>
          {!!soilData?.nutrients?.length && (
            <Grid display={"flex"} flexDirection={"row"} gap={3}>
              <input
                type="button"
                value="Submit"
                onClick={finalSubmit}
                style={{
                  width: "70%",
                  flex: 1,
                  padding: "10px",
                  fontSize: 18,
                  backgroundColor: "#4caf50",
                  color: "#fff",
                  border: "none",
                  borderRadius: 5,
                  cursor: "pointer",
                }}
              />
              {soilData?.id && (
                <input
                  type="button"
                  value="Delete"
                  onClick={onDelete}
                  style={{
                    width: "70%",
                    padding: "10px",
                    flex: 1,
                    fontSize: 18,
                    backgroundColor: "#f44336",
                    color: "#fff",
                    border: "none",
                    borderRadius: 5,
                    cursor: "pointer",
                  }}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Grid>

      {!isError && !!selectedRegion && (
        <Grid
          width={"95%"}
          display="flex"
          justifyContent="space-between"
          mb={2}
          alignItems={"center"}
        >
          <Typography variant="h5">Nutrients</Typography>
          <IconButton
            onClick={() =>
              setSoilData({
                ...soilData,
                nutrients: [
                  {
                    element: "",
                    value: 0,
                    unit: "ppm",
                    min: "",
                    max: "",
                  },
                  ...soilData.nutrients,
                ],
              })
            }
          >
            {<AddCircleOutlineIcon />}
          </IconButton>
        </Grid>
      )}

      {isError && (
        <Button
          color="info"
          variant="contained"
          onClick={() => {
            setSoilData({
              ...soilData,
              nutrients: defaultNutrients,
            });
            setisError(false);
          }}
          style={{ margin: "20px 0" }}
        >
          Add Data
        </Button>
      )}
      {!isError && !!selectedRegion && (
        <Grid>
          {soilData?.nutrients?.map((nutrient: any, index: number) => (
            <Grid
              my={2}
              boxShadow={2}
              borderRadius={3}
              px={2}
              pb={2}
              pt={2}
              mx={4}
              display={"flex"}
              flexDirection={"row"}
            >
              <Grid
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Typography style={{ fontWeight: "bold" }} width={80}>
                  Element
                </Typography>
                <input
                  value={nutrient?.element}
                  onChange={(e) => addData("element", e.target.value, index)}
                  type={"text"}
                  min={0}
                  style={{
                    flexGrow: 1,
                    padding: "8px 20px",
                    width: "10%",
                    fontSize: 16,
                    border: "1px solid",
                    borderRadius: 5,
                  }}
                />
              </Grid>

              <Grid
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                margin={3}
                alignItems={"center"}
              >
                <Typography style={{ fontWeight: "bold" }} width={58}>
                  Value
                </Typography>
                <input
                  value={nutrient?.value}
                  onChange={(e) =>
                    addData("value", Number(e.target.value), index)
                  }
                  type={"number"}
                  min={0}
                  style={{
                    flexGrow: 1,
                    padding: "8px 20px",
                    width: "10%",
                    fontSize: 16,
                    border: "1px solid",
                    borderRadius: 5,
                  }}
                />
              </Grid>
              <Grid
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                margin={3}
                alignItems={"center"}
              >
                <Typography style={{ fontWeight: "bold" }} width={58}>
                  Min
                </Typography>
                <input
                  value={nutrient?.min}
                  onChange={(e) =>
                    addData("min", Number(e.target.value), index)
                  }
                  type={"number"}
                  min={0}
                  style={{
                    flexGrow: 1,
                    padding: "8px 20px",
                    width: "10%",
                    fontSize: 16,
                    border: "1px solid",
                    borderRadius: 5,
                  }}
                />
              </Grid>
              <Grid
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                margin={3}
                alignItems={"center"}
              >
                <Typography style={{ fontWeight: "bold" }} width={58}>
                  Max
                </Typography>
                <input
                  value={nutrient?.max}
                  onChange={(e) =>
                    addData("max", Number(e.target.value), index)
                  }
                  type={"number"}
                  min={0}
                  style={{
                    flexGrow: 1,
                    padding: "8px 20px",
                    width: "10%",
                    fontSize: 16,
                    border: "1px solid",
                    borderRadius: 5,
                  }}
                />
              </Grid>
              <Grid
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                margin={3}
                alignItems={"center"}
              >
                <Typography style={{ fontWeight: "bold" }} width={58}>
                  Unit
                </Typography>
                <Select
                  options={unitOptions}
                  onChange={(selected: SingleValue<any>) =>
                    addData("unit", selected.value, index)
                  }
                  value={unitOptions?.filter(
                    (unit: any) => nutrient?.unit === unit?.value
                  )}
                  isClearable={true}
                  isSearchable={true}
                  placeholder={"- select -"}
                  styles={{
                    container: (baseStyles: any) => ({
                      ...baseStyles,
                      width: 200,
                      padding: 2,
                      fontSize: 16,
                    }),
                  }}
                />
              </Grid>
              <IconButton
                size="small"
                onClick={() => {
                  const updatedNutrients = soilData.nutrients.filter(
                    (_: any, i: number) => i !== index
                  );
                  setSoilData({ ...soilData, nutrients: updatedNutrients });
                }}
                style={{ top: -45, right: -15 }}
              >
                <DeleteIcon style={{ color: "grey" }} fontSize="small" />
              </IconButton>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
  );
};

export default SoilStandard;
