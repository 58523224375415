import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams, useNavigate, useLocation } from "react-router";
import { Grid, Typography, IconButton, Skeleton, Button } from "@mui/material";
import {
  ArrowBack,
  ChangeHistoryTwoTone,
  KeyboardArrowLeftTwoTone,
  KeyboardArrowRightTwoTone,
} from "@mui/icons-material";
import { appNotifsColDef } from "../constants/columnDefs";
import { getPlot, getPlots } from "../services/plot.service";
import { appNotif, appNotifCount } from "../services/user.service";
import { getDate, isArrayWithLength, print } from "../utils/helper";
import {
  Notes,
  OtherPlots,
  CropStages,
  AddNotesModal,
  ColorSwitch,
  NewMaintenanceForm,
} from "../components";
import { getQualityStyle } from "../utils/GridDataRenderer/QualityRenderer";
import { AgGridReact } from "ag-grid-react";
import {
  GridReadyEvent,
  GridOptions,
  FilterChangedEvent,
  SortChangedEvent,
} from "ag-grid-community";
import { Roles } from "src/constants/interfaces";
import { getNewCropId } from "src/services/crop.service";
import { SoilMapping } from "src/constants/WordMapping";

interface Props {
  cellPlotId?: string;
  setShowGrid?: any;
  setShowPlot?: any;
  setShowPlotData?: any;
  setShowFarm?: any;
  doNotContact?: Boolean;
}

interface DisplayProps {
  property: string;
  value: string | ReactNode;
  linkTo?: string;
  action?: any;
  width?: number | string;
  textTransform?: "uppercase" | "capitalize" | "none";
}
const user = window.localStorage.getItem("userId");

export const DisplayProperty: React.FC<DisplayProps> = ({
  property,
  value,
  linkTo,
  action,
  width = 300,
  textTransform = "none",
}) => (
  <Grid data-testid="sample" display={"flex"} my={1}>
    <Typography sx={{ fontWeight: "600" }} width={width}>
      {property}:
    </Typography>
    <Grid container justifyContent={"space-between"}>
      <Typography textTransform={textTransform}>
        {typeof value === "string" ? print(value) : value}
      </Typography>
      <Typography
        onClick={action}
        style={{ cursor: "pointer" }}
        borderBottom={1}
        color={"green"}
      >
        {linkTo}
      </Typography>
    </Grid>
  </Grid>
);

const PlotDetailsScreen: React.FC<Props> = (props: Props): JSX.Element => {
  const { cellPlotId, setShowGrid, setShowPlot, setShowPlotData, setShowFarm } =
    props;
  const userRole = window.localStorage.roles;
  let isAdmin = false;
  if (userRole.length > 0 && userRole[0] != "") {
    let whoAmI = JSON.parse(userRole);
    whoAmI.map((role: string) => {
      if (role == Roles.ADMIN || role == Roles.SUPPORT) isAdmin = true;
    });
  }

  let { plotId } = useParams();
  if (cellPlotId !== undefined) {
    plotId = cellPlotId;
  }
  const navigate = useNavigate();
  const { state } = useLocation();
  // const [dailyReport, setDailyReport] = useState<any>()
  const [isExporter, setIsExporter] = useState<boolean>(false);
  const [isPlotDetailsReady, setIsPlotDetailsReady] = useState<boolean>(false);
  const [areOtherPlotsReady, setAreOtherPlotsReady] = useState<boolean>(false);

  const [plotDetails, setPlotDetails] = useState<any>();
  const [plotQuantityPredictions, setPlotQuantityPredictions] = useState<any>();
  const [otherPlots, setOtherPlots] = useState<any[]>([]);

  const [showOtherPlots, setShowOtherPlots] = useState<boolean>(false);
  const [addNoteModalOpen, setAddNoteModalOpen] = useState<boolean>(false);
  const [hasAddedNewNote, setHasAddedNewNote] = useState<boolean>(false);
  const [appNotifData, setAppNotifData] = useState<any[]>([]);
  const [totalInfoCount, settotalInfoCount] = useState<number>(0);
  const [skip, setSkip] = useState<number>(0);

  // new maintenance form
  const [formState, setFormState] = useState({
    details: "",
    plotId: "",
    id: "",
    farmId: "",
    farmUserId: "",
    servicedBy: "",
    done: false,
    date: "",
    tasks: [
      {
        maintenanceType: "",
        sensor: "",
      },
    ],
  });
  const [open, setOpen] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [tasks, setTasks] = React.useState([1]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    if (edit == true) {
      setEdit(false);
    }
    setOpen(false);
  };

  const gridRef = useRef<AgGridReact>(null);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  useEffect(() => {
    if (!!state && (state as any).pathname === "/exporter") {
      setIsExporter(true);
    }
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    gridRef?.current?.api?.sizeColumnsToFit();
    // gridRef?.current?.api.setColumnDefs(appNotifsColDef);
    // gridRef?.current?.api.setRowData(appNotifData);
  }, []);

  useEffect(() => {
    getPlot(plotId as string).then((res) => {
      setPlotDetails(res);
      setIsPlotDetailsReady(true);
    });

    appNotif(plotId as string, skip).then((res: any) => {
      setAppNotifData(res);
    });

    appNotifCount(plotId as string).then((res: any) => {
      let cnt = Math.round(res.count / 5);

      if (res.count % 5 !== 0) cnt += 1;
      settotalInfoCount(cnt);
    });
  }, [plotId]);

  useEffect(() => {
    if (plotDetails) {
      if (plotDetails?.farmUser?.farmUserId) {
        getPlots(plotDetails?.farmUser?.farmUserId, ["cropsSown"], true).then(
          (res: any[]) => {
            const tempOtherPlots = res?.filter(
              (plot) => plot.plotId !== plotId
            );
            setOtherPlots(tempOtherPlots);
            setAreOtherPlotsReady(true);
          }
        );
      }

      if ("plotQuantityPredictions" in plotDetails) {
        const quantityPredictionsCount =
          plotDetails.plotQuantityPredictions.length;
        // get last 5 predictions
        let lastPredictionsCount = Math.max(-quantityPredictionsCount, -5);
        let lastPredictions =
          plotDetails.plotQuantityPredictions.splice(lastPredictionsCount);
        // reverse them to sort in descending order
        lastPredictions.reverse();
        setPlotQuantityPredictions(lastPredictions);
      }
    }
  }, [plotDetails]);

  const gridOptions: GridOptions = {
    columnDefs: appNotifsColDef,
    rowData: appNotifData,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
  };

  const onPaginationChanged = useCallback(() => {}, []);

  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
  };

  const handleColumnFilter = (event: FilterChangedEvent) => {
    if (event.afterFloatingFilter) {
    }
  };

  const QualityRenderer = () => {
    const { color, quality, hasIncreased } = getQualityStyle(
      plotDetails.plotQualityPredictions
    );
    return (
      <span style={{ display: "flex", alignItems: "center" }}>
        <i style={{ color: `${color}`, fontSize: `10px`, marginRight: `5px` }}>
          {hasIncreased ? (
            <ChangeHistoryTwoTone />
          ) : (
            <ChangeHistoryTwoTone style={{ transform: "rotate(3.142rad)" }} />
          )}
        </i>
        <span style={{ fontSize: `17px` }}> {quality}%</span>
      </span>
    );
  };

  const handleNoteModal = (state: boolean) => {
    setAddNoteModalOpen(state);
  };

  const appendNote = (newData: any) => {
    setHasAddedNewNote(true);
    setPlotQuantityPredictions((prevData: any[]) => [newData, ...prevData]);
  };

  const goBack = () => {
    if (cellPlotId !== undefined) {
      setShowGrid(true);
      setShowPlot(false);
      setShowPlotData(false);
      setShowFarm(false);
    } else {
      navigate(isExporter ? "/exporter" : "/home", {
        state: { ...(state as any), addedNewNote: hasAddedNewNote },
      });
    }
  };

  const onBtNext = () => {
    appNotif(plotId as string, skip + 5).then((res: any) => {
      setAppNotifData(res);
      setSkip(skip + 5);
    });
  };

  const onBtPrevious = () => {
    appNotif(plotId as string, skip - 5).then((res: any) => {
      setAppNotifData(res);
      setSkip(skip - 5);
    });
  };

  const PaginationIconProps = {
    fontSize: "small",
    color: "action",
  };

  const showPlotDataPage = () => {
    setShowGrid(false);
    setShowPlot(false);
    setShowPlotData(true);
    setShowFarm(false);
  };
  const showFarmActivityPage = () => {
    setShowGrid(false);
    setShowPlot(false);
    setShowPlotData(false);
    setShowFarm(true);
  };
  const [urlPlotId, seturlPlotId] = useState<any>("");
  const [urlFarmUserId, seturlFarmUserId] = useState<any>("");
  const [urlFarmId, seturlFarmId] = useState<any>("");
  const [urlData, setUrlData] = useState<any>(true);
  const [farmuserName, setFarmUsername] = useState<any>("");
  const [doNotContact, setDoNotContact] = useState<any>(Boolean);
  const setData: any = () => {
    // console.log("plotDetails",plotDetails)
    setDoNotContact(plotDetails?.farmUser?.doNotContact);
    setFarmUsername(plotDetails?.farmUser?.name);
    seturlPlotId(plotDetails?.plotId);
    seturlFarmUserId(plotDetails?.farmUserId);
    seturlFarmId(plotDetails?.farmId);
    setUrlData(false);

    // by default farmuserid, farmid, plotid will be there in maintenance form
    setFormState({
      details: "",
      plotId: plotDetails?.plotId,
      id: "",
      farmId: plotDetails?.farmId,
      farmUserId: plotDetails?.farmUserId,
      servicedBy: "",
      done: false,
      date: "",
      tasks: [
        {
          maintenanceType: "",
          sensor: "",
        },
      ],
    });
  };

  {
    isPlotDetailsReady && urlData && setData();
  }

  //new conversation window
  const openTheWidnow = () => {
    const data = [urlFarmId, user, urlPlotId, urlFarmUserId]; // url params as array
    const windowFeatures = "left=650,top=25,width=650,height=650";

    // finding base URL
    let splitURL = window.location.href.split("://");
    const baseURL = splitURL[1].split("/")[0];
    window.open(
      `${splitURL[0]}://${baseURL}/conversations?data=${data}`,
      " ",
      windowFeatures
    );
  };

  const redirectToObservationScreen = async () => {
    let cropId = plotDetails?.cropSown?.cropId;
    if (!Number.isNaN(cropId)) {
      const response = await getNewCropId(cropId);
      if (!response || response.length !== 1) {
        return;
      }

      cropId = response[0].newId;
    }

    navigate("/observation-insight", { state: { plotId, cropId: cropId } });
  };

  const roles = JSON.parse(localStorage.getItem("roles")!);
  let allowedRoles = [Roles.ADMIN.toString(), Roles.SUPPORT.toString()];
  let isInternalUser = roles?.find((role: string) =>
    allowedRoles?.includes(role)
  );

  return (
    <Grid width={"90%"} my={2} mx={"auto"} boxShadow={2} borderRadius={4}>
      <Grid container display={"flex"} p={2} borderRadius={20}>
        <Grid item xs={isExporter ? 9 : 12} pr={isExporter ? 2 : 0}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid display={"flex"} item alignItems={"center"}>
              <IconButton onClick={goBack}>
                <ArrowBack />
              </IconButton>
              <Typography m={1} variant="h5">
                Plot Details
              </Typography>
            </Grid>
            <Typography variant="h6" border={1} borderRadius={10} px={2}>
              ggn number
            </Typography>
          </Grid>
          {isPlotDetailsReady ? (
            <Grid>
              <Grid item px={2} py={1}>
                <DisplayProperty
                  property={"Plot Name"}
                  value={plotDetails?.name}
                  textTransform={"uppercase"}
                  linkTo={isInternalUser ? "Observation Insights" : ""}
                  action={redirectToObservationScreen}
                />

                <DisplayProperty
                  property={"Farmer Name"}
                  value={plotDetails?.farmUser?.name}
                  linkTo={"View Other Plots"}
                  action={() => setShowOtherPlots(true)}
                />
                {isAdmin && (
                  <DisplayProperty
                    property={"Do Not Contact"}
                    value={
                      <ColorSwitch
                        doNotContact={doNotContact}
                        farmuserId={plotDetails?.farmUser?.farmUserId}
                        name={farmuserName}
                      />
                    }
                    textTransform={"uppercase"}
                  />
                )}
                <DisplayProperty
                  property={"Farmer Mobile No"}
                  value={plotDetails?.farmUser?.mobile}
                  linkTo={"View Plot Data"}
                  action={
                    cellPlotId !== undefined
                      ? showPlotDataPage
                      : () => navigate("/plot-data/" + plotId)
                  }
                />
                <DisplayProperty
                  property={"Crop"}
                  value={plotDetails?.cropSown?.name}
                  linkTo={"View Farm Activity"}
                  action={
                    cellPlotId !== undefined && plotDetails?.cropSown?.cropId
                      ? showFarmActivityPage
                      : () =>
                          navigate(
                            "/farm-activity/" +
                              plotId +
                              "/" +
                              plotDetails?.cropSown?.cropId
                          )
                  }
                />
                <DisplayProperty
                  property={"Variety"}
                  value={plotDetails?.cropSown?.varietyId}
                />

                <DisplayProperty
                  property={"Soil"}
                  value={SoilMapping[plotDetails?.soilTypeId]}
                />
                <DisplayProperty
                  property={"Installation Date"}
                  value={getDate(plotDetails?.created_date)}
                />
                {isAdmin ? (
                  <DisplayProperty
                    property={"Expected Quantity"}
                    value={
                      isArrayWithLength(plotQuantityPredictions) &&
                      print(plotQuantityPredictions[0]?.value.toFixed(2)) +
                        " tonnes/acre"
                    }
                    data-testid="view-conversations"
                    linkTo={"View Conversations"}
                    action={() => openTheWidnow()}
                  />
                ) : (
                  <DisplayProperty
                    property={"Expected Quantity"}
                    value={
                      isArrayWithLength(plotQuantityPredictions) &&
                      print(plotQuantityPredictions[0]?.value.toFixed(2)) +
                        " tonnes/acre"
                    }
                  />
                )}

                <DisplayProperty
                  property={"Expected Harvesting Period"}
                  value={`${getDate(
                    plotDetails?.expectedHarvestStart,
                    "DD/MM/YYYY"
                  )}  - ${getDate(
                    plotDetails?.expectedHarvestEnd,
                    "DD/MM/YYYY"
                  )} `}
                />
                {isAdmin ? (
                  <DisplayProperty
                    property={"Quality Index"}
                    value={<QualityRenderer />}
                    linkTo={"Create Maintenance"}
                    action={() => setOpen(true)}
                  />
                ) : (
                  <DisplayProperty
                    property={"Quality Index"}
                    value={<QualityRenderer />}
                  />
                )}

                {plotDetails?.cropSown && (
                  <CropStages cropsSown={plotDetails?.cropSown} />
                )}
              </Grid>

              <div style={gridStyle} className="ag-theme-alpine">
                <Grid p={3}>
                  <Typography m={1} variant="h5">
                    Notifications
                  </Typography>
                  <AgGridReact
                    ref={gridRef}
                    rowData={appNotifData}
                    columnDefs={appNotifsColDef}
                    onGridReady={onGridReady}
                    //defaultColDef={defaultColDef}
                    //sideBar={sideBar}
                    gridOptions={gridOptions}
                    domLayout="autoHeight"
                    onPaginationChanged={onPaginationChanged}
                    onSortChanged={handleColumnSort}
                    onFilterChanged={handleColumnFilter}
                  ></AgGridReact>
                  <Grid
                    container
                    py={2}
                    justifyContent={"flex-end"}
                    alignItems={"center"}
                    border={1}
                    borderColor={"silver"}
                    bottom={0}
                    left={0}
                    right={0}
                  >
                    <Button onClick={onBtPrevious} disabled={skip === 0}>
                      <KeyboardArrowLeftTwoTone
                        {...(PaginationIconProps as any)}
                      />
                    </Button>

                    <Typography>
                      Page {Math.round(skip / 5) + 1} of {totalInfoCount}
                    </Typography>

                    <Button
                      onClick={onBtNext}
                      disabled={skip === (totalInfoCount - 1) * 5}
                      id="btNext"
                    >
                      <KeyboardArrowRightTwoTone
                        {...(PaginationIconProps as any)}
                      />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          ) : (
            <Grid px={2} py={1}>
              <Skeleton variant="rectangular" animation="wave" height={300} />
            </Grid>
          )}
        </Grid>
        {isExporter && (
          <Grid
            item
            xs={3}
            borderRadius={3}
            px={1}
            py={2}
            border={"1px solid green"}
          >
            <Grid display={"flex"} justifyContent={"space-between"}>
              <Typography variant="h5">Notes</Typography>
              <Button
                variant="outlined"
                size="small"
                style={{ borderRadius: "20px" }}
                onClick={() => handleNoteModal(true)}
              >
                + New Note
              </Button>
            </Grid>
            <Notes quantityPredictions={plotQuantityPredictions} />
          </Grid>
        )}
      </Grid>
      <AddNotesModal
        plotId={plotId!}
        openModal={addNoteModalOpen}
        handleModalClose={() => handleNoteModal(false)}
        appendNote={appendNote}
      />
      <OtherPlots
        isReady={areOtherPlotsReady}
        open={showOtherPlots}
        handleClose={() => setShowOtherPlots(false)}
        otherPlots={otherPlots}
      />

      <NewMaintenanceForm
        onlyCreate={true}
        formState={formState}
        setFormState={setFormState}
        open={open}
        setOpen={setOpen}
        edit={edit}
        setEdit={setEdit}
        handleOpen={handleOpen}
        handleClose={handleClose}
      />
    </Grid>
  );
};

export default PlotDetailsScreen;
