import { Grid, IconButton, Modal, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  postFertigationConfig,
  updateFertigationConfig,
  deleteFertigationConfig,
  getNutrientPetioleStage,
  getAllFertilizers,
} from "../services/plot.service";
import DeleteIcon from "@mui/icons-material/Delete";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { generateOptions, isArrayWithLength } from "../utils/helper";
import { getCropOptions, getCropVarieties } from "src/services/crop.service";
import Select, { MultiValue, SingleValue } from "react-select";
import DraggableStage from "src/components/DraggableStage";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ToastAlert } from "src/components";
import LocaleDropdown from "src/components/LocaleDropdown";
interface Props {
  selectedModalData?: any;
  setOpenModal?: any;
  appendGrid?: any;
}

const DropdownProps = {
  isClearable: true,
  isSearchable: true,
  placeholder: "- select -",
  styles: {
    container: (baseStyles: any) => ({
      ...baseStyles,
      width: "100%",
      padding: 2,
      fontSize: 16,
      margin: "3px 0",
      marginBottom: 11,
      marginTop: 6,
    }),
  },
};
const FertigationSchedule: React.FC<Props> = (props: Props): JSX.Element => {
  const res: any = props?.selectedModalData;
  const [content, setContent] = useState<any>(res || {});
  const [petioleAnalysisStageId, setpetioleAnalysisStageId] = useState<
    SingleValue<any>
  >([]);
  const [cropVarietyId, setcropVarietyId] = useState<MultiValue<any>>([]);
  const [cropId, setcropId] = useState<any>(res?.cropId || "");
  const [cropOptions, setcropOptions] = useState<any>();
  const [cropVarietyOptions, setcropVarietyOptions] = useState<any>();
  const [petioleOptions, setPetioleOptions] = useState<any>();
  const [seasonId, setseasonId] = useState<any>(res?.seasonId || "");
  const [openModal, setOpenModal] = useState(false);
  const [selectedModalData, setSelectedModalData] = useState<any>(null);
  const [addStageModal, setAddStageModal] = useState(false);
  const [openToast, setOpenToast] = useState<boolean>(false);
  const [newElement, setNewElement] = useState({
    name: "",
    unit: 0,
    sources: [""],
  });
  const [enableCopy, setEnableCopy] = useState<boolean>(false);
  const [prevValue, setPrevValue] = useState<number>(-1);
  const [chemicalOptions, setChemicalOptions] = useState<any>()

  const onSubmit = () => {
    content.cropId = cropId;
    if (cropVarietyId)
      content.cropVarietyId = cropVarietyId?.map((variety) => variety.value);
    if (seasonId) content.seasonId = seasonId;
    if (petioleAnalysisStageId)
      content.petioleAnalysisStageId = isArrayWithLength(petioleAnalysisStageId)
        ? petioleAnalysisStageId[0]?.value
        : petioleAnalysisStageId?.value;
    if (window.confirm("Do you want to submit?")) {
      if (content?.id) {
        updateFertigationConfig(content).then((res: any) => {
          if (res.message === "Success") {
            alert("Updated");
            props?.appendGrid(2);
          } else alert("Some Error Occured");
        });
      } else {
        postFertigationConfig(content).then((res: any) => {
          if (res.id) {
            alert("Fertigation Config added");
            props?.appendGrid(2);
          } else alert("Some Error Ocuured");
        });
      }
    }
    props?.setOpenModal(false);
  };

  const onDelete = () => {
    if (content.id) {
      if (window.confirm("Do you want to delete?")) {
        if (content.id) {
          deleteFertigationConfig(content.id).then((res: any) => {
            if (res.message === "Success") {
              alert("Configuration Deleted");
              props?.appendGrid(2);
            } else alert("Some Error Occured");
          });
        } else alert("Some Error Occured");
      }
    } else alert("No such Configuration");

    props?.setOpenModal(false);
  };

  const addNewStageData = (value: any) => {
    const newStage = {
      order: value,
      stageId: "",
      week: Number,
      day: Number,
      element: {},
      notes: [],
    };
    let newStages = content.stages || [];
    const stageExists = newStages?.find((stage: any) => stage?.order === value);
    if (newStages.length === 0 || !stageExists) {
      newStages.push(newStage);
      newStages.sort((a: any, b: any) => a.order - b.order);
      setSelectedModalData(newStage);
      setContent({ ...content, stages: newStages });
    } else alert("Stage order already exists");
  };

  const changeStageData = (
    order: any,
    type: any,
    value: any,
    isElement = false,
    subKey = ""
  ) => {
    const index = content?.stages?.findIndex(
      (stage: any) => stage.order === order
    );
    if (index !== -1) {
      if (!isElement) {
        if (type === "notes") {
          value = value.split(",")?.map((val: any) => val.trim());
        }
        setSelectedModalData({ ...selectedModalData, [type]: value });
        content.stages[index] = { ...selectedModalData, [type]: value };
        setContent((prevContent: any) => ({
          ...prevContent,
          stages: [
            ...prevContent.stages.slice(0, index),
            { ...selectedModalData, [type]: value },
            ...prevContent.stages.slice(index + 1),
          ],
        }));
      }
      if (isElement) {
        setSelectedModalData({
          ...selectedModalData,
          element: {
            ...selectedModalData.element,
            [type]: {
              ...selectedModalData.element[type],
              [subKey]:
                subKey === "sources"
                  ? value?.split(",")?.map((val: any) => val.trim())
                  : Number(value),
            },
          },
        });
        content.stages[index] = {
          ...selectedModalData,
          element: {
            ...selectedModalData.element,
            [type]: {
              ...selectedModalData.element[type],
              [subKey]:
                subKey === "sources"
                  ? value?.split(",")?.map((val: any) => val.trim())
                  : Number(value),
            },
          },
        };
        setContent((prevContent: any) => ({
          ...prevContent,
          stages: [
            ...prevContent.stages.slice(0, index),
            {
              ...selectedModalData,
              element: {
                ...selectedModalData.element,
                [type]: {
                  ...selectedModalData.element[type],
                  [subKey]:
                    subKey === "sources"
                      ? value?.split(",")?.map((val: any) => val.trim())
                      : Number(value),
                },
              },
            },
            ...prevContent.stages.slice(index + 1),
          ],
        }));
      }
    } else {
      alert("Some Error occurred");
    }
  };

  const deleteElement = (order: any, type: any) => {
    const index = content?.stages?.findIndex(
      (stage: any) => stage.order === order
    );
    if (index !== -1) {
      let stageData = { ...content?.stages[index]?.element };
      delete stageData?.[type];
      setSelectedModalData({ ...selectedModalData, element: stageData });
      setContent((prevContent: any) => ({
        ...prevContent,
        stages: [
          ...prevContent.stages.slice(0, index),
          { ...selectedModalData, element: stageData },
          ...prevContent.stages.slice(index + 1),
        ],
      }));
    } else {
      alert("Some Error occurred");
    }
  };

  useEffect(() => {
    getCropOptions().then((res: any) => {
      setcropOptions(
        [
          {
            label: "--select--",
            value: "--select--",
          },
        ].concat(generateOptions(res, "id", "cropName") as any)
      );
    });
    getAllFertilizers().then((res: any) => {
      setChemicalOptions(generateOptions(res, 'id', 'name') as any)
  })
  }, []);

  useEffect(() => {
    if (cropId.length > 0) {
      getCropVarieties(cropId).then((res: any) => {
        setcropVarietyOptions(generateOptions(res, "id", "name") as any);
      });
      getNutrientPetioleStage(cropId).then((res: any) => {
        setPetioleOptions(generateOptions(res, "stageId", "name") as any);
      });
    }
  }, [cropId]);

  useEffect(() => {
    if (
      props?.selectedModalData?.cropVarietyId &&
      isArrayWithLength(props?.selectedModalData?.cropVarietyId) &&
      cropVarietyOptions
    ) {
      setcropVarietyId(
        cropVarietyOptions?.filter((variety: any) =>
          props?.selectedModalData?.cropVarietyId.includes(variety.value)
        )
      );
    }
    if (props?.selectedModalData?.petioleAnalysisStageId && petioleOptions) {
      setpetioleAnalysisStageId(
        petioleOptions?.filter(
          (petiole: any) =>
            props?.selectedModalData?.petioleAnalysisStageId === petiole.value
        )
      );
    }
    setEnableCopy(true);
  }, [cropVarietyOptions, petioleOptions]);
  const navigate = useNavigate();

  const updateStageOrder = (stage: any, newOrder: number) => {
    const { order } = stage;
    let stages = content.stages.filter(
      (stage: any) => stage.order !== newOrder
    );
    const targetIndex = Math.min(stages.length, Math.max(order - 1, 0));

    stages = [
      ...stages.slice(0, targetIndex),
      stage,
      ...stages.slice(targetIndex),
    ];

    stages = stages.map((stage: any, index: number) => ({
      ...stage,
      order: index + 1,
    }));
    stages?.sort((a: any, b: any) => a.order - b.order);
    setSelectedModalData({ ...stage, order: newOrder });
    setContent((content: any) => ({ ...content, stages: stages }));
  };

  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid
        p={2}
        borderRadius={20}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton
            onClick={() =>
              props?.setOpenModal
                ? props.setOpenModal(false)
                : navigate("/nutrient")
            }
          >
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Fertigation Schedule Configuration
          </Typography>
        </Grid>
        {content?.id && enableCopy && (
          <IconButton
            style={{ marginRight: 25 }}
            onClick={() => {
              const updatedContent = { ...content };
              delete updatedContent.id;
              setContent(updatedContent);
              setOpenToast(true);
            }}
          >
            <ContentCopyIcon />
            <Typography marginLeft={0.8} fontSize={18}>
              Copy
            </Typography>
          </IconButton>
        )}
      </Grid>
      <ToastAlert
        open={openToast}
        setOpen={setOpenToast}
        severity={"info"}
        message={"Content Copied"}
      />

      <div
        style={{
          justifyContent: "center",
          padding: 30,
        }}
      >
        <form>
          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Crop
          </label>
          <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
            *
          </label>
          <select
            onChange={(e) => {
              setcropId(e.target.value);
            }}
            style={{
              width: "97%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
            value={cropId}
          >
            {cropOptions?.map(({ value, label }: any, index: any) => (
              <option value={value}>{label}</option>
            ))}
          </select>
          <br />

          <h3 style={{ margin: 5 }}>Crop Variety</h3>

          <Select
            options={cropVarietyOptions}
            onChange={(selected: MultiValue<any>) => {
              setcropVarietyId(selected);
            }}
            isMulti
            value={cropVarietyId}
            {...DropdownProps}
          />
          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Type
          </label>
          <Select
            options={petioleOptions}
            onChange={(selected: SingleValue<any>) => {
              setpetioleAnalysisStageId(selected);
            }}
            value={petioleAnalysisStageId}
            {...DropdownProps}
          />

          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Season
          </label>
          <input
            name="fertilizerName"
            onChange={(e) =>
              setseasonId(
                e.target.value.split(",")?.map((val: any) => val.trim())
              )
            }
            value={seasonId}
            type="text"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
          ></input>
          <br />

          <Grid
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
            width={"97%"}
          >
            <label
              style={{
                fontSize: 20,
                fontWeight: "500",
                margin: 8,
                marginRight: "70%",
                marginTop: 20,
              }}
            >
              Stages
            </label>
            <IconButton
              onClick={() => {
                setSelectedModalData({});
                setAddStageModal(true);
                setNewElement({ name: "", unit: 0, sources: [] });
              }}
            >
              <AddBoxIcon style={{ color: "grey" }} fontSize="medium" />
            </IconButton>
          </Grid>
          <br />
          <DraggableStage
            content={content}
            setContent={setContent}
            setSelectedModalData={setSelectedModalData}
            setOpenModal={setOpenModal}
          />
          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            style={{ marginTop: 55 }}
          >
            <input
              type="button"
              value="Submit"
              onClick={onSubmit}
              style={{
                width: "45%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
              }}
            />
            <input
              type="button"
              value="Delete"
              onClick={onDelete}
              style={{
                width: "45%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
              }}
            />
          </Grid>
        </form>
      </div>
      <Modal
        open={openModal || addStageModal}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
        }}
        onClose={() => {
          setOpenModal(false);
          setAddStageModal(false);
        }}
      >
        <Grid
          style={{
            backgroundColor: "#fff",
            padding: 12,
            borderRadius: 12,
            margin: 5,
            alignSelf: "center",
            width: "50%",
            maxHeight: "95%",
            overflowY: "auto",
          }}
        >
          <Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: 10,
              }}
            >
              <IconButton
                onClick={() => {
                  setAddStageModal(false);
                  setOpenModal(false);
                  setSelectedModalData(null);
                }}
              >
                <ArrowBack />
              </IconButton>
              <Typography variant="h6" style={{ flexGrow: 1 }}>
                {selectedModalData?.order} {selectedModalData?.stageId}
              </Typography>
              {selectedModalData?.order && (
                <IconButton
                  style={{ marginRight: 25 }}
                  onClick={() => {
                    let newStages: any[] = content.stages || [];
                    const modalData = {
                      ...selectedModalData,
                      order: newStages.length + 1,
                    };
                    newStages.push(modalData);
                    setSelectedModalData(modalData);
                    setContent({ ...content, stages: newStages });
                  }}
                >
                  <ContentCopyIcon fontSize="small" />
                  <Typography marginLeft={0.8} fontSize={14}>
                    Copy
                  </Typography>
                </IconButton>
              )}
            </div>
            <Grid
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-evenly",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 20 }}
              >
                <Typography variant="subtitle1" style={{ width: "25%" }}>
                  Order:
                </Typography>
                <input
                  value={selectedModalData?.order}
                  onFocusCapture={() => setPrevValue(selectedModalData?.order)}
                  onChange={(e) => {
                    setSelectedModalData({
                      ...selectedModalData,
                      order: Number(e.target.value),
                    });
                  }}
                  onBlur={() => {
                    if (addStageModal) {
                      addNewStageData(selectedModalData.order);
                    } else {
                      updateStageOrder(selectedModalData, prevValue);
                    }
                  }}
                  min={1}
                  type="number"
                  style={{
                    padding: "12px 20px",
                    fontSize: 16,
                    border: "1px solid",
                    borderRadius: 5,
                    width: "20%",
                  }}
                ></input>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 10 }}
              >
                <Typography variant="subtitle1" style={{ width: "25%" }}>
                  Week:
                </Typography>
                <input
                  value={selectedModalData?.week}
                  onChange={(e) => {
                    changeStageData(
                      selectedModalData?.order,
                      "week",
                      Number(e.target.value)
                    );
                  }}
                  type="number"
                  min={0}
                  style={{
                    padding: "12px 20px",
                    fontSize: 16,
                    borderRadius: 5,
                    border: "1px solid",
                    width: "20%",
                  }}
                ></input>
              </div>
              <div
                style={{ display: "flex", alignItems: "center", marginTop: 10 }}
              >
                <Typography variant="subtitle1" style={{ width: "25%" }}>
                  Day:
                </Typography>
                <input
                  value={selectedModalData?.day}
                  onChange={(e) => {
                    changeStageData(
                      selectedModalData?.order,
                      "day",
                      Number(e.target.value)
                    );
                  }}
                  type="number"
                  min={0}
                  style={{
                    padding: "12px 20px",
                    fontSize: 16,
                    borderRadius: 5,
                    border: "1px solid",
                    width: "20%",
                  }}
                ></input>
              </div>
            </Grid>

            <LocaleDropdown
              value={selectedModalData?.stageId}
              title="Stage"
              titleStyle={{ width: "15%" }}
              onChange={(selected: SingleValue<any>) => {
                changeStageData(
                  selectedModalData?.order,
                  "stageId",
                  selected?.value
                );
              }}
              containerStyle={{ width: "99%", marginTop: 12 }}
            />

            <LocaleDropdown
              value={selectedModalData?.notes}
              title="Notes"
              titleStyle={{ width: "15%" }}
              onChange={(selected: MultiValue<any>) => {
                changeStageData(
                  selectedModalData?.order,
                  "notes",
                  selected?.map((item) => item?.value || item).join(",")
                );
              }}
              isMulti={true}
              containerStyle={{ width: "99%", marginTop: 12 }}
            />

            <Grid
              display={"flex"}
              flexDirection={"row"}
              justifyContent={"space-between"}
            >
              <Typography
                variant="subtitle1"
                style={{ marginTop: 10, width: "20%" }}
              >
                Elements
              </Typography>
              <Grid
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                width={"80%"}
                mx={"auto"}
              >
                <input
                  value={newElement?.name}
                  onChange={(e) => {
                    setNewElement({ ...newElement, name: e.target.value });
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      const index = content?.stages?.findIndex(
                        (stage: any) => stage.order === selectedModalData?.order
                      );
                      if (index !== -1) {
                        let stageData = { ...content?.stages[index]?.element };
                        stageData[newElement?.name] = {
                          units: newElement?.unit,
                          sources: newElement?.sources,
                        };
                        setOpenModal(false);
                        setSelectedModalData({
                          ...selectedModalData,
                          element: stageData,
                        });
                        setOpenModal(true);
                      } else {
                        alert("Some Error occurred");
                      }
                      setNewElement({ name: "", unit: 0, sources: [""] });
                    }
                  }}
                  type="text"
                  min={0}
                  style={{
                    flexGrow: 1,
                    padding: "12px 20px",
                    fontSize: 16,
                    border: "1px solid #ccc",
                    borderRadius: 5,
                  }}
                />
                <IconButton
                  onClick={() => {
                    const index = content?.stages?.findIndex(
                      (stage: any) => stage.order === selectedModalData?.order
                    );
                    if (index !== -1) {
                      let stageData = { ...content?.stages[index]?.element };
                      stageData[newElement?.name] = {
                        units: newElement?.unit,
                        sources: newElement?.sources,
                      };
                      setOpenModal(false);
                      setSelectedModalData({
                        ...selectedModalData,
                        element: stageData,
                      });
                      setOpenModal(true);
                    } else {
                      alert("Some Error occurred");
                    }
                    setNewElement({ name: "", unit: 0, sources: [""] });
                  }}
                >
                  <CheckCircleOutlineIcon
                    style={{ color: "green" }}
                    fontSize="medium"
                  />
                </IconButton>
              </Grid>
            </Grid>

            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                flexWrap: "wrap",
              }}
            >
              {selectedModalData &&
                openModal &&
                Object?.entries(selectedModalData?.element)?.map(
                  ([key, value]: [any, any], index) => (
                    <Grid
                      my={2}
                      boxShadow={2}
                      borderRadius={3}
                      px={2}
                      pb={2}
                      pt={2}
                      width={320}
                    >
                      <Grid
                        display={"flex"}
                        flexDirection={"row"}
                        justifyContent={"space-between"}
                      >
                        <Typography variant="subtitle1">{key}</Typography>
                        <IconButton
                          onClick={() =>
                            deleteElement(selectedModalData?.order, key)
                          }
                          style={{ top: -15, right: -15 }}
                        >
                          <DeleteIcon
                            style={{ color: "grey" }}
                            fontSize="small"
                          />
                        </IconButton>
                      </Grid>
                      {Object.entries({...value, sources: selectedModalData?.element?.[key]?.sources|| ['']}).map(
                        ([subKey, subValue]: [any, any], subIndex) => (
                          <div
                            key={`${key}-${subKey}-${subIndex}`}
                            style={{ display: "flex", alignItems: "center" }}
                          >                        
                            <Typography
                              style={{ fontWeight: "bold", marginRight: 5 }}
                            >
                              {subKey}
                            </Typography>
                            {subKey === "sources" ? (
                              <Select
                                options={chemicalOptions}
                                onChange={(selected: MultiValue<any>) => {
                                  changeStageData(
                                    selectedModalData?.order,
                                    key,
                                    selected
                                      ?.map((item) => item.value)
                                      .join(","),
                                    true,
                                    subKey
                                  );
                                }}
                                value={chemicalOptions?.filter((note: any) =>
                                  subValue?.includes(note.value)
                                )}
                                isClearable={true}
                                isSearchable={true}
                                placeholder={"- select -"}
                                styles={{
                                  container: (baseStyles: any) => ({
                                    ...baseStyles,
                                    width: 200,
                                    padding: 2,
                                    fontSize: 16,
                                    margin: "3px 0",
                                    marginBottom: 11,
                                    marginTop: 6,
                                  }),
                                }}
                                isMulti
                              />
                            ) : (
                              <input
                                value={subValue}
                                onChange={(e) => {
                                  changeStageData(
                                    selectedModalData?.order,
                                    key,
                                    Number(e.target.value),
                                    true,
                                    subKey
                                  );
                                }}
                                type="number"
                                min={0}
                                style={{
                                  flexGrow: 1,
                                  padding: "12px 20px",
                                  width: "10%",
                                  fontSize: 16,
                                  border: "none",
                                  borderRadius: 5,
                                }}
                              />
                            )}
                          </div>
                        )
                      )}
                    </Grid>
                  )
                )}
            </div>
          </Grid>
        </Grid>
      </Modal>
    </Grid>
  );
};

export default FertigationSchedule;
