import { getAllLocale } from 'src/services/locale.service';
import React from 'react';
import * as XLSX from 'xlsx';
import dayjs from 'dayjs';

const LocaleConverter = () => {
  const handleDownload = async () => {
    // Extract keys and language codes from the provided data
    const keys = new Set();
    const languages = Object.keys(data);

    // Fetch locales from API
    const locales = await getAllLocale();

    // Collect keys from the original data
    languages.forEach(lang => {
      Object.keys(data[lang]).forEach(key => keys.add(key));
    });

    // Create rows for the original data
    const originalDataRows = Array.from(keys).map(key => {
      const row = { Key: key };
      languages.forEach(lang => {
        row[lang] = data[lang][key] || '';
      });
      return row;
    });

    // Sort original data rows alphabetically by 'Key'
    originalDataRows.sort((a, b) => a.Key.localeCompare(b.Key));

    // Create rows for the locales data from API response
    const localesRows = locales.map(locale => {
      const { id, ...translations } = locale;
      return { id, ...translations };
    });

    // Sort locales rows alphabetically by 'id'
    localesRows.sort((a, b) => a.id.localeCompare(b.id));

    // Create combined data rows
    const combinedRows = Array.from(keys).map(key => {
      const combinedRow = { Key: key };
      languages.forEach(lang => {
        combinedRow[lang] = data[lang][key] || '';
      });

      // Find corresponding locale for the current key and add to the row
      const matchingLocale = locales.find(locale => locale.id === key);
      if (matchingLocale) {
        Object.keys(matchingLocale).forEach(lang => {
          if (lang !== 'id') {
            combinedRow[lang] = matchingLocale[lang];
          }
        });
      }
      return combinedRow;
    });

    // Sort combined rows alphabetically by 'Key'
    combinedRows.sort((a, b) => a.Key.localeCompare(b.Key));

    // Convert to worksheet and workbook
    const originalDataSheet = XLSX.utils.json_to_sheet(originalDataRows, { header: ["Key", ...languages] });
    const localesSheet = XLSX.utils.json_to_sheet(localesRows, { header: ["id", ...Object.keys(locales[0]).filter(k => k !== 'id')] });
    const combinedSheet = XLSX.utils.json_to_sheet(combinedRows, { header: ["Key", ...languages] });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, originalDataSheet, 'Mobile');
    XLSX.utils.book_append_sheet(workbook, localesSheet, 'Dashboard');
    XLSX.utils.book_append_sheet(workbook, combinedSheet, 'Combined');

    const fileName = "locales_" + dayjs().format("hh:mm DD/MM/YYYY");
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  return (
    <button onClick={handleDownload}>Download Excel</button>
  );
};

export default LocaleConverter;

// Usage example
// const obj = { hi: {key1: 'value1'}, en: {key1: 'value1'} };
// <JsonToExcel data={obj} />


const hi = {
  Farm: "खेत",
  Calendar: "कैलेंडर",
  News: "ख़बर",
  Disease: "रोग",
  DiseaseAnalytics: "रोग विश्लेषण",
  ViewDetails: "विवरण देखें",
  MyFarm: "खेत परिस्थिती",
  DailyReport: "रिपोर्ट",
  Weather: "मौसम",
  LastUpdated: "आखरी अपडेट",
  SoilMoisture1: "मिट्टी की नमी-1",
  SoilMoisture2: "मिट्टी की नमी-2",
  SoilTemperature: "मिट्टी का तापमान",
  LeafWetness: "पत्ती की नमी",
  AirTemperature: "हवा का तापमान",
  AirHumidity: "हवा की नमी",
  AirPressure: "हवा का दबाव",
  LightIntensity: "सूर्यप्रकाश",
  Rainfall: "बारिश",
  RainFall: "बारिश",
  Eto: "वाष्पन-उत्सर्जन",
  eto: "वाष्पन-उत्सर्जन",
  RainFallLastHour: "बारिश पिछले घंटे",
  Rain: "बारिश",
  Alerts: "अलर्ट",
  SoilMoisture1Details: "मिट्टी की नमी-1 का विवरण",
  SoilMoisture2Details: "मिट्टी की नमी-2 का विवरण",
  SoilTempDetails: "मिट्टी के तापमान का विवरण",
  LeafWetnessDetails: "पत्ती की नमी का विवरण",
  AirTempDetails: "हवा के तापमान का विवरण",
  AirHumidityDetails: "हवा की नमी का विवरण",
  AirPressureDetails: "हवा के दबाव का विवरण",
  lightIntensityDetails: "रोशनी का विवरण",
  rainFallDetails: "बारिश का विवरण",
  windSpeedDetails: "हवा की गति का विवरण",
  batteryDetails: "बैटरी विवरण",
  battery: "बैटरी",
  windDirection: "हवा की दिशा",
  windSpeed: "हवा की गति",
  rainFall: "बारिश",
  leafWetness: "पत्ती की नमी",
  lightIntensity: "रोशनी",
  soilMoisture1: "मिट्टी की नमी-1",
  soilMoisture2: "मिट्टी की नमी-2",
  soilTemp: "मिट्टी का तापमान",
  signalStrength: "नेटवर्क",
  signalStrengthDetails: "नेटवर्क का विवरण",
  baroPressure: "बैरोमीटर दबाव",
  airPressure: "हवा का दबाव",
  airHumidity: "हवा में नमीं",
  airTemp: "हवा का तापमान",
  Nutrient: "पोषक/खाद",
  Spray: "स्प्रे कीट/ रोग",
  CulturalPractice: "खेत पद्धती",
  "Cultural Practice": "खेत पद्धती",
  Inputs: "उत्पादक",
  Services: "सेवाएं",
  Labour: "मज़दूरी",
  Seeds: "बीज",
  Fertilizer: "खाद",
  PandDControl: "कीट और रोग नियंत्रण",
  FieldPreparation: "खेत की तैयारी",
  Irrigation: "सिंचाई",
  Probability: "संभावना",
  RainProbability: "बारिश की संभावना",
  RainQuantity: "बारिश की मात्रा",
  RainQty: "बारिश की मात्रा",
  WindSpeed: "हवा की गति",
  Username: "यूज़र नेम",
  EnterOTP: "OTP दर्ज करें",
  EnterPassword: "पासवर्ड दर्ज करें",
  Login: "लॉग इन करें",
  ForgotPassword: "आप पासवर्ड भूल गए ?",
  ResourcesUsed: "उपयोग किए गए संसाधन",
  Date: "तारीख",
  LabourCost: "मज़दूरी खर्च",
  ResourcesCost: "संसाधन खर्च",
  NotRequired: "नही चाहिए",
  MarkAsDone: "पूर्ण हुआ",
  ActivityDate: "तारीख",
  ActivityType: "काम का प्रकार",
  Done: "जमा करें",
  Cancel: "रद्द करें",
  EnterCostOfResourcesUsed: "संसाधनों की लागत दर्ज करें",
  EmptyCalendarItemText: "इस तारीख को कुछ नहीं !!",
  Posted: "तारीख",
  Min: "न्यूनतम",
  DayMin: "दिन  के समय न्यूनतम",
  Max: "अधिकतम",
  DayMax: "दिन  के समय अधिकतम",
  Avg: "औसत",
  DayAvg: "दिन  के समय औसत",
  NoData: "कोई आकड़ा उपलब्ध नहीं है",
  Day: "दिन",
  Day1: "1 दिन",
  Week1: "1 सप्ताह",
  Month1: "1 महीना",
  Year1: "1 वर्ष",
  Email: "ई-मेल",
  Mobile: "मोबाइल",
  Next: "अगला",
  RegenerateOTP: "नया कोड भेजें ?",
  EmailSupport: "ई - मेल फायलो साथी",
  Network: "नेटवर्क",
  Power: "बिजली",
  ProbabilityInYourPlot: "अपने प्लॉट में संभावना",
  CropCalendar: "फसल कैलेंडर",
  AllCalendar: "फसल कैलेंडर",
  Actions: "गतिविधि",
  Insights: "इनसाइट्स",
  Disease: "रोग",
  MyFarm: "मेरा खेत",
  Farms: "खेत",
  NewsFeed: "समाचार फ़ीड",
  Notifications: "सूचनाएं",
  Profile: "प्रोफाइल",
  ActionsToDo: "करने के लिए",
  ActionsDone: "किए गए कार्य",
  Support: "सहायता",
  Logout: "लोग आउट",
  ChangeLanguage: "Language",
  WetDuration: "गीली अवधि",
  hours: "घंटे",
  "Too Wet": "बहुत गीला",
  "Adequate Moisture": "पर्याप्त ",
  Dry: "सूखा",
  "Too Dry": "बहुत सूखा",
  Wet: " गीला",
  Nil: "कुछ नहीं",
  Low: "कम",
  Medium: "मध्यम",
  High: "उच्च",
  HIGH: "उच्च",
  NIL: "कुछ नहीं",
  LOW: "कम",
  MEDIUM: "मध्यम",
  Night: "रात",
  "Fully Overcast": "पूरी तरह से बादल",
  Overcast: "बादल",
  Sunset: "सूर्यास्त",
  Sunrise: "सूर्योदय",
  "Partly Cloudy": "आंशिक रूप से बादल",
  Sunny: "धूप",
  "Clear Sky": "साफ़ आसमान",
  ClearAll: "सभी हटाएं",
  HoursOfRain: "बारिश के घंटे",
  RainHours: "बारिश के घंटे",
  HoursOfSun: "धूप के घंटे",
  CloudCover: "बादलों का कवरेज",
  HourlyWeatherPrediction: "प्रति घंटा मौसम",
  HourlyPrediction: "प्रति घंटा मौसम",
  Summary: "आज का सारांश",
  SoilMoisture: "मिट्टी की नमी",
  LeafWetnessHours: "पत्ती की नमी के घंटे",
  Minimum: "न्यूनतम",
  Maximum: "अधिकतम",
  Average: "औसत",
  Name: "नाम",
  Severity: "तीव्रता",
  ActionsRequired: "करने के लिए काम",
  NoActionsForToday: "आज कुछ भी नहीं",
  Quantity: "मात्रा",
  BestConditionsForSpray: "छिड़काव के लिए यह समय सबसे अच्छा है",
  ModerateConditionsForSpray: "इस समय सावधानी के साथ छिड़काव करें",
  WorstConditionsForSpray: "इस समय स्प्रे न करें",
  SprayTimings: "आज स्प्रे के लिए उपयुक्त समय",
  DailyReportNotAvailable:
    "रिपोर्ट आज के लिए उपलब्ध नहीं है। जांचें कि डिवाइस काम कर रहा है|",
  Evapotranspiration: "वाष्पन-उत्सर्जन",
  DailyETO: "दैनिक ETO",
  DayRainfall: "दैनिक वर्षा",
  ETODetails: "वाष्पन-उत्सर्जन का विवरण",
  GDD: "डिग्री डे",
  Gdd: "डिग्री डे",
  gdd: "डिग्री डे",
  GDDDetails: "डिग्री डे का विवरण",
  DayTimeAverageLux: "दैनिक सूर्य प्रकाश",
  Pest: "कीटक",
  to: "से",
  ReportsStages: "फसल रिपोर्ट और स्टेज",
  ShowReportFor: "रिपोर्ट दिखाएं",
  StageReports: "स्टेज रिपोर्ट",
  StageReport: "स्टेज रिपोर्ट",
  SeasonReport: "फसल चक्र रिपोर्ट ",
  CropStages: "फसल स्टेज",
  PrevSeasonReports: "पिछले सीजन",
  PlotReports: "प्लॉट रिपोर्ट",
  ActivitiesDone: "किए गए कार्य ",
  Activities: "कार्य ",
  NoActivitiesDone: "कोई काम दर्ज नहीं किया गया",
  TotalRainFall: "कुल वर्षा",
  Total: "कुल",
  TotalETO: "कुल वाष्पीकरण",
  AverageETO: "औसत वाष्पीकरण ",
  AccumulatedGDD: "जमा जीडीडी",
  Custom: "अन्य",
  ReportNotAvailable: "रिपोर्ट उपलब्ध नहीं",
  StartDateforStage: "स्टेज शुरू होने की तारीख",
  StartDate: "शुरू की तारीख",
  EndDate: "अंतिम तारीख",
  Analytics: "एनालिटिक्स",
  UpdateNotifications: "सूचनाएं अपडेट करें",
  Crop: "फसल",
  CropStartDate: "फसल प्रारंभ",
  StageStartDate: "स्टेज प्रारंभ",
  EndCropSeason: "फसल चक्र समाप्त",
  CurrentStage: "स्टेज",
  EndConfirmation:
    "इससे मौजूदा सीजन खत्म हो जाएगा !! क्या आप सुनिश्चित रूप से आगे बढ़ना चाहते हैं?",
  Yes: "हाँ",
  No: "नहीं",
  StartCropSeason: "नया फसल सीजन शुरू करें",
  RestartCropSeason: "पुराना फसल सीजन शुरू करें",
  NewCrop: "नई फसल",
  TwentyFourHours: "24 घंटे",
  Calm: "शांत",
  "Light Air": "हल्की हवा",
  "Light Breeze": "हल्की हवा",
  "Gentle Breeze": "मंद हवा",
  "Moderate Breeze": "मध्यम हवा",
  "Fresh Breeze": "ताज़ी हवा",
  "Strong Breeze": "तेज हवा",
  "Near Gale": "लगभग आंधी",
  Gale: "आंधी",
  "Strong Gale": "मजबूत आंधी",
  Storm: "तूफान",
  "Violent Storm": "हिंसात्मक तूफान",
  "leafDryness": "पत्ती का सूखापन",
  Direction: "दिशा",
  DownloadHistory: "डाउनलोड",
  Today: "आज",
  SeasonDegreeDays: "सीजन डिग्री डे",
  Payment: "पेमेंट करे ",
  Vouchers: "वाउचर",
  Referral: "रेफ़रल",
  PaymentHistory: "भुगतान इतिहास",
  Status: "स्थिति",
  Amount: "रकम",
  PaymentSuccess: "पेमेंट सफल हुआ",
  PaymentFailure: "पेमेंट असफल हुआ",
  Plot: "प्लॉट",
  Date: "तारीख",
  PayFull: "पूरा पेमेंट करें",
  Validity: "वैधता",
  SelectFarm: "कृपया अपने प्लॉट का चयन करें",
  Loading: "लोड हो रहा है",
  ViewBillDetails: "अगले पेज पर विस्तृत बिल देखें",
  Checkout: "चेकआउट",
  OfferApplied: "ऑफ़र लागू किया गया",
  BillDetails: "बिल का विवरण",
  OrderTotal: "ऑर्डर रकम",
  TotalDiscount: "कुल छूट",
  ToPay: "पे करें ",
  ProceedToPay: "भुगतान करें",
  BackToHome: "मुख्य स्क्रीन पर जाएं",
  OrderNum: "ऑर्डर क्रमांक",
  VoucherHistory: "वाउचर",
  ViewDetailedBill: "विस्तृत बिल देखें",
  ReferAndEarn: "पुरस्कार अर्जित करने के लिए अपने मित्र को देखें!!",
  SubmitReferral: "रेफरल सबमिट करें",
  referralHistory: "रेफ़रल हिस्ट्री",
  Expired: "प्लॉट पेमेंट एक्स्पायर्ड",
  redirectAfterExpired: "रिचार्ज करने के लिए यहाँ क्लिक करें",
  History: "हिस्ट्री",
  CropSeasonAndStageReports: "फसल स्टेज और रिपोर्ट",
  "Mobile Number*": "मोबाइल नंबर*",
  "Friend Name*": "मित्र का नाम*",
  "District*": "जिला*",
  "Village(Optional)": "गाँव(ऐच्छिक)",
  "Thank You": "धन्यवाद",
  "Thank you for participation": "भागीदारी के लिए धन्यवाद",
  PENDING: "पेंडिंग",
  PROCESSED: "प्रोसेस्ड",
  EXPIRED: "एक्स्पायर्ड",
  "Name must be at least 2 characters": "नाम कम से कम 2 वर्ण का होना चाहिए",
  "Name must be at max 50 characters": "नाम अधिकतम 50 वर्णों का होना चाहिए",
  "Please enter a valid name": "कृपया एक मान्य नाम दर्ज करें",
  "Name is required": "नाम आवश्यक है",
  "Mobile number must be at least 10 digits":
    "मोबाइल नंबर कम से कम 10 अंकों का होना चाहिए",
  "Mobile number must be at max 10 digits":
    "मोबाइल नंबर अधिकतम 10 अंकों का होना चाहिए",
  "Enter a valid mobile": "एक वैध मोबाइल नंबर दर्ज करें",
  "Mobile is required": "मोबाइल नंबर आवश्यक हैै",
  "District is required": "जिला आवश्यक है",
  referralSuccessText: "आपने {{name}}को सफलतापूर्वक रेफर किया है",
  referralFailureText: "{{name}} पहले से ही रेफरड है",
  Weather: "मौसम",
  Fertigation: "उर्वरक",
  Disease: "रोग",
  Irrigation: "सिंचाई",
  Others: "अन्य",
  Other: "अन्य",
  "Farm Data": "खेत डेटा",
  Alerts: "अलर्ट",
  "Hourly Update": "प्रति घंटा अपडेट",
  "Hide Details": "जानकारी छिपाएँ",
  "Show Details": "अधिक विवरण दिखाएं",
  mm: "मिमी",
  "km/hr": "किमी/घंटा",
  "Avg Lux": "औसत लक्स",
  "Max Lux": "अधिकतम लक्स",
  Activity: "गतिविधि",
  Duration: "अवधि",
  Hour: "घंटा",
  Minutes: "मि.",
  "Irrigation Details": "सिंचाई विवरण",
  "Dripper flow rate": "ड्रिपर प्रवाह दर (ली./घंटा)",
  "drippers per plant": "प्रति पौधे ड्रिपर्स की संख्या",
  numOfPlants: "पौधों की संख्या",
  "Total drippers": "ड्रिपर्स की कुल संख्या",
  "Irrigation per plant": "प्रति पौधा सिंचाई",
  "Total Irrigation": "कुल सिंचाई",
  Save: "सहेजें",
  SaveMoney: "बचाएं",
  "Please fill all the fields!": "कृपया सभी फ़ील्ड भरें!",
  quantity: "Qty.",
  Unit: "युनिट",
  "+ ADD": "+ जोड़ें",
  List: "सूची",
  "Water Amount": "पानी की मात्रा",
  Litre: "ली.",
  "Spraying for": "किस लिए छिड़काव",
  "Spraying Molecule": "स्प्रे मॉलिक्यूल",
  "Please select your": "कृपया चयन करें",
  capACTIVITY: "क्रिया",
  "Select Activity": "गतिविधि का चयन करें",
  Irrigation: "सिंचाई",
  Nutrients: "पोषक तत्व",
  "Spray, Pest & Disease": "स्प्रे, कीट और रोग",
  "Cultural Activity": "पारंपरिक गतिविधि",
  Saved: "सहेजा गया!",
  SavedMessage: "सहेजा गया!",
  "Total irrigation can't be 0": "कुल सिंचाई 0 नहीं हो सकती। ",
  "Please select time duration": "कृपया समय अवधि चुनें। ",
  attention: "खाली इनपुट भरें! ",
  "Please select what you are spraying for":
    "कृपया चुनें कि आप किसके लिए छिड़काव कर रहे हैं! ",
  "Please select spray molecule(s)": "कृपया स्प्रे अणु चुनें! ",
  "Please add water amount in litres":
    "कृपया पानी की मात्रा लीटर में डालें! ",
  "Please add quantity": "कृपया मात्रा जोड़ें",
  "Please add unit": "कृपया यूनिट जोड़ें",
  "Please select fertiliser": "कृपया उर्वरक का चयन करें",
  "Please select cultural activity": "कृपया खेती गतिविधि का चयन करें।",
  Duration: "अवधि",
  TotalIrrigation: "कुल सिंचाई",
  filter: "फ़िल्टर",
  "Type here to start searching": "यहां खोज/टाइप करें....",
  You_sure_you_want_to_delete: "आप हटाना चाहते हैं?",
  attentionDelete: "हटा दे?",
  DiseaseAndPest: "रोग और कीट",
  "Update Crop Stage": "फसल चरण अपडेट करें",
  "Is this correct?": "क्या यह जानकारी सही है?",
  "Search Plot here": " यहां प्लॉट खोजें",
  Details: "विवरण",

  alternaria_fruit_blotch: "Alternaria Fruit Blotch",
  anthracnose: "Anthracnose",
  bacterial_blight: "Bacterial Blight",
  bacterial_canker: "Bacterial Canker",
  bacterial_spot: "Bacterial Spot",
  bacterial_wilt: "Bacterial Wilt",
  black_spot: "Black Spot",
  botrytis: "Botrytis",
  cercospora_leaf_spot: "Cercospora Leaf Spot",
  downy_mildew: "Downy mildew",
  gummosis: "Gummosis",
  powdery_mildew: "Powdery mildew",

  flea_beetle: "Flea beetle",
  mealybug: "Mealybug",
  mites: "Mites",
  thrips: "Thrips",
  aphids: "Aphids",
  fruit_borer: "Fruit Borer",
  citrus_psylla: "Citrus Psylla",
  OR: "या",
  or: "या",
  SeasonFertilizerRequirements: "चक्र उर्वरक आवश्यकताएँ",
  Nutrient: "उर्वरक",
  SoilAmt: "मिट्टी में मात्रा",
  FertigationAmt: "फर्टिगेशन में मात्रा",
  Stage: "स्टेज",
  Weeks: "सप्ताह",
  Week: "सप्ताह",
  MajorNutrients: "प्रमुख पोषक",
  Sources: "स्रोत",
  MicroNutrients: "सूक्ष्म पोषक",
  SoilApplication: "मिट्टी को दिया खाद",
  Season: "चक्र",
  CropType: "फसल का प्रकार",
  CropVariety: "फसल किस्म",
  Rootstock: "रूटस्टॉक",
  ReportType: "रिपोर्ट प्रकार",
  InputDetails: "इनपुट",
  AddReport: "रिपोर्ट जोड़ें",
  ReportDate: "रिपोर्ट दिनांक",
  PleaseSelectCrop: "कृपया फसल का चयन करें",
  PleaseSelectCropVariety: "कृपया फसल किस्म चुनें",
  PleaseSelectRootstock: "कृपया रूटस्टॉक चुनें",
  PleaseSelectCropSeason: "कृपया फसल चक्र चुनें",
  PleaseSelectReportType: "कृपया रिपोर्ट प्रकार चुनें",
  PleaseEnter: "कृपया दर्ज करें",
  PestAnalytics: "कीट विश्लेषण",
  plotStatusExpired: "प्लॉट को सक्रिय करने के लिए कृपया रिचार्ज करें!",
  plotStatusOldData: "आखरी अपडेट: {{latestDataTime}}",
  Acres: "एकड़",
  Plots: "प्लॉट्स",
  PlotSaveSuccess: "प्लॉट विवरण सहेजना सफल रहा।",
  PlotSaveFail: "प्लॉट विवरण सहेजना विफल रहा।",
  ValueOutOfRangePercentage:
    "कृपया 0 और 100 के बीच {{alertVal}} का इनपुट दर्ज करें",
  "About Us": "About Us",
  NoStageReportFound: " कोई स्टेज रिपोर्ट नहीं मिली!!",
  Start: "शुरू करें",
  NoSeasonStartedSubText:
    "आपने अभी तक कोई सीज़न शुरू नहीं किया है। कृपया एक सीज़न शुरू करें",
  NoSeasonStartedText: "कोई सीज़न नहीं मिला !!",
  SowingDate: "बुवाई की तारीख",
  StartCropSeasonSubText: "कृपया एक आरंभ तिथि और फसल का प्रकार चुनें",
  DeltaT: "डेल्टा टी",
  DeltaTVPD: "डेल्टा टी और वीपीडी",
  VPD: "वीपीडी",
  Marginal: "मध्यम",
  MarginalHigherDelta: "मध्यम ",
  Preferred: "योग्य",
  Unsuitable: "अयोग्य",
  StagesInvalidDates: "कृपया चरण को अपडेट  करने के लिए सही तिथियों का चयन करें",
  DeleteStage: "चरण हटाएं",
  SureYouWantToDeleteStage: "क्या आप वाकई चरण को हटाना चाहते हैं?",
  FileSaved: "फ़ाइल सहेजी गई!",
  NoDownloadExcelDataFound: "डाटा प्राप्त नहीं हुआ!",
  YouAreChangingSpray: "आप स्प्रे/कीट/बीमारी बदल रहे हैं!",
  MoleculesListDeleted: "स्प्रे अणुओं की सूची हटा दी जाएगी!",
  StaleDataError:
    "डेटा प्राप्त नहीं कर सका। कृपया बाद में दोबारा प्रयास करें!",
  FetchDataError: "डिवाइस रजिस्टर्ड किया गया है, कृपया डेटा प्राप्त करने के लिए कुछ समय प्रतीक्षा करें।",
  Success: "सफल !",
  Oops: "उफ़ !!",
  From: "से",
  NA: "NA",
  To: "तक",
  "E-Mail fyllo support": "सहायता के लिए ईमेल फायलो",
  "Call fyllo support": "सहायता के लिए कॉल फायलो",
  ReadMore: "अधिक पढ़ें..",
  fyllo: "फायलो",
  EditActivity: "गतिविधि संपादित करें",
  PermissionDenied: "अनुमति अस्वीकृत",
  AllowFilePermission: "कृपया फ़ाइल अनुमति दें!",
  IncorrectEmailPhone: "गलत मोबाइल या ईमेल।",
  EmailForAccountSupport:
  "यदि आप रजिस्टर नहीं हैं तो कृपया contact@fyllo.in पर ई-मेल करें",
  ServerIssue: "सर्वर से कनेक्ट करने में सक्षम नहीं",
  TryLater: "कृपया कुछ समय बाद पुनः प्रयास करें",
  IncorrectOTP: "गलत ओटीपी।",
  RefillOTP: "कृपया सही ओटीपी दोबारा दर्ज करें",
  FileSaved: "फ़ाइल सहेजी गई!",
  NoDataFound: "डाटा प्राप्त नहीं हुआ!",
  "dd-mm-yyyy": "दिनांक",
  NoPrevSeasonText: "कोई पिछला सीजन नहीं मिला !!",
  ChooseAction: "विकल्प चुने",
  SelectionAction: "धिक विवरण के लिए एक क्रिया का चयन करें",
  Download: "डाउनलोड",
  unitkPa: " केपीए ",
  unitCelcius: " ℃ ",
  unitmm: " मिमी ",
  unitLux: " लक्स ",
  unitkmph: " कि/घं ",
  Scouting: "देख-भाल",
  Attachments: "फोटो अटैच करें",
  Notes: "टिप्पणियाँ",
  Description: "वर्णन",
  Upload: "अपलोड",
  "Read less": "कम पढ़ें",
  "Read more": "अधिक पढ़ें",
  Uploading: "अपलोडिंग ",
  PleaseSelectDiseaseLevel: "कृपया रोग की गंभीरता का चयन करें",
  PleaseSelectPestLevel: "कृपया कीट गंभीरता का चयन करें",
  PleaseSelectNutrientLevel: "कृपया पोषक तत्व गंभीरता का चयन करें",
  "Permissions issue": "अनुमति नहीं मिली!",
  GalleryPermissionIssue:
    "कृपया यह कार्य करने के लिए फ़ाइल/फ़ोन गैलरी की अनुमति दें!",
  CameraPermissionIssue: "कृपया कैमरा अनुमति दें!",
  Gallery: "फ़ोन गेलरी",
  Camera: "कैमरा",
  NoFarmMatched: "खेत नहीं मिला",
  WeatherPredictionNotAllowedSubText:
    "सिंचाई युनिट के लिए मौसम पूर्वानुमान उपलब्ध नहीं है।",
  CheckInternet: "कृपया इंटरनेट कनेक्शन जांचें|",
  DeviceHealth: "डिव्हाइस स्थिती",
  Health: "स्थिती",
  LooksLikeDeviceNotWorking: "ऐसा लगता है कि डिवाइस काम नहीं कर रहा है।",
  DEVICE_HEALTH: "डिव्हाइस स्थिती",
  "Device data being recieved": "डिवाइस डेटा प्राप्त किया जा रहा है",
  BatteryReducedText:
    "पिछले {{countVal}} दिनों में बैटरी {{reductionVal}}% कम हुई है।",
  "Sensor Conditions": "सेंसर की स्थिति",
  "All sensors are working fine": "सभी सेंसर काम कर रहे हैं।",
  NextFourHoursSprayNote:
    "स्प्रे करना है या नहीं, यह तय करने के लिए कृपया अगले चार घंटे की भविष्यवाणी देखें।",
  Location: "स्थान",
  Pay: "चुकाएं",
  "Pay using": "भुगतान करें",
  "Other Payment Methods": "अन्य भुगतान के तरीके",
  "Card Bank Transfer": "कार्ड/बैंक ट्रान्सफर",
  "Which plot?": "कौन सा प्लॉट?",
  "What activity?": "कौन सी गतिविधि?",
  DeviceNotInstalledWarning:
    "डेटा देखने के लिए इस प्लॉट पर डिवाइस इंस्टॉल करें!",
  CantUpdateData: "डेटा अपडेट नहीं कर सकता। कृपया बाद में प्रयास करें",
  AddPlot: "प्लॉट जोड़ें",
  HowMuchToIrrigate: "आपने कितनी अवधि तक सिंचाई की?",
  ActHours: "घंटे",
  ActMinutes: "मिनट",
  WhatWithinCultActivity: "खेती गतिविधि के भीतर क्या?",
  Area: "क्षेत्र",
  NoActivityFound: "कोई गतिविधि नहीं मिली",
  OldStyle: "पुराना तरीका",
  ActivityNotedAt: "समय",
  "New crop Season started": "नई फसल शुरू",
  SprayActivity:
    "उदाहरण के लिए:500 लीटर पानी में  1 किलो कॉपर का छिड़काव किया",
  ObservedDiseaseActivity:
    "उदाहरण के लिए: डाउनी मिल्ड्यू को प्लॉट में देखा गया",
  OtherActivity: "प्लाट में जुताई की",
  NutrientActivity: "उदाहरण के लिए:50 किलो यूरिया डाला है",
  AppliedUreaActivity: "उदाहरण के लिए:50 किलो यूरिया डाला है",
  and: "और",
  ApplyFilter: "फिल्टर लागू करें",
  baseStageData: "{{baseStage}} के {{days}} दिन बाद",
  baseStageDataBefore: "{{baseStage}} से {{days}} दिन पहले",
  SoilType: "मिट्टी का प्रकार",
  Foliar: "छिड़काव",
  FoliarAmt: "छिड़काव मात्रा",
  Soil: "मिट्टी",
  PleaseSelectAtleastOneMethod: "कृपया कम से कम एक विकल्प चुनें",
  AvailableOptions: "उपलब्ध विकल्प",
  NoCropSelected: "कोई फसल चयनित नहीं",
  HideOptions: "विकल्प छुपाएं",
  ShowOptions: "और देखें",
  sprayRequired:
    "नीचे दिए गए विकल्पों में से किसी एक को {{waterInLitres}} लीटर/एकड़ पानी में {{purpose}} के लिए स्प्रे करें",
  nutrientRequired:
    "नीचे दिए गए विकल्पों में से किसी एक को {{type}} के ज़रिए आपूर्ति करें:",
  foliar: "स्प्रे",
  fertigation: "फर्टिगेशन",
  soilApplication: "मिट्टी",
  SelectOneOption: "एक विकल्प चुनें",
  CreateReport: "रिपोर्ट बनाएं",
  ViewHistory: "इतिहास देखें",
  DownloadPDF: "रिपोर्ट प्राप्त करें",
  Fertigation: "फर्टिगेशन",
  Summry: "सारांश",
  Weekly: "साप्ताहिक",
  StageSummary: "स्टेज सारांश",
  "UnitsinKg/acre": "इकाई किलो/एकड़ में",
  Options: "विकल्प",
  Applyanyoneoftheoptions: "विकल्पों में से कोई एक लागू करें",
  riskLevel: "जोखिम",
  AnalysisStage: "विश्लेषण चरण/ प्रकार",
  PleaseSelectAnalysisStage: "कृपया विश्लेषण के एक चरण का चयन करें",
  days: "दिन",
  Register: "पंजीकरण",
  EnterMobileNoEmailID: "मोबाइल नंबर दर्ज करें",
  Resend: "पुन: भेजें",
  Seconds: "सेकंड",
  Number: "मोबाइल नंबर",
  Useralreadyregistered: "उपयोगकर्ता पहले से पंजीकृत है",
  InvalidNumber: "अमान्य संख्या",
  FarmDetails: "खेत का विवरण",
  FarmName: "खेत का नाम",
  PlotDetails: "प्लॉट विवरण",
  PlotName: "प्लॉट का नाम",
  DifferentSoilTypes: "विभिन्न मिट्टी के प्रकार",
  Sandy: "रेतीली",
  SandyLoam: "बलुई दोमट",
  Loamy: "बलुई",
  Clay: "चिकनी मिट्टी",
  PlotArea: "भूखंड क्षेत्रफल",
  Plotareacantbegreaterthanfarmarea:
    "प्लाट का क्षेत्रफल कृषि क्षेत्र से बड़ा नहीं हो सकता",
  Allowfyllotoaccessyourlocation: "अपने स्थान तक पहुंच की अनुमति दें?",
  SAVETOADDCROP: "फसल जोड़ने के लिए सहेजें",
  CropDetails: "फसल विवरण",
  CropName: "फसल का नाम",
  SowingPruningDate: "बुवाई/छंटाई की तिथि",
  PlantationYear: "वृक्षारोपण वर्ष",
  AddDevice: "डिवाइस जोडे",
  Adddevicetotheapp:
    "डिवाइस पर क्यूआर कोड स्कैन करके अपने डिवाइस को ऐप में जोड़ें",
  EnterIMEINumber: "IMEI नंबर दर्ज करें",
  Scan: "स्कैन",
  ATTACHDEVICE: "डिवाइस संलग्न करें",
  PopoverFarmDetails: "खेत डेटा और भविष्यवाणियां देखें",
  PopoverCalendarDetails: "गतिविधियां जोड़ें और देखें",
  popoverDiseaseDetails: "रोगों और कीटों के बारे में विवरण",
  popoverAnalyticsDetails: "फर्टिगेशन विवरण देखें",
  popoverPlotsDetails: "अन्य खेत/प्लॉट का चयन करें",
  popoverDailyReportDetails: "दैनिक रिपोर्ट की जाँच करें",
  popoverMyFarmDetails: "लाइव फार्म डेटा और इतिहास",
  popoverWeatherDetails: "प्रति घंटा और दैनिक मौसम भविष्यवाणी",
  popoverCropStageDetails: "फसल चरण देखें और अपडेट करें",
  popoverShareButtonDetails: "आंकड़ा साझा करें",
  popoverUpdatedTimeDetails: "अंतिम अपडेट का समय",
  popoverHealthDetails: "डिवाइस और सेंसर स्वास्थ्य की जाँच करें",
  popoverPlusButtonDetails: "गतिविधियां जोड़ें",
  popoverBarMenuDetails: "भुगतान, रेफरल, समर्थन",
  popoverNotificationDetails: "यहां नोटिफिकेशन चेक करें",
  popoverSoilMoistureDetails: "लाइव डेटा, इतिहास देखने के लिए क्लिक करें",
  Next: "अगला",
  Previous: "पिछला",
  Skip: "छोड़ें",
  Finish: "खत्म  करें",
  Delete: "हटाएँ",
  Cancel: "रद्द करें",
  Redo: "फिर से करें",
  ClickandscanQR:
    "नीचे दिए गए स्कैन बटन पर क्लिक करें और डिवाइस के इस क्षेत्र पर क्यूआर कोड को स्कैन करें",
  Welcome: "स्वागत है",
  Welcomeback: "वापसी पर स्वागत है",
  HelpWithdetails: "कृपया कुछ और विवरणों के साथ हमारी सहायता करें",
  Language: "भाषा",
  InvalidIMEINumber: "अमान्य IMEI नंबर",
  ApplicationMode: "आवेदन मोड",
  kgHectare: "किलो/हेक्टेयर",
  percent: "फ़ीसदी",
  UnitPPM: "पीपीएम",
  Select: "चयन करिए",
  Report: "रिपोर्ट",
  LogoutModalDescription: "क्या आप लॉग आउट करना चाहते हैं?",
  YourReport: "आपकी रिपोर्ट",
  RemovePhoto: "फोटो हटाएं",
  addActivity: "गतिविधि जोड़ें",
  notificationSettings: "अधिसूचना सेटिंग्स",
  Settings: "सेटिंग्स",
  makeAPayment: "भुगतान कीजिए",
  pastTransactions: "पिछला लेनदेन",
  NoPesticideSchedule: "कोई कीटनाशक अनुसूची नहीं मिली!",
  UpcomingSpray: "आगामी स्प्रे",
  Conditional: "सशर्त",
  Fixed: "अनिवार्य",
  Purpose: "उद्देश्य",
  Chemical: "रसायन",
  WaterQty: "पानी की मात्रा",
  CompleteSchedule: "पूरा शेड्यूल",
  UsePasswordInstead: "पासवर्ड का उपयोग करें",
  useOTPInstead: "OTP का उपयोग करें",
  cropStage: "फसल का चरण",
  whenToSpray: "कब स्प्रे करें",
  whenToSprayDescription: `अगर {{days}} दिन के लिए {{severity}} रहे`,
  notApplicable: "लागू नहीं",
  StagewiseSprayGuideline: "चरणवार छिड़काव दिशानिर्देश",
  back: "पीछे जाइये",
  registerNewUser: "नया यूजर रजिस्टर करेंं",
  existingUserLogin: "मौजूदा यूजर लॉगइन करें",
  ComebackLater: "हम इस पर काम कर रहे हैं, कृपया जल्द ही वापस देखें!",
  SelectAPlot: "प्लॉट चुनें",
  ShowReportFor: "रिपोर्ट दिखाएं",
  StartStage: "शुरुआती चरण",
  EndStage: "अंतिम चरण",
  Neutrients: "पोषक तत्व",
  NoSpray: "स्प्रे की संख्या",
  ThisWeek: "इस सप्ताह",
  TillDate: "आज तक",
  ThisMonth: "इस महीने",
  LastMonth: "पिछले महीने",
  CustomDate: "तारीख चुनें",
  SpecifyStage: "चरण छूने ",
  SelectStage: " चरण चयन करें",
  ThisStage: "यह चरण",
  CustomStage: "चरण का रेंज चुने",
  irrigationDetected: "सिंचाई का पता चला!",
  Insights: "इनसाइट्स",
  DurationInMinutes: "अवधि (मिनटों में)",
  ThisSeason: "इस मौसम",
  SelectSeason: "सीज़न का चयन करें",
  stageNotStartedWarning: "{{stageName}} चरण अभी शुरू नहीं हुआ है",
  CREDITED: "क्रेडिट",
  WAITING: "वेटिंग",
  referralScreen: "रेफर करें और क्रेडिट्स पाए",
  Apply: "आवेदन करें",
  EnterCode: "कोड दर्ज करें",
  EnterValidReferralCode: "कृपया करके एक सटीक रेफरल कोड दर्ज करें",
  REDEEMED: "भुनाया गया",
  SelectAPlot: "एक प्लॉट चुनें",
  CreditBalance: "जमा राशि",
  SearchContact: "संपर्क खोजें",
  EnterName: "नाम डालें",
  Contacts: "संपर्क",
  NoContactsFound: "कोई संपर्क नहीं मिला",
  WhyFyllo: "फायलो क्यों चुनें?",
  CropQuality: "फसल की गुणवत्ता और उपज",
  DiseaseManagement: "सर्वोत्तम रोग और कीट प्रबंधन",
  PreciseIrrigation: "सटीक सिंचाई",
  BestService: "सबसे अच्छी सेवा",
  Sendvia: "भेजें",
  Howitwork: "यह कैसे काम करता है?",
  creditedyouraccount: "हर रेफरल पर ₹20/- आपके फायलो रिचार्ज खाते में जमा किया जाएगा।",
  maximumearned: "इसे 25 लोगों में शेयर करके अधिकतम ₹500 कमाए जा सकते हैं.",
  friendinstallsfyllo: "यदि आपका मित्र फायलो स्थापित करता है, तो आपको अतिरिक्त ₹ 250 प्राप्त होंगे और आपके मित्र को फायलो स्थापना पर ₹500 की छूट मिलेगी.",
  off: "छूट",
  Mosteffectivereferers:
    "हर 6 महीने में सबसे प्रभावी रेफर करने वालों को नीरो डिवाइस या तिरुपति की यात्रा से पुरस्कृत किया जाएगा।",
  amountcredited: "₹{{amount}} सफलतापूर्वक क्रेडिट कर दिया गया है",
  fylloaccount: "आपके फायलो खाते में",
  Backtoreferral: "रेफरल पर वापस जाएं",
  TotalCreditsEarned: "अर्जित कुल क्रेडिट",
  CreditsRedeemed: "क्रेडिट भुनाया",
  GotReferralCode: "रेफरल कोड मिला?",
  ClaimYourRewards: "अपने पुरस्कारों का दावा करें",
  StartStage: "पहला भाग",
  Show: "show",
  DateRangeWarning: "शुरू की तारीख अंतिम तारीख के बाद की नहीं हो सकती",
  UseCreditBalance: "क्रेडिट बैलेंस का उपयोग करें",
  ReferralBonus: "रेफरल बोनस",
  RefferalCodeApplied: "रेफरल कोड लागू",
  ManageFarmUsingFyllo: `ननमस्ते, मैं अपने फार्म का प्रबंधन करने के लिए फायलो डिवाइस का उपयोग कर रहा हूं।`,
  FylloBenefit: "फायलो का उपयोग करने से मुझे निम्नलिखित प्रमुख लाभ मिले:",
  InstallFyllo:
  "मैं आपसे आग्रह करता हूं कि आप अपने खेत में भी फायलो को स्थापित करें। आप फायलो टीम से व्हाट्सएप पर +91-9380908490 पर या ईमेल के जरिए contact@fyllo.in पर संपर्क कर सकते हैं।",
  UseRefferalCode: "फायलो ॲप में अपने अगले रिचार्ज पर छूट पाने के लिए {{code}} रेफरल कोड का उपयोग करें",
  SomeErrorOccured: "कुछ त्रुटि हुई",
  Show: "show",
  DateRangeWarning: "शुरू की तारीख अंतिम तारीख के बाद की नहीं हो सकती",
  AnyChangeInReport: "रिपोर्ट में कोई बदलाव?",
  DeleteAccount: "अकाउंट मिटाये",
  ReasonToDelete: "आप क्यों अपना अकाउंट डिलीट करना चाहते हैं?",
  NoFruitfulResult: "मुझे कोई फलदायी परिणाम नहीं मिल रहा है?",
  NothingWorking: "कुछ भी ठीक से काम नहीं कर रहा",
  PrivacyConcerns: "मुझे अपनी प्राइवेसी की चिंता हैं",
  WhatisNotWorking:
    "आपको बेहतर परिणाम देने के लिए हम लगातार खुद में सुधार कर रहे हैं। क्या आप कृपया हमें साझा कर सकते हैं कि आप असंतुष्ट क्यों हैं?",
  Warning: "चेतावनी",
  DeleteWarningMessage:
    "यदि आप अपना खाता हटाते हैं तो आप सभी डेटा और सदस्यता स्थायी रूप से खो देंगे",
  OurTeamWillContact:
    "अकाउंट डिलीट करने के लिए हमारी टीम जल्द ही आपसे संपर्क करेगी",
  Close: "बंद करें",
  GoBack: "वापस जायें",
  ConfirmDeletion: "हटाने की पुष्टि करें",
  GiveYourThoughts: "अपनी राय दें",
  AnyChangeInReport: "रिपोर्ट में कोई बदलाव?",
  DeleteAccount: "अकाउंट मिटाये",
  UpdateStage: "स्टेज को अपडेट करें",
  EndSeasonWarningMsg:
    "क्या आप सुनिश्चित हैं कि आप इस सीज़न को समाप्त करना चाहते हैं?",
  StageUpdated: "स्टेज को अपडेट किया गया है",
  Retry: "पुन: प्रयास करें",
  NoWeekSelected: "कोई सप्ताह नहीं चुना गया",
  thingsToRememberFertigation: "फर्टिगेशन के दौरान याद रखने वाली बातें",
  thingsToRemember: "याद रखने वाली चीज़ें",
  NoDiseaseDataFound: "कोई रोग का डाटा नहीं मिला",
  NoPestDataFound: "कोई किट का डाटा नहीं मिला",
  Range: "सीमा",
  Value: "माप",
  DoYouNeedHelp: "क्या आप किसी समस्या का सामना कर रहे हैं?",
  LetUsHelpYou: "हमें आपकी सहायता करने दें",
  ChatWithUs: "हम से बात करे",
  CallUs: "हमें कॉल करें",
  update: "अपडेट करें",
  cancel: "रद्द करें",
  NewUpdateAvailable: "अपडेट उपलब्ध हैं!!",
  PleaseUpdateYour: "उपयोग करने के लिए कृपया अपने ऐप को अपडेट करें",
  helpMessage:
    "नमस्ते, मुझे अपने खेत {{farmId}} के लिए मदद चाहिए. मेरा यूजर नाम {{username}} हैं",
  notApplicableInfo: "{{pestOrDiseaseName}} इस चरण में लागू नहीं है",
  pesticideScheduleNotAllowed:
    "सिंचाई युनिट के लिए कीटनाशक शेड्यूल उपलब्ध नहीं है",
  latestReport: "नवीनतम रिपोर्ट",
  noReportsAvailable: "कोई रिपोर्ट उपलब्ध नहीं है",
  uploadPdfReport: "रिपोर्ट अपलोड करें",
  scanningPdf: "डॉक्यूमेंट स्कैन हो रहा हैं",
  cantScanPdf: "हम इस डॉक्यूमेंट को स्कैन करने में असमर्थ हैं",
  tryAgainPdf: "कृपया मैन्युअल रूप से पोषक तत्व मान डालें",
  basedOnUploadedReport: "अपलोड की गई रिपोर्ट के आधार पर",
  ScheduleGeneratedOnDefaultReport: "डिफ़ॉल्ट रिपोर्ट पर उत्पन्न अनुसूची",
  ForPreciseSchedule: "सटीक शेड्यूल के लिए",
  remember: "ध्यान दे",
  lowest: "निम्नतम",
  average: "औसत",
  highest: "उच्चतम",
  onAverage: "औसतन",
  Vpd: "वीपीडी",
  vpd: "वीपीडी",
  deltaT: "डेल्टा टी",
  newVersionAvailable: "नया संस्करण उपलब्ध है!!",
  SelectPlot: "प्लॉट चयन करें",
  RechargeUptoDate: "रिचार्ज करने के लिए तारीख चयन करें",
  getUptoDiscount: "{{discountPercentage}}% तक अतिरिक्त छूट पाएं",
  Yearly: "वार्षिक",
  Monthly: "मासिक",
  Quarterly: "त्रैमासिक",
  DueInDays: "{{days}} दिनों में देय",
  ContactSupport: "सहायता के लिए सम्पर्क करें",
  Email: "ईमेल",
  Phone: "फ़ोन",
  Whatsapp: "व्हाट्सप्प",
  INITIATED: "लंबित",
  AUTHORIZED: "अधिकृत",
  SUCCESS: "सफलता",
  FAILED: "असफल",
  NEW: "नई",
  Search: "खोजें",
  VideoGuide: "मार्गदर्शक",
  ViewMyProfile: "अपना प्रोफाइल देखें",
  // ViewMyProfile: 'मेरा प्रोफ़ाइल देखो',
  SearchKeywordToLearnAboutIt: "जानने के लिए एक कीवर्ड खोजें",
  rootWidth: "जड़ों की चौड़ाई (इंच)",
  rootDepth: "जड़ों की गहराई (इंच)",
  dripperDischarge: "ड्रिपर डिस्चार्ज (लीटर)",
  soilRetention: "मृदा प्रतिधारण (मिमी)",
  dripperDistance: "ड्रिपर दूरी (फीट)",
  CalculateValue: "संगणना करें",
  ReCalculateValue: "पुनर्गणना",
  Schedules: "अनुसूचिया",
  ChangeFarmDesc:
    "आपके परिवर्तन सहेजे नहीं जाएंगे। क्या आप जारी रखना चाहते हैं?",
  AddNewFarm: "नया जोड़ें",
  Remove: "हटाएं",
  UseExisting: "मौजूदा",
  NoNotification: "कोई सूचना उपलब्ध नहीं है",
  Edit: "संपादन",
  CopytoPlot: "कॉपी",
  Depth: "गहराई",
  inch: "इंच",
  Error: "त्रुटि",
  daysExceed:
   " ऐसा लगता है कि फसल के चरण दिन फसल के मौसम के दिनों से अधिक हो गए हैं।",
  updateStages:
    "कृपया अपने खेत के संबंध में सटीक डेटा प्राप्त करने के लिए अपनी फसल के चरणों को अपडेट करें!",
  Later: "बाद में",
  selectDuration: "अवधि चुनें",
  Lastdurationdays: "पिछले {{duration}} दिन",
  seasonSummary: "सीज़न सारांश",
  TotalWaterUsed: "उपयोग किया गया कुल पानी",
  TotalIrrigationEvents: "कुल सिंचाई घटनाएँ",
  AvgIntervalbetweenIrrigations: "2 सिंचाईयों के बीच औसत अंतराल",
  NumberofSpraysutilized: "उपयोग किए गए स्प्रे की संख्या",
  AverageSunLight: "औसत धूप",
  AverageSunshineHours: "औसत धूप के घंटे",
  Explore: "चरण सारांश का अन्वेषण करें",
  Lai: "एल.ए.आई ",
  lai: "एल.ए.आई ",
  LaiDetails: "एल.ए.आई विवरण",
  "Not Optimal": "इष्टतम नहीं",
  Optimal: "इष्टतम",
  Image: "छवि",
  AboutDeltaT: "डेल्टा टी क्या है?",
  AboutVPD: "वीपीडी क्या है?",
  AboutLAI: "एलएआई क्या है?",
  AddCost: "गतिविधि की लागत",
  costExample: "उदाहरण के लिए: 400",
  selectCurrency: 'मुद्रा चुनें',
  "No Rain": 'बारिश नहीं',
  "AppGuideVideo": 'ॲप गाईड व्हिडिओ',
  ProductVideo: 'प्रोडक्ट व्हिडिओ',
  AgronomyVideo: 'एग्रोनोमी व्हिडिओ',
  TestimonialVideo: 'टेस्टीमोनियल व्हिडिओ',
  IDPMSuggestion: "{{disease}} का प्रबंधन",
  AddYourFertilizers: "अपना खाद जानकारी जोड़ें",
  SprayNotifs: "स्प्रे की सूचनाएं",
  Cost: 'लागत',
  seedTreatment: 'बीज उपचार',
  insecticide: 'कीटनाशक',
  nematicide: 'नेमाटोसाइड',
  fungicide: 'फंगीसाइड',
  bactericide: 'जीवाणुनाशक',
  InvalidPayment: "अमान्य भुगतान",
  PaymentInProgress: "भुगतान प्रक्रिया चल रही है",
  ServiceRequest: "समस्या रिपोर्ट",
  NoServiceRequestFound: "कोई समस्या रिपोर्ट नहीं मिली",
  YourServiceRequests: "आपकी समस्या रिपोर्ट",
  RaiseServiceRequest: "समस्या रिपोर्ट करें",
  TellAboutYourIssue: "अपनी समस्या बताएं",
  DoYouWantToOpenThisServiceRequest: "क्या आप इस समस्या रिपोर्ट को खोलना चाहते हैं?",
  DoYouWantToCloseThisServiceRequest: "क्या आप इस समस्या रिपोर्ट को बंद करना चाहते हैं?",
  ReopenServiceRequest: "पुनः खोलें",
  CloseServiceRequest: "बंद करें",
  OpenDate: "खुलने की तिथि",
  SelectImage: "फ़ोटो चुनें",
  TakeImage: "फोटो लें",
  open: "खुला",
  closed: "बंद किया",
  new: "नया",
  "pending reminder": "लंबित अनुस्मारक",
  merged: "मिलाया गया",
  removed: "हटा दिया गया",
  lastUpdateHours: "अंतिम {{hours}} घंटे",
  Refresh: "रिफ्रेश",
  Issue: "समस्या",
  IssueIsRequired: "समस्या भरना आवश्यक है",
  DetailIsRequired: "विवरण आवश्यक है",
  ImagesTooLargeError: 'आप जो छवियां अपलोड करने की कोशिश कर रहे हैं वे बहुत बड़ी हैं। कृपया कुछ छवियों को हटा दें और पुनः प्रयास करें।',
  Create: "बनाएं",
  ReachedMaxAttachments: 'आप छवियों के लिए अधिकतम सीमा तक पहुंच गए हैं. कृपया और अधिक जोड़ने से पहले कुछ छवियां हटा दें।'
};
const ma = {
  Farm: "शेत",
  Calendar: "कॅलेंडर",
  News: "बातम्या",
  Disease: "रोग",
  MyFarm: "शेत परिस्थिती",
  DailyReport: "रिपोर्ट",
  ShowReportFor: "रिपोर्ट दाखवा",
  Weather: "हवामान",
  DiseaseDetails: "रोग विश्लेषण",
  GDD: "डिग्री डे",
  Gdd: "डिग्री डे",
  gdd: "डिग्री डे",
  GDDDetails: "डिग्री डे तपशील",
  LastUpdated: "शेवटचा अपडेट",
  SoilMoisture1: "मातीचा ओलावा-1",
  SoilMoisture2: "मातीचा ओलावा-2",
  SoilTemperature: "मातीचे तापमान",
  LeafWetness: "पानांचा ओलावा",
  AirTemperature: "हवेचे तापमान",
  AirHumidity: "हवेची आर्द्रता",
  AirPressure: "हवेचा दाब",
  LightIntensity: "सूर्यप्रकाश",
  Rainfall: "पाऊस",
  RainFall: "पाऊस",
  Probability: "संभाव्यता",
  ViewDetails: "तपशील पहा",
  RainFallLastHour: "पाऊस शेवटच्या तासात",
  Rain: "पाऊस",
  DayRainfall: "दिवस पाऊस",
  Alerts: "अलर्ट",
  SoilMoisture1Details: "माती ओलावा-1 तपशील",
  SoilMoisture2Details: "माती ओलावा-2 तपशील",
  SoilTempDetails: "माती तापमानाचा तपशील",
  LeafWetnessDetails: "पाने ओलावाचा तपशील",
  AirTempDetails: "हवा तापमानाचा तपशील",
  AirHumidityDetails: "हवा आर्द्रता तपशील",
  AirPressureDetails: "हवा दाब तपशील",
  lightIntensityDetails: "सूर्यप्रकाश तपशील",
  rainFallDetails: "पाऊस तपशील",
  windSpeedDetails: "वेग तपशील",
  batteryDetails: "बॅटरी तपशील",
  battery: "बॅटरी",
  windDirection: "वाऱ्याची दिशा",
  windSpeed: "वाऱ्याचा वेग",
  rainFall: "पाऊस",
  leafWetness: "पानांचा ओलावा",
  lightIntensity: "सूर्यप्रकाश",
  soilMoisture1: "माती ओलावा-1",
  soilMoisture2: "माती ओलावा-2",
  soilTemp: "माती तापमान",
  signalStrength: "नेटवर्क",
  signalStrengthDetails: "नेटवर्क तपशील",
  baroPressure: "बॅरोमेट्रिक दाब",
  airPressure: "हवेचा दाब",
  airHumidity: "हवेतील आर्द्रता",
  airTemp: "हवेचे तापमान",
  ETODetails: "बाष्पीभवन तपशील",
  ActivityType: "कामाचा प्रकार",
  Inputs: "उत्पादक",
  Services: "सेवा",
  Labour: "मजदूरी",
  Seeds: "बीज",
  Fertilizer: "खते",
  Next: "पुढे जा",
  Nutrient: "पोषक/खते",
  Spray: "स्प्रे कीटक/ रोग",
  CulturalPractice: "शेत पद्धती",
  "Cultural Practice": "शेत पद्धती",
  PandDControl: "कीटक आणि रोग नियंत्रण",
  FieldPreparation: "शेत तयार करणे",
  Irrigation: "सिंचन",
  RainProbability: "पाऊस संभाव्यता",
  RainQuantity: "पावसाची मात्रा",
  RainQty: "पावसाची मात्रा",
  WindSpeed: "वाऱ्याचा वेग",
  ClearAll: "सर्व हटवा",
  Login: "लॉगिन करा",
  Username: "यूज़र नेम",
  EnterOTP: "OTP टाका",
  EnterPassword: "पासवर्ड टाका",
  ForgotPassword: "तुमचा पासवर्ड विसरलात का ?",
  ResourcesUsed: "वापरलेले स्त्रोत",
  Date: "तारीख",
  LabourCost: "श्रम खर्च",
  ResourcesCost: "संसाधन खर्च",
  NotRequired: "आवश्यक नाही",
  MarkAsDone: "केले",
  ActivityDate: "तारीख",
  StartDate: "प्रारंभ तारीख",
  EndDate: "शेवटची तारीख",
  Done: "सबमिट करा",
  Cancel: "परत जा",
  EnterCostOfResourcesUsed: "वापरलेल्या संसाधनांची किंमत प्रविष्ट करा",
  EmptyCalendarItemText: "आपल्या प्लॉटसाठी या तारखेला काहीही नियोजन केले नाही !!",
  Posted: "तारीख",
  Min: "किमान",
  DayMin: "दिवसकाळ किमान",
  Max: "महत्तम",
  DayMax: "दिवसकाळ महत्तम",
  Avg: "सरासरी",
  DayAvg: "दिवसकाळ सरासरी",
  NoData: "माहिती उपलब्ध नाही",
  Day: "दिवस",
  Day1: "1 दिवस",
  Week1: "1 आठवडा",
  Month1: "1 महिना",
  Year1: "1 वर्ष",
  Custom: "इतर",
  EmailSupport: "ई - मेल फायलो साथी",
  Email: "ई-मेल",
  Mobile: "मोबाइल",
  Network: "नेटवर्क",
  Power: "वीज",
  RegenerateOTP: "नवीन कोड पाठवा ?",
  ProbabilityInYourPlot: "आपल्या प्लॉटमध्ये संभाव्यता",
  CropCalendar: "पीक कॅलेंडर",
  AllCalendar: "पीक कॅलेंडर",
  Actions: "क्रिया",
  Insights: "माहिती",
  Disease: "रोग",
  MyFarm: "माझे शेत",
  Farms: "शेत",
  Direction: "दिशा",
  NewsFeed: "बातम्या",
  Notifications: "अधिसूचना",
  Profile: "प्रोफाइल",
  ActionsToDo: "क्रिया करणे",
  ActionsDone: "कृती केली",
  Logout: "बाहेर पडणे",
  Support: "मदत",
  ChangeLanguage: "Language",
  WetDuration: "ओलावा कालावधी",
  hours: "तास",
  TwentyFourHours: "24 तास",
  "Too Wet": "खूप ओले",
  "Adequate Moisture": "पुरेसा ",
  Dry: "कोरडे",
  "Too Dry": "खूप कोरडे",
  Wet: " ओले",
  Nil: "काहीही नाही",
  Low: "कमी",
  Medium: "मध्यम",
  High: "उच्च",
  HIGH: "उच्च",
  NIL: "काहीही नाही",
  LOW: "कमी",
  MEDIUM: "मध्यम",
  Night: "रात्र",
  "Fully Overcast": "संपूर्णपणे ढगाळ",
  Overcast: "ढगाळ",
  Sunset: "सूर्यास्त",
  Sunrise: "सूर्योदय",
  "Partly Cloudy": "आंशिक ढगाळ",
  Sunny: "सूर्यासारखा दिसणारा",
  "Clear Sky": "स्वच्छ आकाश",
  HoursOfRain: "पावसाळी तास",
  RainHours: "पावसाळी तास",
  HoursOfSun: "सूर्यप्रकाश तास",
  CloudCover: "ढगांचे आवरण",
  HourlyWeatherPrediction: "ताशी हवामान अंदाज",
  HourlyPrediction: "ताशी हवामान",
  Summary: "आजचा सारांश",
  SoilMoisture: "मातीचा ओलावा",
  LeafWetnessHours: "पानांचा ओलावा तास",
  Minimum: "किमान",
  Maximum: "महत्तम",
  Average: "सरासरी",
  Name: "नाव",
  Severity: "तीव्रता",
  ActionsRequired: "करण्याच्या क्रिया",
  NoActionsForToday: "आज काहीही नाही",
  Quantity: "प्रमाण",
  BestConditionsForSpray: "ही वेळ फवारणीसाठी उत्तम आहे",
  ModerateConditionsForSpray: "सावधगिरीने फवारणी करा",
  WorstConditionsForSpray: "यावेळी फवारणी करू नका",
  SprayTimings: "आज फवारणीची वेळ",
  DailyReportNotAvailable:
    "आज अहवाल उपलब्ध नाही. डिव्हाइस कार्यरत आहे की नाही ते तपासा",
  Evapotranspiration: "बाष्पीभवन",
  Eto: "बाष्पीभवन",
  eto: "बाष्पीभवन",
  DailyETO: "रोजचे बाष्पीभवन",
  DayTimeAverageLux: "दैनंदिन सूर्य प्रकाश",
  Pest: "कीटक",
  to: "ते",
  ReportsStages: "पीक स्टेज आणि अहवाल",
  StageReports: "स्टेज रिपोर्ट",
  StageReport: "स्टेज रिपोर्ट",
  SeasonReport: "पीक चक्र रिपोर्ट ",
  CropStages: "पीक स्टेज",
  PrevSeasonReports: "मागील हंगाम रेकॉर्ड",
  PlotReports: "प्लॉट रिपोर्ट",
  ActivitiesDone: "उपक्रम केले",
  Activities: "उपक्रम",
  NoActivitiesDone: "कोणतीही कृती केली नाही",
  TotalRainFall: "एकूण पाऊस",
  TotalETO: "एकूण बाष्पीभवन",
  Total: "एकूण",
  AverageETO: "सरासरी बाष्पीभवन ",
  AccumulatedGDD: "जमा जीडीडी",
  ReportNotAvailable: "अहवाल उपलब्ध नाही",
  StartDateforStage: "प्रारंभ तारीख निवडा",
  Analytics: "विश्लेषण",
  UpdateNotifications: "सूचना अद्यतनित करा",
  Crop: "पीक",
  CropStartDate: "पीक प्रारंभ",
  StageStartDate: "स्टेज प्रारंभ",
  EndCropSeason: "पीक चक्र समाप्त",
  CurrentStage: "स्टेज",
  EndConfirmation:
    "यामुळे चालू पीकचक्र संपुष्टात येईल !! तुम्हाला खात्री आहे ?",
  Yes: "होय",
  No: "नाही",
  StartCropSeason: "नवीन पीक हंगाम सुरू करा",
  RestartCropSeason: "जुना पीक हंगाम सुरू करा",
  NewCrop: "नवीन पीक",
  Calm: "शांत हवा",
  "Light Air": "हलकी हवा",
  "Light Breeze": "हलका वारा",
  "Gentle Breeze": "वाऱ्याची मंद झुळूक",
  "Moderate Breeze": "मध्यम वारा",
  "Fresh Breeze": "ताजी हवा",
  "Strong Breeze": "मजबूत वारा",
  "Near Gale": "जवळ गेल ",
  Gale: "वादळ",
  "Strong Gale": "जोरदार वादळ",
  Storm: "वादळ",
  "Violent Storm": "हिंसक वादळ",
  "leafDryness": "पानांचा कोरडेपणा",
  DownloadHistory: "डाउनलोड",
  Today: "आज",
  SeasonDegreeDays: "सीजन डिग्री डे",
  Payment: "पेमेंट करा ",
  Vouchers: "व्हाउचर",
  Referral: "रेफरल",
  PaymentHistory: "पेमेंट हिस्ट्री",
  Status: "स्थिती",
  Amount: "रक्कम",
  PaymentSuccess: "पेमेंट यशस्वी",
  PaymentFailure: "पेमेंट अयशस्वी",
  Plot: "प्लॉट",
  Date: "तारीख",
  PayFull: "पूर्ण रक्कम भरा",
  Validity: "वैधता",
  SelectFarm: "कृपया आपले प्लॉट निवडा",
  Loading: "लोड करीत आहे",
  ViewBillDetails: "पुढील पानावर सविस्तर बिल पहा",
  Checkout: "चेकआउट",
  OfferApplied: "ऑफर लागू",
  BillDetails: "बिल तपशील",
  OrderTotal: "एकूण रक्कम",
  TotalDiscount: "सूट",
  ToPay: "देय रक्कम",
  ProceedToPay: "पेमेंट करा",
  BackToHome: "मुख्य स्क्रीनवर जा",
  OrderNum: "ऑर्डर क्र.",
  VoucherHistory: "व्हाउचर",
  ViewDetailedBill: "सविस्तर बिल पहा",
  ReferAndEarn: "रिवॉर्ड मिळवण्यासाठी तुमच्या मित्राचा संदर्भ घ्या!!",
  SubmitReferral: "रेफरल सबमिट करा",
  referralHistory: "रेफरल हिस्ट्री",
  Expired: "प्लॉट पेमेंट एक्स्पायर्ड",
  redirectAfterExpired: "रिचार्ज करण्यासाठी येथे क्लिक करा",
  History: "हिस्ट्री",
  CropSeasonAndStageReports: "पीक स्टेज आणि अहवाल",
  "Mobile Number*": "मोबाइल नंबर*",
  "Friend Name*": "मित्राचे नाव*",
  "District*": "जिल्हा*",
  "Village(Optional)": "गाव(ऐच्छिक)",
  "Thank You": "धन्यवाद",
  "Thank you for participation": "सहभागाबद्दल धन्यवाद",
  PENDING: "पेंडिंग",
  PROCESSED: "प्रोसेस्ड",
  EXPIRED: "एक्स्पायर्ड",
  "Name must be at least 2 characters":
    "नाव किमान 2 वर्णांचे असणे आवश्यक आहे",
  "Name must be at max 50 characters":
    "नाव जास्तीत जास्त 50 वर्णांचे असणे आवश्यक आहे",
  "Please enter a valid name": "कृपया योग्य नाव प्रविष्ट करा",
  "Name is required": "नाव आवश्यक आहे",
  "Mobile number must be at least 10 digits":
    "मोबाइल नंबर किमान 10 अंकांचा असणे आवश्यक आहे",
  "Mobile number must be at max 10 digits":
    "मोबाईल क्रमांक जास्तीत जास्त 10 अंकांचा असणे आवश्यक आहे",
  "Enter a valid mobile": "वैध मोबाइल क्रमांक प्रविष्ट करा",
  "Mobile is required": "मोबाइल क्रमांक आवश्यक आहे",
  "District is required": "जिल्हा आवश्यक आहे",
  referralSuccessText: "आपण यशस्वीरित्या {{name}}ला रेफर केले",
  referralFailureText: "{{name}}ला आधीच रेफर केलेले आहेत",
  Weather: "हवामान",
  Fertigation: "खत",
  Disease: "रोग",
  Irrigation: "सिंचन",
  Others: "इतर",
  Other: "इतर",
  "Farm Data": "शेत डेटा",
  Alerts: "अलर्ट",
  "Hourly Update": "ताशी अपडेट",
  "Hide Details": "माहिती लपवा",
  "Show Details": "माहिती दाखवा",
  mm: "मिमी",
  "km/hr": "किमी/तास",
  "Avg Lux": "सरासरी लक्स",
  "Max Lux": "कमाल लक्स",
  Activity: "क्रियाकलाप",
  Duration: "कालावधी",
  Hour: "ता.",
  Minutes: "मि.",
  "Irrigation Details": "सिंचन तपशील",
  "Dripper flow rate": "ठिबक प्रवाह दर (लिटर / तास)",
  "drippers per plant": "प्रति रोप ड्रिपर",
  numOfPlants: "झाडांची संख्या",
  "Total drippers": "ठिबकांची संख्या",
  "Irrigation per plant": "प्रति झाड सिंचन",
  "Total Irrigation": "एकूण सिंचन",
  Save: "जतन करा",
  SaveMoney: "वाचवा",
  "Please fill all the fields!": "कृपया सर्व फील्ड भरा!",
  quantity: "Qty.",
  Unit: "युनिट",
  "+ ADD": "+ जोडा",
  List: "यादी",
  "Water Amount": "पाणी",
  Litre: "लि.",
  "Spraying for": "फवारणीसाठी",
  "Spraying Molecule": "स्प्रे मॉलिक्यूल",
  "Please select your": "कृपया निवडा",
  capACTIVITY: "क्रिया",
  "Select Activity": "क्रिया निवडा",
  Irrigation: "सिंचन",
  Nutrients: "मूलद्रव्य",
  "Spray, Pest & Disease": "फवारणी, कीटक व रोग",
  "Cultural Activity": "शेत क्रिया",
  Saved: "जतन केले!",
  SavedMessage: "आपला डेटा सेव्ह झाला आहे!",
  "Total irrigation can't be 0": "एकूण सिंचन 0 असू शकत नाही.",
  "Please select time duration": "कृपया कालावधी निवडा",
  attention: "कृपया सर्व फील्ड भरा!",
  "Please select what you are spraying for":
    "कृपया आपण ज्यासाठी फवारणी करीत आहात ते निवडा! ",
  "Please select spray molecule(s)": "कृपया स्प्रे मॉलिक्यूल निवडा! ",
  "Please add water amount in litres":
    "कृपया लिटरमध्ये पाण्याचे प्रमाण निवडा! ",
  "Please add quantity": "कृपया quantity निवडा! ",
  "Please add unit": "कृपया युनिट निवडा! ",
  "Please select fertiliser": "कृपया खत निवडा!",
  "Please select cultural activity": "कृपया शेत क्रिया निवडा!",
  Duration: "कालावधी",
  TotalIrrigation: "एकूण सिंचन",
  filter: "फिल्टर",
  "Type here to start searching": "शोधण्यासाठी येथे टाइप करा....",
  You_sure_you_want_to_delete: "तुम्हाला हे नक्की डिलीट करायचे आहे का?",
  attentionDelete: "डिलीट?",
  DiseaseAndPest: "रोग आणि कीटक",
  "Update Crop Stage": "पीक अवस्था अपडेट करा",
  "Is this correct?": "ही माहिती बरोबर आहे का?",
  "Search Plot here": " प्लॉट येथे शोधा",
  Details: "तपशील",
  alternaria_fruit_blotch: "Alternaria Fruit Blotch",
  anthracnose: "Anthracnose",
  bacterial_blight: "Bacterial Blight",
  bacterial_canker: "Bacterial Canker",
  bacterial_spot: "Bacterial Spot",
  bacterial_wilt: "Bacterial Wilt",
  black_spot: "Black Spot",
  botrytis: "Botrytis",
  cercospora_leaf_spot: "Cercospora Leaf Spot",
  downy_mildew: "Downy mildew",
  gummosis: "Gummosis",
  powdery_mildew: "Powdery mildew",

  flea_beetle: "Flea beetle",
  mealybug: "Mealybug",
  mites: "Mites",
  thrips: "Thrips",
  aphids: "Aphids",
  fruit_borer: "Fruit Borer",
  citrus_psylla: "Citrus Psylla",
  OR: "किंवा",
  or: "किंवा",
  SeasonFertilizerRequirements: "हंगाम खत आवश्यकता",
  Nutrient: "पोषक",
  SoilAmt: "मातीमध्ये प्रमाण",
  FertigationAmt: "फर्टिगेशन प्रमाण",
  Stage: "स्टेज",
  Weeks: "आठवडे",
  Week: "आठवडा",
  MajorNutrients: "प्रमुख पोषक",
  Sources: "स्रोत",
  MicroNutrients: "सूक्ष्म पोषक",
  SoilApplication: "जमीन द्वारे पुरवठा",
  Season: "हंगाम",
  CropType: "पीक प्रकार",
  CropVariety: "पीक वरायटी",
  Rootstock: "रूटस्टॉक",
  ReportType: "अहवालाचा प्रकार",
  InputDetails: "इनपुट तपशील",
  AddReport: "अहवाल जोडा",
  ReportDate: "अहवाल दिनांक",
  PleaseSelectCrop: "कृपया पीक निवडा",
  PleaseSelectCropVariety: "कृपया पीक वाण निवडा",
  PleaseSelectRootstock: "कृपया रूटस्टॉक निवडा",
  PleaseSelectCropSeason: "कृपया पीक हंगाम निवडा",
  PleaseSelectReportType: "कृपया अहवाल प्रकार निवडा",
  PleaseEnter: "कृपया एंटर करा ",
  PestAnalytics: "कीटक विश्लेषण",
  plotStatusExpired: "प्लॉट सक्रिय करण्यासाठी कृपया रिचार्ज करा!",
  plotStatusOldData: "शेवटचा अपडेट: {{latestDataTime}}",
  Acres: "एकर",
  Plots: "प्लॉट्स",
  PlotSaveSuccess: "प्लॉट तपशील जतन करणे यशस्वी झाले.",
  PlotSaveFail: "प्लॉट तपशील जतन करणे अयशस्वी.",
  ValueOutOfRangePercentage:
    "कृपया 0 आणि 100 दरम्यान {{alertVal}} चे इनपुट प्रविष्ट करा",
  "About Us": "About Us",
  NoStageReportFound: " कोणतेही स्टेज अहवाल सापडले नाहीत !!",
  Start: "सुरू करा",
  NoSeasonStartedSubText:
    "तुम्ही अद्याप कोणताही हंगाम सुरू केला नाही. कृपया एक हंगाम सुरू करा",
  NoSeasonStartedText: "कोणताही हंगाम सापडला नाही !!",
  SowingDate: "पेरणीची तारीख",
  StartCropSeasonSubText: "कृपया प्रारंभिक तारीख आणि पिकाचा प्रकार निवडा",
  DeltaT: "डेल्टा टी",
  DeltaTVPD: "डेल्टा टी आणि वीपीडी",
  VPD: "वीपीडी",
  Marginal: "मध्यम",
  MarginalHigherDelta: "मध्यम ",
  Preferred: "योग्य",
  Unsuitable: "अयोग्य",
  StagesInvalidDates: "कृपया स्टेज अपडेट करण्यासाठी वैध तारखा निवडा",
  DeleteStage: "स्टेज हटवा",
  SureYouWantToDeleteStage: "तुम्हाला खात्री आहे की तुम्ही निवडलेले स्टेज हटवू इच्छिता?",
  FileSaved: "फाइल सेव्ह केली!",
  NoDownloadExcelDataFound: "माहिती आढळली नाही!",
  YouAreChangingSpray: "आपण स्प्रे/कीटक/रोग बदलत आहात!",
  MoleculesListDeleted: "स्प्रे रेणूंची सूची हटवली जाईल!",
  StaleDataError: "डेटा प्राप्त करू शकलो नाही. कृपया पुन्हा प्रयत्न करा",
  FetchDataError:
    "डिव्हाइस नोंदणीकृत झाले आहे, कृपया डेटा मिळविण्यासाठी काही वेळ प्रतीक्षा करा.",
  Success: "पूर्ण झाले  !",
  Oops: "अरेरे !!",
  From: "पासून",
  NA: "NA",
  To: "पर्यंत",
  "E-Mail fyllo support": "ई-मेल फायलो सपोर्ट",
  "Call fyllo support": "कॉल फायलो सपोर्ट",
  ReadMore: "पुढे वाचा..",
  fyllo: "फायलो",
  EditActivity: "ऍक्टिव्हिटी संपादित करा",
  PermissionDenied: "परवानगी नाकारली",
  AllowFilePermission: "कृपया फाइल परवानगी द्या!",
  IncorrectEmailPhone: "चुकीचा मोबाइल नंबर किंवा ईमेल.",
  EmailForAccountSupport:
    "तुम्ही नोंदणीकृत नसल्यास कृपया contact@fyllo.in वर ई-मेल करा",
  ServerIssue: "सर्व्हरशी कनेक्ट करण्यात सक्षम नाही",
  TryLater: "कृपया काही वेळानंतर पुन्हा प्रयत्न करा",
  IncorrectOTP: "चुकीचा OTP.",
  RefillOTP: "कृपया योग्य OTP पुन्हा एंटर करा",
  FileSaved: "फाइल जतन केली!",
  NoDataFound: "माहिती आढळली नाही!",
  "dd-mm-yyyy": "तारीख",
  NoPrevSeasonText: "मागील हंगाम सापडले नाहीत !!",
  ChooseAction: "क्रिया निवडा",
  SelectionAction: "पुढील तपशीलांसाठी कृती निवडा",
  Download: "डाउनलोड",
  unitkPa: " केपीए ",
  unitCelcius: " ℃ ",
  unitmm: " मिमी ",
  unitLux: " लक्स ",
  unitkmph: " कि/ता ",
  Scouting: "स्काऊटिंग",
  Attachments: "फोटो अटॅच करा",
  Notes: "नोट्स",
  Description: "विवरण",
  Upload: "अपलोड करा",
  "Read less": "कमी वाचा",
  "Read more": "अधिक वाचा",
  Uploading: "अपलोडिंग",
  PleaseSelectDiseaseLevel: "कृपया रोगाची तीव्रता निवडा",
  PleaseSelectPestLevel: "कृपया कीटक तीव्रता निवडा",
  PleaseSelectNutrientLevel: "कृपया पोषक तीव्रता निवडा",
  "Permissions issue": "परवानगी मिळाली नाही",
  GalleryPermissionIssue:
    "कृपया हे कार्य करण्यासाठी फाइल/गॅलरी परवानगी द्या!",
  CameraPermissionIssue: "कृपया हे कार्य करण्यासाठी कॅमेरा परवानगी द्या!",
  Gallery: "फोन गॅलरी",
  Camera: "कॅमेरा",
  NoFarmMatched: "शेत सापडले नाही",
  WeatherPredictionNotAllowedSubText:
    "सिंचन युनिटसाठी हवामानाचा अंदाज उपलब्ध नाही.",
  CheckInternet: "कृपया इंटरनेट कनेक्शन तपासा.",
  DeviceHealth: "डिव्हाइस स्थिती",
  Health: "स्थिती",
  LooksLikeDeviceNotWorking: "असे दिसते की डिव्हाइस कार्य करत नाही.",
  DEVICE_HEALTH: "डिव्हाइस स्थिती",
  "Device data being recieved": "डिव्हाइस डेटा प्राप्त होत आहे?",
  BatteryReducedText:
    "गेल्या {{countVal}} दिवसांत बॅटरी {{reductionVal}}% ने कमी झाली.",
  "Sensor Conditions": "सेन्सर स्थिती",
  "All sensors are working fine": "सर्व सेन्सर्स कार्यरत आहेत.",
  NextFourHoursSprayNote:
    "फवारणी करायची की नाही हे ठरवण्यासाठी कृपया पुढील चार तासांचा अंदाज पहा.",
  Location: "स्थान",
  Pay: "द्या",
  "Pay using": "वापरा",
  "Other Payment Methods": "इतर पेमेंट पद्धती",
  "Card Bank Transfer": "कार्ड/बँक ट्रान्सफर",
  "Which plot?": "कोणता प्लॉट?",
  "What activity?": "कोणती क्रिया?",
  DeviceNotInstalledWarning:
    "डेटा पाहण्यासाठी या प्लॉटवर डिव्हाइस इंस्टॉल करा!",
  CantUpdateData: "डेटा अपडेट करू शकत नाही. कृपया नंतर प्रयत्न करा",
  AddPlot: "प्लॉट जोडा",
  HowMuchToIrrigate: "तुम्ही किती कालावधीसाठी सिंचन केले?",
  ActHours: "तास",
  ActMinutes: "मिनिटे",
  WhatWithinCultActivity: "पारंपरिक गतिविधिमध्ये काय?",
  Area: "क्षेत्रफळ",
  NoActivityFound: "कोणतीही गतिविधी आढळली नाही",
  OldStyle: "जुनी स्टाईल",
  ActivityNotedAt: "वेळ",
  "New crop Season started": "नवीन पीक हंगाम सुरू झाला",
  SprayActivity: "उदाहरणार्थ: ५०० लिटर पाण्यात १ किलो कॉपर फवारले",
  ObservedDiseaseActivity:
    "उदाहरणार्थ: प्लॉटमध्ये डाऊनी मिल्ड्यूचे निरीक्षण केले गेले",
  OtherActivity: "उदाहरणार्थ: प्लॉटमध्ये नांगरणी केली",
  NutrientActivity: "उदाहरणार्थ:५० किलो युरिया टाकला आहे",
  AppliedUreaActivity: "उदाहरणार्थ:५० किलो युरिया टाकला आहे",
  and: "आणि",
  ApplyFilter: "फिल्टर लागू करा",
  baseStageData: "{{baseStage}} नंतर {{days}} दिवस",
  baseStageDataBefore: "{{baseStage}} च्या आधी {{days}} दिवस",
  SoilType: "मातीचा प्रकार",
  Foliar: "फवारणी",
  FoliarAmt: "फवारणी",
  Soil: "माती",
  PleaseSelectAtleastOneMethod: "कृपया किमान एक पर्याय निवडा",
  AvailableOptions: "उपलब्ध पर्याय",
  NoCropSelected: "कोणतेही पीक निवडलेले नाही",
  HideOptions: "कमी पहा",
  ShowOptions: "पर्याय दाखवा",
  sprayRequired:
    "खालीलपैकी एक पर्याय {{waterInLitres}} लिटर/एकर पाण्यात {{purpose}} साठी फवारणी करा",
  nutrientRequired: "खालीलपैकी एक पर्याय {{type}} द्वारे पुरवठा करा:",
  foliar: "स्प्रे",
  fertigation: "फर्टिगेशन",
  soilApplication: "माती",
  SelectOneOption: "एक पर्याय निवडा",
  CreateReport: "अहवाल तयार करा",
  ViewHistory: "इतिहास पहा",
  DownloadPDF: "अहवाल मिळवा",
  Fertigation: "फर्टिगेशन",
  Summry: "सारांश",
  Weekly: "साप्ताहिक",
  StageSummary: "स्टेज सारांश",
  "UnitsinKg/acre": "किलो/एकर मध्ये युनिट",
  Options: "पर्याय",
  Applyanyoneoftheoptions: "पर्यायांपैकी कोणताही एक लागू करा",
  riskLevel: "जोखीम",
  AnalysisStage: "विश्लेषण स्टेज/ प्रकार",
  PleaseSelectAnalysisStage: "कृपया विश्लेषणाचा टप्पा निवडा",
  days: "दिवस",
  Register: "नोंदणी",
  EnterMobileNoEmailID: "मोबाईल नंबर टाका",
  Resend: "पुन्हा पाठवा",
  Seconds: "सेकंद",
  Number: "मोबाईल नंबर",
  Useralreadyregistered: "वापरकर्ता आधीच नोंदणीकृत आहे",
  InvalidNumber: "अवैध संख्या",
  FarmDetails: "शेत तपशील",
  FarmName: "फील्डचे नाव",
  PlotDetails: "प्लॉट तपशील",
  PlotName: "प्लॉटचे नाव",
  DifferentSoilTypes: "मातीचे विविध प्रकार",
  Sandy: "वालुकामय",
  SandyLoam: "वालुकामय चिकणमाती",
  Loamy: "चिकणमाती",
  Clay: "चिकणमाती",
  PlotArea: "भूखंड क्षेत्र",
  Plotareacantbegreaterthanfarmarea:
    "प्लॉटचे क्षेत्रफळ हे शेत क्षेत्रापेक्षा जास्त असू शकत नाही",
  Allowfyllotoaccessyourlocation:
    "तुमच्या स्थानावर प्रवेश करण्याची परवानगी द्या?",
  SAVETOADDCROP: "पीक जोडण्यासाठी सेव्ह करा",
  CropDetails: "पीक तपशील",
  CropName: "पीक नाव",
  SowingPruningDate: "पेरणी/छाटणीची तारीख",
  PlantationYear: "वृक्षारोपण वर्ष",
  AddDevice: "डिव्हाइस जोडा",
  Adddevicetotheapp: "डिव्हाइसवरील QR कोड स्कॅन करून तुमचे डिव्हाइस ॲपमध्ये जोडा",
  EnterIMEINumber: "IMEI क्रमांक प्रविष्ट करा",
  Scan: "स्कॅन करा",
  ATTACHDEVICE: "डिव्हाइस संलग्न करा",
  PopoverFarmDetails: "शेतीचा डेटा आणि अंदाज पहा",
  PopoverCalendarDetails: "क्रियाकलाप जोडा आणि पहा",
  popoverDiseaseDetails: "रोग आणि कीटकांबद्दल तपशील",
  popoverAnalyticsDetails: "फर्टिगेशन तपशील पहा",
  popoverPlotsDetails: "दुसरे शेत/प्लॉट निवडा",
  popoverDailyReportDetails: "दररोज अहवाल तपासा",
  popoverMyFarmDetails: "थेट शेती डेटा आणि इतिहास",
  popoverWeatherDetails: "प्रति तास आणि दररोज हवामान अंदाज",
  popoverCropStageDetails: "पीक वाढण्याची पायरी पहा आणि अपडेट करा",
  popoverShareButtonDetails: "डेटा शेअर करा",
  popoverUpdatedTimeDetails: "शेवटची अपडेट वेळ",
  popoverHealthDetails: "डिव्हाइस आणि सेन्सरचे आरोग्य तपासा",
  popoverPlusButtonDetails: "क्रियाकलाप जोडा",
  popoverBarMenuDetails: "पेमेंट, रेफरल, सपोर्ट",
  popoverNotificationDetails: "येथे सूचना तपासा",
  popoverSoilMoistureDetails: "थेट डेटा, इतिहास पाहण्यासाठी क्लिक करा",
  Next: "पुढे",
  Previous: "मागील",
  Skip: "वगळा",
  Finish: "समाप्त करा",
  Delete: "हे हटवा",
  Cancel: "रद्द करा",
  Redo: "पुन्हा करा",
  ClickandscanQR:
    "खालील स्कॅन बटणावर क्लिक करा आणि डिव्हाइसच्या या क्षेत्रावरील QR कोड स्कॅन करा",
  Welcome: "स्वागत",
  Welcomeback: "परत स्वागत आहे",
  HelpWithdetails: "कृपया काही अधिक तपशीलांसह आम्हाला मदत करा",
  Language: "भाषा",
  InvalidIMEINumber: "अवैध IMEI क्रमांक",
  ApplicationMode: "अनुप्रयोग मोड",
  kgHectare: "किलो/हेक्टर",
  percent: "दर शेकडा",
  UnitPPM: "पीपीएम",
  Select: "निवडा",
  Report: "अहवाल",
  LogoutModalDescription: "तुम्हाला खात्री आहे की तुम्ही लॉगआउट करू इच्छिता?",
  YourReport: "तुमचा अहवाल",
  RemovePhoto: "फोटो काढा",
  addActivity: "क्रियाकलाप जोडा",
  notificationSettings: "सूचना सेटिंग्ज",
  Settings: "सेटिंग्ज",
  makeAPayment: "पैसे भरा",
  pastTransactions: "मागील व्यवहार",
  NoPesticideSchedule: "कोणतेही कीटकनाशक वेळापत्रक आढळले नाही!",
  UpcomingSpray: "आगामी स्प्रे",
  Conditional: "सशर्त",
  Fixed: "अनिवार्य",
  Purpose: "उद्देश",
  Chemical: "रसायने",
  WaterQty: "पाणी प्रमाण",
  CompleteSchedule: "पूर्ण वेळापत्रक",
  UsePasswordInstead: "त्याऐवजी संकेतशब्द वापरा",
  useOTPInstead: "त्याऐवजी ओटीपी वापरा",
  cropStage: "पीक स्टेज",
  whenToSpray: "स्प्रे कधी",
  whenToSprayDescription: `{{days}} दिवसांसाठी {{severity}} असल्यास`,
  notApplicable: "लागू नाही",
  StagewiseSprayGuideline: "स्टेजवाइज फवारणी मार्गदर्शक तत्त्वे",
  back: "परत जा",
  registerNewUser: "नवीन वापरकर्त्याची नोंदणी करा",
  existingUserLogin: "विद्यमान वापरकर्ता लॉगिन करा",
  ComebackLater: "आम्ही त्यावर काम करत आहोत, कृपया लवकरच परत तपासा!",
  SelectAPlot: "एक प्लॉट निवडा",
  ShowReportFor: "साठी अहवाल दर्शवा",
  StartStage: "प्रारंभिक टप्पा",
  EndStage: "शेवटचा टप्पा",
  Neutrients: "पोषक घटक",
  NoSpray: "स्प्रेची संख्या",
  ThisWeek: "या आठवड्यात",
  TillDate: "आज पर्यंत",
  ThisMonth: "या महिन्यात",
  LastMonth: "मागील महिन्यात",
  CustomDate: "सानुकूल तारीख",
  SpecifyStage: "स्टेज निर्दिष्ट करा",
  SelectStage: "स्टेज निवडा",
  ThisStage: "हा टप्पा",
  CustomStage: "फेज श्रेणी निवडा",
  irrigationDetected: "सिंचन सापडले!",
  Insights: "अंतर्दृष्टी",
  DurationInMinutes: "कालावधी (काही मिनिटांत)",
  ThisSeason: "या हंगामात",
  SelectSeason: "सीझन निवडा",
  stageNotStartedWarning: "{{stageName}} टप्पा अद्याप सुरू झालेला नाही",
  CreditBalance: "क्रेडिट शिल्लक",
  SearchContact: "संपर्क शोधा",
  EnterName: "नाव प्रविष्ट करा",
  Contacts: "संपर्क",
  NoContactsFound: "संपर्क सापडला नाही",
  WhyFyllo: "फायलो का निवडायचे?",
  CropQuality: "पीक गुणवत्ता आणि उत्पन्न",
  DiseaseManagement: "सर्वोत्तम रोग आणि कीड व्यवस्थापन",
  PreciseIrrigation: "अचूक सिंचन",
  BestService: "सर्वोत्तम सेवा",
  Sendvia: "पाठवा",
  Howitwork: "हे कस काम करत?",
  creditedyouraccount:
    "प्रत्येक रेफरलवर ₹२०/- तुमच्या फायलो रिचार्ज खात्यात जमा केले जातील.",
  maximumearned: "25 लोकांना शेअर करून कमाल ₹500 मिळवता येतात.",
  friendinstallsfyllo:
    "तुमच्या मित्राने फायलो इंस्टॉल केल्यास, तुम्हाला अतिरिक्त ₹ 250 मिळतील आणि तुमच्या मित्राला फायलो इंस्टॉलेशनवर ₹ 500 ची सूट मिळेल.",
  off: "सवलत",
  Mosteffectivereferers:
    "दर 6 महिन्यांतील सर्वात प्रभावी रेफरर्सना निरो डिव्हाइस किंवा तिरुपतीच्या सहलीने बक्षीस दिले जाईल.",
  amountcredited: "₹{{amount}} यशस्वीरित्या जमा झाले",
  fylloaccount: "तुमच्या फायलो खात्यावर",
  Backtoreferral: "संदर्भाकडे परत",
  TotalCreditsEarned: "एकूण क्रेडिट्स मिळवली",
  CreditsRedeemed: "क्रेडिट रिडीम केले",
  CREDITED: "क्रेडिट",
  WAITING: "वेटिंग",
  referralScreen: "पहा आणि कमवा",
  GotReferralCode: "एक रेफरल कोड आहे?",
  ClaimYourRewards: "आपल्या बक्षिसाचा दावा करा ",
  Apply: "अर्ज करा",
  EnterCode: "तुमचा कोड एंटर करा",
  EnterValidReferralCode: "वैध रेफरल कोड प्रविष्ट करा",
  REDEEMED: "पूर्तता केली",
  Show: "show",
  UseCreditBalance: "शिल्लक क्रेडिट वापरा",
  ReferralBonus: "रेफरल बोनस",
  RefferalCodeApplied: "रेफरल कोड लागू",
  ManageFarmUsingFyllo: "नमस्कार, मी माझे शेत व्यवस्थापित करण्यासाठी फायलो डिव्हाइस वापरत आहे.",
  FylloBenefit:  "फायलो वापरून मला खालील प्रमुख फायदे मिळाले",
  InstallFyllo: "मी तुम्हाला तुमच्या शेतातही फायलो बसवण्याची विनंती करतो. तुम्ही  फायलो टीमशी WhatsApp वर +91-9380908490 वर किंवा contact@fyllo.in वर ईमेलद्वारे संपर्क साधू शकता.",
  UseRefferalCode: "फायलो ॲपमध्ये तुमच्या पुढील रिचार्जवर सूट मिळवण्यासाठी {{code}} रेफरल कोड वापरा",
  SomeErrorOccured: "काही त्रुटी आली",
  AnyChangeInReport: "अहवालात काही बदल?",
  DeleteAccount: "अकाउंट हटवा",
  ReasonToDelete: "तुम्हाला तुमचे खाते का हटवायचे आहे?",
  NoFruitfulResult: "मला काही फलदायी परिणाम मिळत नाहीत का",
  NothingWorking: "काहीही बरोबर काम करत नाही",
  PrivacyConcerns: "मला माझ्या गोपनीयतेची काळजी आहे",
  WhatisNotWorking:
    "तुम्हाला चांगले परिणाम देण्यासाठी आम्ही सतत स्वतःमध्ये सुधारणा करत असतो. तुम्ही असमाधानी का आहात हे तुम्ही आम्हाला शेअर करू शकता का?",
  Warning: "चेतावणी",
  DeleteWarningMessage:
    "तुम्ही तुमचे खाते हटवल्यास तुम्ही सर्व डेटा आणि सदस्यता कायमचे गमवाल",
  OurTeamWillContact: "खाते हटवण्यासाठी आमची टीम तुमच्याशी लवकरच संपर्क करेल",
  Close: "ते थांबवा",
  GoBack: "परत जा",
  ConfirmDeletion: "हटविण्याची पुष्टी करा",
  GiveYourThoughts: "आपले मत द्या",
  AnyChangeInReport: "अहवालात काही बदल?",
  DeleteAccount: "अकाउंट हटवा",
  UpdateStage: "अपडेट स्टेज",
  EndSeasonWarningMsg:
    "तुम्‍हाला खात्री आहे की तुम्‍हाला हा सीझन संपवायचा आहे?",
  StageUpdated: "स्टेज अद्ययावत केले आहे",
  Retry: "पुन्हा प्रयत्न करा",
  NoWeekSelected: "आठवडा निवडलेला नाही",
  thingsToRememberFertigation: "फर्टिगेशन दरम्यान लक्षात ठेवा गोष्टी",
  thingsToRemember: "लक्षात ठेवण्यासारख्या गोष्टी",
  NoDiseaseDataFound: "कोणताही रोग डेटा आढळला नाही",
  NoPestDataFound: "कोणताही किट डेटा आढळला नाही",
  Range: "श्रेणी",
  Value: "परिमिती",
  DoYouNeedHelp: "तुम्हाला काही समस्या येत आहेत का?",
  LetUsHelpYou: "आम्हाला तुमची मदत करू द्या",
  ChatWithUs: "आमच्याशी बोला",
  CallUs: "आम्हाला कॉल करा",
  update: "अपडेट करा",
  cancel: "रद्द करा",
  NewUpdateAvailable: "अपडेट्स उपलब्ध आहे!!",
  PleaseUpdateYour: "कृपया तुमचे एप्लीकेशन वापरण्यासाठी अपडेट करा",
  helpMessage:
    "नमस्कार, मला माझ्या {{farmId}} फार्मसाठी मदत हवी आहे. माझे युझर नेम {{username}} आहे.",
  notApplicableInfo: "या टप्प्यात {{pestOrDiseaseName}} लागू नाही",
  pesticideScheduleNotAllowed:
    "सिंचन युनिटसाठी कीटकनाशक शेड्यूल उपलब्ध नाही.",
  latestReport: "नवीनतम अहवाल",
  noReportsAvailable: "कोणतेही अहवाल उपलब्ध नाहीत",
  uploadPdfReport: "अहवाल अपलोड करा",
  scanningPdf: "डॉक्यूमेंट स्कॅन करत आहे",
  cantScanPdf: "आम्ही ही डॉक्यूमेंट स्कॅन करू शकत नाही",
  tryAgainPdf: "कृपया पोषणमूल्ये व्यक्तिचलितपणे प्रविष्ट करा",
  basedOnUploadedReport: "अपलोड केलेल्या अहवालावर आधारित",
  ScheduleGeneratedOnDefaultReport: "डिफॉल्ट अहवालावर तयार केलेले शेड्यूल",
  ForPreciseSchedule: "अचूक वेळापत्रकासाठी",
  remember: "लक्ष द्या",
  lowest: "सर्वात कमी",
  average: "सरासरी",
  highest: "सर्वोच्च",
  onAverage: "सरासरी",
  Vpd: "वीपीडी",
  vpd: "वीपीडी",
  deltaT: "डेल्टा टी",
  newVersionAvailable: "नवीन आवृत्ती उपलब्ध!!",
  SelectPlot: "प्लॉट निवडा",
  RechargeUptoDate: "एका तारखेपर्यंत रिचार्ज करा",
  getUptoDiscount: "{{discountPercentage}}% पर्यंत अतिरिक्त सवलत मिळवा",
  Yearly: "वार्षिक",
  Monthly: "मासिक",
  Quarterly: "त्रैमासिक",
  DueInDays: "{{days}} दिवसांत देय आहे",
  ContactSupport: "सपोर्टशी संपर्क साधा",
  Email: "ईमेल",
  Phone: "फोन",
  Whatsapp: "व्हाट्सप्प",
  INITIATED: "आरंभ केला",
  AUTHORIZED: "अधिकृत",
  SUCCESS: "यश",
  FAILED: "अयशस्वी",
  NEW: "नवीन",
  Search: "शोधा",
  VideoGuide: "मार्गदर्शक",
  ViewMyProfile: "माझे प्रोफाइल पहा",
  SearchKeywordToLearnAboutIt: "जाणून घेण्यासाठी कीवर्ड शोधा",
  rootWidth: "मुळांची रुंदी (इंच)",
  rootDepth: "मुळांची खोली (इंच)",
  dripperDischarge: "ड्रिपर डिस्चार्ज (लिटर)",
  soilRetention: "माती धारणा (मिमी)",
  dripperDistance: "ड्रिपर अंतर (फूट)",
  CalculateValue: "मूल्य मोजणे",
  ReCalculateValue: "पुनर्गणना",
  Schedules: "वेळापत्रक",
  ChangeFarmDesc:
    "तुमचे बदल जतन केले जाणार नाहीत. तुम्हाला सुरू ठेवायचे आहे का?",
  AddNewFarm: "नवीन जोडा",
  Remove: "काढा",
  UseExisting: "विद्यमान",
  NoNotification: "कोणतीही सूचना उपलब्ध नाही",
  Edit: "सुधारणे",
  CopytoPlot: "कॉपी",
  Depth: "खोली",
  inch: "इंच",
  Error: "त्रुटी",
  daysExceed:
  "पीक हंगामाच्या दिवसांपेक्षा दिवस जास्त झाले आहेत असे दिसते",
  updateStages:
    "तुमच्या शेताशी संबंधित अचूक डेटा मिळवण्यासाठी कृपया तुमचे पीक टप्पे अपडेट करा!",
  Later: "नंतर",
  selectDuration: "कालावधी निवडा",
  Lastdurationdays: "शेवटचे {{duration}} दिवस",
  seasonSummary: "हंगामाचा सारांश",
  TotalWaterUsed: "एकूण पाणी वापरले",
  TotalIrrigationEvents: "एकूण सिंचन घटना",
  AvgIntervalbetweenIrrigations: "2 सिंचन दरम्यान सरासरी अंतर",
  NumberofSpraysutilized: "वापरलेल्या फवारण्यांची संख्या",
  AverageSunLight: "सरासरी सूर्यप्रकाश",
  AverageSunshineHours: "सरासरी सूर्यप्रकाश तास",
  Explore: "स्टेज सारांश एक्सप्लोर करा",
  Lai: "एल.ए.आई",
  lai: "एल.ए.आई",
  LaiDetails: "एल.ए.आई तपशील",
  "Not Optimal": "इष्टतम नाही",
  Optimal: "इष्टतम",
  Image: "प्रतिमा",
  AboutDeltaT: "डेल्टा टी म्हणजे काय?",
  AboutVPD: "व्हीपीडी म्हणजे काय?",
  AboutLAI: "एलएआई म्हणजे काय?",
  AddCost: "क्रियाकलाप खर्च",
  costExample: "उदाहरणार्थ: 400",
  selectCurrency: 'मुद्रा निवडा',
  "No Rain": 'पाऊस नाही',
  "AppGuideVideo": 'ॲप गाईड व्हिडिओ',
  ProductVideo: 'प्रोडक्ट व्हिडिओ',
  AgronomyVideo: 'ऍग्रोनॉमि व्हिडिओ',
  TestimonialVideo: 'टेस्टिमोनिअल व्हिडिओ',
  IDPMSuggestion: "{{disease}} चे व्यवस्थापन",
  AddYourFertilizers: "तुमचे खत घाला",
  SprayNotifs: "फवारणीच्या सूचना",
  Cost: 'खर्च',
  seedTreatment: 'बियाणे उपचार',
  insecticide: 'कीटकनाशक',
  nematicide: 'नेमॅटिसाइड',
  fungicide: 'बुरशीनाशक',
  bactericide: 'जीवाणूनाशक',
  InvalidPayment: "अवैध देयक",
  PaymentInProgress: "पैसे भरण्याची प्रक्रिया सुरू आहे",
  "ServiceRequest": "समस्या रिपोर्ट",
  "NoServiceRequestFound": "कोणताही समस्या रिपोर्ट सापडला नाही",
  "YourServiceRequests": "तुमचे समस्या रिपोर्ट",
  "RaiseServiceRequest": "समस्या रिपोर्ट करा",
  "TellAboutYourIssue": "तुमची समस्या सांगा",
  "DoYouWantToOpenThisServiceRequest": "तुम्हाला हा समस्या रिपोर्ट उघडायचा आहे का?",
  "DoYouWantToCloseThisServiceRequest": "तुम्हाला हा समस्या रिपोर्ट बंद करायचा आहे का?",
  ReopenServiceRequest: "पुन्हा सुरू करण्याची",
  CloseServiceRequest: "बंद करा",
  OpenDate: "उघडण्याची तारीख",
  SelectImage: "फोटो निवडा",
  TakeImage: "फोटो काढा",
  open: "उघडा",
  closed: "बंद केले",
  new: "नवीन",
  "pending reminder": "प्रलंबित स्मरण",
  merged: "विलीन",
  removed: "काढून टाकले",
  lastUpdateHours: "शेवटचे {{hours}} तास",
  Refresh: "रिफ्रेश",
  Issue: "समस्या",
  IssueIsRequired: "समस्या भरणे आवश्यक आहे",
  DetailIsRequired: "तपशील आवश्यक आहे",
  ImagesTooLargeError: 'आपण अपलोड करण्याचा प्रयत्न करत असलेल्या प्रतिमा खूप मोठ्या आहेत. कृपया काही प्रतिमा काढा आणि पुन्हा प्रयत्न करा',
  Create: "सृजन",
  ReachedMaxAttachments: 'तुम्ही इमेजसाठी कमाल मर्यादा गाठली आहे. कृपया अधिक जोडण्यापूर्वी काही प्रतिमा काढा.'
};
const en = {
  Farm: "Farm",
  Calendar: "Calendar",
  News: "News",
  Disease: "Disease",
  ShowReportFor: "Show Report For",
  MyFarm: "Farm Info",
  DailyReport: "Daily Report",
  Weather: "Weather",
  Total: "Total",
  DiseaseAnalytics: "Disease Analytics",
  Username: "Username",
  EnterOTP: "Enter OTP",
  EnterPassword: "Enter Password",
  LastUpdated: "Updated",
  SoilMoisture1: "Soil Moisture-1",
  SoilMoisture2: "Soil Moisture-2",
  SoilTemperature: "Soil Temperature",
  LeafWetness: "Leaf Wetness",
  AirTemperature: "Air Temperature",
  AirHumidity: "Air Humidity",
  ViewDetails: "View Details",
  AirPressure: "Air Pressure",
  LightIntensity: "Light Intensity",
  Probability: "Probability",
  DayTimeAverageLux: "Daily Sun Light",
  Rainfall: "Rainfall",
  RainFall: "Rainfall",
  RainFallLastHour: "Rainfall Last Hour",
  TwentyFourHours: "24 Hours",
  Rain: "Rain",
  Alerts: "Alerts",
  SoilMoisture1Details: "Soil Moisture-1 Details",
  SoilMoisture2Details: "Soil Moisture-2 Details",
  SoilTempDetails: "Soil Temperature Details",
  LeafWetnessDetails: "Leaf Wetness Details",
  AirTempDetails: "Air Temperature Details",
  AirHumidityDetails: "Air Humidity Details",
  AirPressureDetails: "Air Pressure Details",
  lightIntensityDetails: "Light Intensity Details",
  rainFallDetails: "Rain Details",
  windSpeedDetails: "Wind Speed Details",
  batteryDetails: "Battery Details",
  battery: "Battery",
  baseStageData: "{{days}} days after {{baseStage}}",
  baseStageDataBefore: "{{days}} days before {{baseStage}}",
  windDirection: "Wind Direction",
  windSpeed: "Wind Speed",
  rainFall: "Rain Fall",
  leafWetness: "Leaf Wetness",
  lightIntensity: "Light Intensity",
  soilMoisture1: "Soil Moisture-1",
  soilMoisture2: "Soil Moisture-2",
  soilTemp: "Soil Temperature",
  signalStrength: "Network",
  signalStrengthDetails: "Network Details",
  baroPressure: "Barometric Pressure",
  airPressure: "Air Pressure",
  airHumidity: "Air Humidity",
  airTemp: "Air Temperature",
  GDD: "Degree Days",
  Gdd: "Degree Days",
  gdd: "Degree Days",
  GDDDetails: "Degree Day Details",
  Inputs: "Inputs",
  Services: "Services",
  Nutrient: "Nutrients",
  StartDate: "Start Date",
  EndDate: "End Date",
  Spray: "Spray Pest/ Disease",
  CulturalPractice: "Farm Practice",
  "Cultural Practice": "Farm Practice",
  Labour: "Labour",
  Seeds: "Seeds",
  Next: "Next",
  Email: "E-mail",
  Mobile: "Mobile",
  RegenerateOTP: "Generate OTP Again ?",
  Fertilizer: "Fertilizers",
  PandDControl: "Pest and Disease Control",
  and: "and",
  FieldPreparation: "Field Preparation",
  Irrigation: "Irrigation",
  ActivityType: "Activity Type",
  RainProbability: "Rain Probability",
  RainQuantity: "Rain Quantity",
  RainQty: "Rain Quantity",
  WindSpeed: "Wind Speed",
  Login: "Log In",
  ForgotPassword: "Forgot your Password ?",
  ResourcesUsed: "Resources Used",
  Date: "Date",
  ActionsToDo: "Actions To Do",
  ActionsDone: "Actions Done",
  LabourCost: "Labour Cost",
  ResourcesCost: "Resources Cost",
  NotRequired: "Not Required",
  MarkAsDone: "Mark As Done",
  ActivityDate: "Activity Date",
  Done: "Done",
  Cancel: "Cancel",
  EnterCostOfResourcesUsed: "Enter Cost Of Resources Used",
  Done: "Done",
  EmptyCalendarItemText: "Nothing planned on this day for your plot!!",
  Posted: "Posted",
  Min: "Min",
  Max: "Max",
  Avg: "Avg",
  DayMin: "Day Time Min",
  DayMax: "Day Time Max",
  DayAvg: "Day Time Avg",
  NoData: "No data available!!",
  Day: "Day",
  Day1: "1 day",
  Week1: "1 Week",
  Month1: "1 Month",
  Year1: "1 Year",
  Custom: "Custom",
  EmailSupport: "Email for Support",
  Network: "Network",
  Power: "Power",
  ProbabilityInYourPlot: "Probability in Your Plot",
  CropCalendar: "Crop Calendar",
  AllCalendar: "Crop Calendar",
  Actions: "Actions",
  Insights: "Insights",
  MyFarm: "My Farm",
  Farms: "Farms",
  NewsFeed: "NewsFeed",
  Notifications: "Notifications",
  Profile: "Profile",
  Logout: "Logout",
  Support: "Support",
  ChangeLanguage: "Language",
  WetDuration: "Wet Duration",
  hours: "hours",
  "Too Wet": "Too Wet",
  "Adequate Moisture": "Adequate ",
  Dry: "Dry",
  "Too Dry": "Too Dry",
  Wet: "Wet",
  Nil: "Nil",
  Low: "Low",
  Medium: "Medium",
  High: "High",
  HIGH: "High",
  NIL: "Nil",
  LOW: "Low",
  MEDIUM: "Medium",
  Night: "Night",
  "Fully Overcast": "Fully Overcast",
  Overcast: "Overcast",
  Sunset: "Sunset",
  Sunrise: "Sunrise",
  "Partly Cloudy": "Partly Cloudy",
  Sunny: "Sunny",
  "Clear Sky": "Clear Sky",
  HoursOfRain: "Hours Of Rain",
  RainHours: "Rain Hours",
  HoursOfSun: "Hours Of Sun",
  CloudCover: "Cloud Cover",
  HourlyWeatherPrediction: "Hourly Weather",
  HourlyPrediction: "Hourly Weather",
  Summary: "Summary",
  SoilMoisture: "Soil Moisture",
  Average: "Average",
  Minimum: "Minimum",
  Maximum: "Maximum",
  LeafWetnessHours: "Leaf Wetness Hours",
  Name: "Name",
  Severity: "Severity",
  ActionsRequired: "Actions Required",
  NoActionsForToday: "No Actions For Today",
  Quantity: "Quantity",
  BestConditionsForSpray: "This time is best for spraying",
  ModerateConditionsForSpray: "Spray with caution ",
  WorstConditionsForSpray: "Do not spray at this time",
  SprayTimings: "Spray Timings",
  DailyReportNotAvailable:
    "Report not available for today. Check if device is working",
  Evapotranspiration: "Evapotranspiration",
  Eto: "ETO",
  eto: "eto",
  DailyETO: "Daily ETO",
  ETODetails: "Evapotranspiration Details",
  Pest: "Pest",
  to: "to",
  ReportsStages: "Reports and Stages",
  StageReports: "Stage Reports",
  StageReport: "Stage Reports",
  SeasonReport: "Season Report",
  CropStages: "Crop Stages",
  PrevSeasonReports: "Past Seasons",
  PlotReports: "Plot Reports",
  ActivitiesDone: "Activities Done",
  Activities: "Activities",
  NoActivitiesDone: "No Activities Done",
  TotalRainFall: "Total Rainfall",
  DayRainfall: "Day Rainfall",
  TotalETO: "Total Evapotranspiration",
  AverageETO: "Average Evapotranspiration",
  AccumulatedGDD: "Accumulated GDD",
  ReportNotAvailable: "Report Not Available",
  StartDateforStage: " Select Start Date",
  Analytics: "Analytics",
  UpdateNotifications: "Update Notifications",
  Notifications: "Notifications",
  Fertigation: "Fertigation",
  WeeklySchedule: "Weekly Schedule ",
  Create: "Create",
  Report: "Report",
  View: "View",
  Crop: "Crop",
  PDF: "PDF",
  CropStartDate: "Crop Start",
  StageStartDate: "Stage Start",
  EndCropSeason: "End Crop Season",
  CurrentStage: "Stage",
  EndConfirmation: "This will end the current season!! Are you sure ?",
  Yes: "Yes",
  No: "No",
  StartCropSeason: "Start New Crop Season",
  RestartCropSeason: "Restart Crop Season",
  NewCrop: "New Crop",
  Calm: "Calm",
  "Light Air": "Light Air",
  "Light Breeze": "Light Breeze",
  "Gentle Breeze": "Gentle Breeze",
  "Moderate Breeze": "Moderate Breeze",
  "Fresh Breeze": "Fresh Breeze",
  "Strong Breeze": "Strong Breeze",
  "Near Gale": "Near Gale",
  Gale: "Gale",
  "Strong Gale": "Strong Gale",
  Storm: "Storm",
  "Violent Storm": "Violent Storm",
  "leafDryness": "Leaf Dryness",
  Direction: "Direction",
  DownloadHistory: "Download History",
  Today: "Today",
  SeasonDegreeDays: "Season Degree Days",
  Payment: "Payment",
  Vouchers: "Vouchers",
  Referral: "Referral",
  PaymentHistory: "Payment History",
  Status: "Status",
  Amount: "Amount",
  PaymentSuccess: "Payment Successful",
  PaymentFailure: "Payment Failed",
  Plot: "Plots",
  Date: "Date",
  PayFull: "Pay in full",
  Validity: "Validity",
  SelectFarm: "Please select your farm(s)",
  Loading: "Loading..",
  ViewBillDetails: "View detailed bill on next page",
  Checkout: "Checkout",
  OfferApplied: "Offer Applied",
  BillDetails: "Bill Details",
  OrderTotal: "Order Total",
  TotalDiscount: "Total Discount",
  ToPay: "To Pay",
  ProceedToPay: "Proceed to Pay",
  BackToHome: "Back to Home",
  OrderNum: "Order no.",
  VoucherHistory: "Voucher",
  ViewDetailedBill: "View detailed bill",
  ReferAndEarn: "Refer your friend to earn rewards!!",
  SubmitReferral: "Submit Referral",
  referralHistory: " Referral History",
  Expired: "Plot payment expired.",
  redirectAfterExpired: "Click here to recharge",
  History: "History",
  CropSeasonAndStageReports: "Crop stages and Reports",
  "Mobile Number*": "Mobile Number*",
  "Friend Name*": "Friend Name*",
  "District*": "District*",
  "Village(Optional)": "Village(Optional)",
  "Thank You": "Thank You.",
  "Thank you for participation": "Thank you for your participation",
  PENDING: "PENDING",
  PROCESSED: "PROCESSED",
  EXPIRED: "EXPIRED",
  "Name must be at least 2 characters": "Name must be at least 2 characters",
  "Name must be at max 50 characters": "Name must be at max 50 characters",
  "Please enter a valid name": "Please enter a valid name",
  "Name is required": "Name is required",
  "Mobile number must be at least 10 digits":
    "Mobile number must be at least 10 digits",
  "Mobile number must be at max 10 digits":
    "Mobile number must be at max 10 digits",
  "Enter a valid mobile": "Enter a valid mobile",
  "Mobile is required": "Mobile is required",
  "District is required": "District is required",
  referralSuccessText: "You have referred to {{name}} successfully.",
  referralFailureText: "Seems like {{name}} is already referred.",
  Weather: "Weather",
  Fertigation: "Fertigation",
  Disease: "Disease",
  Irrigation: "Irrigation",
  Others: "Others",
  Other: "Others",
  "Farm Data": "Farm Data",
  Alerts: "Alerts",
  "Hourly Update": "Hourly Update",
  "Hide Details": "Hide Details",
  "Show Details": "Show Details",
  mm: "mm",
  "km/hr": "km/hr",
  "Avg Lux": "Avg. Lux",
  "Max Lux": "Max. Lux",
  Activity: "Activity",
  Duration: "Duration",
  Hour: "Hrs",
  Minutes: "Min.",
  "Irrigation Details": "Irrigation Details",
  "Dripper flow rate": "Dripper flow rate (lit./hr)",
  "drippers per plant": "No. of drippers per plant",
  numOfPlants: "No. of plants",
  "Total drippers": "Total no. of drippers",
  "Irrigation per plant": "Irrigation per plant",
  "Total Irrigation": "Total Irrigation",
  Save: "Save",
  SaveMoney: "Save",
  "Please fill all the fields!": "Please fill all the fields!",
  quantity: "Qty.",
  Unit: "Unit",
  "+ ADD": "+ ADD",
  List: "List",
  "Water Amount": "Water Amt.",
  Litre: "lit.",
  "Spraying for": "Spraying for",
  "Spraying Molecule": "Spraying Molecule",
  "Please select your": "Please select your",
  capACTIVITY: "ACTIVITY",
  "Select Activity": "Select Activity",
  Irrigation: "Irrigation",
  Nutrients: "Nutrients",
  "Spray, Pest & Disease": "Spray, Pest & Disease",
  "Cultural Activity": "Cultural Activity",
  Saved: "Saved!",
  SavedMessage: "Your data is saved!",
  "Total irrigation can't be 0": "Total irrigation can't be 0.",
  "Please select time duration": "Please select time duration.",
  attention: "Missing fields!",
  "Please select what you are spraying for":
    "Please select what you are spraying for! ",
  "Please select spray molecule(s)": "Please select spray molecule(s)! ",
  "Please add water amount in litres": "Please add water amount in litres! ",
  "Please add quantity": "Please add quantity! ",
  "Please add unit": "Please add unit! ",
  "Please select fertiliser": "Please select fertiliser! ",
  "Please select cultural activity": "Please select cultural activity!",
  Duration: "Duration",
  TotalIrrigation: "Total Irrigation",
  filter: "Filter",
  "Type here to start searching": "Type here to start searching....",
  You_sure_you_want_to_delete: "You sure you want to delete?",
  attentionDelete: "Delete?",
  DiseaseAndPest: "Disease and Pest",
  "Update Crop Stage": "Update Crop Stage",
  "Is this correct?": "Is this information correct?",
  "Search Plot here": " Search Plot here",
  Details: "Details",

  alternaria_fruit_blotch: "Alternaria Fruit Blotch",
  anthracnose: "Anthracnose",
  bacterial_blight: "Bacterial Blight",
  bacterial_canker: "Bacterial Canker",
  bacterial_spot: "Bacterial Spot",
  bacterial_wilt: "Bacterial Wilt",
  black_spot: "Black Spot",
  botrytis: "Botrytis",
  cercospora_leaf_spot: "Cercospora Leaf Spot",
  downy_mildew: "Downy mildew",
  gummosis: "Gummosis",
  powdery_mildew: "Powdery mildew",

  flea_beetle: "Flea beetle",
  mealybug: "Mealybug",
  mites: "Mites",
  thrips: "Thrips",
  aphids: "Aphids",
  fruit_borer: "Fruit Borer",
  citrus_psylla: "Citrus Psylla",
  OR: "OR",
  or: "or",
  SeasonFertilizerRequirements: "Season Fertilizer Requirements",
  Nutrient: "Nutrient",
  SoilAmt: "Soil Amt.",
  FertigationAmt: "Fertigation Amt.",
  Stage: "Stage",
  Weeks: "Weeks",
  Week: "Week",
  MajorNutrients: "Major Nutrients",
  ClearAll: "Clear All",
  Sources: "Sources",
  MicroNutrients: "Micro Nutrients",
  SoilApplication: "Soil Application",
  Season: "Season",
  CropType: "Crop Type",
  CropVariety: "Crop Variety",
  Rootstock: "Rootstock",
  ReportType: "Report Type",
  InputDetails: "Input Details",
  AddReport: "Add Report",
  ReportDate: "Report Date",
  PleaseSelectCrop: "Please Select Crop",
  PleaseSelectCropVariety: "Please Select Crop Variety",
  PleaseSelectRootstock: "Please Select Rootstock",
  PleaseSelectCropSeason: "Please Select Crop Season",
  PleaseSelectReportType: "Please Select Report Type",
  PleaseEnter: "Please Enter ",
  PestAnalytics: "Pest Analytics",
  plotStatusExpired: "Please recharge to activate plot!",
  plotStatusOldData: "Last synced at: {{latestDataTime}}",
  Acres: "Acres",
  Plots: "Plots",
  PlotSaveSuccess: "Plot details saved successfully",
  PlotSaveFail: "Saving plot details failed.",
  ValueOutOfRangePercentage:
    "Please enter value of {{alertVal}} between 0 and 100",
  "About Us": "About Us",
  NoStageReportFound: " No Stage Reports found!!",
  Start: "Start",
  NoSeasonStartedSubText:
    "Looks like you have not started any season yet. Please start a season",
  NoSeasonStartedText: "No season found !!",
  SowingDate: "Sowing Date",
  StartCropSeasonSubText:
    "Please choose a starting date and the type of crop",
  DeltaT: "Delta T",
  DeltaTVPD: "Delta T & VPD",
  VPD: "VPD",
  Marginal: "Marginal",
  MarginalHigherDelta: "Marginal ",
  Preferred: "Preferred",
  Unsuitable: "Unsuitable",
  StagesInvalidDates: "Please select valid dates for stage to be updated",
  DeleteStage: "Delete Stage",
  SureYouWantToDeleteStage:
    "Are you sure you want to delete the selected stage?",
  FileSaved: "File Saved!",
  NoDownloadExcelDataFound: "No data found!",
  YouAreChangingSpray: "You are changing spray/pest/disease!",
  MoleculesListDeleted: "Spray molecules list will be deleted!",
  StaleDataError: "Could not receive data. Please try again later",
  FetchDataError:
    "Device has been registered, please wait for some time to get the data.",
  Success: "Success !",
  Oops: "Oops !!",
  From: "From",
  NA: "NA",
  To: "To",
  "E-Mail fyllo support": "E-Mail fyllo support",
  "Call fyllo support": "Call fyllo support",
  ReadMore: "Read More..",
  fyllo: "fyllo",
  EditActivity: "Edit Activity",
  PermissionDenied: "Permission denied",
  AllowFilePermission: "Please allow file permission!",
  IncorrectEmailPhone: "Incorrect Mobile or Email . ",
  EmailForAccountSupport:
    "Please e-mail to contact@fyllo.in if you are not registered",
  ServerIssue: "Not able to connect to server",
  TryLater: "Please retry after some time",
  IncorrectOTP: "Incorrect OTP . ",
  RefillOTP: "Please re-enter correct OTP",
  FileSaved: "File Saved!",
  NoDataFound: "No data found!",
  "dd-mm-yyyy": "dd-mm-yyyy",
  NoPrevSeasonText: "No previous seasons found !!",
  ChooseAction: "Choose Actions",
  SelectionAction: "Select an action for further details",
  Download: "Download",
  unitkPa: " kPa ",
  unitCelcius: " ℃ ",
  unitmm: " mm ",
  unitLux: " lux ",
  unitkmph: " kmph ",
  Scouting: "Scouting",
  Attachments: "Attach Images",
  Notes: "Notes",
  Description: "Description",
  Upload: "Upload",
  "Read less": "Read less",
  "Read more": "Read more",
  Uploading: "Uploading",
  PleaseSelectDiseaseLevel: "Please select disease severity",
  PleaseSelectPestLevel: "Please select pest severity",
  PleaseSelectNutrientLevel: "Please select nutrient severity",
  "Permissions issue": "Permissions issue!",
  GalleryPermissionIssue:
    "Please allow file/gallery permissions to make this work!",
  CameraPermissionIssue: "Please allow camera permissions to make this work!",
  Gallery: "Gallery",
  Camera: "Camera",
  NoFarmMatched: "No Farm Matched",
  WeatherPredictionNotAllowedSubText:
    "Weather prediction not available for irrigation unit.",
  CheckInternet: "Please Check Internet Connection.",
  DeviceHealth: "Device Health",
  Health: "Health",
  LooksLikeDeviceNotWorking: "Looks like device is not working.",
  DEVICE_HEALTH: "DEVICE HEALTH",
  "Device data being recieved": "Device data being recieved?",
  BatteryReducedText:
    "Battery reduced by {{reductionVal}}% in last {{countVal}} days.",
  "Sensor Conditions": "Sensor Conditions",
  "All sensors are working fine": "All sensors are working.",
  NextFourHoursSprayNote:
    "Please consider next four hours prediction and decide whether to spray or not.",
  Location: "Location",
  Pay: "Pay",
  "Pay using": "Pay using",
  "Other Payment Methods": "Other Payment Methods",
  "Card Bank Transfer": "Card/Bank Transfer",
  "Which plot?": "Which plot?",
  "What activity?": "What activity?",
  DeviceNotInstalledWarning: "Install device on this plot to see data!",
  CantUpdateData: "Cant't update data. Please try later",
  AddPlot: "Add Plot",
  HowMuchToIrrigate: "For how much duration did you irrigate?",
  ActHours: "Hours",
  ActMinutes: "Minutes",
  WhatWithinCultActivity: "What within cultural activity?",
  Area: "Area",
  NoActivityFound: "No Activity Found",
  OldStyle: "Old Style",
  ActivityNotedAt: "Noted at",
  "New crop Season started": "New crop Season started",
  ObservedDiseaseActivity: "For Example: Observerd Downy Mildew in Plot",
  OtherActivity: "For Example: Did Ploughing in the plot",
  NutrientActivity: "For Example: Applied 50 kg Urea",
  AppliedUreaActivity: "For Example: Applied 50 kg Urea",
  SprayActivity: "For Example: Sprayed Copper 1Kg in 500 litre water",
  ApplyFilter: "Apply Filter",
  SoilType: "Soil Type",
  Foliar: "Foliar",
  FoliarAmt: "Foliar Amt",
  Soil: "Soil",
  PleaseSelectAtleastOneMethod: "Please select atleast one option",
  AvailableOptions: "Available Options",
  NoCropSelected: "No crop selected",
  HideOptions: "Hide Options",
  ShowOptions: "Show Options",
  sprayRequired:
    "Spray one of the below options in {{waterInLitres}} litres/acre of water for {{purpose}}",
  nutrientRequired:
    "Apply one of the below options via {{type}} to provide the required amount of:",
  foliar: "Foliar spray",
  fertigation: "Fertigation",
  soilApplication: "Soil application",
  SelectOneOption: "Select One Option",
  CreateReport: "Create Report",
  ViewHistory: "View History",
  DownloadPDF: "Download PDF",
  Summry: "Summary",
  Weekly: "Weekly",
  StageSummary: "Stage Summary",
  "UnitsinKg/acre": "Units in Kg/acre",
  Options: "Options",
  Applyanyoneoftheoptions: "Apply any one of the options",
  riskLevel: "Risk",
  AnalysisStage: "Analysis Stage/ Type",
  PleaseSelectAnalysisStage: "Please Select Analysis Stage",
  days: "days",
  Register: "Register",
  EnterMobileNoEmailID: "Enter Mobile No",
  Resend: "Resend",
  Seconds: "Seconds",
  Number: "Mobile No.",
  Useralreadyregistered: "User is already registered",
  InvalidNumber: "Invalid Number",
  FarmDetails: "Farm Details",
  FarmName: "Farm Name",
  PlotDetails: "Plot Details",
  PlotName: "Plot Name",
  DifferentSoilTypes: "Different Soil Types",
  Sandy: "Sandy",
  SandyLoam: "Sandy Loam",
  Loamy: "Loamy",
  Clay: "Clay",
  PlotArea: "Plot Area",
  Plotareacantbegreaterthanfarmarea:
    "Plot area can't be greater than farm area",
  Allowfyllotoaccessyourlocation: "Allow fyllo to access your location?",
  SAVETOADDCROP: "SAVE TO ADD CROP",
  CropDetails: "Crop Details",
  CropName: "Crop Name",
  SowingPruningDate: "Sowing/ Pruning Date",
  PlantationYear: "Plantation Year",
  AddDevice: "Add Device",
  Adddevicetotheapp:
    "Add your device to the app by scanning the QR code in the device",
  EnterIMEINumber: "Enter IMEI Number",
  Scan: "Scan",
  ATTACHDEVICE: "ATTACH DEVICE",
  PopoverFarmDetails: "View farm data and predictions",
  PopoverCalendarDetails: "Add and view activities",
  popoverDiseaseDetails: "Details about diseases & pests",
  popoverAnalyticsDetails: "View Fertigation Details",
  popoverPlotsDetails: "Select other farm/plot",
  popoverDailyReportDetails: "Check daily report",
  popoverMyFarmDetails: "Live farm data and history",
  popoverWeatherDetails: "Hourly and daily Weather Prediction",
  popoverCropStageDetails: "View and update crop stage",
  popoverShareButtonDetails: "Share data",
  popoverUpdatedTimeDetails: " Time of last update",
  popoverHealthDetails: "Check device & sensors health",
  popoverPlusButtonDetails: "Add activities",
  popoverBarMenuDetails: " Payment, referral , support",
  popoverNotificationDetails: "Check notifications here",
  popoverSoilMoistureDetails: "Live data, click to check history",
  Next: "Next",
  Previous: "Previous",
  Skip: "Skip",
  Finish: "Finish",
  Delete: "Delete",
  Cancel: "Cancel",
  Redo: "Rewrite",
  ClickandscanQR:
    "Click on the scan button below and scan the QR code on this area of the device",
  Welcome: "Welcome",
  Welcomeback: "Welcome back",
  HelpWithdetails: "Please help us with a few more details",
  Language: "Language",
  InvalidIMEINumber: "Invalid IMEI Number",
  ApplicationMode: "Application Mode",
  kgHectare: "kg/ha",
  percent: "%",
  UnitPPM: "ppm",
  Select: "Select",
  LogoutModalDescription: "Are you sure you want to logout?",
  YourReport: "Your Report",
  RemovePhoto: "Remove Photo",
  addActivity: "Add Activity",
  notificationSettings: "Notification Settings",
  Settings: "Settings",
  makeAPayment: "Make a Payment",
  pastTransactions: "Past Transactions",
  NoPesticideSchedule: "No Pesticide Schedule Found!",
  UpcomingSpray: "Upcoming Spray",
  Conditional: "Conditional",
  Fixed: "Fixed",
  Purpose: "Purpose",
  Chemical: "Chemical",
  WaterQty: "Water Qty",
  CompleteSchedule: "Complete Schedule",
  UsePasswordInstead: "Use password instead",
  useOTPInstead: "Use OTP Instead",
  cropStage: "Crop Stage",
  whenToSpray: "When to Spray",
  whenToSprayDescription: `When {{severity}} for {{days}} days`,
  notApplicable: "Not Applicable",
  StagewiseSprayGuideline: "Stagewise Spray Guideline",
  back: "Back",
  registerNewUser: "Register New User",
  existingUserLogin: "Log in Existing user",
  ComebackLater: "We are working on it, please check back soon!",
  SelectAPlot: "Select plot",
  ShowReportFor: "Show Report For",
  StartStage: "Start Stage",
  EndStage: "End Stage",
  Neutrients: "Nutrients",
  NoSpray: "No of Spray",
  ThisWeek: "This Week",
  TillDate: "Till Today",
  ThisMonth: "This Month",
  LastMonth: "Last Month",
  irrigationDetected: "irrigation detected!",
  Insights: "Insights",
  DurationInMinutes: "Duration (In Minutes)",
  ThisSeason: "This Season",
  SelectSeason: "Select Season",
  stageNotStartedWarning: "{{stageName}} Stage has not started yet",
  CreditBalance: "Credit Balance",
  SearchContact: "Search a Contact",
  EnterName: "Enter Name",
  Contacts: "Contacts",
  NoContactsFound: "No Contacts Found",
  WhyFyllo: "Why Fyllo?",
  CropQuality: "Crop Quality and Yield",
  DiseaseManagement: "Disease and Pest Management",
  PreciseIrrigation: "Precise Irrigation",
  BestService: "Best Service",
  Sendvia: "Send via",
  Howitwork: "How does it work?",
  creditedyouraccount:
    "On each referral ₹20/- will be credited to your fyllo recharge account.",
  maximumearned:
    "A maximum of ₹500 can be earned by sharing it to 25 people.",
  friendinstallsfyllo:
    "If your friend installs fyllo, you will recieve additional ₹ 250 and your friend will get ₹500 off on fyllo installation.",
  off: "off",
  Mosteffectivereferers:
    "Most effective referers of every 6 months will be rewarded with nero device or a trip to tirupathi.",
  amountcredited: "₹{{amount}} has successfully been credited",
  fylloaccount: "to your fyllo account",
  Backtoreferral: "Back to referral",
  TotalCreditsEarned: "Total Credits Earned",
  CreditsRedeemed: "Credits Redeemed",
  GotReferralCode: "Got a referral code?",
  ClaimYourRewards: "Claim Your Rewards",
  EnterCode: "Enter Your Code",
  EnterValidReferralCode: "Enter a valid referral code",
  REDEEMED: "REDEEMED",
  SelectAPlot: "Select A Plot",
  ShowReportFor: "Show report",
  StartStage: "Initial Stage",
  EndStage: "Last Stage",
  NoSpray: "Number of sprays ",
  ThisWeek: "This week",
  TillDate: "Till date",
  ThisMonth: "this month",
  LastMonth: "last month",
  CustomDate: "Date",
  SpecifyStage: "Stage",
  SelectStage: "Select the stage",
  ThisStage: "This phase ",
  CustomStage: "Stage Range",
  irrigationDetected: "Irrigation detected! ",
  DurationInMinutes: "Duration (in minutes) ",
  ThisSeason: "Season",
  CREDITED: "CREDITED",
  WAITING: "WAITING",
  referralScreen: "Refer and Earn",
  Apply: "Apply",
  Show: "Show",
  UseCreditBalance: "Use Credit Balance",
  ReferralBonus: "Referral Bonus",
  RefferalCodeApplied: "Refferal Code Applied",
  ManageFarmUsingFyllo: "Hello,\nI am using Fyllo Device to manage my farm.",
  FylloBenefit: " I got following major benefits by using Fyllo:",
  InstallFyllo:
    "I urge you to install Fyllo at your farm too. You can contact Fyllo team on WhatsApp at  +91-9380908490 or via email at contact@fyllo.in",
  UseRefferalCode:
    "Use {{code}} referral code to get discount on your next recharge in Fyllo App",
  SomeErrorOccured: "Some Error Occured",
  AnyChangeInReport: "Any Changes in Report?",
  DeleteAccount: "Delete Account",
  ReasonToDelete: "Choose the Reason why you wanted to delete your account?",
  NoFruitfulResult: "I am not getting any fruitful result",
  NothingWorking: "Nothing is working right",
  PrivacyConcerns: "I have privacy concerns",
  WhatisNotWorking:
    "We are costantly improving ourself to give better result to you. Can You please share to us why are you dissatisfied?",
  Warning: "Warning",
  DeleteWarningMessage:
    "If you delete your account you will loose all data and subscriptions permanently",
  OurTeamWillContact:
    "Our team will contact you soon for deletion of account.",
  Close: "Close",
  GoBack: "Go Back",
  GiveYourThoughts: "Give your thoughts",
  ConfirmDeletion: "Confirm Deletion",
  AnyChangeInReport: "Any Changes in Report?",
  DeleteAccount: "Delete Account",
  UpdateStage: "Update Stage",
  EndSeasonWarningMsg: "Are you sure that you are wanted to end this season?",
  StageUpdated: "Stage Updated",
  Retry: "Retry",
  NoWeekSelected: "No Week Selected",
  thingsToRememberFertigation: "Things to remember while Fertigation",
  thingsToRemember: "Things to remember",
  NoDiseaseDataFound: "No Disease Data Found",
  NoPestDataFound: "No Pest Data Found",
  Range: "Range",
  Value: "Value",
  DoYouNeedHelp: "Are you facing any issues?",
  LetUsHelpYou: "Let us help you",
  ChatWithUs: "Chat with us",
  CallUs: "Call us",
  update: "Update",
  cancel: "Cancel",
  NewUpdateAvailable: "Update Available!!!",
  PleaseUpdateYour: "Please update Your app to use it",
  helpMessage:
    "Hi, I need help for my farm {{farmId}}. My user name is {{username}}",
  notApplicableInfo: "{{pestOrDiseaseName}} is not applicable in this stage",
  pesticideScheduleNotAllowed:
    "Pesticide schedule is not available for Irrigation Unit.",
  latestReport: "Latest Report",
  noReportsAvailable: "No reports available",
  uploadPdfReport: "Upload Report",
  scanningPdf: "Scanning Document",
  cantScanPdf: "We are unable to scan this document",
  tryAgainPdf: "Please input nutrients values manually",
  basedOnUploadedReport: "Based on the uploaded report",
  ScheduleGeneratedOnDefaultReport: "Schedule generated on default report",
  ForPreciseSchedule: "For precise schedule",
  remember: "Remember",
  lowest: "Lowest",
  average: "Average",
  highest: "Highest",
  onAverage: "on Average",
  Vpd: "Vpd",
  vpd: "Vpd",
  deltaT: "Delta T",
  newVersionAvailable: "New Version Available!!",
  SelectPlot: "Select Plot",
  RechargeUptoDate: "Recharge Upto a Date",
  getUptoDiscount: "Get Upto {{discountPercentage}}% additional discount",
  Yearly: "Yearly",
  Monthly: "Monthly",
  Quarterly: "Quarterly",
  DueInDays: "Due in {{days}} days",
  ContactSupport: "Contact Support",
  Email: "Email",
  Phone: "Phone",
  Whatsapp: "Whatsapp",
  INITIATED: "INITIATED",
  AUTHORIZED: "AUTHORIZED",
  SUCCESS: "SUCCESS",
  FAILED: "FAILED",
  NEW: "NEW",
  Search: "Search",
  VideoGuide: "Guides",
  ViewMyProfile: "View My Profile",
  SearchKeywordToLearnAboutIt: "Search keyword to learn about it",
  rootWidth: "Width of Roots (inch)",
  rootDepth: "Depth of Roots (inch)",
  dripperDischarge: "Dripper Discharge (ltr)",
  soilRetention: "Soil Retention (mm)",
  dripperDistance: "Dripper Distance (ft)",
  CalculateValue: "Calculate",
  ReCalculateValue: "Recalculate",
  Schedules: "Schedules",
  ChangeFarmDesc: "Your changes will not be saved. Do you wish to continue?",
  AddNewFarm: "Add New",
  Remove: "Remove",
  UseExisting: "Existing",
  NoNotification: "No Notification Available",
  Edit: "Edit",
  CopytoPlot: "Copy",
  Depth: "Depth",
  inch: "inch",
  Error: "Error",
  daysExceed:
    "It seems your days have exceeded the days of the crop season days.",
  updateStages:
    "Please update your stages to get accurate data regarding your farm!",
  Later: "Later",
  selectDuration: "Select a duration",
  Lastdurationdays: "Last {{duration}} days",
  seasonSummary: "Season Summary",
  TotalWaterUsed: "Total Water Used",
  TotalIrrigationEvents: "Total Irrigation Events",
  AvgIntervalbetweenIrrigations: "Avg Interval between 2 Irrigations",
  NumberofSpraysutilized: "Number of sprays utilized",
  AverageSunLight: "Average sunlight",
  AverageSunshineHours: "Average sunshine hours",
  Explore: "Explore stage summary",
  Lai: "LAI",
  "Not Optimal": "Not Optimal",
  lai: "LAI",
  LaiDetails: "LAI Details",
  Optimal: "Optimal",
  Image: "Image",
  AboutDeltaT: "What is DeltaT?",
  AboutVPD: "What is VPD?",
  AboutLAI: "What is LAI?",
  AddCost: "Cost of Activity",
  costExample: "For Example: 400",
  selectCurrency: 'Select Currency',
  "No Rain": 'No Rain',
  AppGuideVideo: "App Guide Videos",
  ProductVideo: 'Product Videos',
  AgronomyVideo: 'Agronomy Videos',
  TestimonialVideo: 'Testimonial Videos',
  IDPMSuggestion: "Management of {{disease}}",
  AddYourFertilizers: "Add your fertilizers",
  SprayNotifs: "Spray Notifications",
  Cost: 'Cost',
  seedTreatment: 'Seed treatment',
  insecticide: 'Insecticide',
  nematicide: 'Nematicide',
  fungicide: 'Funigicide',
  bactericide: 'Bactericide',
  InvalidPayment: "Invalid Payment",
  PaymentInProgress: "Payment in progress",
  ServiceRequest: "Service Request",
  NoServiceRequestFound: "No service request found",
  YourServiceRequests: "Your service requests",
  RaiseServiceRequest: "Raise service request",
  TellAboutYourIssue: "Tell about your issue",
  DoYouWantToOpenThisServiceRequest: "Do you want to open this service request?",
  DoYouWantToCloseThisServiceRequest: "Do you want to close this service request?",
  ReopenServiceRequest: "Repoen",
  CloseServiceRequest: "Close",
  OpenDate: "Created on",
  SelectImage: "Select image",
  TakeImage: "Take image",
  open: "Open",
  closed: "Closed",
  new: "New",
  "pending reminder": "Pending reminder",
  merged: "Merged",
  removed: "Removed",
  lastUpdateHours: "Last {{hours}} hours",
  Refresh: "Refresh",
  Issue: "Issue",
  IssueIsRequired: "Issue is required",
  DetailIsRequired: "Details is required",
  ImagesTooLargeError: 'The images you are trying to upload are too large. Please remove a few images and try again',
  ReachedMaxAttachments: "You've reached the maximum limit for attachments. Please remove some attachments before adding more",
  Analysis: "Analysis"
};
const ka = {
  Farm: "ಕೃಷಿಕ್ಷೇತ್ರ",
  Calendar: "ಕ್ಯಾಲೆಂಡರ್",
  News: "ಸುದ್ದಿ",
  Disease: "ರೋಗ",
  MyFarm: "ನನ್ನ ಫಾರ್ಮ್",
  DailyReport: "ಡ್ಯಾಶ್‌ಬೋರ್ಡ್",
  Weather: "ಹವಾಮಾನ",
  Total: "ಒಟ್ಟು",
  DiseaseAnalytics: "ರೋಗ ವಿಶ್ಲೇಷಣೆ",
  Username: "ಬಳಕೆದಾರರಹೆಸರು",
  EnterOTP: "ನಮೂದಿಸಿ OTP",
  EnterPassword: "ಪಾಸ್ವರ್ಡ್ ನಮೂದಿಸಿ",
  LastUpdated: "ಇತ್ತೀಚಿನ ನವೀಕರಿಸಲಾಗಿದೆ",
  SoilMoisture1: "ಮಣ್ಣಿನ ತೇವಾಂಶ-1",
  SoilMoisture2: "ಮಣ್ಣಿನ ತೇವಾಂಶ-2",
  SoilTemperature: "ಮಣ್ಣಿನ ತಾಪಮಾನ",
  LeafWetness: "ಎಲೆ ತೇವ",
  AirTemperature: "ಗಾಳಿಯ ತಾಪಮಾನ",
  AirHumidity: "ಗಾಳಿಯ ಆರ್ದ್ರತೆ",
  AirPressure: "ಗಾಳಿಯ ಒತ್ತಡ",
  LightIntensity: "ಬೆಳಕಿನ ತೀವ್ರತೆ",
  DayTimeAverageLux: "ದೈನಂದಿನ ಸೂರ್ಯನ ಬೆಳಕು",
  Rainfall: "ಮಳೆ",
  RainFall: "ಮಳೆ",
  RainFallLastHour: "ಕಳೆದ ಗಂಟೆ ಮಳೆ",
  TwentyFourHours: "24 ಗಂಟೆ",
  Rain: "ಮಳೆ",
  Alerts: "ಎಚ್ಚರಿಕೆ",
  Probability: "ಸಂಭವನೀಯತೆ",
  ViewDetails: "ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
  SoilMoisture1Details: "ಮಣ್ಣಿನ ತೇವಾಂಶ-1 ವಿವರಗಳು",
  SoilMoisture2Details: "ಮಣ್ಣಿನ ತೇವಾಂಶ-2 ವಿವರಗಳು",
  SoilTempDetails: "ಮಣ್ಣಿನ ತಾಪಮಾನ ವಿವರಗಳು",
  LeafWetnessDetails: "ಎಲೆ ತೇವದ ವಿವರಗಳು",
  AirTempDetails: "ವಾಯು ತಾಪಮಾನದ ವಿವರಗಳು",
  AirHumidityDetails: "ಗಾಳಿಯ ಆರ್ದ್ರತೆ ವಿವರಗಳು",
  AirPressureDetails: "ವಾಯು ಒತ್ತಡದ ವಿವರಗಳು",
  lightIntensityDetails: "ಬೆಳಕಿನ ತೀವ್ರತೆಯ ವಿವರಗಳು",
  rainFallDetails: "ಮಳೆ ವಿವರಗಳು",
  windSpeedDetails: "ವಿಂಡ್ ಸ್ಪೀಡ್ ವಿವರಗಳು",
  batteryDetails: "ಬ್ಯಾಟರಿ ವಿವರಗಳು",
  battery: "ಬ್ಯಾಟರಿ",
  windDirection: "ಗಾಳಿಯ ದಿಕ್ಕು",
  windSpeed: "ಗಾಳಿಯ ವೇಗ",
  rainFall: "ಮಳೆ",
  leafWetness: "ಎಲೆ ತೇವದ",
  lightIntensity: "ಬೆಳಕಿನ ತೀವ್ರತೆ",
  soilMoisture1: "ಮಣ್ಣಿನ ತೇವಾಂಶ-1",
  soilMoisture2: "ಮಣ್ಣಿನ ತೇವಾಂಶ-2",
  soilTemp: "ಮಣ್ಣಿನ ತಾಪಮಾನ",
  signalStrength: "ಜಾಲಬಂಧ",
  signalStrengthDetails: "ಜಾಲಬಂಧ ವಿವರಗಳು",
  baroPressure: "ವಾತಾವರಣದ ಒತ್ತಡ",
  airPressure: "ಗಾಳಿಯ ಒತ್ತಡ",
  airHumidity: "ಗಾಳಿಯ ಆರ್ದ್ರತೆ",
  airTemp: "ಗಾಳಿಯ ಉಷ್ಣತೆ",
  GDD: "ಜಿಡಿಡಿ",
  Gdd: "ಜಿಡಿಡಿ",
  gdd: "ಜಿಡಿಡಿ",
  GDDDetails: "ಜಿಡಿಡಿ ವಿವರಗಳು",
  Inputs: "ಒಳಹರಿವು",
  Services: "ಸೇವೆಗಳು",
  Nutrient: "ಪೋಷಕಾಂಶಗಳು",
  StartDate: "ಪ್ರಾರಂಭ ದಿನಾಂಕ",
  EndDate: "ಅಂತಿಮ ದಿನಾಂಕ",
  Spray: "ಸಿಂಪಡಿಸಿ ಕೀಟ/ ರೋಗ",
  CulturalPractice: "ಕೃಷಿ ಅಭ್ಯಾಸಗಳು",
  "Cultural Practice": "ಕೃಷಿ ಅಭ್ಯಾಸಗಳು",
  Labour: "ಕಾರ್ಮಿಕ",
  Seeds: "ಬೀಜಗಳು",
  Next: "ಮುಂದಿನದು",
  Email: "ಇಮೇಲ್",
  Mobile: "ಮೊಬೈಲ್",
  RegenerateOTP: "ಮತ್ತೆ ಒಟಿಪಿ ರಚಿಸಿ ?",
  Fertilizer: "ರಸಗೊಬ್ಬರಗಳು",
  PandDControl: "ಕೀಟ ಮತ್ತು ರೋಗ ನಿಯಂತ್ರಣ",
  and: "ಮತ್ತು",
  FieldPreparation: "ಕ್ಷೇತ್ರ ತಯಾರಿ",
  Irrigation: "ನೀರಾವರಿ",
  ActivityType: "ಚಟುವಟಿಕೆ ಪ್ರಕಾರ",
  RainProbability: "ಮಳೆ ಸಂಭವನೀಯತೆ",
  RainQuantity: "ಮಳೆ ಪ್ರಮಾಣ",
  RainQty: "ಮಳೆ ಪ್ರಮಾಣ",
  WindSpeed: "ಗಾಳಿಯ ವೇಗ",
  Login: "ಲಾಗಿನ್ ಮಾಡಿ",
  ForgotPassword: "ನಿಮ್ಮ ಪಾಸ್‌ವರ್ಡ್ ಅನ್ನು ಮರೆತಿದ್ದೀರಾ ?",
  ResourcesUsed: "ಬಳಸಿದ ಸಂಪನ್ಮೂಲಗಳು",
  Date: "ದಿನಾಂಕ",
  ActionsToDo: "ಮಾಡಬೇಕಾದ ಕ್ರಮಗಳು",
  ActionsDone: "ಮಾಡಿದ ಕ್ರಮಗಳು",
  LabourCost: "ಕಾರ್ಮಿಕ ವೆಚ್ಚ",
  ResourcesCost: "ಸಂಪನ್ಮೂಲ ವೆಚ್ಚ",
  NotRequired: "ಅಗತ್ಯವಿಲ್ಲ",
  MarkAsDone: "ಗುರುತು ಮುಗಿದಿದೆ",
  ActivityDate: "ಚಟುವಟಿಕೆ ದಿನಾಂಕ",
  Done: "ಮುಗಿದಿದೆ",
  Cancel: "ರದ್ದುಮಾಡಿ",
  EnterCostOfResourcesUsed: "ಬಳಸಿದ ಸಂಪನ್ಮೂಲಗಳ ವೆಚ್ಚವನ್ನು ನಮೂದಿಸಿ",
  Done: "ಮುಗಿದಿದೆ",
  EmptyCalendarItemText: "ನಿಮ್ಮ ಕಥಾವಸ್ತುವಿಗೆ ಈ ದಿನ ಯಾವುದನ್ನೂ ಯೋಜಿಸಲಾಗಿಲ್ಲ!!",
  Posted: "ಪೋಸ್ಟ್",
  Min: "ಕನಿಷ್ಠ",
  Max: "ಗರಿಷ್ಠ",
  Avg: "ಸರಾಸರಿ",
  DayMin: "ದಿನದ ಸಮಯ ಕನಿಷ್ಠ",
  DayMax: "ದಿನದ ಸಮಯ ಗರಿಷ್ಠ",
  DayAvg: "ದಿನದ ಸಮಯ ಸರಾಸರಿ",
  NoData: "ಯಾವುದೇ ಮಾಹಿತಿ ಇಲ್ಲ!!",
  Day: "ದಿನ",
  Day1: "1 ದಿನ",
  Week1: "1 ವಾರ",
  Month1: "1 ತಿಂಗಳು",
  Year1: "1 ವರ್ಷ",
  Custom: "ಇತರರು",
  EmailSupport: "ಬೆಂಬಲಕ್ಕಾಗಿ ಇಮೇಲ್",
  Network: "ನೆಟ್‌ವರ್ಕ್",
  Power: "ಶಕ್ತಿ",
  ProbabilityInYourPlot: "ನಿಮ್ಮ ಕಥಾವಸ್ತುವಿನಲ್ಲಿ ಸಂಭವನೀಯತೆ",
  CropCalendar: "ಬೆಳೆ ಕ್ಯಾಲೆಂಡರ್",
  AllCalendar: "ಬೆಳೆ ಕ್ಯಾಲೆಂಡರ್",
  Actions: "ಕಾರ್ಯಕ್ರಮಗಳು",
  Insights: "ಒಳನೋಟಗಳು",
  Farms: "ಕೃಷಿಭೂಮಿಗಳು",
  NewsFeed: "ಸುದ್ದಿ ಫೀಡ್",
  Notifications: "ಅಧಿಸೂಚನೆಗಳು",
  Profile: "ಪ್ರೊಫೈಲ್",
  Logout: "ಲಾಗ್ ಔಟ್",
  Support: "ಬೆಂಬಲ",
  ChangeLanguage: "Language",
  WetDuration: "ಆರ್ದ್ರ ಅವಧಿ",
  hours: "ಗಂಟೆಗಳು",
  "Too Wet": "ತುಂಬಾ ತೇವ",
  "Adequate Moisture": "ಸಾಕಷ್ಟು ",
  Dry: "ಒಣಗಿಸಿ",
  "Too Dry": "ತುಂಬಾ ಒಣಗಿದೆ",
  Wet: "ಒದ್ದೆ",
  Nil: "Nil",
  Low: "ಕಡಿಮೆ",
  Medium: "ಮಾಧ್ಯಮ",
  High: "ಹೆಚ್ಚು",
  HIGH: "ಹೆಚ್ಚು",
  NIL: "Nil",
  LOW: "ಕಡಿಮೆ",
  MEDIUM: "ಮಾಧ್ಯಮ",
  Night: "ರಾತ್ರಿ",
  "Fully Overcast": "ಪೂರ್ಣ ಮೋಡ",
  Overcast: "ಮೋಡ ಕವಿದ ವಾತಾವರಣ",
  Sunset: "ಸೂರ್ಯಾಸ್ತ",
  Sunrise: "ಸೂರ್ಯೋದಯ",
  "Partly Cloudy": "ಭಾಗಶಃ ಮೋಡ",
  Sunny: "ಬಿಸಿಲು",
  "Clear Sky": "ಶುಭ್ರ ಆಕಾಶ",
  // ClearAll:"ಎಲ್ಲವನ್ನೂ ಆಯ್ಕೆ ಮಾಡಬೇಡಿ",
  ClearAll: "ಸ್ಪಷ್ಟ ಎಲ್ಲಾ",
  HoursOfRain: "ಗಂಟೆಗಳ ಮಳೆ",
  RainHours: "ಗಂಟೆಗಳ ಮಳೆ",
  HoursOfSun: "ಸೂರ್ಯನ ಸಮಯ",
  CloudCover: "ಮೇಘ ಕವರ್",
  HourlyWeatherPrediction: "ಗಂಟೆಯ ಹವಾಮಾನ",
  HourlyPrediction: "ಗಂಟೆಯ ಹವಾಮಾನ",
  Summary: "ಸಾರಾಂಶ",
  SoilMoisture: "ಮಣ್ಣಿನ ತೇವಾಂಶ",
  Minimum: "ಕನಿಷ್ಠ",
  Maximum: "ಗರಿಷ್ಠ",
  Average: "ಸರಾಸರಿ",
  LeafWetnessHours: "ಎಲೆಗಳ ತೇವದ ಗಂಟೆಗಳ",
  Name: "ಹೆಸರು",
  Severity: "ತೀವ್ರತೆ",
  ActionsRequired: "ಅಗತ್ಯವಿರುವ ಕ್ರಮಗಳು",
  NoActionsForToday: "ಇಂದು ಯಾವುದೇ ಕ್ರಿಯೆಗಳಿಲ್ಲ",
  Quantity: "ಪ್ರಮಾಣ",
  BestConditionsForSpray: "ಸಿಂಪಡಿಸಲು ಈ ಸಮಯ ಉತ್ತಮವಾಗಿದೆ",
  ModerateConditionsForSpray: "ಎಚ್ಚರಿಕೆಯಿಂದ ಸಿಂಪಡಿಸಿ ",
  WorstConditionsForSpray: "ಈ ಸಮಯದಲ್ಲಿ ಸಿಂಪಡಿಸಬೇಡಿ",
  SprayTimings: "ಇಂದು ಸಮಯವನ್ನು ಸಿಂಪಡಿಸಿ",
  DailyReportNotAvailable:
    "ವರದಿ ಇಂದು ಲಭ್ಯವಿಲ್ಲ. ಸಾಧನ ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿದೆಯೇ ಎಂದು ಪರಿಶೀಲಿಸಿ",
  Evapotranspiration: "ಬಾಷ್ಪೀಕರಣ",
  Eto: "ಬಾಷ್ಪೀಕರಣ",
  eto: "ಬಾಷ್ಪೀಕರಣ",
  DailyETO: "ದೈನಂದಿನ ಬಾಷ್ಪೀಕರಣ",
  ETODetails: "ಬಾಷ್ಪೀಕರಣ ವಿವರಗಳು",
  Pest: "ಕೀಟ",
  to: "ಗೆ",
  ReportsStages: "ವರದಿಗಳು ಮತ್ತು ಹಂತಗಳು",
  StageReports: "ಹಂತದ ವರದಿಗಳು",
  StageReport: "ಹಂತದ ವರದಿಗಳು",
  SeasonReport: "ಸೀಸನ್ ವರದಿ",
  CropStages: "ಬೆಳೆ ಹಂತಗಳು",
  PrevSeasonReports: "ಹಿಂದಿನ .ತುಗಳು",
  PlotReports: "ಕಥಾವಸ್ತುವಿನ ವರದಿಗಳು",
  ActivitiesDone: "ಚಟುವಟಿಕೆಗಳು ಮುಗಿದಿವೆ",
  Activities: "ಚಟುವಟಿಕೆಗಳು",
  NoActivitiesDone: "ಯಾವುದೇ ಚಟುವಟಿಕೆಗಳು ಮುಗಿದಿಲ್ಲ",
  TotalRainFall: "ಒಟ್ಟು ಮಳೆ",
  DayRainfall: "ದಿನದ ಮಳೆ",
  TotalETO: "ಒಟ್ಟು ಬಾಷ್ಪೀಕರಣ",
  AverageETO: "ಸರಾಸರಿ ಬಾಷ್ಪೀಕರಣ",
  AccumulatedGDD: "ಸಂಚಿತ GDD",
  ReportNotAvailable: "ವರದಿ ಲಭ್ಯವಿಲ್ಲ",
  StartDateforStage: " ಪ್ರಾರಂಭ ದಿನಾಂಕವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Analytics: "ವಿಶ್ಲೇಷಣೆ",
  UpdateNotifications: "ಅಧಿಸೂಚನೆಗಳನ್ನು ನವೀಕರಿಸಿ",
  Crop: "ಬೆಳೆ",
  CropStartDate: "ಬೆಳೆ ಪ್ರಾರಂಭ",
  StageStartDate: "ಹಂತ ಪ್ರಾರಂಭ",
  EndCropSeason: "ಬೆಳೆ .ತುವನ್ನು ಕೊನೆಗೊಳಿಸಿ",
  CurrentStage: "ಹಂತ",
  EndConfirmation:
    "ಇದು ಪ್ರಸಕ್ತ season ತುವನ್ನು ಕೊನೆಗೊಳಿಸುತ್ತದೆ !! ನೀವು ಖಚಿತವಾಗಿರುವಿರಾ ?",
  Yes: "ಹೌದು",
  No: "ಇಲ್ಲ",
  StartCropSeason: "ಹೊಸ ಬೆಳೆ ಋತುವನ್ನು ಪ್ರಾರಂಭಿಸಿ",
  RestartCropSeason: "ಹಳೆಯ ಬೆಳೆ ಋತುವನ್ನು ಪುನರಾರಂಭಿಸಿ",
  NewCrop: "ಹೊಸ ಬೆಳೆ",
  Calm: "ಶಾಂತ",
  "Light Air": "ಲಘು ಗಾಳಿ",
  "Light Breeze": "(Breeze)ಲಘು ಗಾಳಿ",
  "Gentle Breeze": "ಜೆಂಟಲ್ ಬ್ರೀಜ್",
  "Moderate Breeze": "ಮಧ್ಯಮ ತಂಗಾಳಿ",
  "Fresh Breeze": "ತಾಜಾ ತಂಗಾಳಿ",
  "Strong Breeze": "ಬಲವಾದ ತಂಗಾಳಿ",
  "Near Gale": "ಗೇಲ್ ಹತ್ತಿರ",
  Gale: "ಗೇಲ್",
  "Strong Gale": "ಸ್ಟ್ರಾಂಗ್ ಗೇಲ್",
  Storm: "ಬಿರುಗಾಳಿ",
  "Violent Storm": "ಹಿಂಸಾತ್ಮಕ ಚಂಡಮಾರುತ",
  "leafDryness": "ಎಲೆ ಶುಷ್ಕತೆ",
  Direction: "ನಿರ್ದೇಶನ",
  DownloadHistory: "ಇತಿಹಾಸವನ್ನು ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
  Today: "ಇಂದು",
  SeasonDegreeDays: "ಸೀಸನ್ ಜಿಡಿಡಿ",
  Payment: "ಪಾವತಿ ಮಾಡಿ",
  Vouchers: "ವೋಚರುಗಳು",
  Referral: "ರೆಫೆರಲ್ಗಳು",
  PaymentHistory: "ಹಣಕಾಸಿನ ಹಿಸ್ಟರಿ",
  Status: "ಸ್ಟೇಟಸ್",
  Amount: "ಅಮೌಂಟ್",
  PaymentSuccess: "ಪೇಮೆಂಟ್ ಮಾಡಲಾಗಿದೆ",
  PaymentFailure: "ಪಾವತಿ ವಿಫಲವಾಗಿದೆ",
  Plot: "ಪ್ಲಾಟುಗಳು",
  Date: "ದಿನಾಂಕ",
  PayFull: "ಪೂರ್ತಿ ಹಣ ಪಾವತಿಸಿ",
  Validity: "ವ್ಯಾಲಿಡಿಟೀ",
  SelectFarm: ",ದಯವಿಟ್ಟು ನಿಮ್ಮ ಫಾರ್ಮ್ ಅನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
  Loading: "ಲೋಡಿಂಗ್",
  ViewBillDetails: "ಬಿಲ್ ನ ಸಂಪೂರ್ಣ ಮಾಹಿತಿ ಮುಂದಿನ ಪೇಜ್ ಅಲ್ಲಿ ಕಾಣಿರಿ",
  Checkout: "ಮುಂದುವರೆಯಲು",
  OfferApplied: "ಆಫರ್ ಅಪ್ಪ್ಲೈ ಮಾಡಲಾಗಿದೆ",
  BillDetails: "ಬಿಲ್ ನ ಮಾಹಿತಿ",
  OrderTotal: "ಒಟ್ಟು ಆರ್ಡರ್",
  TotalDiscount: "ಒಟ್ಟು ಡಿಸ್ಕೌಂಟ್",
  ToPay: "ಪಾವತಿಸಬೇಕಾದ ಹಣ ",
  ProceedToPay: "ಹಣ ಪಾವತಿಸಲು ಮುಂದುವರೆಯಿರಿ",
  BackToHome: "ಬ್ಯಾಕ್ ಟು ಹೋಂ",
  OrderNum: "ಆರ್ಡರ್ ನಂಬರ್",
  VoucherHistory: "ವೋಚರ್ ಹಿಸ್ಟರಿ",
  ViewDetailedBill: "ವಿವರವಾದ ಪಾವತಿ ವೀಕ್ಷಿಸಿ ",
  ReferAndEarn: "ಬಹುಮಾನಗಳನ್ನು ಗಳಿಸಲು ನಿಮ್ಮ ಸ್ನೇಹಿತನನ್ನು ಉಲ್ಲೇಖಿಸಿ",
  SubmitReferral: "ರೆಫೆರಲ್ಲನ್ನು ಸಬ್ಮಿಟ್ ಮಾಡಿ",
  referralHistory: "ರೆಫೆರಲ್ಲ ನ  ಹಿಸ್ಟರಿ",
  Expired: "ಕಥಾವಸ್ತುವಿನ ಪಾವತಿ ಅವಧಿ ಮೀರಿದೆ",
  redirectAfterExpired: "ಪುನಹ ರೀಚಾರ್ಜ್ ಮಾಡಿರಿ",
  History: "ಹಿಸ್ಟರಿ",
  CropSeasonAndStageReports: "ಬೆಳೆ ಹಂತಗಳು ಮತ್ತು ವರದಿಗಳು",
  "Mobile Number*": "ಮೊಬೈಲ್ ನಂಬರ್",
  "Friend Name*": "ಸ್ನೇಹಿತನ ಹೆಸರು",
  "District*": "ಜಿಲ್ಲೆ",
  "Village(Optional)": "ಹಳ್ಳಿ",
  "Thank You": "Tಧನ್ಯವಾದಗಳು",
  "Thank you for participation": "ನಿಮ್ಮ ಪಾಲ್ಗೊಳ್ಳುವಿಕೆಗಾಗಿ ಧನ್ಯವಾದಗಳು",
  PENDING: "ಪೆಂಡಿಂಗ್ ಇದೆ",
  PROCESSED: "ಸಂಸ್ಕರಿಸಲಾಗಿದೆ",
  EXPIRED: "ಅವಧಿ ಮೀರಿದೆ",
  "Name must be at least 2 characters": "ಹೆಸರು ಎರಡು ಅಂಕಿಗಿಂತಲೂ ಕಮ್ಮಿ ಇರಬಾರದು",
  "Name must be at max 50 characters":
    "ಹೆಸರು ಐವತ್ತು ಅಕ್ಷರಗಳಿಗಿಂತ ಹೆಚ್ಚು ಇರಬಾರದು",
  "Please enter a valid name": "ಸರಿಯಾದ ಹೆಸರನ್ನು ನೋಂದಾಯಿಸಿರಿ",
  "Name is required": "ಹೆಸರು ಬೇಕಾಗಿದೆ",
  "mobile number must be at least 10 digits":
    "ಬೈಲ್ ನಂಬರ್ ಹತ್ತು ಅಂಕಿಗಿಂತಲೂ ಕಮ್ಮಿ ಇರಬಾರದು",
  "mobile number must be at max 10 digits":
    "ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆ ಹತ್ತು  ಅಂಕಿಗಿಂತಲೂ ಹೆಚ್ಚಿರಬಾರದು",
  "Enter a valid mobile": "ಸರಿಯಾದ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
  "mobile is required": "ಮೊಬೈಲ್ ಅಗತ್ಯವಿದೆ",
  "District is required": "ಜಿಲ್ಲೆ ಬೇಕಾಗಿದೆ",
  referralSuccessText: "ನಿಮ್ಮ ಸ್ನೇಹಿತ {{name}} ಅನ್ನು ಉಲ್ಲೇಖಿಸಲಾಗಿದೆ",
  referralFailureText: " {{name}} ಅನ್ನು ಮೊದಲೇ ಉಲ್ಲೇಖಿಸಲಾಗಿದೆ",
  Weather: "ಹವಾಮಾನ",
  Fertigation: "Fertigation",
  Disease: "ರೋಗ",
  Irrigation: "ನೀರಾವರಿ",
  Others: "ಇತರರು",
  Other: "ಇತರರು",
  "Farm Data": "ಕೃಷಿ ಡೇಟಾ",
  Alerts: "ಎಚ್ಚರಿಕೆಗಳು",
  "Hourly Update": "ಗಂಟೆಯ ನವೀಕರಣ",
  "Hide Details": "ವಿವರಗಳನ್ನು ಮರೆಮಾಡಿ",
  "Show Details": "ವಿವರಗಳನ್ನು ತೋರಿಸು",
  ShowReportFor: "ವಿವರಗಳನ್ನು ವರದಿ ",
  mm: "mm",
  "km/hr": "km/hr",
  "Avg Lux": "ಸರಾಸರಿ ಲಕ್ಸ್",
  "Max Lux": "ಗರಿಷ್ಠ ಲಕ್ಸ್",
  Activity: "ಚಟುವಟಿಕೆ",
  Duration: "ಅವಧಿ",
  Hour: "Hrs",
  Minutes: "Min.",
  "Irrigation Details": "ನೀರಾವರಿ ವಿವರಗಳು",
  "Dripper flow rate": "ಡ್ರಿಪ್ಪರ್ ಫ್ಲೋ ರೇಟ್ (lit./hr)",
  "drippers per plant": "ಪ್ರತಿ ಗಿಡಕ್ಕೆ ಡ್ರಿಪ್ಪರ್‌ಗಳ ಸಂಖ್ಯೆ",
  numOfPlants: "ಸಸ್ಯಗಳ ಸಂಖ್ಯೆ",
  "Total drippers": "ಡ್ರಿಪ್ಪರ್‌ಗಳ ಒಟ್ಟು ಸಂಖ್ಯೆ",
  "Irrigation per plant": "ಪ್ರತಿ ಸಸ್ಯಕ್ಕೆ ನೀರಾವರಿ",
  "Total Irrigation": "ಒಟ್ಟು ನೀರಾವರಿ",
  Save: "ಉಳಿಸಿ",
  SaveMoney: "ಉಳಿಸಿ",
  "Please fill all the fields!": "ದಯವಿಟ್ಟು ಎಲ್ಲಾ ಕ್ಷೇತ್ರಗಳನ್ನು ಭರ್ತಿ ಮಾಡಿ!",
  quantity: "Qty.",
  Unit: "ಘಟಕ",
  "+ ADD": "+ ಸೇರಿಸಿ",
  List: "ಪಟ್ಟಿ",
  "Water Amount": "ನೀರಿನ ಪ್ರಮಾಣ",
  Litre: "lit.",
  "Spraying for": "ಗಾಗಿ ಸಿಂಪಡಿಸಲಾಗುತ್ತಿದೆ",
  "Spraying Molecule": "ಅಣುಗಳ ಸಿಂಪಡಿಸುವಿಕೆ",
  "Please select your": "ದಯವಿಟ್ಟು ಆಯ್ಕೆ ಮಾಡಿ",
  capACTIVITY: "ಚಟುವಟಿಕೆ",
  "Select Activity": "ಚಟುವಟಿಕೆ ಆಯ್ಕೆಮಾಡಿ",
  Irrigation: "ನೀರಾವರಿ",
  Nutrients: "ಪೋಷಕಾಂಶಗಳು",
  "Spray, Pest & Disease": "ಸ್ಪ್ರೇ, ಕೀಟ ಮತ್ತು ರೋಗ",
  "Cultural Activity": "ಸಾಂಸ್ಕೃತಿಕ ಚಟುವಟಿಕೆ",
  Saved: "Saved!",
  SavedMessage: "ನಿಮ್ಮ ಡೇಟಾವನ್ನು ಉಳಿಸಲಾಗಿದೆ!",
  "Total irrigation can't be 0": "ಒಟ್ಟು ನೀರಾವರಿ 0 ಇರಬಾರದು.",
  "Please select time duration": "ದಯವಿಟ್ಟು ಸಮಯದ ಅವಧಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ.",
  attention: "ಜಾಗ ಕಾಣೆ!",
  "Please select what you are spraying for":
    "ದಯವಿಟ್ಟು ನೀವು ಸಿಂಪಡಿಸುತ್ತಿರುವುದನ್ನು ಆಯ್ಕೆಮಾಡಿ! ",
  "Please select spray molecule(s)":
    "ದಯವಿಟ್ಟು ಸ್ಪ್ರೇ ಮಾಲಿಕ್ಯೂಲ್ (ಗಳನ್ನು) ಆಯ್ಕೆಮಾಡಿ! ",
  "Please add water amount in litres":
    "ದಯವಿಟ್ಟು ಲೀಟರ್‌ನಲ್ಲಿ ನೀರನ್ನು ಸೇರಿಸಿ! ",
  "Please add quantity": "ದಯವಿಟ್ಟು ಪ್ರಮಾಣವನ್ನು ಸೇರಿಸಿ! ",
  "Please add unit": "ದಯವಿಟ್ಟು ಸೇರಿಸಿ unit! ",
  "Please select fertiliser": "ದಯವಿಟ್ಟು ಸೇರಿಸಿ fertiliser! ",
  "Please select cultural activity": "ದಯವಿಟ್ಟು ಸೇರಿಸಿ cultural activity!",
  Duration: "ಅವಧಿ",
  TotalIrrigation: "ಒಟ್ಟು ನೀರಾವರಿ",
  filter: "ಫಿಲ್ಟರ್",
  "Type here to start searching":
    "ಹುಡುಕಾಟವನ್ನು ಪ್ರಾರಂಭಿಸಲು ಇಲ್ಲಿ ಟೈಪ್ ಮಾಡಿ...",
  You_sure_you_want_to_delete: "ನೀವು ಅಳಿಸಲು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  attentionDelete: "ಅಳಿಸುವುದೇ?",
  DiseaseAndPest: "ರೋಗ ಮತ್ತು ಕೀಟ",
  "Update Crop Stage": "ಬೆಳೆ ಹಂತವನ್ನು ನವೀಕರಿಸಿ",
  "Is this correct?": "ಈ ಮಾಹಿತಿ ಸರಿಯೇ?",
  "Search Plot here": " ಕಥಾವಸ್ತುವನ್ನು ಇಲ್ಲಿ ಹುಡುಕಿ",
  Details: "ವಿವರಗಳು",

  alternaria_fruit_blotch: "Alternaria Fruit Blotch",
  anthracnose: "Anthracnose",
  bacterial_blight: "Bacterial Blight",
  bacterial_canker: "Bacterial Canker",
  bacterial_spot: "Bacterial Spot",
  bacterial_wilt: "Bacterial Wilt",
  black_spot: "Black Spot",
  botrytis: "Botrytis",
  cercospora_leaf_spot: "Cercospora Leaf Spot",
  downy_mildew: "Downy mildew",
  gummosis: "Gummosis",
  powdery_mildew: "Powdery mildew",

  flea_beetle: "Flea beetle",
  mealybug: "Mealybug",
  mites: "Mites",
  thrips: "Thrips",
  aphids: "Aphids",
  fruit_borer: "Fruit Borer",
  citrus_psylla: "Citrus Psylla",
  OR: "ಅಥವಾ",
  or: "ಅಥವಾ",
  SeasonFertilizerRequirements: "ಸೀಸನ್ ಪೋಷಕಾಂಶ ಅಗತ್ಯತೆಗಳು",
  Nutrient: "ಪೋಷಕಾಂಶ",
  SoilAmt: "ಮಣ್ಣಿನಲ್ಲಿರುವ ಪ್ರಮಾಣ",
  FertigationAmt: "ಫಲೀಕರಣ ಪ್ರಮಾಣ",
  Stage: "ವೇದಿಕೆ",
  Weeks: "ವಾರಗಳು",
  Week: "ವಾರ",
  MajorNutrients: "ಮ್ಯಾಕ್ರೋನ್ಯೂಟ್ರಿಯೆಂಟ್ಸ್",
  Sources: "ಮೂಲಗಳು",
  MicroNutrients: "ಸೂಕ್ಷ್ಮ ಪೋಷಕಾಂಶಗಳು",
  SoilApplication: "ಮಣ್ಣಿನ ಅಪ್ಲಿಕೇಶನ್",
  Season: "ಸೀಸನ್",
  CropType: "ಬೆಳೆ ವಿಧ",
  CropVariety: "ಬೆಳೆ ವೈವಿಧ್ಯ",
  Rootstock: "ಬೇರುಕಾಂಡ",
  ReportType: "ವರದಿ ಪ್ರಕಾರ",
  InputDetails: "ಇನ್‌ಪುಟ್ ವಿವರಗಳು",
  AddReport: "ವರದಿಯನ್ನು ಸೇರಿಸಿ",
  ReportDate: "ವರದಿ ದಿನಕ",
  PleaseSelectCrop: "ದಯವಿಟ್ಟು ಬೆಳೆ ಆಯ್ಕೆಮಾಡಿ",
  PleaseSelectCropVariety: "ದಯವಿಟ್ಟು ಬೆಳೆ ವೈವಿಧ್ಯತೆಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
  PleaseSelectRootstock: "ದಯವಿಟ್ಟು ಬೇರುಕಾಂಡವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
  PleaseSelectCropSeason: "ದಯವಿಟ್ಟು ಬೆಳೆ ಅವಧಿಯನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
  PleaseSelectReportType: "ದಯವಿಟ್ಟು ವರದಿ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿ",
  PleaseEnter: "ದಯವಿಟ್ಟು ನಮೂದಿಸಿ ",
  PestAnalytics: "ಕೀಟ ವಿಶ್ಲೇಷಣೆ",
  plotStatusExpired: "ಪ್ಲಾಟ್ ಅನ್ನು ಸಕ್ರಿಯಗೊಳಿಸಲು ದಯವಿಟ್ಟು ರೀಚಾರ್ಜ್ ಮಾಡಿ!!",
  plotStatusOldData: "ನವೀಕರಿಸಲಾಗಿದೆ: {{latestDataTime}}",
  Acres: "ಎಕರೆಗಳು",
  Plots: "ಪ್ಲಾಟ್‌ಗಳು",
  PlotSaveSuccess: "ಕಥಾವಸ್ತುವಿನ ವಿವರಗಳನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಉಳಿಸಲಾಗಿದೆ",
  PlotSaveFail: "ಕಥಾವಸ್ತುವಿನ ವಿವರಗಳನ್ನು ಉಳಿಸಲು ವಿಫಲವಾಗಿದೆ.",
  ValueOutOfRangePercentage:
    "ದಯವಿಟ್ಟು 0 ಮತ್ತು 100 ನಡುವಿನ {{alertVal}} ಮೌಲ್ಯವನ್ನು ನಮೂದಿಸಿ",
  "About Us": "ನಮ್ಮ ಬಗ್ಗೆ",
  NoStageReportFound: " ಯಾವುದೇ ಬೆಳೆ ಹಂತದ ವರದಿಗಳು ಕಂಡುಬಂದಿಲ್ಲ !!",
  Start: "ಪ್ರಾರಂಭಿಸಿ",
  NoSeasonStartedSubText:
    "ನೀವು ಇನ್ನೂ ಯಾವುದೇ ಸೀಸನ್ ಅನ್ನು ಪ್ರಾರಂಭಿಸಿಲ್ಲ ಎಂದು ತೋರುತ್ತಿದೆ. ದಯವಿಟ್ಟು ಋತುವನ್ನು ಪ್ರಾರಂಭಿಸಿ",
  NoSeasonStartedText: "ಯಾವುದೇ ಸೀಸನ್ ಕಂಡುಬಂದಿಲ್ಲ !!",
  SowingDate: "ಬಿತ್ತನೆ ದಿನಾಂಕ",
  StartCropSeasonSubText:
    "ದಯವಿಟ್ಟು ಪ್ರಾರಂಭ ದಿನಾಂಕ ಮತ್ತು ಕ್ರಾಪ್ ಪ್ರಕಾರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  DeltaT: "ಡೆಲ್ಟಾ ಟಿ",
  DeltaTVPD: "ಡೆಲ್ಟಾ ಟಿ ಮತ್ತು ವಿಪಿಡಿ",
  VPD: "ವಿಪಿಡಿ",
  Marginal: "ಮಾರ್ಜಿನಲ್",
  MarginalHigherDelta: "ಮಾರ್ಜಿನಲ್ ",
  Preferred: "ಆದ್ಯತೆ",
  Unsuitable: "ಸೂಕ್ತವಲ್ಲ",
  StagesInvalidDates:
    "ನವೀಕರಿಸಲು ಕ್ರಾಪ್ ಹಂತಕ್ಕೆ ಮಾನ್ಯವಾದ ದಿನಾಂಕಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  DeleteStage: "ಬೆಳೆ ಹಂತವನ್ನು ಅಳಿಸಿ",
  SureYouWantToDeleteStage:
    "ಆಯ್ಕೆಮಾಡಿದ ಕ್ರಾಪ್ ಹಂತವನ್ನು ಅಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  FileSaved: "ಫೈಲ್ ಅನ್ನು ಉಳಿಸಲಾಗಿದೆ!",
  NoDownloadExcelDataFound: "ಯಾವುದೇ ಡೇಟಾ ಕಂಡುಬಂದಿಲ್ಲ!",
  YouAreChangingSpray: "ನೀವು ಸ್ಪ್ರೇ / ಕೀಟ / ರೋಗವನ್ನು ಬದಲಾಯಿಸುತ್ತಿದ್ದೀರಿ!",
  MoleculesListDeleted: "ಸ್ಪ್ರೇ ಅಣುಗಳ ಪಟ್ಟಿಯನ್ನು ಅಳಿಸಲಾಗಿದೆ!",
  StaleDataError:
    "ಡೇಟಾವನ್ನು ಸ್ವೀಕರಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
  FetchDataError:
    "ಸಾಧನವನ್ನು ನೋಂದಾಯಿಸಲಾಗಿದೆ, ಡೇಟಾವನ್ನು ಪಡೆಯಲು ಸ್ವಲ್ಪ ಸಮಯ ಕಾಯಿರಿ.",
  Success: "ಯಶಸ್ಸು !",
  Oops: "ಅಯ್ಯೋ !!",
  From: "ಇಂದ",
  NA: "NA",
  To: "ಗೆ",
  "E-Mail fyllo support": "ಇಮೇಲ್ ಫೈಲ್ ಬೆಂಬಲ",
  "Call fyllo support": "ಫಿಲೋ ಬೆಂಬಲಕ್ಕೆ ಕರೆ ಮಾಡಿ",
  ReadMore: "ಮತ್ತಷ್ಟು ಓದು ..",
  fyllo: "fyllo",
  EditActivity: "ಚಟುವಟಿಕೆಯನ್ನು ಸಂಪಾದಿಸಿ",
  PermissionDenied: "ಅನುಮತಿಯನ್ನು ಅನುಮತಿಸಲಾಗುವುದಿಲ್ಲ",
  AllowFilePermission: "ದಯವಿಟ್ಟು ಫೈಲ್ ಅನುಮತಿಯನ್ನು ಅನುಮತಿಸಿ!",
  IncorrectEmailPhone: "ತಪ್ಪಾದ ಮೊಬೈಲ್ ಅಥವಾ ಇಮೇಲ್. ",
  EmailForAccountSupport:
    "ನೀವು ನೋಂದಾಯಿಸದಿದ್ದರೆ ದಯವಿಟ್ಟು contact@fyllo.in ಗೆ ಇಮೇಲ್ ಮಾಡಿ",
  ServerIssue: "ಸರ್ವರ್‌ಗೆ ಸಂಪರ್ಕಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ",
  TryLater: "ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
  IncorrectOTP: "ತಪ್ಪಾದ OTP . ",
  RefillOTP: "ದಯವಿಟ್ಟು ಸರಿಯಾದ OTP ಅನ್ನು ಮರು-ನಮೂದಿಸಿ",
  FileSaved: "ಫೈಲ್ ಅನ್ನು ಉಳಿಸಲಾಗಿದೆ!",
  NoDataFound: "ಯಾವುದೇ ಡೇಟಾ ಕಂಡುಬಂದಿಲ್ಲ!",
  "dd-mm-yyyy": "ದಿನಾಂಕ",
  NoPrevSeasonText: "ಯಾವುದೇ ಹಿಂದಿನ ಋತುಗಳು ಕಂಡುಬಂದಿಲ್ಲ !!",
  ChooseAction: "ಕ್ರಿಯೆಗಳನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  SelectionAction: "ಮುಂದಿನ ಡಯಲ್‌ಗಳಿಗಾಗಿ ಕ್ರಿಯೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Download: "ಡೌನ್‌ಲೋಡ್ ಮಾಡಿ",
  unitkPa: " kPa ",
  unitCelcius: " ℃ ",
  unitmm: " mm ",
  unitLux: " lux ",
  unitkmph: " kmph ",
  Scouting: "ಸ್ಕೌಟಿಂಗ್",
  Attachments: "ಲಗತ್ತುಗಳು",
  Notes: "ಟಿಪ್ಪಣಿಗಳು",
  Description: "ವಿವರಣೆ",
  Upload: "ಅಪ್ಲೋಡ್ ಮಾಡಿ",
  "Read less": "ಕಡಿಮೆ ಓದಿ",
  "Read more": "ಮತ್ತಷ್ಟು ಓದು",
  Uploading: "Uploading",
  PleaseSelectDiseaseLevel: "ದಯವಿಟ್ಟು ರೋಗದ ತೀವ್ರತೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  PleaseSelectPestLevel: "ದಯವಿಟ್ಟು ಕೀಟದ ತೀವ್ರತೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  PleaseSelectNutrientLevel: "ದಯವಿಟ್ಟು ಪೋಷಕಾಂಶದ ತೀವ್ರತೆಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  "Permissions issue": "ಅನುಮತಿಗಳ ಸಮಸ್ಯೆ!",
  GalleryPermissionIssue:
    "ಇದು ಕಾರ್ಯನಿರ್ವಹಿಸಲು ದಯವಿಟ್ಟು ಫೈಲ್ / ಗ್ಯಾಲರಿ ಅನುಮತಿಗಳನ್ನು ಅನುಮತಿಸಿ!",
  CameraPermissionIssue:
    "ದಯವಿಟ್ಟು ಇದನ್ನು ಮಾಡಲು ಕ್ಯಾಮರಾ ಅನುಮತಿಗಳನ್ನು ಅನುಮತಿಸಿ!!",
  Gallery: "ಗ್ಯಾಲರಿ",
  Camera: "ಕ್ಯಾಮೆರಾ",
  NoFarmMatched: "ಯಾವುದೇ ಫಾರ್ಮ್ ಹೊಂದಿಕೆಯಾಗುವುದಿಲ್ಲ",
  WeatherPredictionNotAllowedSubText:
    "ನೀರಾವರಿ ಘಟಕಕ್ಕೆ ಹವಾಮಾನ ಮುನ್ಸೂಚನೆ ಲಭ್ಯವಿಲ್ಲ.",
  CheckInternet: "ದಯವಿಟ್ಟು ಇಂಟರ್ನೆಟ್ ಸಂಪರ್ಕವನ್ನು ಪರಿಶೀಲಿಸಿ.",
  DeviceHealth: "ಸಾಧನದ ಸ್ಥಿತಿ",
  Health: "ಸಾಧನದ ಸ್ಥಿತಿ",
  LooksLikeDeviceNotWorking: "ಸಾಧನವು ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿಲ್ಲ ಎಂದು ತೋರುತ್ತಿದೆ.",
  DEVICE_HEALTH: "ಸಾಧನದ ಸ್ಥಿತಿ",
  "Device data being recieved": "ಸಾಧನದ ಡೇಟಾವನ್ನು ಪಡೆಯುತ್ತಿರುವಿರಾ?",
  BatteryReducedText:
    "ಕಳೆದ {{countVal}} ದಿನಗಳಲ್ಲಿ ಬ್ಯಾಟರಿಯನ್ನು {{reductionVal}}% ಕಡಿಮೆ ಮಾಡಲಾಗಿದೆ.",
  "Sensor Conditions": "ಸಂವೇದಕ ಪರಿಸ್ಥಿತಿಗಳು",
  "All sensors are working fine": "ಎಲ್ಲಾ ಸಂವೇದಕಗಳು ಕಾರ್ಯನಿರ್ವಹಿಸುತ್ತಿವೆ",
  NextFourHoursSprayNote:
    "ದಯವಿಟ್ಟು ಮುಂದಿನ ನಾಲ್ಕು ಗಂಟೆಗಳ ಭವಿಷ್ಯವನ್ನು ಪರಿಗಣಿಸಿ ಮತ್ತು ಸಿಂಪಡಿಸಬೇಕೆ ಅಥವಾ ಬೇಡವೇ ಎಂಬುದನ್ನು ನಿರ್ಧರಿಸಿ.",
  Location: "ಸ್ಥಳ",
  Pay: "ಪಾವತಿ",
  "Pay using": "ಬಳಸಿ ಪಾವತಿಸಿ",
  "Other Payment Methods": "ಇತರ ಪಾವತಿ ವಿಧಾನಗಳು",
  "Card Bank Transfer": "ಕಾರ್ಡ್ / ಬ್ಯಾಂಕ್ ವರ್ಗಾವಣೆ",
  "Which plot?": "ಯಾವ ಕಥಾವಸ್ತು?",
  "What activity?": "ಯಾವ ಚಟುವಟಿಕೆ?",
  DeviceNotInstalledWarning:
    "ಡೇಟಾವನ್ನು ನೋಡಲು ಈ ಪ್ಲಾಟ್‌ನಲ್ಲಿ ಸಾಧನವನ್ನು ಸ್ಥಾಪಿಸಿ!",
  CantUpdateData:
    "ಡೇಟಾವನ್ನು ನವೀಕರಿಸಲು ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ. ದಯವಿಟ್ಟು ನಂತರ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ",
  AddPlot: "ಈ ಫಾರ್ಮ್ ಅನ್ನು ಸೇರಿಸಿ",
  HowMuchToIrrigate: "ನೀವು ಎಷ್ಟು ಸಮಯದಿಂದ ನೀರಾವರಿ ಮಾಡುತ್ತಿದ್ದೀರಿ?",
  ActHours: "ಗಂಟೆಗಳು",
  ActMinutes: "ನಿಮಿಷಗಳು",
  WhatWithinCultActivity: " ಸಾಂಸ್ಕೃತಿಕ ಚಟುವಟಿಕೆಗಳ ಬಗ್ಗೆ ಏನು?",
  Area: "ಪ್ರದೇಶ",
  NoActivityFound: "ಯಾವುದೇ ಚಟುವಟಿಕೆ ಕಂಡುಬಂದಿಲ್ಲ",
  OldStyle: "ಹಳೆಯ ಶೈಲಿ",
  ActivityNotedAt: "ಸಮಯ",
  "New crop Season started": "ಹೊಸ ಬೆಳೆ ಕಾಲ ಪ್ರಾರಂಭಿಸಲಾಗಿದೆ",
  SprayActivity:
    "ಉದಾಹರಣೆಗೆ: 500 ಲೀಟರ್ ನೀರಿನಲ್ಲಿ ತಾಮ್ರವನ್ನು 1 ಕೆಜಿ ಸಿಂಪಡಿಸಿದೆ",
  ObservedDiseaseActivity:
    "ಉದಾಹರಣೆಗೆ: ಡೌನಿ ಮಿಲ್ಡೆವ್ ಅನ್ನು ಪ್ಲಾಟ್‌ನಲ್ಲಿ ಗಮನಿಸಲಾಯಿತು",
  NutrientActivity: "ಉದಾಹರಣೆಗೆ: 50 ಕೆಜಿ ಯೂರಿಯಾ ಹಾಕಿದ್ದೇನೆ",
  OtherActivity: "ಉದಾಹರಣೆಗೆ: ಜಮೀನಿನಲ್ಲಿ ಉಳುಮೆ ಮಾಡಿದೆ",
  AppliedUreaActivity: "ಉದಾಹರಣೆಗೆ: 50 ಕೆಜಿ ಯೂರಿಯಾ ಹಾಕಿದ್ದೇನೆ",
  ApplyFilter: "ಅನ್ವಯಿಸು ಫಿಲ್ಟರ್",
  baseStageData: "{{baseStage}} ಮಾಡಿದ ದಿನಗಳ ನಂತರ {{days}}",
  baseStageDataBefore: "{{days}} ದಿನಗಳ ಮೊದಲು {{baseStage}}",
  SoilType: "ಮಣ್ಣಿನ ಪ್ರಕಾರ",
  Foliar: "ಸಿಂಪಡಿಸಿ",
  FoliarAmt: "ಸಿಂಪಡಿಸಿ",
  Soil: "ಮಣ್ಣು",
  PleaseSelectAtleastOneMethod: "ದಯವಿಟ್ಟು ಕನಿಷ್ಠ ಒಂದು ಆಯ್ಕೆಯನ್ನು ಆರಿಸಿ",
  AvailableOptions: "ಲಭ್ಯವಿರುವ ಆಯ್ಕೆಗಳು",
  NoCropSelected: "ಯಾವುದೇ ಬೆಳೆ ಆಯ್ಕೆ ಮಾಡಲಾಗಿಲ್ಲ",
  HideOptions: "ಕಡಿಮೆ ವೀಕ್ಷಿಸಿ",
  ShowOptions: "ಹೆಚ್ಚು ವೀಕ್ಷಿಸಿ",
  sprayRequired:
    "ಕೆಳಗಿನ ಆಯ್ಕೆಗಳಲ್ಲಿ ಒಂದನ್ನು {{waterInLitres}} ಲೀಟರ್/ಎಕರೆ ನೀರಿನಲ್ಲಿ {{purpose}} ಸಿಂಪಡಿಸಿ",
  nutrientRequired: "ಕೆಳಗಿನ ಆಯ್ಕೆಗಳಲ್ಲಿ ಒಂದನ್ನು {{type}} ಮೂಲಕ ಅನ್ವಯಿಸಿ:",
  foliar: "ಸ್ಪ್ರೇ",
  fertigation: "ಫರ್ಟಿಗೇಷನ್",
  soilApplication: "ಮಣ್ಣು",
  SelectOneOption: "ಒಂದು ಆಯ್ಕೆಯನ್ನು ಆರಿಸಿ",
  CreateReport: "ವರದಿ ಮಾಡಿ",
  ViewHistory: "ನೋಟ ಇತಿಹಾಸ",
  DownloadPDF: "ವರದಿ ಪಡೆಯಿರಿ",
  Fertigation: "ಫಲೀಕರಣ",
  Summry: "ಸಾರಾಂಶ",
  Weekly: "ಸಾಪ್ತಾಹಿಕ",
  StageSummary: "ಹಂತ ಸಾರಾಂಶ",
  "UnitsinKg/acre": "ಕೆಜಿ / ಎಕರೆಯಲ್ಲಿ ಘಟಕ",
  Options: "ಆಯ್ಕೆಗಳು",
  Applyanyoneoftheoptions: "ಆಯ್ಕೆಗಳಲ್ಲಿ ಯಾವುದಾದರೂ ಒಂದನ್ನು ಅನ್ವಯಿಸಿ",
  riskLevel: "ಅಪಾಯದ",
  AnalysisStage: "ವಿಶ್ಲೇಷಣೆಯ ಹಂತ/ ಮಾದರಿ",
  PleaseSelectAnalysisStage: "ದಯವಿಟ್ಟು ವಿಶ್ಲೇಷಣೆಯ ಹಂತವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  days: "ದಿನಗಳು",
  Register: "ನೋಂದಣಿ",
  EnterMobileNoEmailID: "ಮೊಬೈಲ್ ಸಂಖ್ಯೆ ನಮೂದಿಸಿ",
  Resend: "ಮರುಕಳುಹಿಸಿ",
  Seconds: "ಸೆಕೆಂಡುಗಳು",
  Number: "ಮೊಬೈಲ್ ನಂಬರ",
  Useralreadyregistered: "ಬಳಕೆದಾರರು ಈಗಾಗಲೇ ನೋಂದಾಯಿಸಿಕೊಂಡಿದ್ದಾರೆ",
  InvalidNumber: "ಅಮಾನ್ಯ ಸಂಖ್ಯೆ",
  FarmDetails: "ಕೃಷಿ ವಿವರಗಳು",
  FarmName: "ಕ್ಷೇತ್ರದ ಹೆಸರು",
  PlotDetails: "ಕಥಾವಸ್ತುವಿನ ವಿವರಗಳು",
  PlotName: "ಕಥಾವಸ್ತುವಿನ ಹೆಸರು",
  DifferentSoilTypes: "ವಿವಿಧ ಮಣ್ಣಿನ ವಿಧಗಳು",
  Sandy: "ಮರಳು",
  SandyLoam: "ಮರಳು ಲೋಮ್",
  Loamy: "ಲೋಮಿ",
  Clay: "ಮಣ್ಣಿನ",
  PlotArea: "ಪ್ಲಾಟ್ ಪ್ರದೇಶ",
  Plotareacantbegreaterthanfarmarea:
    "ಪ್ಲಾಟ್ ಪ್ರದೇಶವು ಫಾರ್ಮ್ ಪ್ರದೇಶಕ್ಕಿಂತ ಹೆಚ್ಚಿರಬಾರದು",
  Allowfyllotoaccessyourlocation: "ನಿಮ್ಮ ಸ್ಥಳಕ್ಕೆ ಪ್ರವೇಶವನ್ನು ಅನುಮತಿಸಿ?",
  SAVETOADDCROP: "ಸೇರಿಸಲು ಉಳಿಸಿ",
  CropDetails: "ಬೆಳೆ ವಿವರಗಳು",
  CropName: "ಬೆಳೆ ಹೆಸರು",
  SowingPruningDate: "ಬಿತ್ತನೆ/ ಸಮರುವಿಕೆ ದಿನಾಂಕ",
  PlantationYear: "ನೆಟ್ಟ ವರ್ಷ",
  AddDevice: "ಸಾಧನವನ್ನು ಸೇರಿಸಿ",
  Adddevicetotheapp:
    "ಸಾಧನದಲ್ಲಿರುವ QR ಕೋಡ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡುವ ಮೂಲಕ ನಿಮ್ಮ ಸಾಧನವನ್ನು ಅಪ್ಲಿಕೇಶನ್‌ಗೆ ಸೇರಿಸಿ",
  EnterIMEINumber: "IMEI ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ",
  Scan: "ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
  ATTACHDEVICE: "ಸಾಧನವನ್ನು ಲಗತ್ತಿಸಿ",

  PopoverFarmDetails: "ಕೃಷಿ ಡೇಟಾ ಮತ್ತು ಮುನ್ಸೂಚನೆಗಳನ್ನು ನೋಡಿ",
  PopoverCalendarDetails: "ಚಟುವಟಿಕೆಯನ್ನು ಸೇರಿಸಿ ಮತ್ತು ವೀಕ್ಷಿಸಿ",
  popoverDiseaseDetails: "ರೋಗಗಳು ಮತ್ತು ಕೀಟಗಳ ಬಗ್ಗೆ ವಿವರಗಳು",
  popoverAnalyticsDetails: "ಫಲೀಕರಣದ ವಿವರಗಳನ್ನು ವೀಕ್ಷಿಸಿ",
  popoverPlotsDetails: "ಇನ್ನೊಂದು ಫಾರ್ಮ್/ಪ್ಲಾಟ್ ಆಯ್ಕೆಮಾಡಿ",
  popoverDailyReportDetails: "ದೈನಂದಿನ ವರದಿಯನ್ನು ಪರಿಶೀಲಿಸಿ",
  popoverMyFarmDetails: "ಲೈವ್ ಫಾರ್ಮ್ ಡೇಟಾ ಮತ್ತು ಇತಿಹಾಸ",
  popoverWeatherDetails: "ಗಂಟೆಯ ಮತ್ತು ದೈನಂದಿನ ಹವಾಮಾನ ಮುನ್ಸೂಚನೆ",
  popoverCropStageDetails: "ಬೆಳೆ ಹಂತಗಳನ್ನು ವೀಕ್ಷಿಸಿ ಮತ್ತು ನವೀಕರಿಸಿ",
  popoverShareButtonDetails: "ಸತ್ಯಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಿ",
  popoverUpdatedTimeDetails: "ಕೊನೆಯ ನವೀಕರಣ ಸಮಯ",
  popoverHealthDetails: "ಸಾಧನ ಮತ್ತು ಸಂವೇದಕ ಆರೋಗ್ಯವನ್ನು ಪರಿಶೀಲಿಸಿ",
  popoverPlusButtonDetails: "ಚಟುವಟಿಕೆಗಳನ್ನು ಸೇರಿಸಿ",
  popoverBarMenuDetails: "ಪಾವತಿ, ರೆಫರಲ್, ಬೆಂಬಲ",
  popoverNotificationDetails: "ಅಧಿಸೂಚನೆಯನ್ನು ಇಲ್ಲಿ ಪರಿಶೀಲಿಸಿ",
  popoverSoilMoistureDetails: "ಲೈವ್ ಡೇಟಾ, ಇತಿಹಾಸವನ್ನು ವೀಕ್ಷಿಸಲು ಕ್ಲಿಕ್ ಮಾಡಿ",
  Next: "ಮುಂದೆ",
  Previous: "ಹಿಂದಿನ",
  Skip: "ಬಿಟ್ಟುಬಿಡಿ",
  Finish: "ಮುಗಿಸು",
  Delete: "ಇದನ್ನು ಅಳಿಸಿ",
  Cancel: "ರದ್ದುಮಾಡು",
  Redo: "ಮತ್ತೆಮಾಡು",
  ClickandscanQR:
    "ಕೆಳಗಿನ ಸ್ಕ್ಯಾನ್ ಬಟನ್ ಕ್ಲಿಕ್ ಮಾಡಿ ಮತ್ತು ಸಾಧನದ ಈ ಪ್ರದೇಶದಲ್ಲಿ QR ಕೋಡ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಿ",
  Welcome: "ಸ್ವಾಗತಿಸಿದರು",
  Welcomeback: "ಮರಳಿ ಸ್ವಾಗತ",
  HelpWithdetails: "ದಯವಿಟ್ಟು ಹೆಚ್ಚಿನ ವಿವರಗಳೊಂದಿಗೆ ನಮಗೆ ಸಹಾಯ ಮಾಡಿ",
  Language: "ಭಾಷೆ",
  InvalidIMEINumber: "ಅಮಾನ್ಯ IMEI ಸಂಖ್ಯೆ",
  ApplicationMode: "ಅಪ್ಲಿಕೇಶನ್ ಮೋಡ್",
  kgHectare: "ಕೆಜಿ/ಹೆಕ್ಟೇರ್",
  percent: "ಶೇಕಡಾ",
  UnitPPM: "ppm",
  Select: "ಆಯ್ಕೆ ಮಾಡಿ",
  Report: "ವರದಿ",
  LogoutModalDescription: "ನೀವು ಖಚಿತವಾಗಿ ಲಾಗ್ಔಟ್ ಮಾಡಲು ಬಯಸುವಿರಾ?",
  YourReport: "ನಿಮ್ಮ ವರದಿ",
  RemovePhoto: "ಚಿತ್ರವನ್ನು ಅಳಿಸಿ",
  addActivity: "ಚಟುವಟಿಕೆ ಸೇರಿಸಿ",
  notificationSettings: "ಅಧಿಸೂಚನೆ ಸೆಟ್ಟಿಂಗ್‌ಗಳು",
  Settings: "ಸೆಟ್ಟಿಂಗ್‌ಗಳು",
  makeAPayment: "ಪಾವತಿ ಮಾಡಿ",
  pastTransactions: "ಹಿಂದಿನ ವಹಿವಾಟು",
  NoPesticideSchedule: "ಯಾವುದೇ ಕೀಟನಾಶಕ ವೇಳಾಪಟ್ಟಿ ಕಂಡುಬಂದಿಲ್ಲ!",
  UpcomingSpray: "ಮುಂಬರುವ ಸ್ಪ್ರೇ",
  Conditional: "ಷರತ್ತುಬದ್ಧ",
  Fixed: "ಕಡ್ಡಾಯ",
  Purpose: "ಉದ್ದೇಶ",
  Chemical: "ರಾಸಾಯನಿಕಗಳು",
  WaterQty: "ನೀರಿನ ಪ್ರಮಾಣ",
  CompleteSchedule: "ಸಂಪೂರ್ಣ ವೇಳಾಪಟ್ಟಿ",
  UsePasswordInstead: "ಬದಲಿಗೆ ಪಾಸ್ವರ್ಡ್ ಬಳಸಿ",
  useOTPInstead: "ಬದಲಿಗೆ ಒಟಿಪಿ ಬಳಸಿ",
  cropStage: "ಬೆಳೆ ಹಂತ",
  whenToSpray: "ಯಾವಾಗ ಸಿಂಪಡಿಸಬೇಕು",
  whenToSprayDescription: "{{days}} ದಿನಗಳವರೆಗೆ {{severity}} ಆಗಿದ್ದರೆ",
  notApplicable: "ಅನ್ವಯಿಸುವುದಿಲ್ಲ",
  StagewiseSprayGuideline: "ಹಂತಹಂತವಾಗಿ ಸ್ಪ್ರೇ ಮಾರ್ಗಸೂಚಿ",
  back: "ಹಿಂದೆ ಹೋಗು",
  registerNewUser: "ಹೊಸ ಬಳಕೆದಾರರನ್ನು ನೋಂದಾಯಿಸಿ",
  ComebackLater:
    "ನಾವು ಅದರ ಮೇಲೆ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ಶೀಘ್ರದಲ್ಲೇ ಮತ್ತೆ ಪರಿಶೀಲಿಸಿ!",
  existingUserLogin: "ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ ಬಳಕೆದಾರರಿಗೆ ಲಾಗಿನ್ ಮಾಡಿ",
  SelectAPlot: "ಒಂದು ಕಥಾವಸ್ತುವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  ShowReportFor: "ಇದಕ್ಕಾಗಿ ವರದಿಯನ್ನು ತೋರಿಸಿ",
  StartStage: "ಪ್ರಾರಂಭ ಹಂತ",
  EndStage: "ಕೊನೆಯ ಹಂತ",
  Neutrients: "ಪೋಷಕತ್ವ",
  NoSpray: "ತುಂತುರು",
  ThisWeek: "ಈ ವಾರ",
  TillDate: "ಇಂದಿನವರೆಗೆ",
  ThisMonth: "ಈ ತಿಂಗಳು",
  LastMonth: "ಕಳೆದ ತಿಂಗಳು",
  CustomDate: "ಕಸ್ಟಮ್ ದಿನಾಂಕ",
  SpecifyStage: "ಹಂತವನ್ನು ಸೂಚಿಸಿ",
  SelectStage: "ಹಂತವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  ThisStage: "ಈ ಹಂತ",
  CustomStage: "ಹಂತದ ವ್ಯಾಪ್ತಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  irrigationDetected: "ನೀರಾವರಿ ಪತ್ತೆಯಾಗಿದೆ!",
  Insights: "ಒಳನೋಟ",
  DurationInMinutes: "ಅವಧಿ (ನಿಮಿಷಗಳಲ್ಲಿ)",
  ThisSeason: "ಈ .ತುವಿನಲ್ಲಿ",
  SelectSeason: "ಸೀಸನ್ ಆಯ್ಕೆಮಾಡಿ",
  stageNotStartedWarning: "{{stageName}} ಹಂತ ಇನ್ನೂ ಪ್ರಾರಂಭವಾಗಿಲ್ಲ",
  ComebackLater:
    "ನಾವು ಅದರ ಮೇಲೆ ಕೆಲಸ ಮಾಡುತ್ತಿದ್ದೇವೆ, ದಯವಿಟ್ಟು ಶೀಘ್ರದಲ್ಲೇ ಮತ್ತೆ ಪರಿಶೀಲಿಸಿ!",
  CreditBalance: "ಸಾಲ ಬಾಕಿ",
  SearchContact: "ಸಂಪರ್ಕವನ್ನು ಹುಡುಕಿ",
  EnterName: "ಹೆಸರನ್ನು ನಮೂದಿಸಿ",
  Contacts: "ಸಂಪರ್ಕಗಳು",
  NoContactsFound: "ಯಾವುದೇ ಸಂಪರ್ಕ ಕಂಡುಬಂದಿಲ್ಲ",
  WhyFyllo: "ಏಕೆ fyllo ಆಯ್ಕೆ?",
  CropQuality: "ಬೆಳೆ ಗುಣಮಟ್ಟ ಮತ್ತು ಇಳುವರಿ",
  DiseaseManagement: "ಅತ್ಯುತ್ತಮ ರೋಗ ಮತ್ತು ಕೀಟ ನಿರ್ವಹಣೆ",
  PreciseIrrigation: "ನಿಖರವಾದ ನೀರಾವರಿ",
  BestService: "ಅತ್ಯುತ್ತಮ ಸೇವೆ",
  Sendvia: "ಮೂಲಕ ಕಳುಹಿಸಿ",
  Howitwork: "ಇದು ಹೇಗೆ ಕೆಲಸ ಮಾಡುತ್ತದೆ?",
  creditedyouraccount:
    "ಪ್ರತಿ ರೆಫರಲ್‌ನಲ್ಲಿ ₹20/- ಅನ್ನು ನಿಮ್ಮ fyllo ರೀಚಾರ್ಜ್ ಖಾತೆಗೆ ಕ್ರೆಡಿಟ್ ಮಾಡಲಾಗುತ್ತದೆ.",
  maximumearned: "ಇದನ್ನು 25 ಜನರಿಗೆ ಹಂಚುವ ಮೂಲಕ ಗರಿಷ್ಠ ₹500 ಗಳಿಸಬಹುದು.",
  friendinstallsfyllo:
    "ನಿಮ್ಮ ಸ್ನೇಹಿತರು fyllo ಅನ್ನು ಸ್ಥಾಪಿಸಿದರೆ, ನೀವು ಹೆಚ್ಚುವರಿ ₹ 250 ಅನ್ನು ಸ್ವೀಕರಿಸುತ್ತೀರಿ ಮತ್ತು ನಿಮ್ಮ ಸ್ನೇಹಿತರು fyllo ಅನುಸ್ಥಾಪನೆಯ ಮೇಲೆ ₹ 500 ರಿಯಾಯಿತಿಯನ್ನು ಪಡೆಯುತ್ತಾರೆ.",
  off: "ರಿಯಾಯಿತಿ",
  Mosteffectivereferers:
    "ಪ್ರತಿ 6 ತಿಂಗಳಿಗೊಮ್ಮೆ ಹೆಚ್ಚು ಪರಿಣಾಮಕಾರಿ ರೆಫರರ್‌ಗಳಿಗೆ ನೀರೋ ಸಾಧನ ಅಥವಾ ತಿರುಪತಿಗೆ ಟ್ರಿಪ್‌ನೊಂದಿಗೆ ಬಹುಮಾನ ನೀಡಲಾಗುತ್ತದೆ.",
  amountcredited: "₹{{amount}} ಅನ್ನು ಯಶಸ್ವಿಯಾಗಿ ಕ್ರೆಡಿಟ್ ಮಾಡಲಾಗಿದೆ",
  fylloaccount: "ನಿಮ್ಮ fyllo ಖಾತೆಗೆ",
  Backtoreferral: "ಉಲ್ಲೇಖಕ್ಕೆ ಹಿಂತಿರುಗಿ",
  TotalCreditsEarned: "ಗಳಿಸಿದ ಒಟ್ಟು ಕ್ರೆಡಿಟ್‌ಗಳು",
  CreditsRedeemed: "ಕ್ರೆಡಿಟ್‌ಗಳನ್ನು ರಿಡೀಮ್ ಮಾಡಲಾಗಿದೆ",
  CREDITED: "ಮನ್ನಣೆ ಮಾಡಿದ",
  WAITING: "ಕಾಯುವುದು",
  referralScreen: "ಉಲ್ಲೇಖಿಸಿ ಮತ್ತು ಸಂಪಾದಿಸಿ",
  GotReferralCode: "ರೆಫರಲ್ ಕೋಡ್ ಸಿಕ್ಕಿದೆಯೇ?",
  ClaimYourRewards: "ನಿಮ್ಮ ಪ್ರತಿಫಲವನ್ನು ಪಡೆದುಕೊಳ್ಳಿ",
  Apply: "ಅನ್ವಯಿಸು",
  EnterCode: "ನಿಮ್ಮ ಕೋಡ್ ನಮೂದಿಸಿ",
  EnterValidReferralCode: "ಮಾನ್ಯ ಉಲ್ಲೇಖಿತ ಕೋಡ್ ಅನ್ನು ನಮೂದಿಸಿ",
  REDEEMED: "ಉದ್ಧಾರವಾಯಿತು",
  Show: "show",
  UseCreditBalance: "ಕ್ರೆಡಿಟ್ ಬ್ಯಾಲೆನ್ಸ್ ಬಳಸಿ",
  ReferralBonus: "ಉಲ್ಲೇಖಿತ ಬೋನಸ್",
  RefferalCodeApplied: "ರೆಫರಲ್ ಕೋಡ್ ಅನ್ವಯಿಸಲಾಗಿದೆ",
  ManageFarmUsingFyllo:
    "ನಮಸ್ಕಾರ,\nನನ್ನ ಫಾರ್ಮ್ ಅನ್ನು ನಿರ್ವಹಿಸಲು ನಾನು Fyllo ಸಾಧನವನ್ನು ಬಳಸುತ್ತಿದ್ದೇನೆ.",
  FylloBenefit:
    " Fyllo ಬಳಸುವ ಮೂಲಕ ನಾನು ಈ ಕೆಳಗಿನ ಪ್ರಮುಖ ಪ್ರಯೋಜನಗಳನ್ನು ಪಡೆದುಕೊಂಡಿದ್ದೇನೆ:",
  InstallFyllo:
    "ನಿಮ್ಮ ಫಾರ್ಮ್‌ನಲ್ಲಿಯೂ ಫೈಲೊವನ್ನು ಸ್ಥಾಪಿಸಲು ನಾನು ನಿಮ್ಮನ್ನು ಒತ್ತಾಯಿಸುತ್ತೇನೆ. ನೀವು Fyllo ತಂಡವನ್ನು WhatsApp ನಲ್ಲಿ +91-9380908490 ನಲ್ಲಿ ಸಂಪರ್ಕಿಸಬಹುದು ಅಥವಾ contact@fyllo.in ನಲ್ಲಿ ಇಮೇಲ್ ಮೂಲಕ ಸಂಪರ್ಕಿಸಬಹುದು",
  UseRefferalCode:
    "Fyllo App ಅಪ್ಲಿಕೇಶನ್‌ನಲ್ಲಿ ನಿಮ್ಮ ಮುಂದಿನ ರೀಚಾರ್ಜ್‌ನಲ್ಲಿ ರಿಯಾಯಿತಿ ಪಡೆಯಲು {{code}} ರೆಫರಲ್ ಕೋಡ್ ಬಳಸಿ",
  SomeErrorOccured: "ಕೆಲವು ದೋಷ ಸಂಭವಿಸಿದೆ",
  AnyChangeInReport: "ವರದಿಯಲ್ಲಿ ಏನಾದರೂ ಬದಲಾವಣೆ ಇದೆಯೇ?",
  DeleteAccount: "ಖಾತೆಯನ್ನು ಅಳಿಸಿ",
  ReasonToDelete: "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ನೀವು ಏಕೆ ಅಳಿಸಲು ಬಯಸುತ್ತೀರಿ?",
  NoFruitfulResult: "ನಾನು ಯಾವುದೇ ಫಲಪ್ರದ ಫಲಿತಾಂಶವನ್ನು ಪಡೆಯುತ್ತಿಲ್ಲವೇ",
  NothingWorking: "ಯಾವುದೂ ಸರಿಯಾಗಿ ಕೆಲಸ ಮಾಡುತ್ತಿಲ್ಲ",
  PrivacyConcerns: "ನಾನು ನನ್ನ ಗೌಪ್ಯತೆಯ ಬಗ್ಗೆ ಕಾಳಜಿ ವಹಿಸುತ್ತೇನೆ",
  WhatisNotWorking:
    "ನಿಮಗೆ ಉತ್ತಮ ಫಲಿತಾಂಶವನ್ನು ನೀಡಲು ನಾವು ನಿರಂತರವಾಗಿ ನಮ್ಮನ್ನು ಸುಧಾರಿಸಿಕೊಳ್ಳುತ್ತಿದ್ದೇವೆ. ನೀವು ಏಕೆ ಅತೃಪ್ತರಾಗಿದ್ದೀರಿ ಎಂಬುದನ್ನು ದಯವಿಟ್ಟು ನಮ್ಮೊಂದಿಗೆ ಹಂಚಿಕೊಳ್ಳಬಹುದೇ?",
  Warning: "ಎಚ್ಚರಿಕೆ",
  DeleteWarningMessage:
    "ನಿಮ್ಮ ಖಾತೆಯನ್ನು ನೀವು ಅಳಿಸಿದರೆ ನೀವು ಎಲ್ಲಾ ಡೇಟಾ ಮತ್ತು ಚಂದಾದಾರಿಕೆಗಳನ್ನು ಶಾಶ್ವತವಾಗಿ ಕಳೆದುಕೊಳ್ಳುತ್ತೀರಿ",
  OurTeamWillContact:
    "ಖಾತೆಯನ್ನು ಅಳಿಸಲು ನಮ್ಮ ತಂಡವು ಶೀಘ್ರದಲ್ಲೇ ನಿಮ್ಮನ್ನು ಸಂಪರ್ಕಿಸುತ್ತದೆ",
  Close: "ನಿಲ್ಲಿಸು",
  GoBack: "ಹಿಂದೆ ಹೋಗು",
  ConfirmDeletion: "ಅಳಿಸುವಿಕೆಯನ್ನು ಖಚಿತಪಡಿಸಿ",
  GiveYourThoughts: "ನಿಮ್ಮ ಅಭಿಪ್ರಾಯವನ್ನು ತಿಳಿಸಿ",
  AnyChangeInReport: "ವರದಿಯಲ್ಲಿ ಏನಾದರೂ ಬದಲಾವಣೆ ಇದೆಯೇ?",
  DeleteAccount: "ಖಾತೆಯನ್ನು ಅಳಿಸಿ",
  UpdateStage: "ಹಂತವನ್ನು ನವೀಕರಿಸಿ",
  EndSeasonWarningMsg: "ಈ ಸೀಸನ್ ಅನ್ನು ಕೊನೆಗೊಳಿಸಲು ನೀವು ಖಚಿತವಾಗಿ ಬಯಸುವಿರಾ?",
  StageUpdated: "ಹಂತವನ್ನು ನವೀಕರಿಸಲಾಗಿದೆ",
  Retry: "ಮತ್ತೆ ಪ್ರಯತ್ನಿಸು",
  NoWeekSelected: "ಯಾವುದೇ ವಾರವನ್ನು ಆಯ್ಕೆ ಮಾಡಿಲ್ಲ",
  thingsToRememberFertigation: "ಫಲೀಕರಣದ ಸಮಯದಲ್ಲಿ ನೆನಪಿಡುವ ವಿಷಯಗಳು",
  thingsToRemember: "ನೆನಪಿಡುವ ವಿಷಯಗಳು",
  NoDiseaseDataFound: "ಯಾವುದೇ ರೋಗದ ಡೇಟಾ ಕಂಡುಬಂದಿಲ್ಲ",
  NoPestDataFound: "ಕಿಟ್ ಡೇಟಾ ಕಂಡುಬಂದಿಲ್ಲ",
  Range: "ವ್ಯಾಪ್ತಿ",
  Value: "ಮೌಲ್ಯ",
  DoYouNeedHelp: "ನೀವು ಯಾವುದೇ ಸಮಸ್ಯೆಗಳನ್ನು ಎದುರಿಸುತ್ತಿರುವಿರಾ?",
  LetUsHelpYou: "ನಾವು ನಿಮಗೆ ಸಹಾಯ ಮಾಡೋಣ",
  ChatWithUs: "ನಮ್ಮೊಂದಿಗೆ ಮಾತನಾಡಿ",
  CallUs: "ನಮ್ಮನ್ನು ಕರೆ ಮಾಡಿ",
  update: "ನವೀಕರಿಸಿ",
  cancel: "ರದ್ದುಮಾಡು",
  NewUpdateAvailable: "ನವೀಕರಣಗಳು ಲಭ್ಯವಿದೆ!!",
  PleaseUpdateYour: "ಅದನ್ನು ಬಳಸಲು ನಿಮ್ಮ ಅಪ್ಲಿಕೇಶನ್ ಅನ್ನು ನವೀಕರಿಸಿ",
  helpMessage:
    "ಹಾಯ್, ನನ್ನ ಫಾರ್ಮ್ {{farmId}} ನಲ್ಲಿ ನನಗೆ ಸಹಾಯ ಬೇಕು. ನನ್ನ ಬಳಕೆದಾರ ಹೆಸರು {{username}}",
  notApplicableInfo: "ಈ ಹಂತದಲ್ಲಿ {{pestOrDiseaseName}} ಅನ್ವಯಿಸುವುದಿಲ್ಲ",
  pesticideScheduleNotAllowed: "ನೀರಾವರಿ ಘಟಕಕ್ಕೆ ಕೀಟನಾಶಕ ಶೆಡ್ಯೂಲ್ ಲಭ್ಯವಿಲ್ಲ",
  latestReport: "ಇತ್ತೀಚಿನ ವರದಿ",
  noReportsAvailable: "ಯಾವುದೇ ವರದಿಗಳು ಲಭ್ಯವಿಲ್ಲ",
  uploadPdfReport: "ವರದಿಯನ್ನು ಅಪ್ಲೋಡ್ ಮಾಡಿ",
  scanningPdf: "ಡಾಕ್ಯುಮೆಂಟ್ ಸ್ಕ್ಯಾನಿಂಗ್",
  cantScanPdf: "ಈ ಡಾಕ್ಯುಮೆಂಟ್ ಅನ್ನು ಸ್ಕ್ಯಾನ್ ಮಾಡಲು ನಮಗೆ ಸಾಧ್ಯವಾಗುತ್ತಿಲ್ಲ",
  tryAgainPdf: "ದಯವಿಟ್ಟು ಪೋಷಕಾಂಶದ ಮೌಲ್ಯಗಳನ್ನು ಹಸ್ತಚಾಲಿತವಾಗಿ ನಮೂದಿಸಿ",
  basedOnUploadedReport: "ಅಪ್‌ಲೋಡ್ ಮಾಡಿದ ವರದಿಯನ್ನು ಆಧರಿಸಿ",
  ScheduleGeneratedOnDefaultReport:
    "ಡೀಫಾಲ್ಟ್ ವರದಿಯಲ್ಲಿ ವೇಳಾಪಟ್ಟಿಯನ್ನು ರಚಿಸಲಾಗಿದೆ",
  ForPreciseSchedule: "ನಿಖರವಾದ ವೇಳಾಪಟ್ಟಿಗಾಗಿ",
  remember: "ಗಮನಿಸಿ",
  lowest: "ಕಡಿಮೆ",
  average: "ಸರಾಸರಿ",
  highest: "ಅತ್ಯಧಿಕ",
  onAverage: "ಸರಾಸರಿ",
  Vpd: "ವಿಪಿಡಿ",
  vpd: "ವಿಪಿಡಿ",
  deltaT: "ಡೆಲ್ಟಾ ಟಿ",
  newVersionAvailable: "ಹೊಸ ಆವೃತ್ತಿ ಲಭ್ಯವಿದೆ!!",
  SelectPlot: "ಕಥಾವಸ್ತುವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  RechargeUptoDate: "ದಿನಾಂಕದವರೆಗೆ ರೀಚಾರ್ಜ್ ಮಾಡಿ",
  getUptoDiscount: "{{discountPercentage}}% ವರೆಗೆ ಹೆಚ್ಚುವರಿ ರಿಯಾಯಿತಿ ಪಡೆಯಿರಿ",
  Yearly: "ವಾರ್ಷಿಕ",
  Monthly: "ಮಾಸಿಕ",
  Quarterly: "ತ್ರೈಮಾಸಿಕ",
  DueInDays: "{{days}} ದಿನಗಳಲ್ಲಿ ಬಾಕಿಯಿದೆ",
  ContactSupport: "ಬೆಂಬಲವನ್ನು ಸಂಪರ್ಕಿಸಿ",
  Email: "ಇಮೇಲ್",
  Phone: "ದೂರವಾಣಿ",
  Whatsapp: "ವ್ಹಾಟ್ಸಪ್ಪ",
  INITIATED: "ಆರಂಭಿಸಿದ",
  AUTHORIZED: "ಅಧಿಕೃತ",
  SUCCESS: "ಯಶಸ್ಸು",
  FAILED: "ವಿಫಲವಾಗಿದೆ",
  NEW: "ಹೊಸ",
  Search: "ಹುಡುಕಿ",
  VideoGuide: "ಮಾರ್ಗದರ್ಶಕರು",
  ViewMyProfile: "ನನ್ನ ಪ್ರೊಫೈಲ್ ವೀಕ್ಷಿಸಿ",
  SearchKeywordToLearnAboutIt: "ತಿಳಿದುಕೊಳ್ಳಲು ಕೀವರ್ಡ್ ಅನ್ನು ಹುಡುಕಿ",
  rootWidth: "ಬೇರುಗಳ ಅಗಲ (ಇಂಚು)",
  rootDepth: "ಬೇರುಗಳ ಆಳ (ಇಂಚು)",
  dripperDischarge: "ಡ್ರಿಪ್ಪರ್ ಡಿಸ್ಚಾರ್ಜ್ (ಲೀಟರ್)",
  soilRetention: "ಮಣ್ಣಿನ ಧಾರಣ (ಮಿಮೀ)",
  dripperDistance: "ಡ್ರಿಪ್ಪರ್ ದೂರ (ಅಡಿ)",
  CalculateValue: "ಮೌಲ್ಯವನ್ನು ಲೆಕ್ಕ ಹಾಕಿ",
  ReCalculateValue: "ಮರು ಲೆಕ್ಕಾಚಾರ",
  Schedules: "ವೇಳಾಪಟ್ಟಿಗಳು",
  ChangeFarmDesc:
    "ನಿಮ್ಮ ಬದಲಾವಣೆಗಳನ್ನು ಉಳಿಸಲಾಗುವುದಿಲ್ಲ. ನೀವು ಮುಂದುವರಿಸಲು ಬಯಸುವಿರಾ?",
  AddNewFarm: "ಹೊಸದನ್ನು ಸೇರಿಸಿ",
  Remove: "દૂર કરો",
  UseExisting: "ಅಸ್ತಿತ್ವದಲ್ಲಿರುವ",
  NoNotification: "ಯಾವುದೇ ಅಧಿಸೂಚನೆ ಲಭ್ಯವಿಲ್ಲ",
  Edit: "ತಿದ್ದು",
  CopytoPlot: "ನಕಲು",
  Depth: "ಆಳ",
  inch: "ಇಂಚು",
  Error: "ದೋಷ",
  daysExceed: "ನಿಮ್ಮ ದಿನಗಳು ಬೆಳೆಯ ಋತುವಿನ ದಿನಗಳನ್ನು ಮೀರಿದೆಯೆಂದು ಕಾಣುತ್ತದೆ.",
  updateStages:
    "ನಿಮ್ಮ ಫಾರ್ಮ್‌ಗೆ ಸಂಬಂಧಿಸಿದ ನಿಖರವಾದ ಡೇಟಾವನ್ನು ಪಡೆಯಲು ದಯವಿಟ್ಟು ನಿಮ್ಮ ಬೆಳೆ ಹಂತಗಳನ್ನು ನವೀಕರಿಸಿ!",
  Later: "ನಂತರ",
  selectDuration: "ಅವಧಿಯನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  Lastdurationdays: "ಕಳೆದ {{duration}} ದಿನ",
  seasonSummary: "ಋತುವಿನ ಸಾರಾಂಶ",
  TotalWaterUsed: "ಬಳಸಿದ ಒಟ್ಟು ನೀರು",
  TotalIrrigationEvents: "ಒಟ್ಟು ನೀರಾವರಿ ಘಟನೆಗಳು",
  AvgIntervalbetweenIrrigations: "2 ನೀರಾವರಿಗಳ ನಡುವಿನ ಸರಾಸರಿ ಮಧ್ಯಂತರ",
  NumberofSpraysutilized: "ಬಳಸಿದ ಸ್ಪ್ರೇಗಳ ಸಂಖ್ಯೆ",
  AverageSunLight: "ಸರಾಸರಿ ಸೂರ್ಯನ ಬೆಳಕು",
  AverageSunshineHours: "ಸರಾಸರಿ ಸನ್ಶೈನ್ ಗಂಟೆಗಳು",
  Explore: "ಹಂತದ ಸಾರಾಂಶವನ್ನು ಅನ್ವೇಷಿಸಿ",
  Lai: "ಎಲ್.ಎ.ಐ",
  lai: "ಎಲ್.ಎ.ಐ",
  LaiDetails: "ಎಲ್.ಎ.ಐ ವಿವರಗಳು",
  "Not Optimal": "ಸೂಕ್ತವಲ್ಲ",
  Optimal: "ಸೂಕ್ತ",
  Image: "ಚಿತ್ರ",
  AboutDeltaT: "ಡೆಲ್ಟಾ ಟಿ ಯಾವುದು?",
  AboutVPD: "ಏನಿದು ವಿಪಿಡಿ?",
  AboutLAI: "ಏನಿದು ಎಲ್.ಎ.ಐ?",
  AddCost: "ಚಟುವಟಿಕೆಯ ವೆಚ್ಚ",
  costExample: "ಉದಾಹರಣೆಗೆ: 400",
  selectCurrency: 'ನಾಣಯ ಆಯ್ಕೆಮಾಡಿ',
  "No Rain": 'ಮಳೆಯಿಲ್ಲ',
  "AppGuideVideo": 'ಅಪ್ಲಿಕೇಶನ್ ಮಾರ್ಗದರ್ಶನ ವೀಡಿಯೊ',
  ProductVideo: 'ಪ್ರಾಡಕ್ಟ್ ವೀಡಿಯೊ',
  AgronomyVideo: 'ಅಗ್ರೋಣೋಮಿ ವೀಡಿಯೋಸ್',
  TestimonialVideo: 'ಟೆಸ್ತಿಮೊನಿಯಾಳ್ ವೀಡಿಯೋಸ್',
  IDPMSuggestion: "{{disease}} ನಿರ್ವಹಣೆ",
  AddYourFertilizers: "ನಿಮ್ಮ ರಸಗೊಬ್ಬರವನ್ನು ಸೇರಿಸಿ",
  SprayNotifs: "ಸ್ಪ್ರೇನ ಸೂಚನೆಗಳು",
  Cost: 'ವೆಚ್ಚ',
  seedTreatment: 'ಬೀಜೋಪಚಾರ',
  insecticide: 'ಕೀಟನಾಶಕ',
  nematicide: 'ನೆಮಾಟಿಸೈಡ್',
  fungicide: 'ಶಿಲೀಂಧ್ರನಾಶಕ',
  bactericide: 'ಬ್ಯಾಕ್ಟೀರಿಯಾನಾಶಕ',
  InvalidPayment: "ಅಮಾನ್ಯ ಪಾವತಿ",
  PaymentInProgress: "ಪಾವತಿ ಪ್ರಕ್ರಿಯೆಯಲ್ಲಿದೆ",
  "ServiceRequest": "ಸಮಸ್ಯೆ ವರದಿ",
  "NoServiceRequestFound": "ಯಾವುದೇ ಸಮಸ್ಯೆ ವರದಿ ಕಂಡುಬಂದಿಲ್ಲ",
  "YourServiceRequests": "ನಿಮ್ಮ ಸಮಸ್ಯೆ ವರದಿ",
  "RaiseServiceRequest": "ಸಮಸ್ಯೆ ವರದಿ",
  "TellAboutYourIssue": "ನಿಮ್ಮ ಸಮಸ್ಯೆಯನ್ನು ತಿಳಿಸಿ",
  "DoYouWantToOpenThisServiceRequest": "ಈ ಸಮಸ್ಯೆ ವರದಿಯನ್ನು ತೆರೆಯಲು ಬಯಸುವಿರಾ?",
  "DoYouWantToCloseThisServiceRequest": "ಈ ಸಮಸ್ಯೆ ವರದಿಯನ್ನು ಮುಚ್ಚಲು ಬಯಸುವಿರಾ?",
  ReopenServiceRequest: "ಪುನಃ ತೆರೆಯಿರಿ",
  CloseServiceRequest: "ಮುಚ್ಚಿ",
  OpenDate: "ದಿನಾಂಕವನ್ನು ತೆರೆಯಿರಿ",
  SelectImage: "ಚಿತ್ರವನ್ನು ಆಯ್ಕೆಮಾಡಿ",
  TakeImage: "ಚಿತ್ರವನ್ನು ತೆಗೆದುಕೊಳ್ಳಿ",
  open: "ತೆರೆಯಿರಿ",
  closed: "ಮುಗಿದಿದೆ",
  new: "ಹೊಸದು",
  "pending reminder": "ಬಾಕಿ ಉಳಿದಿರುವ ಜ್ಞಾಪನೆ",
  merged: "ವಿಲೀನಗೊಳಿಸಲಾಗಿದೆ",
  removed: "ತೆಗೆದುಹಾಕಲಾಗಿದೆ",
  lastUpdateHours: "ಕೊನೆಯ {{hours}} ಗಂಟೆಗಳು",
  Refresh: "ರಿಫ್ರೆಶ್ ಮಾಡಿ",
  Issue: "ಸಮಸ್ಯೆ",
  IssueIsRequired: "ಸಮಸ್ಯೆ ಅಗತ್ಯವಿದೆ",
  DetailIsRequired: "ವಿವರಗಳು ಅಗತ್ಯವಿದೆ",
  ImagesTooLargeError: 'ನೀವು ಅಪ್ಲೋಡ್ ಮಾಡಲು ಪ್ರಯತ್ನಿಸುತ್ತಿರುವ ಚಿತ್ರಗಳು ತುಂಬಾ ದೊಡ್ಡವು. ದಯವಿಟ್ಟು ಕೆಲವು ಚಿತ್ರಗಳನ್ನು ತೆಗೆದುಹಾಕಿ ಮತ್ತೆ ಪ್ರಯತ್ನಿಸಿ',
  Create: 'ರಚಿಸು',
  ReachedMaxAttachments: 'ನೀವು ಚಿತ್ರಗಳ ಗರಿಷ್ಠ ಮಿತಿಯನ್ನು ತಲುಪಿರುವಿರಿ. ಹೆಚ್ಚಿನದನ್ನು ಸೇರಿಸುವ ಮೊದಲು ದಯವಿಟ್ಟು ಕೆಲವು ಚಿತ್ರಗಳನ್ನು ತೆಗೆದುಹಾಕಿ.'
};
const gu = {
  Farm: "ફાર્મ",
  Calendar: " કેલેન્ડર",
  News: " સમાચાર",
  Disease: " રોગ",
  ShowReportFor: "રિપોર્ટ બતાવો",
  MyFarm: " મારું ખેતર",
  DailyReport: " દૈનિક અહેવાલ",
  Weather: " હવામાન",
  Total: " કુલ",
  DiseaseAnalytics: " રોગ વિશ્લેષણ",
  Username: " વપરાશકર્તા નામ",
  EnterOTP: " OTP દાખલ કરો",
  EnterPassword: "પાસવર્ડ દાખલ કરો",
  LastUpdated: " અપડેટ કરેલ",
  SoilMoisture1: "જમીનનું ભેજ 1",
  SoilMoisture2: "જમીનનું ભેજ 2",
  SoilTemperature: " જમીનનું તાપમાન",
  LeafWetness: " પાંદડાની ભીનાશ",
  AirTemperature: " હવા તાપમાન",
  AirHumidity: " હવામાં ભેજ",
  ViewDetails: " વિગતો જુઓ",
  AirPressure: " હવાનું દબાણ",
  LightIntensity: " પ્રકાશની તીવ્રતા",
  Probability: " સંભાવના",
  DayTimeAverageLux: " દૈનિક સૂર્ય પ્રકાશ",
  Rainfall: " વરસાદ",
  RainFall: " વરસાદ",
  RainFallLastHour: " છેલ્લા કલાકમાં વરસાદ",
  TwentyFourHours: " 24 કલાક",
  Rain: " વરસાદ",
  Alerts: " ચેતવણીઓ",
  SoilMoisture1Details: " જમીનની ભેજ-1 વિગતો",
  SoilMoisture2Details: " જમીનની ભેજ-2 વિગતો",
  SoilTempDetails: " જમીનના તાપમાનની વિગતો",
  LeafWetnessDetails: "પાંદડાની ભીનાશની વિગતો",
  AirTempDetails: " હવાના તાપમાનની વિગતો",
  AirHumidityDetails: " હવાના ભેજની વિગતો",
  AirPressureDetails: " હવા દબાણની વિગતો",
  lightIntensityDetails: " પ્રકાશની તીવ્રતાની વિગતો",
  rainFallDetails: " વરસાદની વિગતો",
  windSpeedDetails: " પવનની ઝડપની વિગતો",
  batteryDetails: " બેટરી વિગતો",
  battery: " બેટરી",
  baseStageData: " {{days}} દિવસ પછી {{baseStage}}",
  baseStageDataBefore: "{{baseStage}} ના {{days}} દિવસ પહેલા",
  windDirection: " પવનની દિશા",
  windSpeed: " પવનની ઝડપ",
  rainFall: " વરસાદ",
  leafWetness: " પાંદડાની ભીનાશ",
  lightIntensity: " પ્રકાશની તીવ્રતા",
  soilMoisture1: " જમીનની ભેજ-1",
  soilMoisture2: " જમીનની ભેજ -2",
  soilTemp: " જમીનનું તાપમાન",
  signalStrength: " નેટવર્ક",
  signalStrengthDetails: "નેટવર્ક વિગતો",
  baroPressure: " બેરોમેટ્રિક દબાણ",
  airPressure: " હવાનું દબાણ",
  airHumidity: " હવામાં ભેજ",
  airTemp: " હવા તાપમાન",
  GDD: " ડિગ્રી દિવસો",
  Gdd: " ડિગ્રી દિવસો",
  gdd: " ડિગ્રી દિવસો",
  GDDDetails: " ડિગ્રી દિવસની વિગતો",
  Inputs: " ઇનપુટ્સ",
  Services: " સેવાઓ",
  Nutrient: " પોષક",
  StartDate: " પ્રારંભ તારીખ",
  EndDate: " અંતિમ તારીખ",
  Spray: " જંતુ/રોગ સ્પ્રે કરો",
  CulturalPractice: " ફાર્મ પ્રેક્ટિસ",
  "Cultural Practice": " ફાર્મ પ્રેક્ટિસ",
  Labour: " શ્રમ",
  Seeds: " બીજ",
  Next: "આગળ",
  Email: " ઈ-મેલ",
  Mobile: " મોબાઇલ",
  RegenerateOTP: "ફરીથી OTP જનરેટ કરશો?",
  Fertilizer: " ખાતર",
  PandDControl: " જંતુ અને રોગ નિયંત્રણ",
  FieldPreparation: " ક્ષેત્ર તૈયારી",
  Irrigation: " સિંચાઈ",
  ActivityType: " પ્રવૃત્તિનો પ્રકાર",
  RainProbability: " વરસાદની સંભાવના",
  RainQuantity: " વરસાદની માત્રા",
  RainQty: " વરસાદની માત્રા",
  WindSpeed: " પવનની ઝડપ",
  Login: " પ્રવેશ કરો",
  ForgotPassword: " તમારો પાસવર્ડ ભૂલી ગયા છો ?",
  ResourcesUsed: " ઉપયોગમાં લેવાયેલ સંસાધનો",
  Date: " તારીખ",
  ActionsToDo: " કરવા માટેની ક્રિયાઓ",
  ActionsDone: " ક્રિયાઓ થઈ ગઈ",
  LabourCost: " મજૂરી ખર્ચ",
  ResourcesCost: " સંસાધન ખર્ચ",
  NotRequired: " જરૂરી નથી",
  MarkAsDone: " પૂર્ણ તરીકે ચિહ્નિત કરો",
  ActivityDate: " પ્રવૃત્તિ તારીખ",
  Done: " થઈ ગયું",
  Cancel: "રદ કરો",
  EnterCostOfResourcesUsed: " વપરાયેલ સંસાધનોની કિંમત દાખલ કરો",
  EmptyCalendarItemText: " તમારા કાવતરા માટે આ દિવસે કંઈ આયોજન કર્યું નથી!!",
  Posted: " પોસ્ટ કરેલ",
  Min: " મિનિટ",
  Max: " મહત્તમ",
  Avg: " સરેરાશ",
  DayMin: " દિવસનો સમય ઓછો",
  DayMax: " દિવસનો સમય મહત્તમ",
  DayAvg: " દિવસનો સમય સરેરાશ",
  NoData: "કોઈ ડેટા ઉપલબ્ધ નથી!!",
  Day: " દિવસ",
  Day1: " 1 દિવસ",
  Week1: " 1 અઠવાડિયું",
  Month1: " 1 મહિનો",
  Year1: " 1 વર્ષ",
  Custom: " કસ્ટમ",
  EmailSupport: " સપોર્ટ માટે ઇમેઇલ",
  Network: " નેટવર્ક",
  Power: " શક્તિ",
  ProbabilityInYourPlot: " તમારા પ્લોટમાં સંભાવના",
  CropCalendar: " પાક કેલેન્ડર",
  AllCalendar: " પાક કેલેન્ડર",
  Actions: " ક્રિયાઓ",
  Insights: "આંતરદૃષ્ટિ",
  Farms: " ખેતરો",
  NewsFeed: " ન્યૂઝફીડ",
  Notifications: " સૂચના",
  Profile: " પ્રોફાઇલ",
  Logout: " લૉગ આઉટ",
  Support: " સપોર્ટ",
  ChangeLanguage: " ભાષા",
  WetDuration: " ભીની અવધિ",
  hours: " કલાક",
  "Too Wet": " ખૂબ ભીનું",
  "Adequate Moisture": " પર્યાપ્ત",
  Dry: " સૂકી",
  "Too Dry": " ખૂબ શુષ્ક",
  Wet: " ભીનું",
  Nil: " શૂન્ય",
  Low: " નીચું",
  Medium: " મધ્યમ",
  High: " ઉચ્ચ",
  NIL: " શૂન્ય",
  Night: " રાત",
  "Fully Overcast": " સંપૂર્ણ વાદળછાયું",
  Overcast: " ઘેરાયેલું",
  Sunset: " સૂર્યાસ્ત",
  Sunrise: " સૂર્યોદય",
  "Partly Cloudy": "આંશિક વાદળછાયું",
  Sunny: " સન્ની",
  "Clear Sky": " ચોખું આકાશ",
  HoursOfRain: " વરસાદના કલાકો",
  RainHours: " વરસાદના કલાકો",
  HoursOfSun: " સૂર્યના કલાકો",
  CloudCover: " વાદળોનું આવરણ",
  HourlyWeatherPrediction: " કલાકનું હવામાન",
  HourlyPrediction: " કલાકનું હવામાન",
  Summary: " સારાંશ",
  SoilMoisture: " જમીનની ભેજ",
  Average: " સરેરાશ",
  Minimum: " ન્યૂનતમ",
  Maximum: " મહત્તમ",
  LeafWetnessHours: " લીફ વેટનેસ અવર્સ",
  Name: " નામ",
  Severity: " ગંભીરતા",
  ActionsRequired: " આવશ્યક ક્રિયાઓ",
  NoActionsForToday: " આજ માટે કોઈ ક્રિયાઓ નથી",
  Quantity: " જથ્થા",
  BestConditionsForSpray: " છાંટવા માટે આ સમય શ્રેષ્ઠ છે",
  ModerateConditionsForSpray: " સાવધાની સાથે છંટકાવ કરો",
  WorstConditionsForSpray: " આ સમયે છંટકાવ કરશો નહીં",
  SprayTimings: " છાંટવાનો સમય",
  DailyReportNotAvailable:
    " આજ માટે રિપોર્ટ ઉપલબ્ધ નથી. તપાસો કે ઉપકરણ કામ કરી રહ્યું છે કે નહીં",
  Evapotranspiration: " બાષ્પીભવન",
  Eto: " ETO",
  DailyETO: " દૈનિક ETO",
  ETODetails: " ઇવેપોટ્રાન્સપિરેશન વિગતો",
  Pest: " જંતુ",
  to: " પ્રતિ",
  ReportsStages: "અહેવાલ અને તબક્કાઓ",
  StageReports: " સ્ટેજ રિપોર્ટ્સ",
  StageReport: " સ્ટેજ રિપોર્ટ્સ",
  SeasonReport: " સિઝન રિપોર્ટ",
  CropStages: " પાકના તબક્કા",
  PrevSeasonReports: " ભૂતકાળની ઋતુઓ",
  PlotReports: " પ્લોટ રિપોર્ટ્સ",
  ActivitiesDone: " પ્રવૃતિઓ થઈ ગઈ",
  Activities: "પ્રવૃત્તિઓ",
  NoActivitiesDone: " કોઈ પ્રવૃત્તિઓ થઈ નથી",
  TotalRainFall: " કુલ વરસાદ",
  DayRainfall: " દિવસનો વરસાદ",
  TotalETO: " કુલ બાષ્પીભવન",
  AverageETO: " સરેરાશ બાષ્પીભવન",
  AccumulatedGDD: " સંચિત GDD",
  ReportNotAvailable: " રિપોર્ટ ઉપલબ્ધ નથી",
  StartDateforStage: " પ્રારંભ તારીખ પસંદ કરો",
  Analytics: " વિશ્લેષણ",
  UpdateNotifications: " સુચનાઓ અપડેટ કરો",
  Fertigation: " ફર્ટિગેશન",
  fertigation: " ફર્ટિગેશન",
  WeeklySchedule: " સાપ્તાહિક સમયપત્રક",
  Create: " બનાવો",
  Report: " અહેવાલ",
  View: " જુઓ",
  Crop: " પાક",
  PDF: " પીડીએફ",
  CropStartDate: " પાકની શરૂઆત",
  StageStartDate: " સ્ટેજ સ્ટાર્ટ",
  EndCropSeason: " પાકની સીઝનનો અંત",
  CurrentStage: " સ્ટેજ",
  EndConfirmation: " આ ચાલુ સિઝનનો અંત આવશે!! શું તમને ખાતરી છે?",
  Yes: " હા",
  No: "ના",
  StartCropSeason: "નવી પાક ઋતુ શરૂ કરો",
  RestartCropSeason: "જૂની પાકની ઋતુ ફરી શરૂ કરો",
  NewCrop: " નવો પાક",
  Calm: " શાંત",
  "Light Air": " હળવા હવા",
  "Light Breeze": " આછો પવન",
  "Gentle Breeze": " સૌમ્ય પવન",
  "Moderate Breeze": " મધ્યમ પવન",
  "Fresh Breeze": " તાજી પવન",
  "Strong Breeze": " મજબૂત પવન",
  "Near Gale": " ગેલ નજીક",
  Gale: " ગેલ",
  "Strong Gale": " સ્ટ્રોંગ ગેલ",
  Storm: " તોફાન",
  "Violent Storm": " હિંસક તોફાન",
  "leafDryness": "પાંદડાની શુષ્કતા",
  Direction: " દિશા",
  DownloadHistory: " ઇતિહાસ ડાઉનલોડ કરો",
  Today: " આજે",
  SeasonDegreeDays: " સિઝન ડિગ્રી દિવસો",
  Payment: " ચુકવણી",
  Vouchers: " વાઉચર્સ",
  Referral: " રેફરલ",
  PaymentHistory: " ચુકવણી ઇતિહાસ",
  Status: " સ્થિતિ",
  Amount: " રકમ",
  PaymentSuccess: " ચુકવણી સફળ",
  PaymentFailure: " ચુકવણી નિષ્ફળ",
  Plot: " પ્લોટ્સ",
  PayFull: " સંપૂર્ણ ચૂકવણી કરો",
  Validity: " માન્યતા",
  SelectFarm: " કૃપા કરીને તમારું ખેતર પસંદ કરો",
  Loading: " લોડ કરી રહ્યું છે..",
  ViewBillDetails: "આગલા પૃષ્ઠ પર વિગતવાર બિલ જુઓ",
  Checkout: " ચેકઆઉટ",
  OfferApplied: " ઓફર લાગુ",
  BillDetails: " બિલની વિગતો",
  OrderTotal: " ઓર્ડર કુલ",
  TotalDiscount: " કુલ ડિસ્કાઉન્ટ",
  ToPay: " ચૂકવવા",
  ProceedToPay: " ચુકવવા માટે આગળ વધો",
  BackToHome: " ઘરે પાછા",
  OrderNum: " અનુક્રમ નંબર.",
  VoucherHistory: " વાઉચર",
  ViewDetailedBill: " વિગતવાર બિલ જુઓ",
  ReferAndEarn: " પુરસ્કારો મેળવવા માટે તમારા મિત્રનો સંદર્ભ લો!!",
  SubmitReferral: " રેફરલ સબમિટ કરો",
  referralHistory: " રેફરલ ઇતિહાસ",
  Expired: " પ્લોટની ચુકવણી સમાપ્ત થઈ ગઈ છે.",
  redirectAfterExpired: " રિચાર્જ કરવા માટે અહીં ક્લિક કરો",
  History: " ઇતિહાસ",
  CropSeasonAndStageReports: " પાકના તબક્કા અને અહેવાલો",
  "Mobile Number*": " મોબાઇલ નંબર*",
  "Friend Name*": " મિત્રનું નામ*",
  "District*": " જિલ્લો*",
  "Village(Optional)": " ગામ(વૈકલ્પિક)",
  "Thank You": " આભાર.",
  "Thank you for participation": " તમારી ભાગીદારી બદલ આભાર",
  PENDING: " બાકી",
  PROCESSED: " પ્રક્રિયા કરેલ",
  EXPIRED: " સમાપ્ત",
  "Name must be at least 2 characters":
    " નામ ઓછામાં ઓછા 2 અક્ષરોનું હોવું જોઈએ",
  "Name must be at max 50 characters": "નામ મહત્તમ 50 અક્ષરોનું હોવું જોઈએ",
  "Please enter a valid name": " કૃપા કરીને માન્ય નામ દાખલ કરો",
  "Name is required": " નામ જરૂરી છે",
  "Mobile number must be at least 10 digits":
    " મોબાઈલ નંબર ઓછામાં ઓછો 10 અંકનો હોવો જોઈએ",
  "Mobile number must be at max 10 digits":
    " મોબાઈલ નંબર મહત્તમ 10 અંકોનો હોવો જોઈએ",
  "Enter a valid mobile": " માન્ય મોબાઇલ દાખલ કરો",
  "Mobile is required": " મોબાઇલ જરૂરી છે",
  "District is required": " જિલ્લો જરૂરી છે",
  referralSuccessText: " તમે {{name}} નો સફળતાપૂર્વક ઉલ્લેખ કર્યો છે.",
  referralFailureText: " એવું લાગે છે કે {{name}} નો ઉલ્લેખ પહેલેથી જ છે.",
  Others: " અન્ય",
  Other: " અન્ય",
  "Farm Data": " ફાર્મ ડેટા",
  "Hourly Update": " કલાકનું અપડેટ",
  "Hide Details": " વિગતો છુપાવો",
  "Show Details": " વિગતો બતાવો",
  mm: " મીમી",
  "km/hr": " કિમી/કલાક",
  "Avg Lux": " સરેરાશ લક્સ",
  "Max Lux": " મેક્સ. લક્સ",
  Activity: " પ્રવૃત્તિ",
  Duration: " સમયગાળો",
  Hour: " કલાક",
  Minutes: " મિનિટ.",
  "Irrigation Details": " સિંચાઈની વિગતો",
  "Dripper flow rate": " ડ્રિપર ફ્લો રેટ (lit./hr)",
  "drippers per plant": " છોડ દીઠ ડ્રિપરની સંખ્યા",
  numOfPlants: " છોડની સંખ્યા",
  "Total drippers": "ડ્રિપરની કુલ સંખ્યા",
  "Irrigation per plant": " છોડ દીઠ સિંચાઈ",
  "Total Irrigation": " કુલ સિંચાઈ",
  Save: " સાચવો",
  SaveMoney: " સાચવો",
  "Please fill all the fields!": " કૃપા કરીને તમામ ક્ષેત્રો ભરો!",
  quantity: " માત્રા.",
  Unit: " એકમ",
  ADD: " + ઉમેરો",
  List: " યાદી",
  "Water Amount": " પાણી એમટી.",
  Litre: "લિટર",
  "Spraying for": " માટે છંટકાવ કરવો",
  "Spraying Molecule": " છંટકાવ પરમાણુ",
  "Please select your": " કૃપા કરીને તમારું પસંદ કરો",
  capACTIVITY: " પ્રવૃત્તિ",
  "Select Activity": " પ્રવૃત્તિ પસંદ કરો",
  Nutrients: " પોષક તત્વો",
  "Spray, Pest & Disease": " સ્પ્રે, જંતુ અને રોગ",
  "Cultural Activity": " સાંસ્કૃતિક પ્રવૃત્તિ",
  Saved: " સાચવેલ!",
  SavedMessage: " તમારો ડેટા સાચવવામાં આવ્યો છે!",
  "Total irrigation can't be 0": " કુલ સિંચાઈ 0 ન હોઈ શકે.",
  "Please select time duration": " કૃપા કરીને સમય અવધિ પસંદ કરો.",
  attention: " ગુમ થયેલ ક્ષેત્રો!",
  "Please select what you are spraying for":
    " કૃપા કરીને તમે શેના માટે છંટકાવ કરી રહ્યાં છો તે પસંદ કરો!",
  "Please select spray molecule(s)": " કૃપા કરીને સ્પ્રે પરમાણુ(ઓ) પસંદ કરો!",
  "Please add water amount in litres":
    " કૃપા કરીને પાણીની માત્રા લિટરમાં ઉમેરો!",
  "Please add quantity": " કૃપા કરીને જથ્થો ઉમેરો!",
  "Please add unit": "કૃપા કરીને એકમ ઉમેરો!",
  "Please select fertiliser": " કૃપા કરીને ખાતર પસંદ કરો!",
  "Please select cultural activity":
    " કૃપા કરીને સાંસ્કૃતિક પ્રવૃત્તિ પસંદ કરો!",
  TotalIrrigation: " કુલ સિંચાઈ",
  filter: " ફિલ્ટર",
  "Type here to start searching": " શોધ શરૂ કરવા માટે અહીં ટાઇપ કરો....",
  You_sure_you_want_to_delete: " તમે ખરેખર કાઢી નાખવા માંગો છો?",
  attentionDelete: " ડીલીટ?",
  DiseaseAndPest: " રોગ અને જીવાત",
  "Update Crop Stage": " ક્રોપ સ્ટેજ અપડેટ કરો",
  "Is this correct?": " શું આ માહિતી સાચી છે?",
  "Search Plot here": " અહીં પ્લોટ શોધો",
  Details: " વિગતો",
  OR: " અથવા",
  SeasonFertilizerRequirements: " સિઝન ખાતરની જરૂરિયાતો",
  SoilAmt: " માટી એએમટી.",
  FertigationAmt: " ફર્ટિગેશન એએમટી.",
  Stage: " સ્ટેજ",
  Weeks: " અઠવાડિયાઓ",
  Week: " સપ્તાહ",
  MajorNutrients: " મુખ્ય પોષક તત્વો",
  ClearAll: "બધું સાફ કરો",
  Sources: " સ્ત્રોતો",
  MicroNutrients: " સૂક્ષ્મ પોષક તત્વો",
  SoilApplication: " સોઇલ એપ્લીકેશન",
  Season: " સિઝન",
  CropType: " પાકનો પ્રકાર",
  CropVariety: " પાકની વિવિધતા",
  Rootstock: " રુટસ્ટોક",
  ReportType: " અહેવાલનો પ્રકાર",
  InputDetails: " ઇનપુટ વિગતો",
  AddReport: " રિપોર્ટ ઉમેરો",
  ReportDate: " રિપોર્ટ તારીખ",
  PleaseSelectCrop: " કૃપા કરીને કાપો પસંદ કરો",
  PleaseSelectCropVariety: " કૃપા કરીને પાકની વિવિધતા પસંદ કરો",
  PleaseSelectRootstock: " કૃપા કરીને રૂટસ્ટોક પસંદ કરો",
  PleaseSelectCropSeason: " કૃપા કરીને પાકની સીઝન પસંદ કરો",
  PleaseSelectReportType: " કૃપા કરીને રિપોર્ટનો પ્રકાર પસંદ કરો",
  PleaseEnter: " કૃપા કરીને દાખલ કરો",
  PestAnalytics: " પેસ્ટ એનાલિટિક્સ",
  plotStatusExpired: " પ્લોટ સક્રિય કરવા માટે કૃપા કરીને રિચાર્જ કરો!",
  plotStatusOldData: "છેલ્લે {{latestDataTime}} વાગ્યે સમન્વયિત થયું",
  Acres: " એકર",
  Plots: " પ્લોટ્સ",
  PlotSaveSuccess: " પ્લોટની વિગતો સફળતાપૂર્વક સાચવવામાં આવી",
  PlotSaveFail: " પ્લોટની વિગતો સાચવવાનું નિષ્ફળ થયું.",
  ValueOutOfRangePercentage:
    " કૃપા કરીને 0 અને 100 ની વચ્ચે {{alertVal}} નું મૂલ્ય દાખલ કરો",
  "About Us": " અમારા વિશે",
  NoStageReportFound: " કોઈ સ્ટેજ રિપોર્ટ્સ મળ્યા નથી !!",
  Start: " શરૂઆત",
  NoSeasonStartedSubText:
    " એવું લાગે છે કે તમે હજુ સુધી કોઈ સીઝન શરૂ કરી નથી. કૃપા કરીને સીઝન શરૂ કરો",
  NoSeasonStartedText: " કોઈ મોસમ મળી નથી !!",
  SowingDate: " વાવણી તારીખ",
  StartCropSeasonSubText:
    " કૃપા કરીને શરૂઆતની તારીખ અને પાકનો પ્રકાર પસંદ કરો",
  DeltaT: " ડેલ્ટા ટી",
  DeltaTVPD: "ડેલ્ટા ટી એન્ડ વીપીડી",
  VPD: "વીપીડી",
  Marginal: " સીમાંત",
  MarginalHigherDelta: " સીમાંત",
  Preferred: " પસંદ",
  Unsuitable: " અયોગ્ય",
  StagesInvalidDates:
    " કૃપા કરીને સ્ટેજ અપડેટ કરવા માટે માન્ય તારીખો પસંદ કરો",
  DeleteStage: " સ્ટેજ કાઢી નાખો",
  SureYouWantToDeleteStage:
    " શું તમે ખરેખર પસંદ કરેલ સ્ટેજ કાઢી નાખવા માંગો છો?",
  FileSaved: " ફાઇલ સાચવી!",
  NoDownloadExcelDataFound: " કોઈ ડેટા મળ્યો નથી!",
  YouAreChangingSpray: "તમે સ્પ્રે/જંતુ/રોગ બદલો છો!",
  MoleculesListDeleted: " સ્પ્રે પરમાણુઓની સૂચિ કાઢી નાખવામાં આવશે!",
  StaleDataError:
    " ડેટા પ્રાપ્ત કરી શકાયો નથી. કૃપા કરીને પછીથી ફરી પ્રયાસ કરો",
  FetchDataError:
    "ઉપકરણ નોંધાયેલ છે, કૃપા કરીને ડેટા મેળવવા માટે થોડો સમય રાહ જુઓ.",
  Success: " સફળતા!",
  Oops: " ઉફ્ફ!!",
  From: " માંથી",
  NA: " NA",
  To: " પ્રતિ",
  "E-Mail fyllo support": " ઇ-મેઇલ ફિલો સપોર્ટ",
  "Call fyllo support": " ફાયલો સપોર્ટને કૉલ કરો",
  ReadMore: " વધુ વાંચો..",
  fyllo: " ફાયલો",
  EditActivity: " પ્રવૃત્તિ સંપાદિત કરો",
  PermissionDenied: " પરવાનગી નકારી",
  AllowFilePermission: " કૃપા કરીને ફાઇલની પરવાનગી આપો!",
  IncorrectEmailPhone: " ખોટો મોબાઈલ અથવા ઈમેલ .",
  EmailForAccountSupport:
    " જો તમે રજીસ્ટર ન હોવ તો કૃપા કરીને contact@fyllo.in પર ઈ-મેલ કરો",
  ServerIssue: " સર્વર સાથે કનેક્ટ કરવામાં સક્ષમ નથી",
  TryLater: " કૃપા કરીને થોડા સમય પછી ફરી પ્રયાસ કરો",
  IncorrectOTP: " ખોટો OTP .",
  RefillOTP: " કૃપા કરીને સાચો OTP ફરીથી દાખલ કરો",
  NoDataFound: " કોઈ ડેટા મળ્યો નથી!",
  "dd-mm-yyyy": " dd-mm-yyyy",
  NoPrevSeasonText: " પહેલાની કોઈ સીઝન મળી નથી !!",
  ChooseAction: "ક્રિયાઓ પસંદ કરો",
  SelectionAction: " વધુ વિગતો માટે ક્રિયા પસંદ કરો",
  Download: " ડાઉનલોડ કરો",
  unitkPa: "  kPa ",
  unitCelcius: " ℃",
  unitmm: " મીમી",
  unitLux: " લક્સ",
  unitkmph: "  kmph ",
  Scouting: " સ્કાઉટિંગ",
  Attachments: " છબીઓ જોડો",
  Notes: " નોંધો",
  Description: " વર્ણન",
  Upload: " અપલોડ કરો",
  "Read less": " ઓછું વાંચો",
  "Read more": " વધુ વાંચો",
  Uploading: " અપલોડ કરી રહ્યું છે",
  PleaseSelectDiseaseLevel: " કૃપા કરીને રોગની તીવ્રતા પસંદ કરો",
  PleaseSelectPestLevel: " કૃપા કરીને જંતુની તીવ્રતા પસંદ કરો",
  PleaseSelectNutrientLevel: " કૃપા કરીને પોષક તત્ત્વોની તીવ્રતા પસંદ કરો",
  "Permissions issue": " પરવાનગીનો મુદ્દો!",
  GalleryPermissionIssue:
    " કૃપા કરીને આ કાર્ય કરવા માટે ફાઇલ/ગેલેરી પરવાનગીઓને મંજૂરી આપો!",
  CameraPermissionIssue: " કૃપા કરીને આ કામ કરવા માટે કૅમેરાની પરવાનગીઓ આપો!",
  Gallery: " ગેલેરી",
  Camera: " કેમેરો",
  NoFarmMatched: " કોઈ ફાર્મ મેચ નથી",
  WeatherPredictionNotAllowedSubText:
    " સિંચાઈ એકમ માટે હવામાનની આગાહી ઉપલબ્ધ નથી.",
  CheckInternet: " કૃપા કરીને ઇન્ટરનેટ કનેક્શન તપાસો.",
  DeviceHealth: " ઉપકરણ આરોગ્ય",
  Health: "સ્વાસ્થ્ય",
  LooksLikeDeviceNotWorking: " એવું લાગે છે કે ઉપકરણ કામ કરતું નથી.",
  DEVICE_HEALTH: " ઉપકરણ આરોગ્ય",
  "Device data being recieved": " ઉપકરણ ડેટા પ્રાપ્ત થઈ રહ્યો છે?",
  BatteryReducedText:
    " છેલ્લા {{countVal}} દિવસમાં બેટરીમાં {{reductionVal}}% ઘટાડો થયો છે.",
  "Sensor Conditions": " સેન્સર શરતો",
  "All sensors are working fine": " બધા સેન્સર કામ કરી રહ્યા છે.",
  NextFourHoursSprayNote:
    " કૃપા કરીને આગામી ચાર કલાકની આગાહી ધ્યાનમાં લો અને સ્પ્રે કરવું કે નહીં તે નક્કી કરો.",
  Location: " સ્થાન",
  Pay: " પે",
  "Pay using": " ઉપયોગ કરીને ચૂકવણી કરો",
  "Other Payment Methods": " અન્ય ચુકવણી પદ્ધતિઓ",
  "Card Bank Transfer": " કાર્ડ/બેંક ટ્રાન્સફર",
  "Which plot?": " કયો પ્લોટ?",
  "What activity?": " કઈ પ્રવૃત્તિ?",
  DeviceNotInstalledWarning: " ડેટા જોવા માટે આ પ્લોટ પર ઉપકરણ ઇન્સ્ટોલ કરો!",
  CantUpdateData: " ડેટા અપડેટ કરી શકતા નથી. કૃપા કરીને પછીથી પ્રયાસ કરો",
  AddPlot: " પ્લોટ ઉમેરો",
  HowMuchToIrrigate: " તમે કેટલા સમયગાળા માટે સિંચાઈ કરી?",
  ActHours: " કલાક",
  ActMinutes: " મિનિટ",
  WhatWithinCultActivity: " સાંસ્કૃતિક પ્રવૃત્તિમાં શું?",
  Area: " વિસ્તાર",
  NoActivityFound: " કોઈ પ્રવૃત્તિ મળી નથી",
  OldStyle: "જૂની શૈલી",
  ActivityNotedAt: " એટલે નોંધ્યું",
  "New crop Season started": " નવી પાકની સિઝન શરૂ થઈ",
  ObservedDiseaseActivity:
    "ઉદાહરણ તરીકે, પ્લોટમાં ઓબ્ઝર્વર્ડ ડાઉની માઇલ્ડ્યુ",
  OtherActivity: "ઉદાહરણ તરીકે, પ્લોટમાં ખેડાણ કર્યું",
  NutrientActivity: "ઉદાહરણ તરીકે, 50 કિલો યુરિયા લાગુ કરો",
  AppliedUreaActivity: "ઉદાહરણ તરીકે, 50 કિલો યુરિયા લાગુ કરો",
  SprayActivity: "ઉદાહરણ તરીકે, 500 લિટર પાણીમાં કોપર 1Kg છાંટવામાં આવે છે",
  ApplyFilter: " ફિલ્ટર લાગુ કરો",
  SoilType: "માટીનો પ્રકાર",
  Foliar: "પાંદડી",
  FoliarAmt: "ફોલિઅર એએમટી",
  Soil: "માટી",
  PleaseSelectAtleastOneMethod: "કૃપા કરીને ઓછામાં ઓછો એક વિકલ્પ પસંદ કરો",
  AvailableOptions: "ઉપલબ્ધ વિકલ્પો",
  NoCropSelected: "કોઈ પાક પસંદ નથી",
  HideOptions: "વિકલ્પો છુપાવો",
  ShowOptions: "વિકલ્પો બતાવો",
  sprayRequired:
    "{{purpose}} માટે {{waterInLitres}} લિટર/એકર પાણીમાં નીચેના વિકલ્પોમાંથી કોઈ એક છંટકાવ કરો",
  nutrientRequired:
    " ની જરૂરી રકમ પ્રદાન કરવા માટે નીચેના વિકલ્પોમાંથી એકને {{type}} દ્વારા લાગુ કરો",
  SelectOneOption: "એક વિકલ્પ પસંદ કરો",
  CreateReport: "રિપોર્ટ બનાવો",
  ViewHistory: "ઇતિહાસ જુઓ",
  DownloadPDF: "પીડીએફ ડાઉનલોડ કરો",
  Summry: "સારાંશ",
  Weekly: "સાપ્તાહિક",
  StageSummary: "સ્ટેજ સારાંશ",
  "UnitsinKg/acre": "કિલો/એકરમાં એકમો",
  Options: "વિકલ્પો",
  Applyanyoneoftheoptions: "કોઈ પણ એક વિકલ્પનો ઉપયોગ કરો",
  riskLevel: "જોખમ",
  AnalysisStage: "વિશ્લેષણ સ્ટેજ/પ્રકાર",
  PleaseSelectAnalysisStage: "કૃપા કરીને વિશ્લેષણ સ્ટેજ પસંદ કરો",
  days: "દિવસ",
  Register: "નોંધણી કરો",
  EnterMobileNoEmailID: "મોબાઈલ નંબર દાખલ કરો",
  Resend: "ફરી મોકલો",
  Seconds: "સેકન્ડ",
  Number: "મોબાઈલ નમ્બર.",
  Useralreadyregistered: "વપરાશકર્તા પહેલેથી જ નોંધાયેલ છે",
  InvalidNumber: "અમાન્ય નંબર",
  FarmDetails: "ખેતરની વિગતો",
  FarmName: "ખેતરનું નામ",
  PlotDetails: "પ્લોટ વિગતો",
  PlotName: "પ્લોટનું નામ",
  DifferentSoilTypes: "માટીના વિવિધ પ્રકારો",
  Sandy: "સેન્ડી",
  SandyLoam: "સેન્ડી લોમ",
  Loamy: "લોમી",
  Clay: "માટી",
  PlotArea: "પ્લોટ એરિયા",
  Plotareacantbegreaterthanfarmarea:
    " પ્લોટનો વિસ્તાર ખેતરના વિસ્તાર કરતા વધારે ન હોઈ શકે",
  Allowfyllotoaccessyourlocation:
    "ફાઈલોને તમારું સ્થાન એક્સેસ કરવાની મંજૂરી આપીએ?",
  SAVETOADDCROP: "પાક ઉમેરવા માટે સાચવો",
  CropDetails: "પાકની વિગતો",
  CropName: "પાકનું નામ",
  SowingPruningDate: "વાવણી/કાપણી તારીખ",
  PlantationYear: "વાવેતરનું વર્ષ",
  AddDevice: "ઉપકરણ ઉમેરો",
  Adddevicetotheapp:
    "ઉપકરણમાં QR કોડ સ્કેન કરીને તમારા ઉપકરણને એપ્લિકેશનમાં ઉમેરો",
  EnterIMEINumber: "IMEI નંબર દાખલ કરો",
  Scan: "સ્કેન",
  ATTACHDEVICE: "ડિવાઈસ જોડો",
  PopoverFarmDetails: "ફાર્મ ડેટા અને આગાહીઓ જુઓ",
  PopoverCalendarDetails: "પ્રવૃત્તિઓ ઉમેરો અને જુઓ",
  popoverDiseaseDetails: "રોગો અને જીવાતો વિશે વિગતો",
  popoverAnalyticsDetails: "ફર્ટિગેશન વિગતો જુઓ",
  popoverPlotsDetails: "અન્ય ફાર્મ/પ્લોટ પસંદ કરો",
  popoverDailyReportDetails: "દૈનિક અહેવાલ તપાસો",
  popoverMyFarmDetails: "જીવંત ફાર્મ ડેટા અને ઇતિહાસ",
  popoverWeatherDetails: "કલાકદીઠ અને દૈનિક હવામાનની આગાહી",
  popoverCropStageDetails: "ક્રોપ સ્ટેજ જુઓ અને અપડેટ કરો",
  popoverShareButtonDetails: "ડેટા શેર કરો",
  popoverUpdatedTimeDetails: " છેલ્લા અપડેટનો સમય",
  popoverHealthDetails: "ઉપકરણ અને સેન્સર આરોગ્ય તપાસો",
  popoverPlusButtonDetails: "પ્રવૃત્તિઓ ઉમેરો",
  popoverBarMenuDetails: " ચુકવણી, રેફરલ, આધાર",
  popoverNotificationDetails: "અહીં સૂચનાઓ તપાસો",
  popoverSoilMoistureDetails: "લાઇવ ડેટા, ઇતિહાસ તપાસવા માટે ક્લિક કરો",
  Previous: "અગાઉના",
  Skip: "છોડો",
  Finish: "સમાપ્ત કરો",
  Delete: "કાઢી નાખો",
  Redo: "ફરીથી લખો",
  ClickandscanQR:
    "નીચેના સ્કેન બટન પર ક્લિક કરો અને ઉપકરણના આ વિસ્તાર પર QR કોડ સ્કેન કરો",
  Welcome: "સ્વાગત",
  Welcomeback: "સ્વાગત છે પાછા",
  HelpWithdetails: "કૃપા કરીને થોડી વધુ વિગતોમાં અમને મદદ કરો",
  Language: "ભાષા",
  InvalidIMEINumber: "અમાન્ય IMEI નંબર",
  ApplicationMode: "એપ્લિકેશન મોડ",
  kgHectare: "કિગ્રા/હે",
  percent: "%",
  UnitPPM: "પીપીએમ",
  Select: "પસંદ કરો",
  LogoutModalDescription: "શું તમે ખરેખર લોગઆઉટ કરવા માંગો છો?",
  YourReport: "તમારો રિપોર્ટ",
  RemovePhoto: "દૂર કરો",
  addActivity: "પ્રવૃત્તિ ઉમેરો",
  notificationSettings: "સૂચના સેટિંગ્સ",
  Settings: "સેટિંગ્સ",
  makeAPayment: "ચુકવણી કરી",
  pastTransactions: "ભૂતકાળના વ્યવહારો",
  NoPesticideSchedule: "કોઈ જંતુનાશક શિડ્યુલ મળ્યું નથી!",
  UpcomingSpray: "આગામી સ્પ્રે",
  Conditional: "શરતી",
  Fixed: "નિશ્ચિત",
  Purpose: "હેતુ",
  Chemical: "કેમિકલ",
  WaterQty: "પાણીની માત્રા",
  CompleteSchedule: "સંપૂર્ણ શેડ્યૂલ",
  UsePasswordInstead: "તેના બદલે પાસવર્ડનો ઉપયોગ કરો",
  useOTPInstead: "તેના બદલે OTP નો ઉપયોગ કરો",
  cropStage: "પાક સ્ટેજ",
  whenToSpray: "ક્યારે છંટકાવ કરવો",
  whenToSprayDescription: "`જ્યારે {{severity}} {{days}} દિવસો માટે`",
  notApplicable: "લાગુ પડતું નથી",
  StagewiseSprayGuideline: "સ્ટેજપ્રમાણે સ્પ્રેનું માર્ગદર્શન",
  back: "પાછળ",
  registerNewUser: "નવા વપરાશકર્તાની નોંધણી કરો",
  existingUserLogin: "હાલના વપરાશકર્તામાં લૉગ ઇન કરો",
  ComebackLater:
    " અમે તેના પર કામ કરી રહ્યા છીએ, કૃપા કરીને ટૂંક સમયમાં પાછા તપાસો!",
  SelectAPlot: "પ્લોટ પસંદ કરો",
  StartStage: "પ્રારંભિક તબક્કો",
  EndStage: "છેલ્લો તબક્કો",
  Neutrients: "પોષક તત્વો",
  NoSpray: " સ્પ્રેની સંખ્યા",
  ThisWeek: "આ અઠવાડિયે",
  TillDate: "આજ સુધી",
  ThisMonth: "આ મહિને",
  LastMonth: "ગયા મહિને",
  irrigationDetected: " સિંચાઈ મળી!",
  DurationInMinutes: " અવધિ (મિનિટમાં)",
  ThisSeason: "મોસમ",
  SelectSeason: "સિઝન પસંદ કરો",
  stageNotStartedWarning: "{{stageName}} સ્ટેજ હજી શરૂ થયો નથી",
  CreditBalance: "ક્રેડિટ બેલેન્સ",
  SearchContact: "સંપર્ક શોધો",
  EnterName: "નામ દાખલ કરો",
  Contacts: "સંપર્કો",
  NoContactsFound: "કોઈ સંપર્કો મળ્યા નથી",
  WhyFyllo: "કેમ ફાયલો?",
  CropQuality: "પાકની ગુણવત્તા અને ઉપજ",
  DiseaseManagement: "ઉત્તમ રોગ અને જંતુ વ્યવસ્થાપન",
  PreciseIrrigation: "ચોક્કસ સિંચાઈ",
  BestService: "શ્રેષ્ઠ સેવા",
  Sendvia: "માર્ગે મોકલો",
  Howitwork: "તે કેવી રીતે કામ કરે છે?",
  creditedyouraccount:
    "દરેક રેફરલ પર €¹20/- તમારા ફાયલો રિચાર્જ એકાઉન્ટમાં જમા થશે.",
  maximumearned: " 25 લોકોને શેર કરીને વધુમાં વધુ 500 કમાઈ શકાય છે.'",
  friendinstallsfyllo:
    "જો તમારો મિત્ર fyllo ઇન્સ્ટોલ કરે છે, તો તમને વધારાના £250 મળશે અને તમારા મિત્રને fyllo ઇન્સ્ટોલ કરવા પર 500 ની છૂટ મળશે.",
  off: "બંધ",
  Mosteffectivereferers:
    "દર 6 મહિનામાં સૌથી વધુ અસરકારક રેફરર્સને નીરો ડિવાઇસ અથવા તિરુપતિની ટ્રીપથી પુરસ્કૃત કરવામાં આવશે.",
  amountcredited: "₹{{amount}} has successfully been credited",
  fylloaccount: "તમારા ફાયલો એકાઉન્ટમાં",
  Backtoreferral: "રેફરલ પર પાછા",
  TotalCreditsEarned: "કુલ ક્રેડિટ્સ કમાઈ",
  CreditsRedeemed: "ક્રેડિટ રિડીમ",
  GotReferralCode: "રેફરલ કોડ મળ્યો?",
  ClaimYourRewards: "તમારા પુરસ્કારોનો દાવો કરો",
  EnterCode: "તમારો કોડ નાખો",
  EnterValidReferralCode: "માન્ય રેફરલ કોડ દાખલ કરો",
  REDEEMED: "રિડીમ કર્યું",
  CustomDate: "તારીખ",
  SpecifyStage: "સ્ટેજ",
  SelectStage: "સ્ટેજ પસંદ કરો",
  ThisStage: " આ તબક્કો",
  CustomStage: "સ્ટેજ રેન્જ",
  CREDITED: "ક્રેડિટ",
  WAITING: "રાહ જોઈ રહ્યું છે",
  referralScreen: "સંદર્ભ લો અને કમાઓ",
  Apply: "અરજી કરો",
  Show: "બતાવો",
  UseCreditBalance: "ક્રેડિટ બેલેન્સનો ઉપયોગ કરો",
  ReferralBonus: "રેફરલ બોનસ",
  RefferalCodeApplied: "રેફરલ કોડ લાગુ",
  ManageFarmUsingFyllo:
    "હેલો,\\nહું મારા ખેતરનું સંચાલન કરવા માટે ફાયલો ઉપકરણનો ઉપયોગ કરું છું.",
  FylloBenefit: " Fylo નો ઉપયોગ કરીને મને નીચેના મુખ્ય લાભો મળ્યા",
  InstallFyllo:
    "હું તમને વિનંતી કરું છું કે તમારા ખેતરમાં પણ Fyllo લગાવો. તમે Fyllo ટીમનો WhatsApp પર +91-9380908490 પર અથવા contact@fyllo.in પર ઈમેલ દ્વારા સંપર્ક કરી શકો છો.",
  UseRefferalCode:
    "ફાઇલો એપમાં તમારા આગામી રિચાર્જ પર ડિસ્કાઉન્ટ મેળવવા માટે {{code}} રેફરલ કોડનો ઉપયોગ કરો",
  SomeErrorOccured: "કેટલીક ભૂલ થઈ",
  AnyChangeInReport: "રિપોર્ટમાં કોઈ ફેરફાર?",
  DeleteAccount: "એકાઉન્ટ કાઢી નાખો",
  ReasonToDelete:
    "તમે તમારું એકાઉન્ટ કેમ કાઢી નાખવા માગો છો તે કારણ પસંદ કરો?",
  NoFruitfulResult: "મને કોઈ ફળદાયી પરિણામ મળતું નથી",
  NothingWorking: "કંઈપણ બરાબર કામ કરી રહ્યું નથી",
  PrivacyConcerns: "મને ગોપનીયતાની ચિંતા છે",
  WhatisNotWorking:
    "તમને વધુ સારું પરિણામ આપવા માટે અમે ખર્ચાળ રીતે અમારી જાતને સુધારી રહ્યા છીએ. શું તમે કૃપા કરીને અમને શેર કરી શકો છો કે તમે શા માટે અસંતુષ્ટ છો?",
  Warning: "ચેતવણી",
  DeleteWarningMessage:
    "જો તમે તમારું એકાઉન્ટ કાઢી નાખો છો, તો તમે કાયમ માટે તમામ ડેટા અને સબ્સ્ક્રિપ્શન્સ ગુમાવશો",
  OurTeamWillContact:
    "એકાઉન્ટ ડિલીટ કરવા માટે અમારી ટીમ ટૂંક સમયમાં તમારો સંપર્ક કરશે.",
  Close: "બંધ",
  GoBack: "પાછા જાવ",
  GiveYourThoughts: "તમારા વિચારો આપો",
  ConfirmDeletion: "કાઢી નાખવાની પુષ્ટિ કરો",
  UpdateStage: "અપડેટ સ્ટેજ",
  EndSeasonWarningMsg:
    "શું તમને ખાતરી છે કે તમે આ સિઝનને સમાપ્ત કરવા માગો છો?",
  StageUpdated: "સ્ટેજ અપડેટ કર્યું",
  Retry: "ફરી પ્રયાસ કરો",
  NoWeekSelected: "કોઈ અઠવાડિયું પસંદ કરેલ નથી",
  thingsToRememberFertigation: "ફર્ટિગેશન વખતે યાદ રાખવા જેવી બાબતો",
  thingsToRemember: "યાદ રાખવા જેવી બાબતો",
  NoDiseaseDataFound: "કોઈ રોગનો ડેટા મળ્યો નથી",
  NoPestDataFound: "કોઈ પેસ્ટ ડેટા મળ્યો નથી",
  Range: "શ્રેણી",
  Value: "મૂલ્ય",
  DoYouNeedHelp: "શું તમે કોઈ સમસ્યાનો સામનો કરી રહ્યા છો?",
  LetUsHelpYou: "ચાલો તમને મદદ કરીએ",
  ChatWithUs: "અમારી સાથે ચેટ કરો",
  CallUs: "અમને કૉલ કરો",
  update: "અપડેટ કરો",
  cancel: "રદ કરો",
  NewUpdateAvailable: "અપડેટ ઉપલબ્ધ !!!",
  PleaseUpdateYour:
    "કૃપા કરીને તેનો ઉપયોગ કરવા માટે તમારી એપ્લિકેશનને અપડેટ કરો",
  helpMessage:
    "હાય, મને મારા ખેતર {{farmId}} માટે મદદની જરૂર છે. મારું વપરાશકર્તા નામ {{username}} છે",
  notApplicableInfo: "આ તબક્કામાં {{pestOrDiseaseName}} લાગુ પડતું નથી",
  pesticideScheduleNotAllowed: "સિંચાઈ એકમ માટે જંતુનાશક શેડ્યૂલ ઉપલબ્ધ નથી.",
  latestReport: " તાજેતરનો અહેવાલ",
  noReportsAvailable: " કોઈ અહેવાલો ઉપલબ્ધ નથી",
  uploadPdfReport: " અહેવાલ અપલોડ કરો",
  scanningPdf: " સ્કેનિંગ દસ્તાવેજ",
  cantScanPdf: " અમે આ દસ્તાવેજને સ્કેન કરવામાં અસમર્થ છીએ",
  tryAgainPdf: " કૃપા કરીને પોષક તત્વોના મૂલ્યો જાતે જ દાખલ કરો",
  basedOnUploadedReport: " અપલોડ કરેલા અહેવાલના આધારે",
  ScheduleGeneratedOnDefaultReport: " ડિફોલ્ટ રિપોર્ટ પર જનરેટ થયેલ શેડ્યૂલ",
  ForPreciseSchedule: " ચોક્કસ સમયપત્રક માટે",
  remember: "યાદ રાખો",
  lowest: "સૌથી નીચો",
  average: "સરેરાશ",
  highest: "સર્વોચ્ચ",
  onAverage: "સરેરાશ",
  Vpd: "વીપીડી",
  vpd: "વીપીડી",
  deltaT: " ડેલ્ટા ટી",
  newVersionAvailable: "નવું સંસ્કરણ ઉપલબ્ધ!!",
  SelectPlot: "પ્લોટ પસંદ કરો",
  RechargeUptoDate: "એક તારીખ સુધી રિચાર્જ કરો",
  getUptoDiscount:
    "{{discountPercentage}}% સુધીનું વધારાનું ડિસ્કાઉન્ટ મેળવો",
  Yearly: "વાર્ષિક",
  Monthly: "માસિક",
  Quarterly: "ત્રિમાસિક",
  DueInDays: "{{days}} દિવસમાં બાકી છે",
  ContactSupport: "સંપર્ક આધાર",
  Email: "ઈમેલ",
  Phone: "ફોન",
  Whatsapp: "વોટ્સેપ",
  INITIATED: "પ્રારંભ",
  AUTHORIZED: "અધિકૃત",
  SUCCESS: "સફળતા",
  FAILED: "નિષ્ફળ",
  NEW: "નવું",
  Search: "શોધો",
  VideoGuide: "માર્ગદર્શિકાઓ",
  ViewMyProfile: "મારી પ્રોફાઇલ જુઓ",
  SearchKeywordToLearnAboutIt: "વિશે જાણવા માટે કીવર્ડ શોધો",
  rootWidth: "મૂળની પહોળાઈ (ઇંચ)",
  rootDepth: "મૂળની ઊંડાઈ (ઇંચ)",
  dripperDischarge: "ડ્રિપર ડિસ્ચાર્જ (લિટર)",
  soilRetention: "માટીની જાળવણી (mm)",
  dripperDistance: "ડ્રિપર અંતર (ફૂટ)",
  CalculateValue: "મૂલ્યની ગણતરી કરો",
  ReCalculateValue: "પુનઃ ગણતરી",
  Schedules: "જંતુનાશકોનું શેડ્યૂલ",
  ChangeFarmDesc:
    "તમારા ફેરફારો સાચવવામાં આવશે નહીં. શું તમે ચાલુ રાખવા માંગો છો?",
  AddNewFarm: "નવો ઉમેરો",
  Remove: "ફોટો દૂર કરો",
  UseExisting: "અસ્તિત્વમાં છે",
  NoNotification: "કોઈ સૂચના ઉપલબ્ધ નથી",
  Edit: "ફેરફાર",
  CopytoPlot: "નકલ",
  Depth: "ઊંડાઈ",
  inch: "ઇંચ",
  Error: "ભૂલ",
  daysExceed:
    "એવું લાગે છે કે તમારા દિવસો લણણીની મોસમના દિવસો કરતાં લાંબા છે.",
  updateStages:
    "તમારા ફાર્મ સંબંધિત ચોક્કસ ડેટા મેળવવા માટે કૃપા કરીને તમારા પાકના તબક્કાને અપડેટ કરો!",
  Later: "પાછળથી",
  selectDuration: "સમયગાળો પસંદ કરો",
  Lastdurationdays: "છેલ્લા {{duration}} દિવસ",
  seasonSummary: "મોસમ સારાંશ",
  TotalWaterUsed: "કુલ વપરાયેલ પાણી",
  TotalIrrigationEvents: "કુલ સિંચાઈની ઘટનાઓ",
  AvgIntervalbetweenIrrigations: "2 સિંચાઈ વચ્ચે સરેરાશ અંતરાલ",
  NumberofSpraysutilized: "વપરાયેલ સ્પ્રેની સંખ્યા",
  AverageSunLight: "સરેરાશ સૂર્યપ્રકાશ",
  AverageSunshineHours: "સરેરાશ સનશાઇન કલાકો",
  Explore: "સ્ટેજ સારાંશનું અન્વેષણ કરો",
  Lai: "એલ.એ.આઈ",
  "Not Optimal": "શ્રેષ્ઠ નથી",
  lai: "એલ.એ.આઈ",
  LaiDetails: "એલ.એ.આઈ વિગતો",
  Optimal: "શ્રેષ્ઠ",
  Image: "છબી",
  AboutDeltaT: "ડેલ્ટા ટી શું છે?",
  AboutVPD: "વીપીડી એટલે શું?",
  AboutLAI: "એલ.એ.આઈ શું છે?",
  AddCost: "પ્રવૃત્તિની કિંમત",
  costExample: "ઉદાહરણ તરીકે: 400",
  selectCurrency: "મુદ્રા પસંદ કરો",
  "No Rain": 'વરસાદ નથી',
  "AppGuideVideo": 'અપ્પ ગુઇડે વિડિઓઝ',
  ProductVideo: 'પ્રોડક્ટ વિડિઓઝ',
  AgronomyVideo: 'એગ્રોનોમી વિડિઓઝ',
  TestimonialVideo: 'ટેસ્ટીમોનીઅલ વિડિઓઝ',
  IDPMSuggestion: "{{disease}} નું સંચાલન",
  AddYourFertilizers: "તમારું ખાતરની માહિતી ભરો",
  SprayNotifs: "સ્પ્રેની સૂચનાઓ",
  Cost: 'ખર્ચ',
  seedTreatment: 'બીજની સારવાર',
  insecticide: 'કીટનાશક',
  nematicide: 'નેમાટીસાઇડ',
  fungicide: 'ફંગીસાઇડ',
  bactericide: 'બેક્ટેરિસાઇડ',
  InvalidPayment: "અમાન્ય ચુકવણી",
  PaymentInProgress: "પ્રક્રિયામાં ચુકવણી",
  "ServiceRequest": "સમस्या રિપોર્ટ",
  "NoServiceRequestFound": "કોઈ સમસ્યા રિપોર્ટ મળ્યો નથી",
  "YourServiceRequests": "તમારા સમસ્યા રિપોર્ટ",
  "RaiseServiceRequest": "સમस्या રિપોર્ટ કરો",
  "TellAboutYourIssue": "તમારી સમસ્યા જણાવો",
  "DoYouWantToOpenThisServiceRequest": "શું તમે આ સમસ્યા રિપોર્ટ ખોલવા માંગો છો?",
  "DoYouWantToCloseThisServiceRequest": "શું તમે આ સમસ્યા રિપોર્ટ બંધ કરવા માંગો છો?",
  ReopenServiceRequest: "ફરીથી ખોલો",
  CloseServiceRequest: "બંધ કરો",
  OpenDate: "તારીખ ખોલો",
  SelectImage: "ફોટો પસંદ કરો",
  TakeImage: "ફોટો લ્યો",
  open: "ખોલો",
  closed: "બાંધકરો",
  new: "નવું",
  "pending reminder": "બાકીનું રીમાઇન્ડર",
  merged: "મર્જ",
  removed: "નીકળો",
  lastUpdateHours: "છેલ્લા {{hours}} કલાક",
  Refresh: "તાજું કરો",
  Issue: "મુદ્દો",
  IssueIsRequired: "સમસ્યા ભરવા જરૂરી છે",
  DetailIsRequired: "વિગતો જરૂરી છે",
  ImagesTooLargeError: 'તમે જે છબીઓ અપલોડ કરવાનો પ્રયાસ કરી રહ્યા છો તે ઘણી મોટી છે. કૃપા કરીને કેટલીક છબીઓ હટાવો અને ફરી પ્રયાસ કરો',
  ReachedMaxAttachments: 'તમે છબીઓ માટેની મહત્તમ મર્યાદા સુધી પહોંચી ગયા છો. વધુ ઉમેરતા પહેલા કૃપા કરીને કેટલીક છબીઓ દૂર કરો.'
};
const te = {
  Farm: "వ్యవసాయం",
  Calendar: "క్యాలెండర్",
  News: "వార్తలు",
  Disease: "వ్యాధి",
  ShowReportFor: "నివేదిక",
  MyFarm: "నా వ్యవసాయం",
  DailyReport: "రోజువారీ రిపోర్ట్క",
  Weather: "వాతావరణం",
  Total: "మొత్తం",
  DiseaseAnalytics: "డిసీజ్ అనలిటిక్స్",
  Username: "వినియోగదారుని పేరు",
  EnterOTP: "OTP నమోదు చేయండి",
  EnterPassword: "పాస్వర్డ్ నమోదు చేయండి",
  LastUpdated: "చివరి అప్డేట్",
  SoilMoisture1: "నేలలో తేమ-1",
  SoilMoisture2: "నేలలో తేమ-2",
  SoilTemperature: "నేల ఉష్ణోగ్రత",
  LeafWetness: "ఆకు తడి",
  AirTemperature: "గాలి ఉష్ణోగ్రత",
  AirHumidity: "గాలి తేమ",
  ViewDetails: "వివరాలను చూడండి",
  AirPressure: "గాలి ఒత్తిడి",
  LightIntensity: "కాంతి తీవ్రత",
  Probability: "సాధ్యత",
  DayTimeAverageLux: "రోజు సూర్య ప్రకాశం",
  Rainfall: "వర్షపాతం",
  RainFallLastHour: "చివరి గంట వర్షపాతం",
  TwentyFourHours: "24 గంటలు",
  Rain: "వర్షం",
  Alerts: "హెచ్చరికలు",
  SoilMoisture1Details: "నేల తేమ-1 వివరాలు",
  SoilMoisture2Details: "నేల తేమ-2 వివరాలు",
  SoilTempDetails: "భూగతి వివరాలు",
  LeafWetnessDetails: "ఆకు నీటి వివరాలు",
  AirTempDetails: "గాలి ఉష్ణోగ్రత వివరాలు",
  AirHumidityDetails: "గాలి తేమ వివరాలు",
  AirPressureDetails: "గాలి ఒత్తిడి వివరాలు",
  lightIntensityDetails: "కాంతి తీవ్రత వివరాలు",
  rainFallDetails: "వర్షాల వివరాలు",
  windSpeedDetails: "గాలి వేగం వివరాలు",
  batteryDetails: "బ్యాటరీ వివరాలు",
  battery: "బ్యాటరీ",
  baseStageData: "{{days}} రోజులు {{baseStage}} తర్వాత",
  baseStageDataBefore: "{{days}} రోజులు {{baseStage}} ముందు",
  windDirection: "గాలి దిక్కు",
  windSpeed: "గాలి వేగం",
  rainFall: "వర్షపాతం",
  leafWetness: "ఆకు తడి",
  lightIntensity: "కాంతి తీవ్రత",
  soilMoisture1: "నేల తేమ-1 ",
  soilMoisture2: "నేల తేమ-2",
  soilTemp: "నేల ఉష్ణోగ్రత",
  signalStrength: "నెట్వర్క్",
  signalStrengthDetails: "నెట్వర్క్ వివరాలు",
  baroPressure: "బ్యారోమెట్రిక్ ఒత్తిడి",
  airPressure: "వాయు ఒత్తిడి",
  airHumidity: "గాలి తేమ",
  airTemp: "గాలి ఉష్ణోగ్రత",
  GDD: "డిగ్రీ డేస్",
  Gdd: "డిగ్రీ డేస్",
  gdd: "డిగ్రీ డేస్",
  GDDDetails: "డిగ్రీ డే వివరాలు",
  Inputs: "ఇన్పుట్లు",
  Services: "సేవలు",
  Nutrient: "పోషణలు",
  StartDate: "ప్రారంభ తేది",
  EndDate: "ముగింపు తేది",
  Spray: "స్ప్రే (spray)",
  CulturalPractice: "వ్యవసాయ వ్యవహారం",
  "Cultural Practice": "వ్యవసాయ వ్యవహారం",
  Labour: "కూలీలు",
  Seeds: "విత్తనాలు",
  Next: "తర్వాత",
  Email: "ఈ-మెయిల్",
  Mobile: "మొబైల్",
  RegenerateOTP: "OTPని పునరుత్పత్తి చేయండి",
  Fertilizer: "ఎరువులు",
  PandDControl: "తెగులు మరియు వ్యాధి నియంత్రణ",
  and: "మరియు",
  FieldPreparation: "ఫీల్డ్ తయారీ",
  Irrigation: "నీటిపారుదల",
  ActivityType: "యాక్టివిటీ రకం",
  RainProbability: "వర్షం సంభావ్యత",
  RainQuantity: "వర్షపు పరిమాణం",
  RainQty: "వర్షపు పరిమాణం",
  WindSpeed: "గాలి వేగం",
  Login: "లాగిన్",
  ForgotPassword: "పాస్‌వర్డ్ మర్చిపోయాను?",
  ResourcesUsed: "ఉపయోగించిన వనరులు",
  Date: "తేది",
  ActionsToDo: "చేయవలసిన చర్యలు",
  ActionsDone: "చేయబడిన కార్యాలు",
  LabourCost: "లేబర్ ఖర్చు",
  ResourcesCost: "వనరుల ఖర్చు",
  NotRequired: "అవసరము లేదు",
  MarkAsDone: "పూర్తి అయినట్లు గుర్తించు",
  ActivityDate: "యాక్టివిటీ తేదీ",
  Done: "పూర్తి",
  Cancel: "రద్దు",
  EnterCostOfResourcesUsed: "వనరుల వ్యయం నమోదు చేయండి",
  EmptyCalendarItemText: "మీ ప్లాట్ కోసం ఈ రోజున ఏమీ ప్లాన్ చేయలేదు!!",
  Posted: "పోస్ట్",
  Min: "కనిష్ఠ",
  Max: "గరిష్ఠ",
  Avg: "సగటు",
  DayMin: "రోజు సమయం కనిష్ఠ",
  DayMax: "రోజు సమయం గరిష్ఠ",
  DayAvg: "రోజు సమయం సగటు",
  NoData: "డేటా లేదు!!",
  Day: "రోజు",
  Day1: "1 రోజు",
  Week1: "1 వారం",
  Month1: "1 నెల",
  Year1: "1 సంవత్సరం",
  Custom: "కస్టమ్",
  EmailSupport: "ఇమెయిల్ మద్దతు",
  Network: "నెట్వర్క్",
  Power: "శక్తి",
  ProbabilityInYourPlot: "మీ ప్లాట్లో సాధ్యత",
  CropCalendar: "పంట క్యాలెండర్",
  AllCalendar: "అన్ని క్యాలెండర్",
  Actions: "చర్యలు",
  Insights: "అంశాలు",
  MyFarm: "నా వ్యవసాయం",
  Farms: "పొలాలు",
  NewsFeed: "న్యూస్ ఫీడ్",
  Notifications: "నోటిఫికేషన్‌లు",
  Profile: "ప్రొఫైలు",
  Logout: "లాగౌట్",
  Support: "మద్దతు",
  ChangeLanguage: "భాష",
  WetDuration: "తడి వ్యవధి",
  hours: "గంటలు",
  "Too Wet": "చాలా తడి",
  "Adequate Moisture": "తగినంత తేమ",
  Dry: "పొడిగా",
  "Too Dry": "చాలా పొడిగా",
  Wet: "తడి",
  Nil: "ఏమి లేదు",
  Low: "తక్కువ",
  Medium: "మధ్యస్థ",
  High: "అత్యధిక",
  HIGH: "అధిక",
  NIL: "ఏమి లేదు",
  LOW: "తక్కువ",
  MEDIUM: "మధ్యస్థ",
  Night: "రాత్రి",
  "Fully Overcast": "పూర్తిగా మంచుగా",
  Overcast: "మంచుగా",
  Sunset: "సూర్యాస్తమయం",
  Sunrise: "ఉదయం",
  "Partly Cloudy": "భాగంగా మంచుగా",
  Sunny: "చివరి",
  "Clear Sky": "స్పష్ట ఆకాశం",
  HoursOfRain: "వర్షపాత గంటలు",
  RainHours: "వర్షపాత గంటలు",
  HoursOfSun: "సూర్యోదయ గంటలు",
  CloudCover: "మేఘావృతం",
  HourlyWeatherPrediction: "గంటల వాతావరణ అంచనా",
  HourlyPrediction: "గంటల అంచనా",
  Summary: "సారాంశం",
  SoilMoisture: "నేలలో తేమ",
  Average: "సగటు",
  Minimum: "కనిష్ఠ",
  Maximum: "గరిష్ఠ",
  LeafWetnessHours: "ఆకు తడి గంటలు",
  Name: "పేరు",
  Severity: "తీవ్రత",
  ActionsRequired: "చర్యలు అవసరం",
  NoActionsForToday: "ఈరోజు కోసం చర్యలు లేవు",
  Quantity: "పరిమాణం",
  BestConditionsForSpray: "స్ప్రే కోసం ఉత్తమ సమయం",
  ModerateConditionsForSpray: "జాగ్రత్తగా పిచికారీ చేయండి",
  WorstConditionsForSpray: "ఈ సమయంలో స్ప్రే చేయవద్దు",
  SprayTimings: "స్ప్రే సమయాలు",
  DailyReportNotAvailable: "డైలీ రిపోర్ట్ అందుబాటులో లేదు",
  Evapotranspiration: "ఎవాపొట్రాన్స్పిరేషన్",
  Eto: "ఇటో",
  eto: "ఇటో",
  DailyETO: "డేలీ ఇటో",
  ETODetails: "ఎవాపొట్రాన్స్పిరేషన్ వివరాలు",
  Pest: "పెస్ట్",
  to: "కు",
  ReportsStages: "నివృత్తులు మరియు దశలు",
  StageReports: "దశ  రిపోర్ట్",
  StageReport: "దశ నివృత్తులు",
  SeasonReport: "రుతువు నివృత్తి",
  CropStages: "పంట దశలు",
  PrevSeasonReports: "గత రుతువులు",
  PlotReports: "ప్లాట్  రిపోర్ట్",
  ActivitiesDone: "చర్యలు చేసినవి",
  Activities: "చర్యలు",
  NoActivitiesDone: "చర్యలు చేయబడలేదు",
  TotalRainFall: "మొత్తం వర్షపాతం",
  DayRainfall: "రోజు వర్షపాతం",
  TotalETO: "మొత్తం ఇటో",
  AverageETO: "సగటే ఇటో",
  AccumulatedGDD: "నమ్మకాల గ్రో డిగ్రీ డేస్",
  ReportNotAvailable: "నివృత్తి అందుబాటులో లేదు",
  StartDateforStage: "దశ ప్రారంభ తేదీని ఎంచుకోండి",
  Analytics: "విశ్లేషణ",
  UpdateNotifications: "అప్డేట్ నోటిఫికేషన్స్",
  Notifications: "నోటిఫికేషన్స్",
  Fertigation: "ఫెర్టిగేషన్",
  WeeklySchedule: "వారపు షెడ్యూల్",
  Create: "సృష్టించు",
  Report: "నివృత్తి",
  View: "చూడండి",
  Crop: "పంట",
  PDF: "పి.డి.ఎఫ్",
  CropStartDate: "పంట ప్రారంభ తేదీ",
  StageStartDate: "దశ ప్రారంభ తేదీ",
  EndCropSeason: "పంట రుతువు ముగించు",
  CurrentStage: "ప్రస్తుత దశ",
  EndConfirmation:
    "ఇది ప్రస్తుత సీజన్‌ను ముగిస్తుంది.. మీరు ఖచ్చితంగా  ముగించాలి అనుకుంటున్నారా?",
  Yes: "అవును",
  No: "కాదు",
  StartCropSeason: "కొత్త పంట సీజన్‌ను ప్రారంభించండిి",
  RestartCropSeason: "పాత పంట సీజన్‌ను పునఃప్రారంభించండి",
  NewCrop: "కొత్త పంట",
  Calm: "ప్రశాంతత",
  "Light Air": "తేలికపాటి గాలి",
  "Light Breeze": "తేలికపాటి గాలి",
  "Gentle Breeze": "సున్నితమైన గాలి",
  "Moderate Breeze": "మితమైన గాలి",
  "Fresh Breeze": "తాజా గాలి",
  "Strong Breeze": "బలమైన గాలి",
  "Near Gale": "కనుమబువి",
  Gale: "గేలు",
  "Strong Gale": "బలమైన గేలు",
  Storm: "తుఫాను",
  "Violent Storm": "బలమైన తుఫాను",
  "leafDryness": "లీఫ్ డ్రైనెస్",
  Direction: "దిశ",
  DownloadHistory: "హిస్టరీని డౌన్‌లోడ్ చేయండి",
  Today: "ఈ రోజు",
  SeasonDegreeDays: "రుతువు డిగ్రీ డేస్",
  Payment: "పేమెంట్",
  Vouchers: "వోచర్లు",
  Referral: "రెఫరల్",
  PaymentHistory: "పేమెంట్ హిస్టరీ",
  Status: "స్థితి",
  Amount: "మొత్తం",
  PaymentSuccess: "చెల్లించడం విజయం",
  PaymentFailure: "చెల్లించడం విఫలం",
  Plot: "ప్లాటులు",
  Date: "తేదీ",
  PayFull: "పూర్తిగా చెల్లించండి",
  Validity: "చెల్లుబాటు",
  SelectFarm: "దయచేసి మీ ఫార్ము(లు) ఎంచుకోండి",
  Loading: "లోడింగ్..",
  ViewBillDetails: "బిల్లు వివరాలను చూడండి",
  Checkout: "చెక్ ఔట్",
  OfferApplied: "ఆఫర్ వర్తింపజేయబడింది",
  BillDetails: "బిల్ వివరాలు",
  OrderTotal: "ఆర్డర్ మొత్తం",
  TotalDiscount: "మొత్తం డిస్కౌంట్",
  ToPay: "మొత్తం",
  ProceedToPay: "చెల్లించడానికి ముందు వెళ్ళండి",
  BackToHome: "ఇంటికి వెళ్ళండి",
  OrderNum: "ఆర్డర్ నం.",
  VoucherHistory: "వోచర్ హిస్టరీ",
  ViewDetailedBill: "విస్తరముగా బిల్లు చూడండి",
  ReferAndEarn: "రివార్డ్‌లను సంపాదించడానికి మీ స్నేహితుడికి రెఫర్ చేయండి",
  SubmitReferral: "సూచించు రిఫరల్",
  referralHistory: "రిఫరల్ చరిత్ర",
  Expired: "ప్లాట్ పేమెంట్ గడువు ముగిసింది",
  redirectAfterExpired: "రీఛార్జ్ చేయడానికి ఇక్కడ క్లిక్ చేయండి",
  History: "చరిత్ర",
  CropSeasonAndStageReports: "పంట దశలు మరియు రిపోర్టులు",
  "Mobile Number*": "మొబైల్ నంబర్*",
  "Friend Name*": "స్నేహితుడి పేరు*",
  "District*": "జిల్లా*",
  "Village(Optional)": "గ్రామం(ఆప్షనల్)",
  "Thank You": "ధన్యవాదాలు",
  "Thank you for participation": "పాల్గొన్నందుకు ధన్యవాదాలు",
  PENDING: "పెండింగ్‌లో ఉంది",
  PROCESSED: "ప్రాసెస్ చేయబడింది",
  EXPIRED: "గడువు ముగిసింది",
  "Name must be at least 2 characters": "పేరు తప్పనిసరిగా కనీసం 2 అక్షరాలు ఉండాలి",
  "Name must be at max 50 characters": "పేరు తప్పనిసరిగా గరిష్టంగా 50 అక్షరాలు ఉండాలి",
  "Please enter a valid name": "దయచేసి చెల్లుబాటు అయ్యే పేరును నమోదు చేయండి",
  "Name is required": "పేరు అవసరం",
  "Mobile number must be at least 10 digits":
    "మొబైల్ నంబర కనిష్ఠమిది 10 అంకెలు ఉండాలి",
  "Mobile number must be at max 10 digits":
    "మొబైల్ నంబర గరిష్ఠ 10 అంకెలు ఉండాలి",
  "Enter a valid mobile": "చెల్లుబాటు అయ్యే మొబైల్ నంబర్‌ను నమోదు చేయండి",
  "Mobile is required": "మొబైల్ అవసరం",
  "District is required": "జిల్లా అవసరం",
  referralSuccessText: "మీరు {{name}} కోసం సూచించారు.",
  referralFailureText: "లాగదా {{name}} సూచించబడింది.",
  Weather: "వాతావరణం",
  Fertigation: "ఫెర్టిగేషన్",
  Disease: "వ్యాధి",
  Irrigation: "నీటిపారుదల",
  Others: "ఇతర",
  Other: "ఇతర",
  "Farm Data": "ఫార్ము డేటా",
  Alerts: "హెచ్చరికలు",
  "Hourly Update": "గంటకోసారి అప్‌డేట్",
  "Hide Details": "వివరాలను దాచు",
  "Show Details": "వివరాలను చూపు",
  mm: "మి.మీ.",
  "km/hr": "కిమ్./గంట",
  "Avg Lux": "సగటు లక్స్",
  "Max Lux": "గరిష్ఠ లక్స్",
  Activity: "చర్య",
  Duration: "వ్యవధి",
  Hour: "గంట",
  Minutes: "నిమిషాలు",
  "Irrigation Details": "ఇరిగేషన్ వివరాలు",
  "Dripper flow rate": "డ్రిపర్ ప్రవాహ రేట్ (లిటర్/గంట)",
  "drippers per plant": "ప్లాంటు ప్లాంటులో డ్రిపర్ల సంఖ్య",
  numOfPlants: "ప్లాంటుల సంఖ్య",
  "Total drippers": "మొత్తం డ్రిపర్ల సంఖ్య",
  "Irrigation per plant": "ప్లాంటు ప్లాంటులో ఇరిగేషన్",
  "Total Irrigation": "మొత్తం ఇరిగేషన్",
  Save: "సేవ్ చేయండి",
  SaveMoney: "డబ్బుని ఆదాచెయండి",
  "Please fill all the fields!": "దయచేసి అన్ని ఫీల్డ్లను పూరించండి!",
  quantity: "పరిమాణం",
  Unit: "యూనిట్",
  "+ ADD": "+ జోడించు",
  List: "జాబితా",
  "Water Amount": "నీటి పరిమాణం",
  Litre: "లీటరు",
  "Spraying for": "ప్రేక్షకుడికి స్ప్రేయింగ్",
  "Spraying Molecule": "స్ప్రేయింగ్ మోలిక్యూల్",
  "Please select your": "దయచేసి మీరు ఎంచుకోండి",
  capACTIVITY: "చర్య",
  "Select Activity": "చర్య ఎంచుకోండి",
  Irrigation: "నీటిపారుదల",
  Nutrients: "పోషకాలు",
  "Spray, Pest & Disease": "స్ప్రే, పేస్టు & డిజీజు",
  "Cultural Activity": "సాంస్కృతిక చర్య",
  Saved: "సేవ్ చేయబడింది!",
  SavedMessage: "మీ డేటా సేవ్ చేయబడింది!",
  "Total irrigation can't be 0": "మొత్తం ఇరిగేషన్ 0 కావు.",
  "Please select time duration": "దయచేసి సమయ వ్యవధి ఎంచుకోండి.",
  attention: "కొన్ని ఫీల్డ్స్ లేవు!",
  "Please select what you are spraying for":
    "దయచేసి మీరు దేని కోసం స్ప్రే చేస్తున్నారో ఎంచుకోండి!",
  "Please select spray molecule(s)":
    "దయచేసి స్ప్రేయింగ్ మోలిక్యూల్(లు) ఎంచుకోండి!",
  "Please add water amount in litres": "దయచేసి లీటర్లలో నీటి పరిమాణం జోడించండి!",
  "Please add quantity": "దయచేసి పరిమాణం చేయండి!",
  "Please add unit": "దయచేసి యూనిట్ చేయండి!",
  "Please select fertiliser": "దయచేసి ఎరువులు ఎంచుకోండి!",
  "Please select cultural activity": "దయచేసి సాంస్కృతిక చర్య ఎంచుకోండి!",
  Duration: "వ్యవధి",
  TotalIrrigation: "మొత్తం ఇరిగేషన్",
  filter: "ఫిల్టర్",
  "Type here to start searching": "శోధన ప్రారంభించడానికి ఇక్కడ రాయండి....",
  You_sure_you_want_to_delete: "మీరు ఖచ్చితంగా తొలగించా లనుకుంటున్నారా?",
  attentionDelete: "తొలగించాలా?",
  DiseaseAndPest: "వ్యాధి మరియు తెగులు",
  "Update Crop Stage": "పంట దశ నవీకరణ",
  "Is this correct?": "ఇది సరైనదా?",
  "Search Plot here": "ఇక్కడ పొలాన్ని వెతకండి",
  Details: "వివరాలు",
  alternaria_fruit_blotch: "అల్టెర్నారియా ఫ్రూట్ బ్లాచ్",
  anthracnose: "ఆంత్రాక్నోజ్",
  bacterial_blight: "బ్యాక్టీరియల్ బ్లైట్",
  bacterial_canker: "బ్యాక్టీరియల్ క్యాంకర్",
  bacterial_spot: "బ్యాక్టీరియల్ స్పాట్",
  bacterial_wilt: "బ్యాక్టీరియల్ విల్ట్",
  black_spot: "బ్లాక్ స్పాట్",
  botrytis: "బోట్రైటిస్",
  cercospora_leaf_spot: "సెర్కోస్పోరా లీఫ్ స్పాట్",
  downy_mildew: "బూజు తెగులు్డ్యూ",
  gummosis: "గమ్మోసిస్",
  powdery_mildew: "బూడిద తెగులు్డ్యూ",
  flea_beetle: "ఫ్లీ బీటిల్",
  mealybug: "మీలీబగ్",
  mites: "మైట్స్",
  thrips: "థ్రిప్స్",
  aphids: "ఎఫిడ్స్",
  fruit_borer: "ఫ్రూట్ బోరర్",
  citrus_psylla: "సిట్రస్ ప్సిల్లా",
  OR: "లేదా",
  or: "లేదా",
  SeasonFertilizerRequirements: "సీజన్ ఫెర్టిలైజర్ ఆవశ్యకతలు",
  Nutrient: "పోషణ",
  SoilAmt: "భూసారి పరిమాణం",
  FertigationAmt: "ఫెర్టిగేషన్ పరిమాణం",
  Stage: "దశ",
  Weeks: "వారాలు",
  Week: "వారం",
  MajorNutrients: "ముఖ్య పోషణాలు",
  ClearAll: "అన్నీ క్లియర్",
  Sources: "మూలాలు",
  MicroNutrients: "మైక్రో పోషణాలు",
  SoilApplication: "భూసారణ",
  Season: "సీజన్",
  CropType: "పంట రకం",
  CropVariety: "పంట వివిధము",
  Rootstock: "రూట్‌స్టాక్",
  ReportType: "రిపోర్ట్ రకం",
  InputDetails: "ఇన్పుట్ వివరాలు",
  AddReport: "నివృత్తం చేర్చు",
  ReportDate: "రిపోర్ట్ తేదీ",
  PleaseSelectCrop: "దయచేసి పంట ఎంచుకోండి",
  PleaseSelectCropVariety: "దయచేసి పంట వివిధము ఎంచుకోండి",
  PleaseSelectRootstock: "దయచేసి రూట్‌స్టాక్ ఎంచుకోండి",
  PleaseSelectCropSeason: "దయచేసి పంట సీజన్ ఎంచుకోండి",
  PleaseSelectReportType: "దయచేసి నివృత్తం రకం ఎంచుకోండి",
  PleaseEnter: "దయచేసి నమోదు చేయండి ",
  PestAnalytics: "పెస్ట్ అనలిటిక్స్",
  plotStatusExpired: "ప్లాట్ యాక్టివేట్ చేయడానికి దయచేసి రిచార్జ్ చేయండి!",
  plotStatusOldData: "అంతిమ సింక్ చేయబడినది: {{latestDataTime}}",
  Acres: "ఎకరాలు",
  Plots: "ప్లాట్లు",
  PlotSaveSuccess: "ప్లాట్ వివరాలు విజయవంతంగా సేవ్ చేయబడ్డాయి",
  PlotSaveFail: "ప్లాట్ వివరాలు సేవ్ చేయడం విఫలమైంది.",
  ValueOutOfRangePercentage:
    "{{alertVal}} విలువను 0 నుండి 100 లో నమోదు చేయండి",
  "About Us": "మా గురించి",
  NoStageReportFound: "స్టేజ్ రిపోర్ట్ దొరకలేదు!!",
  Start: "ప్రారంభించు",
  NoSeasonStartedSubText: "సీజన్ ప్రారంభం కాలేదు.దయచేసి ఒక సీజన్ ఆరంభించండి",
  NoSeasonStartedText: "సీజన్ ప్రారంభం కాలేదు!!",
  SowingDate: "విత్తనం తేదీ",
  StartCropSeasonSubText: "దయచేసి ప్రారంభించు తేదీ మరియు పంట రకం ఎంచుకోండి",
  DeltaT: "డెల్టా టి",
  DeltaTVPD: "డెల్టా టి & విపిడి",
  VPD: "విపిడి",
  Marginal: "మార్జినల్",
  MarginalHigherDelta: "మార్జినల్ హైయర్ డెల్టా",
  Preferred: "ఇష్టమైన",
  Unsuitable: "అనుచితమైన",
  StagesInvalidDates: "దశలు చెల్లని తేదీలు",
  DeleteStage: "దశను తొలగించండి",
  SureYouWantToDeleteStage: "ఖచ్చితంగా మీరు స్టేజ్‌ని తొలగించాలనుకుంటున్నారా?",
  FileSaved: "ఫైలు సేవ్ చేయబడింది!",
  NoDownloadExcelDataFound: "డేటా కనబడలేదు!",
  YouAreChangingSpray: "మీరు స్ప్రే/పేస్ట్/డిజీజు మార్చుతున్నారు!",
  MoleculesListDeleted: "స్ప్రే మోలిక్యూల్ల జాబితా తొలగించబడును!",
  StaleDataError: "డేటా గురించి మారుతున్నది. దయచేసి తర్వాత ప్రయత్నించండి",
  FetchDataError:
    "డివైస్ నమోదు చేయబడింది, దయచేసి డేటా పొందడానికి కొన్ని సమయం ఎక్కువ ఉండాలి.",
  Success: "విజయం!",
  Oops: "ఓహ్!",
  From: "నుండి",
  NA: "లేదా",
  To: "కు",
  "E-Mail fyllo support": "ఇ-మెయిల్ fyllo మద్దతు",
  "Call fyllo support": "fyllo సపోర్ట్‌కి కాల్ చేయండి",
  ReadMore: "ఇంకా చదవండి..",
  fyllo: "fyllo",
  EditActivity: "ఎడిట్ యాక్టివిటీ",
  PermissionDenied: "అనుమతి తిరస్కరించబడింది",
  AllowFilePermission: "ఫైల్ అనుమతిని అనుమతించండి!",
  IncorrectEmailPhone: "చెల్లని మొబైల్ లేదా ఇమెయిల్ . ",
  EmailForAccountSupport: "మీరు నమోదు చేసుకోనట్లయితే, దయచేసి contact@fyllo.inకి ఇమెయిల్ చేయండి",
  ServerIssue: "సర్వర్ సమస్య",
  TryLater: "తర్వాత ప్రయత్నించండి",
  IncorrectOTP: "తప్పు OTP . ",
  RefillOTP: "దయచేసి మళ్ళీ సరిగా OTP నమోదు చేయండి",
  FileSaved: "ఫైలు సేవ్ చేయబడింది!",
  NoDataFound: "డేటా కనబడలేదు!",
  "dd-mm-yyyy": "dd-mm-yyyy",
  NoPrevSeasonText: "మునుపటి సీజన్ లేదు!!",
  ChooseAction: "చర్యను ఎంచుకోండి",
  SelectionAction: "ఎంపిక చర్య",
  Download: "డౌన్లోడ్",
  unitkPa: " kPa ",
  unitCelcius: " ℃ ",
  unitmm: " mm ",
  unitLux: " lux ",
  unitkmph: " kmph ",
  Scouting: "స్కౌటింగ్",
  Attachments: "చిత్రాలను అటాచ్ చేయండి",
  Notes: "నోట్స్",
  Description: "వివరణ",
  Upload: "అప్లోడ్",
  "Read less": "కనబడనిది",
  "Read more": "మరింత చదవండి",
  Uploading: "అప్లోడింగ్",
  PleaseSelectDiseaseLevel: "దయచేసి రోగ కఠినత ఎంచుకోండి",
  PleaseSelectPestLevel: "దయచేసి పేస్ట్ కఠినత ఎంచుకోండి",
  PleaseSelectNutrientLevel: "దయచేసి సారాన్ని కఠినత ఎంచుకోండి",
  "Permissions issue": "అనుమతుల సమస్య!",
  GalleryPermissionIssue: "దయచేసి ఫైలు/గ్యాలరీ అనుమతులను అనుమతించండి!",
  CameraPermissionIssue: "దయచేసి కెమెరా అనుమతులను అనుమతించండి!",
  Gallery: "గ్యాలరీ",
  Camera: "కెమెరా",
  NoFarmMatched: "పొలం ఏదీ సరిపోలలేదు",
  WeatherPredictionNotAllowedSubText: "వాతావరణ అంచనా అనుమతించబడలేదు",
  CheckInternet: "దయచేసి ఇంటర్నెట్ కనెక్షన్‌ని తనిఖీ చేయండి",
  DeviceHealth: "డివైస్ ఆరోగ్యం",
  Health: "ఆరోగ్యం",
  LooksLikeDeviceNotWorking: "డివైస్ పని చేయడం లేదు అని ఉంది.",
  DEVICE_HEALTH: "డివైస్ ఆరోగ్యం",
  "Device data being recieved": "డివైస్ డేటా అందుతోంది?",
  BatteryReducedText:
    "మొదట {{countVal}} రోజులో బ్యాటరీ రెడ్యూస్ చేయబడింది {{reductionVal}}%.",
  "Sensor Conditions": "సెన్సర్ శరతులు",
  "All sensors are working fine": "అన్ని సెన్సర్లు బాగా పని చేస్తున్నాయి.",
  NextFourHoursSprayNote: "దయచేసి తదుపరి నాలుగు గంటల సూచనను పరిగణించండి మరియు స్ప్రే చేయాలా వద్దా అని నిర్ణయించుకోండి",
  Location: "స్థానం",
  Pay: "చెల్లించు",
  "Pay using": "ఉపయోగించేది",
  "Other Payment Methods": "ఇతర చెల్లింపు పద్ధతులు",
  "Card Bank Transfer": "కార్డ్/బ్యాంకు మార్పు",
  "Which plot?": "ప్లాట్ వివరాలు?",
  "What activity?": "కార్యాచరణ వివరాలు?",
  DeviceNotInstalledWarning: "డేటాను చూడటానికి ఈ ప్లాట్‌లో పరికరాన్ని ఇన్‌స్టాల్ చేయండి!",
  CantUpdateData: "డేటాను అప్డేట్ చేయలేదు. దయచేసి తర్వాత ప్రయత్నించండి",
  AddPlot: "ప్లాట్లు జోడించండి",
  HowMuchToIrrigate: "మీరు ఎంత నీటిపారుదల చేసారు?",
  ActHours: "గంటలు",
  ActMinutes: "నిమిషాలు",
  WhatWithinCultActivity: "సాంస్కృతిక కార్యకలాపం లో ఏది?",
  Area: "ప్రాంతం",
  NoActivityFound: "కార్యకలాపం కనబడలేదు",
  OldStyle: "పాతస్వరూపం",
  ActivityNotedAt: "గమనించబడింది",
  "New crop Season started": "కొత్త పంటల సీజన్ ప్రారంభమైంది",
  ObservedDiseaseActivity: "ఉదాహరణకు: ప్లాట్లో డౌనీ మైల్డూ కనిపించింది",
  OtherActivity: "ఉదాహరణకు: ప్లాట్‌లో దున్నడం ",
  NutrientActivity: "ఉదాహరణకు: 50 కిలోగ్రాంల యూరియను అందించాడు",
  AppliedUreaActivity: "ఉదాహరణకు: 50 కిలోగ్రాంల యూరియను అందించాడు",
  SprayActivity:
    "ఉదాహరణకు: 500 లీటర్ నీటిలో 1 కిలోగ్రాం కాపర్ను స్ప్రే చేసాడు",
  ApplyFilter: "ఫిల్టర్ అనుమతించు",
  SoilType: "నేల రకం",
  Foliar: "ఫోలియార్",
  FoliarAmt: "ఫోలియార్ అంతా",
  Soil: "మట్టి",
  PleaseSelectAtleastOneMethod: "దయచేసి కనీసం ఒక ఆప్షన్‌ని ఎంచుకోండి",
  AvailableOptions: "అందుబాటులో ఉన్న ఎంపికలు",
  NoCropSelected: "పంట ఏదీ ఎంచుకోబడలేదు",
  HideOptions: "ఎంపికలను దాచండి",
  ShowOptions: "ఎంపికలను చూపించు",
  sprayRequired: "{{purpose}} కోసం {{waterInLitres}} లీటర్లు/ఎకరం నీటిలో కింది ఎంపికలలో ఒకదానిని పిచికారీ చేయండి",
  nutrientRequired: "అవసరమైన మొత్తాన్ని అందించడానికి {{type}} దిగువ ఎంపికలలో ఒకదాన్ని వర్తించండి",
  foliar: "ఫోలియర్ స్ప్రే",
  fertigation: "ఫెర్టిగేషన్",
  soilApplication: "భూస్వరూపం ద్వారా అనుమతించండి",
  SelectOneOption: "ఒక ఆప్షన్‌ని ఎంచుకోండి",
  CreateReport: "క్రెడిట్ నివేదిక",
  ViewHistory: "చరిత్రను వీక్షించండి",
  DownloadPDF: "పి.డి.ఎఫ్ డౌన్‌లోడ్",
  Summry: "సారాంశం",
  Weekly: "వారపు",
  StageSummary: "దశ సారాంశం",
  "UnitsinKg/acre": "యూనిట్లు {{unitsVal}} Kg/ఏకరు",
  Options: "ఆప్షన్‌లు",
  Applyanyoneoftheoptions: "ఎంపికలలో ఏదైనా ఒకదాన్ని ఎంచుకోండి",
  riskLevel: "రిస్క్",
  AnalysisStage: "ఎనాలిసిస్ దశ/రకం",
  PleaseSelectAnalysisStage: "దయచేసి విశ్లేషణ చరిక ఎంచుకోండి",
  days: "రోజులు",
  Register: "నమోదు",
  EnterMobileNoEmailID: "మొబైల్ నెంబర్ నమోదు చేయండి",
  Resend: "మళ్ళీ పంపు",
  Seconds: "సెకన్లు",
  Number: "మొబైల్ నంబర్",
  Useralreadyregistered: "యూజరు ఇప్పటికే నమోదు చేసాడు",
  InvalidNumber: "చెల్లని నంబర్",
  FarmDetails: "వ్యవసాయ వివరాలు",
  FarmName: "వ్యవసాయ పేరు",
  PlotDetails: "ప్లాట్ వివరాలు",
  PlotName: "ప్లాట్ పేరు",
  DifferentSoilTypes: "వివిధ నేల రకాలు",
  Sandy: "ఇసుక",
  SandyLoam: "బల్లిన మరియు బల్లిన లోమ్",
  Loamy: "లోమ్",
  Clay: "చిక్కు",
  PlotArea: "ప్లాట్ ప్రాంతం",
  Plotareacantbegreaterthanfarmarea:
    "ప్లాట్ ప్రాంతం వ్యవసాయ ప్రాంతం కంటే ఎక్కువగా ఉండలేదు",
  Allowfyllotoaccessyourlocation:
    "మీ స్థానంను యాక్సెస్ చేయడానికి fyllo అనుమతి ఇవ్వండి?",
  SAVETOADDCROP: "క్రాప్ నమోదు చేయడానికి సేవ్ చేయండి",
  CropDetails: "క్రాప్ వివరాలు",
  CropName: "క్రాప్ పేరు",
  SowingPruningDate: "మార్పిడిత తేదీ",
  PlantationYear: "ప్లాంటేషన్ సంవత్సరం",
  AddDevice: "డివైసు జోడించండి",
  Adddevicetotheapp:
    "QR కోడ్ను డివైసులో ఉన్నది స్కాన్ చేసి మీ డివైసును యాప్‌కు జోడించండి",
  EnterIMEINumber: "IMEI సంఖ్యను నమోదు చేయండి",
  Scan: "స్కాన్",
  ATTACHDEVICE: "డివైసు  అటాచ్ చేయండి",
  PopoverFarmDetails: "వ్యవసాయ డేటా మరియు అంచనాలను వీక్షించండి",
  PopoverCalendarDetails: "చర్యలను జోడించండి మరియు వీక్షించండి",
  popoverDiseaseDetails: "వ్యాధులు మరియు తెగుళ్ళ వివరాలు",
  popoverAnalyticsDetails: "ఫెర్టిగేషన్ వివరాలను చూడండి",
  popoverPlotsDetails: "ఇతర పొలం/ప్లాట్‌ని ఎంచుకోండి",
  popoverDailyReportDetails: "రోజువారీ నివేదనను తనిఖీ చేయండి",
  popoverMyFarmDetails: "లైవ్ వ్యవసాయ డేటా మరియు చరిత్ర",
  popoverWeatherDetails: "గంట మరియు రోజువారీ వాతావరణ అంచనా",
  popoverCropStageDetails: "క్రాప్ చరికను చూడండి మరియు నవీకరణ చేయండి",
  popoverShareButtonDetails: "డేటాను పంచుకోండి",
  popoverUpdatedTimeDetails: "చివరి నవీకరణ సమయం",
  popoverHealthDetails: "డివైసు మరియు సెన్సర్ల ఆరోగ్యాన్ను తనిఖీ చేయండి",
  popoverPlusButtonDetails: "కార్యకలాపాలను జోడించండి",
  popoverBarMenuDetails: "చెల్లింపు, రెఫరల్, మద్దతు",
  popoverNotificationDetails: "ఇక్కడ నోటిఫికేషన్లను తనిఖీ చేయండి",
  popoverSoilMoistureDetails: "ప్రత్యక్ష డేటా, చరిత్రను తనిఖీ చేయడానికి క్లిక్ చేయండి",
  Next: "తరువాయి",
  Previous: "మునుపటి",
  Skip: "స్కిప్",
  Finish: "ముగించు",
  Delete: "తొలగించు",
  Cancel: "రద్దు",
  Redo: "మళ్లీ రాయండి",
  ClickandscanQR: "క్లిక్ చేసి, QR కోడ్ను స్కాన్ చేయండి",
  Welcome: "స్వాగతం",
  Welcomeback: "పునఃస్వాగతం",
  HelpWithdetails: "దయచేసి మరికొన్ని వివరాలతో మాకు సహాయపడండి",
  Language: "భాష",
  InvalidIMEINumber: "చెల్లని IMEI నంబర్",
  ApplicationMode: "అప్లికేషన్ మోడ్",
  kgHectare: "కిలోగ్రాము/హెక్టేరు",
  percent: "%",
  UnitPPM: "ppm",
  Select: "ఎంచుకోండి",
  LogoutModalDescription: "మీరు ఖచ్చితంగా లాగ్ అవుట్ చేయాలనుకుంటున్నారా?",
  YourReport: "మీ నివేదిక",
  RemovePhoto: "ఫోటో తొలగించు",
  addActivity: "ఆక్టివిటీని జోడించండి",
  notificationSettings: "నోటిఫికేషన్ సెట్టింగ్‌లు",
  Settings: "సెట్టింగ్స్",
  makeAPayment: "డబ్బులు చెల్లించండి",
  pastTransactions: "గత లావాదేవీలు",
  NoPesticideSchedule: "పేస్టైసెెడు షెడ్యూలు కనపడలేదు!",
  UpcomingSpray: "రాబోయే స్ప్రే",
  Conditional: "షరతులు",
  Fixed: "ఫిక్స్డ్",
  Purpose: "ప్రయోజనం",
  Chemical: "రసాయనిక",
  WaterQty: "నీటి పరిమాణం",
  CompleteSchedule: "పూర్తి షెడ్యూల్",
  UsePasswordInstead: "పాస్‌వర్డ్ స్థానంలో ఉపయోగించండి",
  useOTPInstead: "OTP ప్రాంతంలో ఉపయోగించండి",
  cropStage: "పంట దశ",
  whenToSpray: "ఎప్పుడు స్ప్రే చేయాలి?",
  whenToSprayDescription: "{{days}} రోజులు {{severity}} కోసం",
  notApplicable: "వర్తించదు",
  StagewiseSprayGuideline: "దశలవారీగా స్ప్రే మార్గదర్శకం",
  back: "తిరిగి",
  registerNewUser: "కొత్త వినియోగదారును నమోదు చేయండి",
  existingUserLogin: "కొత్త వినియోగదారును నమోదు చేయండి",
  ComebackLater: "తర్వాత తిరిగి రండి!",
  SelectAPlot: "ప్లాట్ ఎంచుకోండి",
  ShowReportFor: "నివేదిక",
  StartStage: "ప్రారంభ దశ",
  EndStage: "ముగింపు దశ",
  Neutrients: "పోషకాలు",
  NoSpray: "స్ప్రే లేదు",
  ThisWeek: "ఈ వారం",
  TillDate: "ఈ తేదీ వరకు",
  ThisMonth: "ఈ నెల",
  LastMonth: "గత నెల",
  irrigationDetected: "ఇరిగేషను కనుగొనింది!",
  Insights: "అంశాలు",
  DurationInMinutes: "నిమిషాలులో కాలం",
  ThisSeason: "ఈ సీజన్",
  SelectSeason: "సీజన్ ఎంచుకోండి",
  stageNotStartedWarning: "{{stageName}} చరణం ఇప్పటికే ప్రారంభించలేదు",
  CreditBalance: "క్రెడిట్ బ్యాలెన్స్",
  SearchContact: "కాంటాక్టును శోధించు",
  EnterName: "పేరు నమోదు చేయండి",
  Contacts: "కాంటాక్టులు",
  NoContactsFound: "కాంటాక్టులు కనపడలేదు",
  WhyFyllo: "ఎందుకు ఫైల్లో?",
  CropQuality: "పంట నాణ్యత మరియు దిగుబడి",
  DiseaseManagement: "వ్యాధి మరియు తెగులు నిర్వహణ",
  PreciseIrrigation: "ఖచ్చితమైన నీటిపారుదల",
  BestService: "ఉత్తమ సేవ",
  Sendvia: "ద్వారా పంపండి",
  Howitwork: "ఇది ఎలా పని చేస్తుంది?",
  creditedyouraccount:
    "ప్రతి రిఫరల్ మీ ఫిలో రీచార్జ్ ఖాతాకు ₹20/- క్రెడిట్ అయినది.",
  maximumearned:
    "దీన్ని 25 మందికి షేర్ చేయడం ద్వారా గరిష్టంగా ₹500 సంపాదించవచ్చు",
  friendinstallsfyllo:
    "మీ స్నేహితుడు fylloని ఇన్‌స్టాల్ చేస్తే, మీరు అదనంగా ₹250 అందుకుంటారు మరియు మీ స్నేహితుడు fyllo ఇన్‌స్టాలేషన్‌పై ₹500 తగ్గింపు పొందుతారు",
  off: "ఆఫ్",
  Mosteffectivereferers:
    "ప్రతి 6 నెలలకు  సమర్థవంతమైన సిఫార్సుదారులుకి నీరో పరికరం లేదా తిరుపతి పర్యటనతో రివార్డ్ ఇవ్వబడుతుంది",
  amountcredited: "₹{{amount}} యశస్వంగా క్రెడిట్ అయింది",
  fylloaccount: "మీ ఫిలో ఖాతాకు",
  Backtoreferral: "రిఫరల్ కు తిరిగి వెళ్ళండి",
  TotalCreditsEarned: "మొత్తం క్రెడిట్స్ సంపాదించబడింది",
  CreditsRedeemed: "క్రెడిట్స్ రీడీమ్ చేయబడింది",
  GotReferralCode: "రెఫరల్ కోడ్ ఉందా?",
  ClaimYourRewards: "మీ ప్రతిఫలాన్ని పొందండి",
  EnterCode: "మీ కోడ్ నమోదు చేయండి",
  EnterValidReferralCode: "చెల్లుబాటు అయ్యే రెఫరల్ కోడ్‌ని నమోదు చేయండి",
  REDEEMED: "రీడీమ్",
  SelectAPlot: "ప్లాట్ ఎంచుకోండి",
  ShowReportFor: "నివేదిక",
  StartStage: "ప్రారంభ దశ",
  EndStage: "ముగింపు దశ",
  NoSpray: "స్ప్రే లేదు",
  ThisWeek: "ఈ వారం",
  TillDate: "ఈ తేదీ వరకు",
  ThisMonth: "ఈ నెల",
  LastMonth: "గత నెల",
  CustomDate: "తేదీ",
  SpecifyStage: "స్టేజ్‌ని పేర్కొనండి",
  SelectStage: "స్టేజ్‌ని ఎంచుకోండి",
  ThisStage: "ఈ స్టేజ్‌",
  CustomStage: "చరణం శ్రేణి",
  irrigationDetected: "ఇరిగేషను కనపడింది",
  DurationInMinutes: "వ్యవధి (నిమిషాలు)",
  ThisSeason: "సీజన్",
  CREDITED: "క్రెడిట్ అయింది",
  WAITING: "వేచన",
  referralScreen: "రిఫర్ మరియు ఆదా",
  Apply: "అప్లై",
  Show: "చూపండి",
  UseCreditBalance: "క్రెడిట్ బ్యాలెన్స్ ఉపయోగించండి",
  ReferralBonus: "రిఫరల్ బోనస్",
  RefferalCodeApplied: "రిఫరల్ కోడ్ అప్లై చేయబడింది",
  ManageFarmUsingFyllo: "హలో,\nనేను నా పొలాన్ని నిర్వహించడానికి Fyllo పరికరాన్ని ఉపయోగిస్తున్నాను",
  FylloBenefit: "Fyllo ఉపయోగించడానికి నాకు ఈ ముఖ్య లాభాలు పొందాం:",
  InstallFyllo:
    "మీరు మీ వ్యవసాయంలో ఫిలోను స్థాపించడానికి కోరుకున్నాం. మీరు WhatsApp ద్వారా +91-9380908490 లేదా ఇమెయిల్ ద్వారా contact@fyllo.in గా ఫిలో టీమ్ తో సంప్రదించవచ్చు",
  UseRefferalCode:
    "మీరు మీ తదుపరి ఫిలో అప్‌నలని ఉపయోగించడానికి {{code}} రిఫరల్ కోడ్ ను ఉపయోగించండి",
  SomeErrorOccured: "కొన్ని లోపాలు సంభవించాయి",
  AnyChangeInReport: "నివేదనలో ఏ మార్పు ఉందా?",
  DeleteAccount: "ఖాతా తొలగించండి",
  ReasonToDelete: "మీ ఖాతాను తొలగించడానికి కారణం ఎంచుకోండి?",
  NoFruitfulResult: "నాకు ఫలకారక ఫలితాలేమీ దొరకలేదు",
  NothingWorking: "ఏదీ సరిగా పని చేయదు",
  PrivacyConcerns: "నాకు గౌప్యతా సందేహాలు ఉన్నాయి",
  WhatisNotWorking: "మీకు మెరుగైన ఫలితాన్ని అందించడానికి మేము  నిరంతరం మెరుగుపరుచుకుంటున్నాము. మీరు ఎందుకు అసంతృప్తిగా ఉన్నారో దయచేసి మాతో పంచుకోగలరా?",
  Warning: "హెచ్చరిక",
  DeleteWarningMessage: "మీరు మీ ఖాతాను తొలగిస్తే, మీరు మొత్తం డేటా మరియు సబ్‌స్క్రిప్షన్‌లను శాశ్వతంగా కోల్పోతారు",
  OurTeamWillContact:
    "మన జట్ల ఖాతాను తొలగించడానికి మన టీము మీకు త్వరలో సంప్రదించుటకు ప్రయత్నిస్తుంది.",
  Close: "మూసివేయండి",
  GoBack: "తిరిగి వెళ్ళండి",
  GiveYourThoughts: "మీ ఆలోచనలు ఇవ్వండి",
  ConfirmDeletion: "తొలగించండి",
  AnyChangeInReport: "నివేదనలో ఏ మార్పు ఉందా?",
  DeleteAccount: "ఖాతా తొలగించండి",
  UpdateStage: "అప్‌డేట్ స్టేజ్",
  EndSeasonWarningMsg: "మీరు ఖచ్చితంగా ఈ సీజన్‌ని ముగించాలని అనుకుంటున్నారా?",
  StageUpdated: "చరణం నవీకరించబడింది",
  Retry: "మళ్లీ ప్రయత్నించండి",
  NoWeekSelected: "ఏ వారం ఎంచుకోబడలేదు",
  thingsToRememberFertigation:
    "ఎరువులు వేసేటప్పుడు గుర్తుంచుకోవలసిన విషయాలు",
  thingsToRemember: "గుర్తుంచుకోవలసిన విషయాలు",
  NoDiseaseDataFound: "రోగాల డేటా కనపడలేదు",
  NoPestDataFound: "పురుగుల డేటా కనపడలేదు",
  Range: "రేంజ్",
  Value: "విలువ",
  DoYouNeedHelp: "మీరు ఏదైనా సమస్యను ఎదుర్కొంటున్నారా",
  LetUsHelpYou: "మీకు సహాయం చేయనివ్వండి",
  ChatWithUs: "మాతో చాట్ చేయండి",
  CallUs: "మాకు కాల్ చేయండి",
  update: "నవీకరణ",
  cancel: "రద్దు చేయండి",
  NewUpdateAvailable: "కొత్త నవీకరణ అందుబాటులోకి వచ్చింది!!!",
  PleaseUpdateYour: "మీ అప్‌ను ఉపయోగించడానికి దయచేసి మీ అప్‌ను నవీకరించండి",
  helpMessage: "హాయ్, నా పొలం {{farmId}} కోసం నాకు సహాయం కావాలి. నా వినియోగదారు పేరు {{username}}",
  notApplicableInfo:
    "{{pestOrDiseaseName}} ఈ చరణంలో అన్వయించడానికి అనుమతించలేదు",
  pesticideScheduleNotAllowed: "పురుగుమందుల షెడ్యూల్ అనుమతించబడదు",
  latestReport: "తాజా రిపోర్ట్",
  noReportsAvailable: "రిపోర్ట్ లు ఏవీ అందుబాటులో లేవు",
  uploadPdfReport: "రిపోర్ట్ అప్‌లోడ్ చేయండి",
  scanningPdf: "డాక్యుమెంట్ స్క్యాన్ చేస్తోంది",
  cantScanPdf: "మేము ఈ డాక్యుమెంట్ను స్క్యాన్ చేయలేకున్నాం",
  tryAgainPdf: "దయచేసి న్యూట్రియెంట్ విలువలను మాన్యువలీగా నమోదు చేయండి",
  basedOnUploadedReport: "అప్‌లోడ్ చేసిన నివేదనానికి ఆధారంగా",
  ScheduleGeneratedOnDefaultReport:
    "డిఫాల్ట్ రిపోర్ట్  పై షెడ్యూల్ రూపొందించబడింది",
  ForPreciseSchedule: "ఖచిత షెడ్యూల్ కోసం",
  remember: "గుర్తుంచుకోవాలి",
  lowest: "కనిష్ఠమైన",
  average: "సగటు",
  highest: "అతిపెద్ద",
  onAverage: "సగటున",
  Vpd: "విపిడి",
  vpd: "విపిడి",
  deltaT: "డెల్టా టి",
  newVersionAvailable: "కొత్త వెర్షన్ అందుబాటులో ఉంది!!",
  SelectPlot: "ప్లాట్ ఎంచుకోండి",
  RechargeUptoDate: "తేదీ వరకు రీఛార్జ్ చేయండి",
  getUptoDiscount:
    "{{discountPercentage}}% వరకు అదనపు డిస్కౌంట్ పొందండి",
  Yearly: "సంవత్సరానికి",
  Monthly: "ప్రతి నెల",
  Quarterly: "త్రైమాసిక",
  DueInDays: "గడువు {{days}}",
  ContactSupport: "మద్దతు సంప్రదించండి",
  Email: "ఇమెయిల్",
  Phone: "ఫోన్",
  Whatsapp: "వాట్సాప్",
  INITIATED: "ప్రారంభించబడింది",
  AUTHORIZED: "అనుమతించబడింది",
  SUCCESS: "విజయమైంది",
  FAILED: "విఫలమైంది",
  NEW: "కొత్త",
  Search: "వెతకండి",
  VideoGuide: "వీడియో గైడ్",
  ViewMyProfile: "నా ప్రొఫైల్",
  SearchKeywordToLearnAboutIt: "దీని గురించి తెలుసుకోవడానికి కీలకపదాలను వెతకండి",
  rootWidth: "రూట్ల వెడల్పు (ఇంచెస్)",
  rootDepth: "రూట్ లోతు (ఇంచెస్)",
  dripperDischarge: "డ్రిప్పర్ డిశ్చార్జ్ (లీటర్లు)",
  soilRetention: "భూస్థానము (మిమీ)",
  dripperDistance: "డ్రిప్పర్ దూరము (అడి)",
  CalculateValue: "విలువను లెక్కించండి",
  ReCalculateValue: "విలువను మళ్లీ లెక్కించండి",
  Schedules: "షెడ్యూలు",
  ChangeFarmDesc: "మీరు చేసిన మార్పులు సేవ్ చేయబడవు. మీరు కొనసాగించాలనుకుంటున్నారా?",
  AddNewFarm: "కొత్త ఫార్మ్ జోడించండి",
  Remove: "తొలగించు",
  UseExisting: "ఇప్పటికే ఉన్నదాన్ని ఉపయోగించండి",
  NoNotification: "నోటిఫికేషన్ లేదు",
  Edit: "సవరించు",
  CopytoPlot: "ప్లాట్ కి కాపీ",
  Depth: "లోతు",
  inch: "అంగుళం",
  Error: "లోపం",
  daysExceed: "రోజులు మించిపోయాయి",
  updateStages: "దశలను నవీకరించండి!",
  Later: "తరువాత",
  selectDuration: "వ్యవధిని ఎంచుకోండి",
  Lastdurationdays: "చివర {{duration}} రోజులు",
  seasonSummary: "సీజన్ సారాంశం",
  TotalWaterUsed: "వాడిన మొత్తం నీరు",
  TotalIrrigationEvents: "మొత్తం నీటిపారుదల ఈవెంట్‌లు",
  AvgIntervalbetweenIrrigations: "నీటిపారుదల మధ్య సగటు విరామం",
  NumberofSpraysutilized: "ఉపయోగించిన స్ప్రేస్ సంఖ్య",
  AverageSunLight: "సగటు సూర్యకాంతి",
  AverageSunshineHours: "సగటు సూర్యరశ్మి గంటలు",
  Explore: "అన్వేషించండి",
  Lai: "ఎలేఐ",
  "Not Optimal": "ఆప్టిమల్ కాదు",
  lai: "ఎలేఐ",
  LaiDetails: "ఎలేఐ వివరాలు",
  Optimal: "ఆప్టిమల్",
  Image: "చిత్రం",
  AboutDeltaT: "డెల్టాటి గురించి ఏమిటి?",
  AboutVPD: "విపిడి గురించి ఏమిటి?",
  AboutLAI: "ఎలేఐ గురించి ఏమిటి?",
  AddCost: "కార్యాచరణ ఖర్చు",
  costExample: "ఉదాహరణకు: 400",
  selectCurrency: "కరెన్సీ ఎంచుకోండి",
  "No Rain": 'వర్షం లేదు',
  "AppGuideVideo": 'అప్ గైడ్ వీడియోస్',
  ProductVideo: 'ప్రోడక్ట్ వీడియోస్',
  AgronomyVideo: 'అగ్రోనోమి వీడియోస్',
  TestimonialVideo: 'టెస్టిమోనియాల్ వీడియోస్',
  IDPMSuggestion: "{{disease}} నిర్వహణ",
  AddYourFertilizers: "మీ ఎరువులు జోడించండి",
  SprayNotifs: "స్ప్రే యొక్క నోటిఫికేషన్లు",
  Cost: 'ఖరీదు',
  seedTreatment: 'విత్తన శుద్ధి',
  insecticide: 'పురుగుల మందు',
  nematicide: 'నెమాటిసైడ్',
  fungicide: 'ఫంగిసైడ్',
  bactericide: 'బ్యాక్టీరిసైడ్',
  InvalidPayment: "చెల్లుబాటు కాని పేమెంట్",
  PaymentInProgress: "పేమెంట్ ప్రాసెస్‌లో ఉంది",
  "ServiceRequest": "సమస్య నివేదిక",
  "NoServiceRequestFound": "ఏ సమస్య నివేదిక కనబడలేదు",
  "YourServiceRequests": "మీ సమస్య నివేదిక",
  "RaiseServiceRequest": "సమస్య నివేదిక ఇవ్వండి",
  "TellAboutYourIssue": "మీ సమస్య గురించి చెప్పండి",
  "DoYouWantToOpenThisServiceRequest": "మీరు ఈ సమస్య నివేదికను తెరవాలనుకుంటున్నారా?",
  "DoYouWantToCloseThisServiceRequest": "మీరు ఈ సమస్య నివేదికను మూసివేయాలనుకుంటున్నారా?",
  ReopenServiceRequest: "మళ్లీ తెరవండి",
  CloseServiceRequest: "మూసివేయండి",
  OpenDate: "ఓపెన్ డేట్",
  SelectImage: "చిత్రాన్ని ఎంచుకోండి",
  TakeImage: "చిత్రం తీసుకోండి",
  open: "ఓపెన్",
  closed: "మూసివేయబడింది",
  new: "కొత్తది",
  "pending reminder": "పెండింగ్ రిమైండర్",
  merged: "విలీనమైంది",
  removed: "తొలగించారు",
  lastUpdateHours: "చివరి {{hours}} గంటలు",
  Refresh: "రిఫ్రెష్",
  IssueIsRequired: "సమస్య పూరించడం అవసరం",
  DetailIsRequired: "వివరాలు కావాలి",
  ImagesTooLargeError: 'మీరు అప్‌లోడ్ చేయడానికి ప్రయత్నిస్తున్న చిత్రాలు చాలా పెద్దవిగా ఉన్నాయి. దయచేసి కొన్ని చిత్రాలను తొలగించి మళ్లీ ప్రయత్నించండి',
  Issue: "సమస్య",
  ReachedMaxAttachments: 'మీరు చిత్రాల గరిష్ట పరిమితిని చేరుకున్నారు. మరిన్ని జోడించే ముందు దయచేసి కొన్ని చిత్రాలను తీసివేయండి.'
};
const ta = {
  "Farm": "பண்ணை",
  "Calendar": "காலண்டர்",
  "News": "செய்திகள்",
  "Disease": "நோய்",
  "ShowReportFor": "அறிக்கை காட்டு",
  "MyFarm": "என் பண்ணை தகவல்",
  "DailyReport": "தினசரி அறிக்கை",
  "Weather": "வானிலை",
  "Total": "மொத்தம்",
  "DiseaseAnalytics": "நோய் பகுப்பாய்வு",
  "Username": "பயனர்பெயர்",
  "EnterOTP": "OTP உள்ளிடவும்",
  "EnterPassword": "கடவுச்சொல் உள்ளிடவும்",
  "LastUpdated": "இறுதியாக புதுப்பிக்கப்பட்டது",
  "SoilMoisture1": "மண் ஈர்மை-1",
  "SoilMoisture2": "மண் ஈர்மை-2",
  "SoilTemperature": "மண் வெப்பநிலை",
  "LeafWetness": "இலை ஈர்மை",
  "AirTemperature": "காற்று வெப்பநிலை",
  "AirHumidity": "காற்று ஈர்மை",
  "ViewDetails": "விவரங்களைக் காண்க",
  "AirPressure": "காற்று அழுத்தம்",
  "LightIntensity": "ஒளியின் அதிர்வெண்",
  "Probability": "சாத்தியம்",
  "DayTimeAverageLux": "தினசரி சூரிய ஒளி",
  "Rainfall": "மழை",
  "RainFall": "மழை",
  "RainFallLastHour": "கடைசி ஒரு மணி நேரத்தில் மழை",
  "TwentyFourHours": "24 மணி நேரங்கள்",
  "Rain": "மழை",
  "Alerts": "எச்சரிக்கைகள்",
  "SoilMoisture1Details": "மண் ஈர்மை-1 விவரங்கள்",
  "SoilMoisture2Details": "மண் ஈர்மை-2 விவரங்கள்",
  "SoilTempDetails": "மண் வெப்பநிலை விவரங்கள்",
  "LeafWetnessDetails": "இலை ஈர்மை விவரங்கள்",
  "AirTempDetails": "காற்று வெப்பநிலை விவரங்கள்",
  "AirHumidityDetails": "காற்று ஈர்மை விவரங்கள்",
  "AirPressureDetails": "காற்று அழுத்தம் விவரங்கள்",
  "lightIntensityDetails": "ஒளியின் அதிர்வெண் விவரங்கள்",
  "rainFallDetails": "மழை விவரங்கள்",
  "windSpeedDetails": "காற்று வேக விவரங்கள்",
  "batteryDetails": "பேட்டரி விவரங்கள்",
  "battery": "பேட்டரி",
  "baseStageData": "{{days}} நாட்கள் {{baseStage}} பிறகு",
  "baseStageDataBefore": "{{days}} நாட்கள் {{baseStage}} முன்பு",
  "windDirection": "காற்று இருப்பின் திசை",
  "windSpeed": "காற்று வேகம்",
  "rainFall": "மழை",
  "leafWetness": "இலை ஈர்மை",
  "lightIntensity": "ஒளியின் அதிர்வெண்",
  "soilMoisture1": "மண் ஈர்மை-1",
  "soilMoisture2": "மண் ஈர்மை-2",
  "soilTemp": "மண் வெப்பநிலை",
  "signalStrength": "நெட்வொர்க்",
  "signalStrengthDetails": "நெட்வொர்க் விவரங்கள்",
  "baroPressure": "பாரோமெட்ரிக் அழுத்தம்",
  "airPressure": "காற்று அழுத்தம்",
  "airHumidity": "காற்று ஈர்மை",
  "airTemp": "காற்று வெப்பநிலை",
  "GDD": "நாள் நடப்புத் தடவுகள்",
  "Gdd": "நாள் நடப்புத் தடவுகள்",
  "gdd": "நாள் நடப்புத் தடவுகள்",
  "GDDDetails": "நாள் நடப்புத் தடவு விவரங்கள்",
  "Inputs": "உள்ளுறைகள்",
  "Services": "சேவைகள்",
  "Nutrient": "உடல் எடைகள்",
  "StartDate": "தொடக்க தேதி",
  "EndDate": "முடிவு தேதி",
  "Spray": "பூச்சி நோய் / பூச்சி",
  "CulturalPractice": "விவசாய பயிற்சி",
  "Labour": "தொழில்",
  "Seeds": "விதைகள்",
  "Next": "அடுத்து",
  "Email": "மின்னஞ்சல்",
  "Mobile": "கைபேசி",
  "RegenerateOTP": "மீண்டும் OTP உருவாக்கு?",
  "Fertilizer": "உரம்",
  "PandDControl": "பூச்சி மற்றும் நோய் கட்டுப்படுத்தல்",
  "and": "மற்றும்",
  "FieldPreparation": "பெரும்பாலக் கட்டுப்பாடு",
  "Irrigation": "நீர்வழிச் செயல்பாடு",
  "ActivityType": "செயல்பாடு வகை",
  "RainProbability": "மழை சாத்தியம்",
  "RainQuantity": "மழை அளவு",
  "RainQty": "மழை அளவு",
  "WindSpeed": "காற்று வேகம்",
  "Login": "உள் நுழை",
  "ForgotPassword": "உங்கள் கடவுச்சொல்லை மறந்துவிட்டீர்களா?",
  "ResourcesUsed": "பயன்படுத்தப்பட்ட வளங்கள்",
  "Date": "தேதி",
  "ActionsToDo": "செய்ய வேண்டிய செயல்கள்",
  "ActionsDone": "செய்யப்பட்ட செயல்கள்",
  "LabourCost": "தொழில் செலவு",
  "ResourcesCost": "வளங்கள் செலவு",
  "NotRequired": "தேவையில்லை",
  "MarkAsDone": "செய்யப்பட்டதாக குறிக்கவும்",
  "ActivityDate": "செயல்தினம்",
  "Done": "செய்யப்பட்டது",
  "Cancel": "ரத்து",
  "EnterCostOfResourcesUsed": "பயன்படுத்தப்பட்ட வளங்களின் செலவை உள்ளிடவும்",
  "EmptyCalendarItemText": "உங்கள் பூட்டுக்கு இந்த நாள் ஏதும் தியானத்தில் இல்லை!!",
  "Posted": "அனுப்பப்பட்டது",
  "Min": "குறைந்த",
  "Max": "அதிக",
  "Avg": "சராசரி",
  "DayMin": "நாள் நேர குறைந்தது",
  "DayMax": "நாள் நேர அதிகமானது",
  "DayAvg": "நாள் நேர சராசரி",
  "NoData": "தரவு இல்லை!!",
  "Day": "நாள்",
  "Day1": "1 நாள்",
  "Week1": "1 வாரம்",
  "Month1": "1 மாதம்",
  "Year1": "1 வருடம்",
  "Custom": "தனிப்பயன்",
  "EmailSupport": "ஆதரவுக்கு மின்னஞ்சல்",
  "Network": "நெட்வொர்க்",
  "Power": "அன்பு",
  "ProbabilityInYourPlot": "உங்கள் துளிக்கு சாத்தியம்",
  "CropCalendar": "பயிர் நாட்காட்டி",
  "AllCalendar": "பயிர் நாட்காட்டி",
  "Actions": "செயல்கள்",
  "Insights": "அறிகுறிகள்",
  "Farms": "பண்ணைகள்",
  "NewsFeed": "செய்திகள்",
  "Notifications": "அறிவிப்புகள்",
  "Profile": "சுயவிவரம்",
  "Logout": "வெளியேறு",
  "Support": "ஆதரவு",
  "ChangeLanguage": "மொழியை மாற்று",
  "WetDuration": "ஈர்ந்த காலம்",
  "hours": "மணிகள்",
  "Too Wet": "மிக ஈர்ம்படும்",
  "Adequate Moisture": "போதாயமான ஈர்மன்பல்",
  "Dry": "உலர்ந்த",
  "Too Dry": "மிகவும் உலர்ந்த",
  "Wet": "ஈர்ப்பு",
  "Nil": "கோடு",
  "Low": "குறைந்த",
  "Medium": "நடுத்தமான",
  "High": "உயர்",
  "HIGH": "உயர்",
  "NIL": "கோடு",
  "LOW": "குறைந்த",
  "MEDIUM": "நடுத்தமான",
  "Night": "இரவு",
  "Fully Overcast": "முழுமையாக மேகமாகியுள்ளது",
  "Overcast": "மேகமாகியுள்ளது",
  "Sunset": "சூரிய அஸ்தமனம்",
  "Sunrise": "சூரிய உதிரி",
  "Partly Cloudy": "பகுதி மேகமாகியுள்ளது",
  "Sunny": "சூரியச் சுழற்சி",
  "Clear Sky": "தெளிவான ஆகாசம்",
  "HoursOfRain": "மழை நேரங்கள்",
  "RainHours": "மழை நேரங்கள்",
  "HoursOfSun": "சூரிய நேரங்கள்",
  "CloudCover": "மேகமாகிய பர்வதி",
  "HourlyWeatherPrediction": "மணிநேர வானிலை",
  "HourlyPrediction": "மணிநேர வானிலை",
  "Summary": "சுருக்கமான",
  "SoilMoisture": "மண் ஈர்மை",
  "Average": "சராசரி",
  "Minimum": "குறைந்த",
  "Maximum": "அதிகமான",
  "LeafWetnessHours": "இலை ஈர்மை நேரங்கள்",
  "Name": "பெயர்",
  "Severity": "கஷ்டம்",
  "ActionsRequired": "செய்ய வேண்டிய செயல்கள்",
  "NoActionsForToday": "இன்று செயல்கள் இல்லை",
  "Quantity": "அளவு",
  "BestConditionsForSpray": "இந்த நேரம் கண்ணாடிப் பராமரிப்புக்கு சிறந்தது",
  "ModerateConditionsForSpray": "ஏதேனும் பாராமரிக்க கண்டிப்பான நேரம்",
  "WorstConditionsForSpray": "இந்த நேரத்தில் பாராமரிப்பு செய்ய வேண்டாம்",
  "SprayTimings": "பாராமரிப்பு நேரங்கள்",
  "DailyReportNotAvailable": "இன்று அறிக்கை இல்லை. சாதனம் செயல்படுத்தினால் சரிபார்க்கவும்",
  "Evapotranspiration": "நீர்ப்பழிப்பதற்கான நேரம்",
  "Eto": "ETO",
  "eto": "eto",
  "DailyETO": "தினசரி ETO",
  "ETODetails": "நீர்ப்பழிப்பதற்கான விவரங்கள்",
  "Pest": "பூச்சி",
  "to": "இல்",
  "ReportsStages": "அறிக்கைகள் மற்றும் அருகில்",
  "StageReports": "படிகள் அறிக்கைகள்",
  "StageReport": "படிகள் அறிக்கைகள்",
  "SeasonReport": "பருவ அறிக்கை",
  "CropStages": "பயிர் படிகள்",
  "PrevSeasonReports": "முந்தைய பருவ அறிக்கைகள்",
  "PlotReports": "புதிய அறிக்கைகள்",
  "ActivitiesDone": "செயல்கள் செய்யப்பட்டன",
  "Activities": "செயல்கள்",
  "NoActivitiesDone": "செயல்கள் செய்யப்படவில்லை",
  "TotalRainFall": "மொத்த மழை",
  "DayRainfall": "நாள் மழை",
  "TotalETO": "மொத்த ETO",
  "AverageETO": "சராசரி ETO",
  "AccumulatedGDD": "கூட்டிய GDD",
  "ReportNotAvailable": "அறிக்கை இல்லை",
  "StartDateforStage": "தொடக்க தேதி தேர்வு",
  "Analytics": "அணிகலனம்",
  "UpdateNotifications": "அறிவிப்புகளை புதுப்பிக்கவும்",
  "Fertigation": "உரத்திக்கு நீரின் உரியவை",
  "WeeklySchedule": "வார அட்டவணை",
  "Create": "உருவாக்கு",
  "Report": "அறிக்கை",
  "View": "பார்க்க",
  "Crop": "பயிர்",
  "PDF": "பி.டி.எஃப்",
  "CropStartDate": "பயிர் தொடக்க தேதி",
  "StageStartDate": "படி தொடக்க தேதி",
  "EndCropSeason": "பயிர் பருவத்தை முடிக்கவும்",
  "CurrentStage": "படி",
  "EndConfirmation": "தற்போதைய பருவத்தை முடிக்கும்!! நிச்சயமா ?",
  "Yes": "ஆம்",
  "No": "இல்லை",
  "StartCropSeason": "புதிய பருவத்தை தொடங்கவும்",
  "RestartCropSeason": "பருவத்தை மீண்டும் ஆரம்பிக்கவும்",
  "NewCrop": "புதிய பயிர்",
  "Calm": "சமாதானம்",
  "Light Air": "சிறிய காற்று",
  "Light Breeze": "சிறிய காற்று",
  "Gentle Breeze": "மெதுவான காற்று",
  "Moderate Breeze": "நடுத்தர காற்று",
  "Fresh Breeze": "புதிய காற்று",
  "Strong Breeze": "வலிமையான காற்று",
  "Near Gale": "அருகிலுள்ள காற்று",
  "Gale": "காற்று",
  "Strong Gale": "வலிமையான காற்று",
  "Storm": "புயல்",
  "Violent Storm": "கொடுமையான புயல்",
  "leafDryness": "இலை உணர்ச்சி",
  "Direction": "திசை",
  "DownloadHistory": "வரலாறு பதிவிறக்கம்",
  "Today": "இன்று",
  "SeasonDegreeDays": "பருவ நாள் நடப்புத் தடவுகள்",
  "Payment": "கட்டணம்",
  "Vouchers": "வவோசர்கள்",
  "Referral": "பரிந்துரை",
  "PaymentHistory": "கட்டண வரலாறு",
  "Status": "நிலை",
  "Amount": "தொகை",
  "PaymentSuccess": "கட்டணம் வெற்றிகரமாகப் பெறப்பட்டது",
  "PaymentFailure": "கட்டணம் தோல்வியுற்றது",
  "Plot": "திரைகள்",
  "Date": "தேதி",
  "PayFull": "முழுவதும் கட்ட",
  "Validity": "செல்லுபடியுள்ளது",
  "SelectFarm": "உங்கள் பண்ணையைத் தேர்வு செய்யவும்",
  "Loading": "ஏற்றுகிறது..",
  "ViewBillDetails": "அடுத்த பக்கத்தில் விரிவான பில்களைப் பார்க்கவும்",
  "Checkout": "வாங்கு",
  "OfferApplied": "சலுகை பயன்படுத்தப்பட்டது",
  "BillDetails": "பில் விவரங்கள்",
  "OrderTotal": "ஆர்டர் மொத்தம்",
  "TotalDiscount": "மொத்த தள்ளுபடி",
  "ToPay": "செலுத்த வேண்டும்",
  "ProceedToPay": "செலுத்த தொடர்க",
  "BackToHome": "வீட்டுக்கு திரும்பு",
  "OrderNum": "ஆர்டர் எண்",
  "VoucherHistory": "வாவ்சர் வரலாறு",
  "ViewDetailedBill": "விரிவான பில் பார்க்க",
  "ReferAndEarn": "உங்கள் நண்பர்களுக்கு பரிந்துரை செய்து பணம் சம்பாதிக்கவும்!!",
  "SubmitReferral": "பரிந்துரை சமர்ப்பிக்கவும்",
  "referralHistory": "பரிந்துரை வரலாறு",
  "Expired": "ப்ளாட் கொடுப்பு காலாவதியானது.",
  "redirectAfterExpired": "இங்கே ரீசார்ஜ் செய்ய கிளிக் செய்க",
  "History": "வரலாறு",
  "CropSeasonAndStageReports": "பருத்தி நாள்கள் மற்றும் அறிக்கைகள்",
  "Mobile Number*": "மொபைல் எண்*",
  "Friend Name*": "நண்பர் பெயர்*",
  "District*": "மாவட்டம்*",
  "Village(Optional)": "கிராமம்(விரும்பினால்)",
  "Thank You": "நன்றி",
  "Thank you for participation": "பங்கேற்கும்போது நன்றி",
  "PENDING": "நிலுவையிலுள்ளது",
  "PROCESSED": "செயல்படுத்தப்பட்டது",
  "EXPIRED": "காலாவதியானது",
  "Name must be at least 2 characters": "பெயர் குறைந்தது 2 எழுத்துக்கள் இருக்க வேண்டும்",
  "Name must be at max 50 characters": "பெயர் அதிகபட்சமாக 50 எழுத்துக்கள் இருக்க வேண்டும்",
  "Please enter a valid name": "செல்லுபடியாகும் பெயரை உள்ளிடவும்",
  "Name is required": "பெயர் தேவை",
  "Mobile number must be at least 10 digits": "மொபைல் எண் குறைந்தது 10 இலக்கங்கள் இருக்க வேண்டும்",
  "Mobile number must be at max 10 digits": "மொபைல் எண் அதிகபட்சமாக 10 இலக்கங்கள் இருக்க வேண்டும்",
  "Enter a valid mobile": "செல்லுபடியாகும் மொபைல் எணை உள்ளிடவும்",
  "Mobile is required": "மொபைல் தேவை",
  "District is required": "மாவட்டம் தேவை",
  "referralSuccessText": "நீங்கள் {{name}} உடன் பரிந்துரை செய்துவிட்டீர்கள்.",
  "referralFailureText": "{{name}} ஏற்கனவே பரிந்துரை செய்யப்பட்டுள்ளது என்று தெரிகிறது.",
  "Weather": "வானிலை",
  "Fertigation": "கரிப்பான்",
  "Disease": "நோய்",
  "Irrigation": "நீரைக்கும்",
  "Others": "மற்றவை",
  "Other": "மற்றவை",
  "Farm Data": "பண்ணை தரவு",
  "Alerts": "எச்சரிக்கைகள்",
  "Hourly Update": "மணிநேர புதுப்பிப்பு",
  "Hide Details": "விவரங்களை மறைக்க",
  "Show Details": "விவரங்களை காட்டு",
  "mm": "மிமீ",
  "km/hr": "கிமீ/மணி",
  "Avg Lux": "சராசரி லக்ஸ்",
  "Max Lux": "அதிசய லக்ஸ்",
  "Activity": "செயல்பாடு",
  "Duration": "காலம்",
  "Hour": "மணி",
  "Minutes": "நிமிடங்கள்",
  "Irrigation Details": "நீரைக்கும் விவரங்கள்",
  "Dripper flow rate": "டிரிப்பர் பரப்பு வீதம் (லிட்டர்/மணி)",
  "drippers per plant": "கொட்டு வரிசை உற்பத்தி எண்ணிக்கை",
  "numOfPlants": "தாவரங்களின் எண்ணிக்கை",
  "Total drippers": "மொத்த கொட்டு வரிசைகள்",
  "Irrigation per plant": "தாவரங்களுக்கு நீர் செலுத்தல்",
  "Total Irrigation": "மொத்த நீர் செலுத்தல்",
  "Save": "சேமி",
  "SaveMoney": "சேமி",
  "Please fill all the fields!": "அனைத்து புலங்களையும் நிரப்புக!",
  "quantity": "அளவு",
  "Unit": "அலகு",
  "+ ADD": "+ சேர்க்கவும்",
  "List": "பட்டியல்",
  "Water Amount": "நீரின் அளவு",
  "Litre": "லிட்டர்",
  "Spraying for": "பரவல் செய்யும் வரை",
  "Spraying Molecule": "பரவல் செய்கிறது",
  "Please select your": "உங்கள் தேர்வு செய்க",
  "capACTIVITY": "செயல்திறன்",
  "Select Activity": "செயல்திறனைத் தேர்வுசெய்க",
  "Nutrients": "பச்சைக்காரங்கள்",
  "Spray, Pest & Disease": "பரவல், பேச்ட் & நோய்",
  "Cultural Activity": "கலாச்சார செயல்பாடு",
  "Saved": "சேமிக்கப்பட்டது!",
  "SavedMessage": "உங்கள் தரவு சேமிக்கப்பட்டுள்ளது!",
  "Total irrigation can't be 0": "மொத்த நீர் செலுத்தல் 0 ஆக முடியாது.",
  "Please select time duration": "நேர காலத்தைத் தேர்ந்தெடுக்கவும்",
  "attention": "கவனிக்கவும்!",
  "Please select what you are spraying for": "நீங்கள் என்னவாய் பரவல் செய்கிறீர்கள் என்பதைத் தேர்ந்தெடுக்கவும்!",
  "Please select spray molecule(s)": "பரவல் செய்கிறது மோலெக்குள்(கள்) தேர்ந்தெடுக்கவும்!",
  "Please add water amount in litres": "லிட்டிர் அளவில் நீர் அளவை சேர்க்கவும்!",
  "Please add quantity": "அளவை சேர்க்கவும்!",
  "Please add unit": "அலகை சேர்க்கவும்!",
  "Please select fertiliser": "பசுமையினைத் தேர்ந்தெடுக்கவும்!",
  "Please select cultural activity": "கலாச்சார செயல்பாட்டைத் தேர்ந்தெடுக்கவும்!",
  "Duration": "காலம்",
  "TotalIrrigation": "மொத்த நீர் செலுத்தல்",
  "filter": "வடிகட்டி",
  "Type here to start searching": "தேடல் ஆரம்பிக்க இங்கே தட்டச்சு செய்யவும்....",
  "You_sure_you_want_to_delete": "நீங்கள் நீக்க உறுதியாக உள்ளீர்களா?",
  "attentionDelete": "நீக்க வேண்டுமா?",
  "DiseaseAndPest": "நோய் மற்றும் கீட",
  "Update Crop Stage": "பருத்தி நிலையை புதுப்பிக்கவும்",
  "Is this correct?": "இது சரியா?",
  "Search Plot here": "இங்கே புள்ளியை தேடுக",
  "Details": "விவரங்கள்",
  "alternaria_fruit_blotch": "அல்டெர்னாரியா பழப்பட்டிகள்",
  "anthracnose": "அந்த்ராக்னோஸ்",
  "bacterial_blight": "பெக்டீரியல் பட்டிக்கழுத்தம்",
  "bacterial_canker": "பெக்டீரியல் காங்கர்",
  "bacterial_spot": "பெக்டீரியல் கள்ளல்",
  "bacterial_wilt": "பெக்டீரியல் பராசித்தம்",
  "black_spot": "கருப்பு பாட்டு",
  "botrytis": "போட்ரிடிஸ்",
  "cercospora_leaf_spot": "செர்கோஸ்போரா இலை பாட்டு",
  "downy_mildew": "டவுனி மைல்டூ",
  "gummosis": "கம்மோசிஸ்",
  "powdery_mildew": "பவ்டரி மைல்டூ",
  "flea_beetle": "பூச்சி கீரை",
  "mealybug": "மீலிபக்",
  "mites": "மைட்ஸ்",
  "thrips": "த்ரிப்ஸ்",
  "aphids": "அப்பிட்ஸ்",
  "fruit_borer": "பழங்கள் கொத்துக்கூடும் போலேபுகை",
  "citrus_psylla": "சிட்ரஸ் சித்தாப்",
  "OR": "அல்லது",
  "SeasonFertilizerRequirements": "பருவ பச்சை தேவைகள்",
  "Nutrient": "பச்சை",
  "SoilAmt": "மண் அளவு",
  "FertigationAmt": "கரிப்பான் அளவு",
  "Stage": "நிலை",
  "Weeks": "வாரங்கள்",
  "Week": "வாரம்",
  "MajorNutrients": "பெருந்தர பச்சைகள்",
  "ClearAll": "அனைத்தையும் அழி",
  "Sources": "மூலங்கள்",
  "MicroNutrients": "சின்னத்தின் பச்சைகள்",
  "SoilApplication": "மண் பயன்படுத்தல்",
  "Season": "பருவம்",
  "CropType": "பயிர் வகை",
  "CropVariety": "பயிர் வகைகள்",
  "Rootstock": "விதை கட்டியம்",
  "ReportType": "அறிக்கை வகை",
  "InputDetails": "உள்ளீட்டு விவரங்கள்",
  "AddReport": "அறிக்கையைச் சேர்க்கவும்",
  "ReportDate": "அறிக்கை தேதி",
  "PleaseSelectCrop": "விவசாயம் தேர்ந்தெடுக்கவும்",
  "PleaseSelectCropVariety": "விவசாய வகையைத் தேர்ந்தெடுக்கவும்",
  "PleaseSelectRootstock": "ரூட்ஸ்டாக்கையைத் தேர்ந்தெடுக்கவும்",
  "PleaseSelectCropSeason": "விவசாய பருவத்தைத் தேர்ந்தெடுக்கவும்",
  "PleaseSelectReportType": "அறிக்கை வகையைத் தேர்ந்தெடுக்கவும்",
  "PleaseEnter": "உள்ளிடவும்",
  "PestAnalytics": "கீட விபரம்",
  "plotStatusExpired": "புள்ளி செயல்படுத்த தொலைந்து இருக்கும்!",
  "plotStatusOldData": "கடைசியாக ஒருமுறை ஒதுக்கப்பட்டது: {{latestDataTime}}",
  "Acres": "ஏக்கர்கள்",
  "Plots": "புள்ளிகள்",
  "PlotSaveSuccess": "புள்ளி விவரங்கள் வெற்றிகரமாக சேமிக்கப்பட்டன",
  "PlotSaveFail": "புள்ளி விவரங்களை சேமிக்க தோல்வி ஏற்பட்டது.",
  "ValueOutOfRangePercentage": "{{alertVal}} மதிப்பை 0 மற்றும் 100 இடத்தில் உள்ள மதிப்பு உள்ளிடவும்",
  "About Us": "எங்களை பற்றி",
  "NoStageReportFound": "நிலை அறிக்கைகள் காணப்படவில்லை!!",
  "Start": "தொடங்கு",
  "NoSeasonStartedSubText": "நீங்கள் இன்னும் ஏதோ பருவத்தை தொடங்கவில்லை என்று பார்க்கின்றீர்கள். தயவுசெய்து ஒரு பருவம் தொடங்கவும்",
  "NoSeasonStartedText": "ஒரு பருவம் காணப்படவில்லை !!",
  "SowingDate": "விதைப் பயிர்த்த திகதி",
  "StartCropSeasonSubText": "தொடங்கு தேதி மற்றும் பயிர் வகையைத் தேர்வு செய்க",
  "DeltaT": "டெல்டா T",
  "DeltaTVPD": "டெல்டா T & VPD",
  "VPD": "VPD",
  "Marginal": "எதிர்மறை",
  "MarginalHigherDelta": "எதிர்மறை",
  "Preferred": "முன்னேற்றம் செய்யப்படுகிறது",
  "Unsuitable": "பொருத்தமற்றது",
  "StagesInvalidDates": "நிலைகளுக்கான தேதிகள் செல்லுபடியற்றன",
  "DeleteStage": "நிலையை நீக்கு",
  "SureYouWantToDeleteStage": "நீங்கள் தேர்ந்தெடுத்த நிலையை நீக்க உறுதியாக உள்ளீர்களா?",
  "FileSaved": "கோப்பு சேமிக்கப்பட்டது!",
  "NoDownloadExcelDataFound": "தரவு கிடைக்கவில்லை!",
  "YouAreChangingSpray": "நீங்கள் ஸ்பிரே / கீடம் / நோய் மாற்றுகின்றீர்கள்!",
  "MoleculesListDeleted": "ஸ்பிரே அணுகள் பட்டியல் நீக்கப்படும்!",
  "StaleDataError": "தரவு பெறுகிறது இல்லை. தயவு செய்து பிறகும் முயற்சிக்கவும்",
  "FetchDataError": "சாதனம் பதிவுசெய்யப்பட்டுள்ளது, தரவு கிடைக்கும் வரை கேள்விக்கு காத்திருக்கவும்.",
  "Success": "வெற்றி!",
  "Oops": "அய்யோ !!",
  "From": "இருந்து",
  "NA": "தெரியவில்லை",
  "To": "க்கு",
  "E-Mail fyllo support": "இமெயில் fyllo ஆதரவு",
  "Call fyllo support": "அழைக்கவும் fyllo ஆதரவு",
  "ReadMore": "மேலும் வாசிக்கவும்..",
  "fyllo": "பைலோ",
  "EditActivity": "செயல்பாடையை திருத்து",
  "PermissionDenied": "அனுமதி நிர்ணயிக்கப்பட்டது",
  "AllowFilePermission": "இந்தப் பணியை செய்ய கோப்பு அனுமதியை அனுமதிக்கவும்!",
  "IncorrectEmailPhone": "தவறான மொபைல் அல்லது மின்னஞ்சல்.",
  "EmailForAccountSupport": "உங்கள் பதிவு செய்யப்படவில்லையெனில் contact@fyllo.in என்பதற்கு மின்னஞ்சல் அனுப்பவும்",
  "ServerIssue": "சர்வருக்கு இணைய செய்ய முடியவில்லை",
  "TryLater": "சில நேரங்கள் பிற்கும் முயற்சிக்கவும்",
  "IncorrectOTP": "தவறான OTP . ",
  "RefillOTP": "மீண்டும் சரியான OTP ஐ உள்ளிடவும்",
  "NoDataFound": "தரவு கிடைக்கவில்லை!",
  "dd-mm-yyyy": "டி.டி-மம்-வருவாய்",
  "NoPrevSeasonText": "முந்தைய பருவங்கள் காணப்படவில்லை !!",
  "ChooseAction": "செயல்கையைத் தேர்ந்தெடு",
  "SelectionAction": "மேலெழுத்துக்களுக்கு மேலும் விவரங்களைக் குறிப்பிட ஒரு செயல்கையைத் தேர்ந்தெடுக்கவும்",
  "Download": "பதிவிறக்கம்",
  "unitkPa": " கேபி ஏ ",
  "unitCelcius": " கெல்சியஸ் ",
  "unitmm": " மி ",
  "unitLux": " லக்ஸ் ",
  "unitkmph": " கி.மீ.கி. ",
  "Scouting": "தேடல்",
  "Attachments": "இணைப்புகள்",
  "Notes": "குறிப்புகள்",
  "Description": "விளக்கம்",
  "Upload": "பதிவேற்று",
  "Read less": "குறைந்ததை வாசிக்க",
  "Read more": "மேலும் வாசிக்க",
  "Uploading": "பதிவேற்றம் செய்யப்படுகின்றது",
  "PleaseSelectDiseaseLevel": "நோய் கஷ்ட நிலையைத் தேர்ந்தெடுக்கவும்",
  "PleaseSelectPestLevel": "பூச்சி கஷ்ட நிலையைத் தேர்ந்தெடுக்கவும்",
  "PleaseSelectNutrientLevel": "பருத்தி கஷ்ட நிலையைத் தேர்ந்தெடுக்கவும்",
  "Permissions issue": "அனுமதிகள் கோரப்பட்டன!",
  "GalleryPermissionIssue": "இந்த பணியை செய்ய கோப்பு / கேலரி அனுமதிகளை அனுமதிக்கவும்!",
  "CameraPermissionIssue": "இந்த பணியை செய்ய கேமரா அனுமதிகளை அனுமதிக்கவும்!",
  "Gallery": "கேலரி",
  "Camera": "கேமரா",
  "NoFarmMatched": "ஒரு பருவத்துக்கும் பொருந்தவில்லை",
  "WeatherPredictionNotAllowedSubText": "நீர்வழிப்படுத்தலுக்கான காலாண்டு படிவம் இல்லை.",
  "CheckInternet": "தயவுசெய்து இணைய இணைப்பைச் சரிபார்க்கவும்.",
  "DeviceHealth": "சாதன உறுதியாகாமல்",
  "Health": "உறுதியாகாமல்",
  "LooksLikeDeviceNotWorking": "சாதனம் செயல்படவில்லை என்று பார்க்கின்றது.",
  "DEVICE_HEALTH": "சாதன உறுதியாகாமல்",
  "Device data being recieved": "சாதன தரவு பெறப்படுகிறது?",
  "BatteryReducedText": "கடந்த {{countVal}} நாட்களில் பேட்டரி {{reductionVal}}% குறைந்துவிட்டது.",
  "Sensor Conditions": "செளுத்து நிலைகள்",
  "All sensors are working fine": "எல்லா செளுத்துக்களும் சரியாக செயல்படுகின்றன.",
  "NextFourHoursSprayNote": "அடுத்த நான்கு மணி நேர கட்ட எடுத்துக்கொள்கின்று அதிகாரிக்க அல்லது அத்தியாவசிக்க என்ன செய்ய என்பதை கொண்டு மீளவும்.",
  "Location": "இருப்பிடம்",
  "Pay": "கட்டணம்",
  "Pay using": "பயன்படுத்தி கட்டணம் செய்யுங்கள்",
  "Other Payment Methods": "பிற கட்டண முறைகள்",
  "Card Bank Transfer": "அட்டை வங்கி இடமாக பரிமாற்றம்",
  "Which plot?": "எண்ணிக்கை?",
  "What activity?": "எந்த செயல்பாடு?",
  "DeviceNotInstalledWarning": "இந்த புள்ளியில் சாதனம் நிறுவப்படவில்லை உள்ளிட்ட தரவை பார்க்க!",
  "CantUpdateData": "தரவை புதுப்பிக்க முடியாது. தயவுசெய்து பிற்கும் முயற்சிக்கவும்",
  "AddPlot": "புள்ளி சேர்க்க",
  "HowMuchToIrrigate": "எவ்வளவு நீரை மதிக்க வேண்டும்?",
  "ActHours": "மணி",
  "ActMinutes": "நிமிடங்கள்",
  "WhatWithinCultActivity": "எது உள்ளிட்ட கிள்ளார்கள் உள்ளது?",
  "Area": "பரப்பு",
  "NoActivityFound": "செயல்பாடு கண்டுபிடிக்கப்படவில்லை",
  "OldStyle": "பழைய பாணி",
  "ActivityNotedAt": "அட்டவணை காலத்தில்",
  "New crop Season started": "புதிய வருமான பருவம் தொடங்கியது",
  "ObservedDiseaseActivity": "உத்தியை கண்டுபிடிக்கவும்: புள்ளியில் இருந்து கீரை கண்டுபிடிக்கப்பட்டது",
  "OtherActivity": "உத்தியை கண்டுபிடிக்கவும்: புள்ளியில் செய்யப்பட்டது",
  "NutrientActivity": "உத்தியை கண்டுபிடிக்கவும்: 50 கிலோ உரியம் பயன்படுத்தப்பட்டது",
  "AppliedUreaActivity": "உத்தியை கண்டுபிடிக்கவும்: 50 கிலோ உரியம் பயன்படுத்தப்பட்டது",
  "SprayActivity": "உத்தியை கண்டுபிடிக்கவும்: 500 லிட்டர் நீரில் 1 கிலோ காப்பர் ஸ்பிரேட் செய்யப்பட்டது",
  "ApplyFilter": "குழு பட்டியல் பட்டியல் செய்யவும்",
  "SoilType": "மண் வகை",
  "Foliar": "படிப்புத் தோட்டம்",
  "FoliarAmt": "படிப்புத் தோட்டம் அளவு",
  "Soil": "மண்",
  "PleaseSelectAtleastOneMethod": "தயவுசெய்து குறைந்தது ஒரு விதம் தேர்ந்தெடுக்கவும்",
  "AvailableOptions": "கிடைக்கும் விருப்பங்கள்",
  "NoCropSelected": "விவசாயம் தேர்ந்தெடுக்கப்படவில்லை",
  "HideOptions": "விருப்பங்களை மறை",
  "ShowOptions": "விருப்பங்களை காட்டு",
  "sprayRequired": "{{waterInLitres}} லிட்டர் / ஏக்கர் நீரில் ஒரு காப்பர் விதை ஸ்பிரே செய்ய",
  "nutrientRequired": "தேவையான தேர்வு ஒரு விதம் மூலிகை மூலிகையை வழங்கும் {{type}} மூலம் விளையாட்டுக்கான:",
  "foliar": "படிப்புத் தோட்டம்",
  "fertigation": "கனிக்கும் கனிக்கும் முயற்சி",
  "soilApplication": "மண் பயன்படுத்துதல்",
  "SelectOneOption": "ஒரு விருப்பத்தைத் தேர்ந்தெடு",
  "CreateReport": "அறிக்கை உருவாக்கவும்",
  "ViewHistory": "வரலாறு பார்க்க",
  "DownloadPDF": "PDF பதிவிறக்கம்",
  "Summry": "சுருக்கம்",
  "Weekly": "வாரம்",
  "StageSummary": "நிலை சுருக்கம்",
  "UnitsinKg/acre": "யூனிட்கள் கிலோ / ஏக்கர்",
  "Options": "விருப்பங்கள்",
  "Applyanyoneoftheoptions": "விருப்பங்களில் ஒன்றை பயன்படுத்தவும்",
  "riskLevel": "பாதிக்கப்படுகின்றது",
  "AnalysisStage": "பகுப்பாய்வு நிலை / வகை",
  "PleaseSelectAnalysisStage": "பகுப்பாய்வு நிலையைத் தேர்ந்தெடுக்கவும்",
  "days": "நாட்கள்",
  "Register": "பதிவு",
  "EnterMobileNoEmailID": "மொபைல் எண் உள்ளிடவும்",
  "Resend": "மீண்டும் அனுப்பு",
  "Seconds": "செக்",
  "Number": "மொபைல் எண்",
  "Useralreadyregistered": "பயனர் ஏற்கனவே பதிவு செய்யப்பட்டுள்ளார்",
  "InvalidNumber": "தவறான எண்",
  "FarmDetails": "பணியின் விவரங்கள்",
  "FarmName": "பணியின் பெயர்",
  "PlotDetails": "புள்ளி விவரங்கள்",
  "PlotName": "புள்ளி பெயர்",
  "DifferentSoilTypes": "வேறுபாடுகளுள் மண் வகைகள்",
  "Sandy": "மணம்",
  "SandyLoam": "மணம் மணம்",
  "Loamy": "மணம்",
  "Clay": "மண்",
  "PlotArea": "புள்ளி பருவம்",
  "Plotareacantbegreaterthanfarmarea": "புள்ளி பருவம் பண்ணை பருவம் விட அதிகமாக இருக்கக்கூடாது",
  "Allowfyllotoaccessyourlocation": "உங்கள் இருப்பிடத்திற்கு fyllo அணுகுவதற்கு அனுமதிக்கவும்?",
  "SAVETOADDCROP": "கற்பனையை சேமி",
  "CropDetails": "கற்பனை விவரங்கள்",
  "CropName": "கற்பனைப் பெயர்",
  "SowingPruningDate": "சாகுதம் / சிறை தேதி",
  "PlantationYear": "நடுவரையில் வருமான ஆண்டு",
  "AddDevice": "சாதனம் சேர்க்க",

  "Adddevicetotheapp": "சாதனத்தை பயன்படுத்த வழி உங்கள் சாதனத்தை சரிபார்க்கவும்",
  "EnterIMEINumber": "IMEI எண் உள்ளிடவும்",
  "Scan": "ஸ்கேன்",
  "ATTACHDEVICE": "சாதனத்தை இணைக்கவும்",
  "PopoverFarmDetails": "பண்ணை தரவு மற்றும் கணிப்புகளைக் காண்க",
  "PopoverCalendarDetails": "செயல்பாடுகளைச் சேர்க்கும் மற்றும் பார்க்கும்",
  "popoverDiseaseDetails": "பாலைகள் மற்றும் பூச்சிகள் பற்றிய விவரங்கள்",
  "popoverAnalyticsDetails": "உரதி விவரங்களைக் காண்க",
  "popoverPlotsDetails": "பிற பண்ணை / பருவம் தேர்ந்தெடுக்கவும்",
  "popoverDailyReportDetails": "டெய்லி அறிக்கையைச் சரிபார்க்கவும்",
  "popoverMyFarmDetails": "நெருக்கமான பண்ணை தரவு மற்றும் வரலாற்றைக் காண்க",
  "popoverWeatherDetails": "மண் மற்றும் டெய்லி வானிலை படிகளை காண்க",
  "popoverCropStageDetails": "பட்டியலில் படியைக் காண்பி மறைக்க",
  "popoverShareButtonDetails": "தரவை பகிர்க",
  "popoverUpdatedTimeDetails": "கடைசியாக புதுப்பிக்கப்பட்ட நேரம்",
  "popoverHealthDetails": "சாதனம் மற்றும் கானங்களின் உறுதியைச் சரிபார்க்கவும்",
  "popoverPlusButtonDetails": "செயல்களைச் சேர்க்கவும்",
  "popoverBarMenuDetails": "கடைசி, பரிந்துரை, ஆதரவு",
  "popoverNotificationDetails": "அறிவிப்புகளை இங்கு சரிபார்க்கவும்",
  "popoverSoilMoistureDetails": "நெருங்கடி தரவு, சரிபார்க்க கிளிக் செய்வதற்கு வரவு",
  "Next": "அடுத்து",
  "Previous": "முந்தைய",
  "Skip": "வழி போக",
  "Finish": "முடிக்க",
  "Delete": "அழி",
  "Cancel": "ரத்து செய்",
  "Redo": "மீளமைக்க",
  "ClickandscanQR": "சாதனத்தில் இந்த பகுதியில் உள்ள QR குறியீட்டை ஸ்கேன் செய்யவும்",
  "Welcome": "வரவேற்கின்றேன்",
  "Welcomeback": "மீண்டும் வருக",
  "HelpWithdetails": "தயவுசெய்து சில விபரங்களை உதவி செய்கிறது",
  "Language": "மொழி",
  "InvalidIMEINumber": "தவறான IMEI எண்",
  "ApplicationMode": "செயலி முறை",
  "kgHectare": "கிலோகிராம் / ஹெக்டேர்",
  "percent": "%",
  "UnitPPM": "பிபிஎம்",
  "Select": "தேர்ந்தெடு",
  "LogoutModalDescription": "நீங்கள் வெளியேற விரும்புகிறீர்களா?",
  "YourReport": "உங்கள் அறிக்கை",
  "RemovePhoto": "படத்தை அகற்று",
  "addActivity": "செயல்பாடு சேர்",
  "notificationSettings": "அறிவிப்பு அமைப்புகள்",
  "Settings": "அமைப்புகள்",
  "makeAPayment": "கட்டணம் செலுத்து",
  "pastTransactions": "முந்தைய பரிவர்த்தனைகள்",
  "NoPesticideSchedule": "பூச்சியடித்தல் அட்டவணை கிடைக்கவில்லை!",
  "UpcomingSpray": "வரவிருக்கும் ஸ்ப்ரே",
  "Conditional": "கட்டுப்படுத்தப்பட்ட",
  "Fixed": "நிலையான",
  "Purpose": "போலி",
  "Chemical": "வேதியியல்",
  "WaterQty": "நீரின் அளவு",
  "CompleteSchedule": "முழு அடிக்கோல்",
  "UsePasswordInstead": "பாஸ்வேர்டைப் பயன்படுத்தவும்",
  "useOTPInstead": "OTP ஐப் பயன்படுத்தவும்",
  "cropStage": "பயிர் நிலை",
  "whenToSpray": "எப்போது ஸ்ப்ரே செய்ய?",
  "whenToSprayDescription": "{{severity}} பக்கங்கள் மாதங்கள் பற்றி",
  "notApplicable": "பொருந்துவதில்லை",
  "StagewiseSprayGuideline": "பட்டியில் படிக்கு எப்போது ஸ்ப்ரே செய்ய?",
  "back": "பின்செல்",
  "registerNewUser": "புதிய பயனரை பதிவு செய்க",
  "existingUserLogin": "ஏற்கனவே உள்நுழை",
  "ComebackLater": "நாம் அதிக செய்து உங்களுக்கு சிறந்த முடிவுகளைப் பெற வேலை செய்கின்றோம்!",
  "SelectAPlot": "புதிய செய்தி செயல் தேர்வு",
  "ShowReportFor": "அறிக்கையைக் காண்பி",
  "StartStage": "ஆரம்ப நிலை",
  "EndStage": "கடைசி நிலை",
  "Neutrients": "போக்குகள்",
  "NoSpray": "ஸ்ப்ரே எண்ணிக்கை",
  "ThisWeek": "இந்த வாரம்",
  "TillDate": "தேதி வரை",
  "ThisMonth": "இந்த மாதம்",
  "LastMonth": "கடந்த மாதம்",
  "irrigationDetected": "நீர்ப்பை கண்டறியப்பட்டது!",
  "Insights": "அறிவுறுத்தல்கள்",
  "DurationInMinutes": "கால அளவு (நிமிடங்களில்)",
  "ThisSeason": "இந்த பருவம்",
  "SelectSeason": "பருவத்தை தேர்வு செய்க",
  "stageNotStartedWarning": "{{stageName}} நிலை இன்னும் தொடங்கப்படவில்லை",
  "CreditBalance": "கிரெடிட் இருப்பு",
  "SearchContact": "ஒரு தொடர்பைத் தேடு",
  "EnterName": "பெயரை உள்ளிடுக",
  "Contacts": "தொடர்புகள்",
  "NoContactsFound": "தொடர்புகள் கிடைக்கவில்லை",
  "WhyFyllo": "என்னக்கான பயன்கள்?",
  "CropQuality": "பயிர் தர மற்றும் உதிர்கள்",
  "DiseaseManagement": "நோய்களும் பூச்சிகளும் மேலும்",
  "PreciseIrrigation": "துணை நீர்ச்சத்திற்கான நிலைகள்",
  "BestService": "சிறந்த சேவை",
  "Sendvia": "மூலம் அனுப்பு",
  "Howitwork": "அது எப்படி வேலை செய்கிறது?",
  "creditedyouraccount": "ஒவ்வொரு முன்னேற்றத்திலும் ₹20/- உங்கள் fyllo ரீசார்ஜ் கணக்கில் கிடைக்கும்.",
  "maximumearned": "25 பேர்க்கு வரை இதைப் பகிரும்படியாக ₹500 சாதாரணமாக சம்பாதிக்க முடியும்.",
  "friendinstallsfyllo": "உங்கள் நண்பர் fyllo நிறுவியிருந்தால், நீங்கள் கூடப்பட்டு ₹ 250 குறியீடு பெறும் மீது ₹500 பெறும் மீது உதவும்.",
  "off": "அதிகரித்தல்",
  "Mosteffectivereferers": "சிறந்த உற்பத்தியாளர்கள் ஒவ்வொரு 6 மாதத்திற்கும் நெரோ சாதனம் அல்லது திருப்பதி கட்டுமானத்துக்கு வெகுமதியாக வெகுமதி செய்யப்படும்.",
  "amountcredited": "₹{{amount}} வெற்றிகரமாக கணக்குக்கு கொண்டுள்ளது",
  "fylloaccount": "உங்கள் fyllo கணக்கில்",
  "Backtoreferral": "மீண்டும் பரிந்துரை",
  "TotalCreditsEarned": "மொத்த கிரெடிட் சம்பாதித்தது",
  "CreditsRedeemed": "கிரெடிட் மீட்டுச்செய்யப்பட்டது",
  "GotReferralCode": "ஒரு பரிந்துரை குறியீடு கிடைத்ததா?",
  "ClaimYourRewards": "உங்கள் பரிசுகளை கோர்க்கவும்",
  "EnterCode": "உங்கள் குறியீடை உள்ளிடவும்",
  "EnterValidReferralCode": "செல்லுபடியாகும் ஒரு பரிந்துரை குறியீடு உள்ளிடவும்",
  "REDEEMED": "மீட்டுச்செய்யப்பட்டது",
  "SelectAPlot": "புதிய செய்தி செயல் தேர்வு",
  "ShowReportFor": "அறிக்கையைக் காண்பி",
  "StartStage": "ஆரம்ப நிலை",
  "EndStage": "கடைசி நிலை",
  "NoSpray": "ஸ்ப்ரே எண்ணிக்கை",
  "ThisWeek": "இந்த வாரம்",
  "TillDate": "தேதி வரை",
  "ThisMonth": "இந்த மாதம்",
  "LastMonth": "கடந்த மாதம்",
  "CustomDate": "தேதி",
  "SpecifyStage": "நிலையை குறிக்க",
  "SelectStage": "நிலையைத் தேர்ந்தெடு",
  "ThisStage": "இந்த செய்தியில்",
  "CustomStage": "நிலை வரம்பு",
  "irrigationDetected": "நீர்ப்பை கண்டறியப்பட்டது",
  "DurationInMinutes": "கால அளவு (நிமிடங்களில்)",
  "ThisSeason": "பருவம்",
  "CREDITED": "கிரெடிட்",
  "WAITING": "காத்திருக்கின்றது",
  "referralScreen": "பரிந்துரை மற்றும் சம்பாதிக்க",
  "Apply": "பிடித்ததாக்கு",
  "Show": "காட்டு",
  "UseCreditBalance": "கிரெடிட் இருப்பைப் பயன்படுத்து",
  "ReferralBonus": "பரிந்துரை போன்ஸ்",
  "RefferalCodeApplied": "பரிந்துரை குறியீடு பயன்படுத்தப்பட்டது",
  "ManageFarmUsingFyllo": "வணக்கம்,\nஎன் பண்ணையை நிர்வகிக்கும் பயன்படுத்துவரம் உபயோகிப்பது Fyllo Device.",
  "FylloBenefit": " நான் Fyllo பயன்படுத்தும்போது பின்னணி பயனர்களால் பயன்படுத்தும் முக்கியமான பயன்களைப் பெற்றேன்:",
  "InstallFyllo": "உங்கள் பண்ணையில் மூலம் Fyllo நிறுவுக. நீங்கள் +91-9380908490 என்ற WhatsApp இல் பொருளாதார அறிக்கையை அல்லது மின்னஞ்சல் மூலம் contact@fyllo.in என்ற மின்னஞ்சலில் Fyllo அணுக முயற்சிக்கவும்",
  "UseRefferalCode": "உங்கள் அடிப்படையான ரீசார்ஜில் இந்த {{குறியீடு}} குறியீட்டைப் பயன்படுத்து",
  "SomeErrorOccured": "சம்பந்தப்பட்ட பிழை ஏற்பட்டது",
  "AnyChangeInReport": "அறிக்கையில் ஏதேனும் மாற்றம்?",
  "DeleteAccount": "கணக்கை அழி",
  "ReasonToDelete": "உங்கள் கணக்கை அழுத்த வேண்டும் என்று காரணத்தை தேர்ந்தெடுக்கவும்?",
  "NoFruitfulResult": "நான் யோசிக்கிறேன்",
  "NothingWorking": "எதுவும் வேலையாகாது",
  "PrivacyConcerns": "என் தனியுரிமை கவனிப்புகள்",
  "WhatisNotWorking": "நாங்கள் உங்களுக்கு சிறந்த முடிவுகளைப் பெற வேலை செய்கின்றோம். நீங்கள் ஏன் பயனர்கள் அதிர்ஷ்டமாக இருக்கிறீர்கள் என்று நாங்கள் பகிரவும் மனதில் உள்ளீர்களா?",
  "Warning": "எச்சரிக்கை",
  "DeleteWarningMessage": "உங்கள் கணக்கை அழிக்கும்போது நீங்கள் அனைத்து தரவுகளும் மற்றும் சந்தாக்களும் நிரந்தரமாக இழந்து விடும்",
  "OurTeamWillContact": "எங்கள் குழு உங்களை அகற்றுவார் விரைவில் உங்கள் கணக்கை அழிக்க மேலும் தகவல் விவரங்களுக்கான உங்களை அழைப்பார்.",
  "Close": "மூடு",
  "GoBack": "பின்செல்",
  "GiveYourThoughts": "உங்கள் எண்ணங்களை அளிக்கவும்",
  "ConfirmDeletion": "அழிப்பை உறுதிப்படுத்தவும்",
  "AnyChangeInReport": "அறிக்கையில் ஏதேனும் மாற்றம்?",
  "DeleteAccount": "கணக்கை அழி",
  "UpdateStage": "நிலையை புதுப்பிக்கவும்",
  "EndSeasonWarningMsg": "இந்த பருவத்தை முடிக்க வேண்டும் என்பதை உறுதிப்படுத்தவும்?",
  "StageUpdated": "நிலை புதுப்பிக்கப்பட்டது",
  "Retry": "மீண்டும் முயற்சி",
  "NoWeekSelected": "எந்த வாரம் தேர்ந்தெடுக்கப்படவில்லை",
  "thingsToRememberFertigation": "நீர் போக்குதல் போலவே நினைவில் கொள்ளத் தேவையானது",
  "thingsToRemember": "நினைவில் கொள்ளத் தேவையானது",
  "NoDiseaseDataFound": "நோய் தரவு கிடைக்கவில்லை",
  "NoPestDataFound": "கீழ்மான் தரவு கிடைக்கவில்லை",
  "Range": "எல்லை",
  "Value": "மதிப்பு",
  "DoYouNeedHelp": "ஏதோ பிரச்சினை உள்ளதா?",
  "LetUsHelpYou": "நாங்கள் உங்களை உதவ உள்ளோம்",
  "ChatWithUs": "எங்கு உங்களுடன் உரையாடவும்",
  "CallUs": "எங்களை அழையவும்",
  "update": "புதுப்பிக்கவும்",
  "cancel": "ரத்து",
  "NewUpdateAvailable": "புதிய புதுப்பித்தல் கிடைக்கின்றது!",
  "PleaseUpdateYour": "தயவுசெய்து உங்கள் பயன்பாட்டை புதுப்பிக்கவும்",
  "helpMessage": "ஹாய், என் பண்ணை பற்றி உதவுகின்றது {{farmId}}. என் பயனர் பெயர் {{username}}",
  "notApplicableInfo": "{{pestOrDiseaseName}} இந்த நிலையில் பொருந்துவதில் பொருந்தவில்லை",
  "pesticideScheduleNotAllowed": "நீர்ப்பாவத்திற்கு கீழ்மான் வரையறு கொள்ள முடியாது.",
  "latestReport": "சமீபத்திய அறிக்கை",
  "noReportsAvailable": "அறிக்கைகள் கிடைக்கவில்லை",
  "uploadPdfReport": "அறிக்கை பதிவேற்றம்",
  "scanningPdf": "ஆவணத்தை தட்டுதல்",
  "cantScanPdf": "நாங்கள் இந்த ஆவணத்தை தட்ட முடியவில்லை",
  "tryAgainPdf": "தயவுசெய்து உருவாக்குகின்றது நீர் மதிப்புகளை மையமிருந்து உள்ளிடவும்",
  "basedOnUploadedReport": "பதிவேற்றப்பட்ட அறிக்கைக்கு அடிப்படையாக",
  "ScheduleGeneratedOnDefaultReport": "பொது அறிக்கையில் உருவாக்கப்பட்ட அடிப்படையில் திட்டமிடப்பட்டுள்ளது",
  "ForPreciseSchedule": "சரியான திட்டமிடத்திற்கு",
  "remember": "நினைவில் கொள்ளுங்கள்",
  "lowest": "அதிகமாக",
  "average": "சராசரி",
  "highest": "அதிகம்",
  "onAverage": "சராசரி பேர்செல்",
  "Vpd": "Vpd",
  "vpd": "Vpd",
  "deltaT": "டெல்டா T",
  "newVersionAvailable": "புதிய புதுப்பித்தல் கிடைக்கின்றது!!!",
  "SelectPlot": "பூட்டைத் தேர்வுசெய்க",
  "RechargeUptoDate": "தேதி வரை ரீசார்ஜ் செய்க",
  "getUptoDiscount": "{{discountPercentage}}% கூட்டு வரவு பெறுங்கள்",
  "Yearly": "வாரியாக",
  "Monthly": "மாதாந்திர",
  "Quarterly": "திருகாலிக",
  "DueInDays": "{{days}} நாட்களில் செலுத்தப்படும்",
  "ContactSupport": "ஆதரவு தொடர்பு கொள்ளுங்கள்",
  "Email": "மின்னஞ்சல்",
  "Phone": "தொலைபேசி",
  "Whatsapp": "வாட்ஸ்அப்",
  "INITIATED": "துவக்கம் செய்யப்பட்டது",
  "AUTHORIZED": "அங்கீகரிக்கப்பட்டது",
  "SUCCESS": "வெற்றி",
  "FAILED": "தோல்வி",
  "NEW": "புதிய",
  "Search": "தேடு",
  "VideoGuide": "வீடியோ வழிகாட்டி",
  "ViewMyProfile": "என் சுயவிவரத்தைக் காண்க",
  "SearchKeywordToLearnAboutIt": "அதை பற்றி அறிந்துகொள்ள தேடுக",
  "CalculateValue": "மதிப்பை கணக்கிடு",
  "ReCalculateValue": "மதிப்பை மீள்பார்",
  "Schedules": "திட்டங்கள்",
  "ChangeFarmDesc": "உங்கள் மாற்றங்கள் சேமிக்கப்படாது. தொடரவும்?",
  "AddNewFarm": "புதியதைச் சேர்க்கவும்",
  "Remove": "அகற்று",
  "UseExisting": "உள்ளதைப் பயன்படுத்து",
  "NoNotification": "அறிவித்தல் இல்லை",
  "Edit": "திருத்து",
  "CopytoPlot": "நகலை நகலைப் பெறு",
  "inch": "அங்கு",
  "Error": "பிழை",
  "daysExceed": "உங்கள் நாட்கங்கள் பருவ நாட்கங்களை மீறுகின்றன.",
  "updateStages": "உங்கள் நிலைகளை புதுப்பிக்கவும் உங்கள் பண்ணையின் சட்டப்பைப் பற்றிய உள்ளடக்கத்தை பெற உடனடியாக புதுப்பிக்கவும்!",
  "Later": "பின்னுகிறேன்",
  "selectDuration": "ஒரு காலத்தைத் தேர்ந்தெடு",
  "Lastdurationdays": "கடைசி {{duration}} நாட்கள்",
  "seasonSummary": "பருவ சுருக்கம்",
  "TotalWaterUsed": "மொத்த நீர் பயன்படுத்தப்பட்டது",
  "TotalIrrigationEvents": "மொத்த நீர்வழிச் சம்பவங்கள்",
  "AvgIntervalbetweenIrrigations": "2 நீர்வழிச் செயல்படுத்துகள் இடைவேளை சராசரி",
  "NumberofSpraysutilized": "பயன்படுத்தப்பட்ட நீர்வழிச் செயல்படுத்துகள்",
  "AverageSunLight": "சராசரி சூரிய ஒளி",
  "AverageSunshineHours": "சராசரி சூரிய ஒளி மணிநேரம்",
  "Explore": "நிலை சுருக்கம் கொண்டு செல்க",
  "Lai": "LAI",
  "Not Optimal": "செல்லும் ஒழிப்பு இல்லை",
  "Optimal": "செல்லும் ஒழிப்பு",
  "AboutDeltaT": "DeltaT என்றால் என்ன?",
  "AboutVPD": "VPD என்றால் என்ன?",
  "AboutLAI": "LAI என்றால் என்ன?",
  "AddCost": "செலவுப் பெறுக",
  "costExample": "உதாரணமாக: 400",
  "selectCurrency": 'நாணயத்தைத் தேர்வு செய்க',
  "No Rain": 'மழை இல்லை',
  "AppGuideVideo": "பயன்பாட்டு வீடியோக்கள்",
  "ProductVideo": 'தயாரிப்பு வீடியோக்கள்',
  "AgronomyVideo": 'விவசாய வீடியோக்கள்',
  "TestimonialVideo": 'முன்னிலை வீடியோக்கள்',
  "IDPMSuggestion": "{{disease}} மேலாண்மை",
  "AddYourFertilizers": "உங்கள் உரம்களைச் சேர்க்கவும்",
  "SprayNotifs": "பூச்சி அறிக்கைகள்",
  "Cost": 'செலவு',
  "Cultural Practice": "பாரம்பரிய பயிற்சி",
  "rootWidth": "வேர் அகலம்",
  "rootDepth": "வேர் ஆழம்",
  "dripperDischarge": "ட்ரிப்பர் விடுமுறை",
  "soilRetention": "மண் வைத்திருத்தம்",
  "dripperDistance": "ட்ரிப்பர் இடையோளம்",
  "Depth": "ஆழம்",
  "lai": "LAI",
  "LaiDetails": "LAI விவரங்கள்",
  "Image": "படம்",
  "or": "அல்லது",
  InvalidPayment: "தவறான கட்டணம்",
  PaymentInProgress: "கட்டணம் செலுத்தப்படுகிறது",
  "ServiceRequest": "சிக்கல் அறிக்கை",
  "NoServiceRequestFound": "எந்த சிக்கல் அறிக்கையும் கிடைக்கவில்லை",
  "YourServiceRequests": "உங்கள் சிக்கல் அறிக்கைகள்",
  "RaiseServiceRequest": "சிக்கல் அறிக்கை செய்யுங்கள்",
  "TellAboutYourIssue": "உங்கள் சிக்கலைச் சொல்லுங்கள்",
  "DoYouWantToOpenThisServiceRequest": "இந்த சிக்கல் அறிக்கையை திறக்க விரும்புகிறீர்களா?",
  "DoYouWantToCloseThisServiceRequest": "இந்த சிக்கல் அறிக்கையை மூட விரும்புகிறீர்களா?",
  ReopenServiceRequest: "மீண்டும் திறக்க",
  CloseServiceRequest: "நெருக்கமான",
  OpenDate: "திறந்த தேதி",
  SelectImage: "படத்தைத் தேர்ந்தெடுக்கவும்",
  TakeImage: "படம் எடுக்க",
  open: "திறந்த",
  closed: "மூடப்பட்டது",
  new: "புதியது",
  "pending reminder": "நினைவூட்டல் நிலுவையில் உள்ளது",
  merged: "இணைக்கப்பட்டது",
  removed: "அகற்றப்பட்டது",
  lastUpdateHours: "கடந்த {{hours}} மணிநேரம்",
  Refresh: "புதுப்பிப்பு",
  Issue: "பிரச்சனை",
  IssueIsRequired: "சிக்கலை நிரப்புவது அவசியம்",
  DetailIsRequired: "விவரங்கள் தேவை",
  ImagesTooLargeError: 'நீங்கள் பதிவேற்ற முயற்சிக்கும் படங்கள் மிகவும் பெரியவை. சில படங்களை நீக்கி மீண்டும் முயற்சிக்கவும்',
  Create: 'உருவாக்கு',
  ReachedMaxAttachments: 'படங்களுக்கான அதிகபட்ச வரம்பை அடைந்துவிட்டீர்கள். மேலும் சேர்க்கும் முன் சில படங்களை அகற்றவும்.'
};



const data = { hi, en, te, ta, gu, ka, ma }