import { getData, postData } from "./common.service";


const CORE_API = process.env.REACT_APP_CORE_API;


interface CustomParam {
  name: string;
  value: any;
}

interface Receiver {
  customParams: CustomParam[];
  whatsappNumber: string;
  lang:string
}




export async function getTemplates(filters = {}) {
    let url = `/wati/templates`;
  
    // https://core.fyllo.in/wati/templates?searchText=banana
    // /wati/templates?templateName=banana
    if (Object.keys(filters).length > 0) {
        const queryParams = new URLSearchParams(filters).toString();
        url += `?${queryParams}`;
      } 
      return getData(url, {
        basePath: CORE_API,
        sendTokenInHeader: true,
      });
  }


export async function postTemplateData(data = {}) {
  const url = `/wati/templateMessages`;   
  try {
    const response = await postData(url, {
      basePath: CORE_API,
      sendTokenInHeader: true,
      body: data,  
    });
    // alert(`Response  ${JSON.stringify(response)}`);
    return response; 

  } catch (error: unknown) {
    console.error('Error posting data:', error);

    const errorMessage = error instanceof Error ? error.message : 'An unknown error occurred';
    alert(`Error: ${errorMessage}`);
    throw error;  
  }
}
