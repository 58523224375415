import { Grid, IconButton, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useNavigate } from "react-router";
import { generateOptions } from "../utils/helper";
import {
  getCropOptions,
  getCropVarieties,
  postCropCharacteristicsData,
  putCropCharacteristicsData,
  deleteCropCharacteristicsData,
} from "src/services/crop.service";
import {
  ColDef,
  GridOptions,
  GridReadyEvent,
  SideBarDef,
  SortChangedEvent,
  FilterChangedEvent,
  ColGroupDef,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { isArrayWithLength } from "../utils/helper";
import { KeyValues } from "src/constants/interfaces";
import Select, { MultiValue } from "react-select";
import { getAllLocale } from "src/services/locale.service";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { ToastAlert } from "src/components";

interface Props {
  selectedModalData?: any;
  setOpenModal?: any;
}

const DropdownProps = {
  isClearable: true,
  isSearchable: true,
  placeholder: "- select -",
  styles: {
    container: (baseStyles: any) => ({
      ...baseStyles,
      width: "100%",
      padding: 2,
      fontSize: 16,
      margin: "3px 0",
      marginBottom: 11,
      marginTop: 6,
    }),
  },
};

const initialContentState: any = {
  numberOfDaysinStages: [],
  cropCoeffecientinStages: [],
  minOptimalMoisture1inStages: [],
  minThresholdMoisture1inStages: [],
  maxThresholdMoisture1inStages: [],
  minOptimalMoisture2inStages: [],
  minThresholdMoisture2inStages: [],
  maxOptimalMoisture2inStages: [],
  maxThresholdMoisture2inStages: [],
  minOptimalleafWetnessinStages: [],
  minThresholdleafWetnessinStages: [],
  maxOptimalleafWetnessinStages: [],
  maxThresholdleafWetnessinStages: [],
  minOptimalSoilTemperatureinStages: [],
  maxOptimalSoilTemperatureinStages: [],
  minThresholdSoilTemperatureinStages: [],
  maxThresholdSoilTemperatureinStages: [],
  minOptimalAirTemperatureinStages: [],
  maxOptimalAirTemperatureinStages: [],
  minThresholdAirTemperatureinStages: [],
  maxThresholdAirTemperatureinStages: [],
  minLightIntensityinStages: [],
  maxLightIntensityinStages: [],
  minOptimalHumidityinStages: [],
  maxOptimalHumidityinStages: [],
  minThresholdHumidityinStages: [],
  maxThresholdHumidityinStages: [],
  maxOptimalMoisture1inStages: [],
  autoIncrementDisabled: [],
  stageUpdateNotifDisabled: []
};

const CropCharacteristicsDetails: React.FC<Props> = (
  props: Props
): JSX.Element => {
  const res: any = props?.selectedModalData;
  const gridRef = useRef<AgGridReact>(null);
  const [enableCopy, setEnableCopy] = useState<boolean>(false);
  const [cropVarietyId, setcropVarietyId] = useState<MultiValue<any>>([]);
  const [soilTypeId, setsoilTypeId] = useState(res?.soilTypeId || "");
  const [cropId, setcropId] = useState(res?.cropId || "");
  const [cropOptions, setcropOptions] = useState<any>();
  const [cropVarietyOptions, setcropVarietyOptions] = useState<any>();
  const [seasonId, setseasonId] = useState(res?.season || "");
  const [cropPeriod, setCropPeriod] = useState(res?.cropPeriod || 0);
  const [cropStages, setCropStages] = useState<MultiValue<any>>([]);
  const [stageOptions, setStageOptions] = useState<any>();
  const [content] = useState<any>(() => {
    const dynamicContent = { ...initialContentState };
    for (const key in initialContentState) {
      if (res[key]) {
        dynamicContent[key] = res[key];
      }
    }
    return dynamicContent;
  });
  const [convertedData, setConvertedData] = useState<any>([]);
  const [columnDefs, setColumnDefs] = useState<
    (ColDef | ColGroupDef | KeyValues)[]
  >([]);
  const [openToast, setOpenToast] = useState<boolean>(false);

  const dynamicallyConfigureColumnsFromObject = (data: any) => {
    const keys = Object.keys(data);
    const newColumnDefs = keys.map((key) => ({
      headerName: key,
      field: key,
      autoHeight: true,
      sortable: true,
      editable: key === "name" ? false : true,
      filter: "agTextColumnFilter",
      minWidth: 230,
    }));
    setColumnDefs(newColumnDefs);
  };

  const convertData = () => {
    const output = [];
    for (const key in content) {
      let keyData: { name: string;[key: string]: any } = { name: key };
      for (let i = 0; i < cropStages.length; i++) {
        if (res[key]) keyData[cropStages[i]?.label] = res[key][i];
        else {
          keyData[cropStages[i].label] = 0;
        }
      }
      output.push(keyData);
    }
    setConvertedData(output);
    dynamicallyConfigureColumnsFromObject(output[0]);
  };

  const gridOptions: GridOptions = {
    columnDefs: columnDefs,
    rowData: convertedData,
    rowSelection: "single",
    animateRows: true,
    overlayLoadingTemplate:
      '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>',
    onCellClicked: (e) => { },
  };

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      flex: 1,
      resizable: true,
      sortable: true,
      floatingFilter: true,
      menuTabs: ["filterMenuTab"],
    };
  }, []);

  const sideBar = useMemo<
    SideBarDef | string | string[] | boolean | null
  >(() => {
    return {
      toolPanels: ["filters"],
    };
  }, []);

  const onGridReady = useCallback((params: GridReadyEvent) => {
    // gridRef?.current?.api.setColumnDefs(farmActivitiesColDef);
    // gridRef?.current?.api.setRowData(tableData);
  }, []);

  // function to perform server side sorting
  const handleColumnSort = (event: SortChangedEvent) => {
    const columns = event.columnApi.getColumnState();
    let sortedCol = columns.filter((obj) => obj.sort !== null)[0].colId;
    console.log("🚀 ~ handleColumnSort ~ sortedCol:", sortedCol);
  };

  // function to perform server side filtering
  const handleColumnFilter = (event: FilterChangedEvent) => {
    if (event.afterFloatingFilter) {
    }
  };

  const onSubmit = () => {
    if (cropId) {
      if (window.confirm("Do you want to submit?")) {
        const finalData: any = {
          cropStageIds: cropStages.map((stage: any) => stage.value),
          cropId: cropId,
          soilTypeId: soilTypeId,
          season: seasonId,
          cropPeriod: cropPeriod,
          totalStage: cropStages.length,
          varietyId: cropVarietyId.map((variety: any) => variety.value),
        };
        convertedData.forEach((item: any) => {
          const stageName = item.name;
          delete item.name;
          if (stageName === "autoIncrementDisabled" || stageName === "stageUpdateNotifDisabled") {
            const increamentArray: any = [];
            Object.values(item).forEach((it) => {
              increamentArray.push(
                typeof it === "boolean" ? it : (Boolean(it === "true"))
              );
            });
            finalData[stageName] = increamentArray;
          } else {
            finalData[stageName] = Object.values(item);
            if (
              finalData[stageName].at(-1) === null ||
              finalData[stageName].at(-1) === undefined
            ) {
              finalData[stageName].pop();
            }
          }
        });
        if (res?.cropCharId) {
          putCropCharacteristicsData(finalData, res?.cropCharId).then(
            (response: any) => {
              if (response.message === "Success") {
                alert("Characteristics Updated successfully");
                props?.setOpenModal(false);
              }
              else alert("Some Error Ocuured");
            }
          );
        } else {
          postCropCharacteristicsData(finalData).then((response: any) => {
            if (response.cropCharId) {
              alert("Characteristics Added successfully");
              props?.setOpenModal(false);
            }
            else alert("Some Error Ocuured");
          });
        }
      }
    } else alert("No such chemical");
  };

  const onDelete = () => {
    if (res?.cropCharId) {
      if (window.confirm("Do you want to delete?")) {
        deleteCropCharacteristicsData(res?.cropCharId).then((response: any) => {
          if (response.message === "Success") {
            alert("Characteristics deleted successfully");
            props?.setOpenModal(false);
          } else {
            alert("Something error occured");
          }
        });
      }
    } else alert("No such Configuration");
  };

  useEffect(() => {
    convertData();
  }, [cropStages]);

  useEffect(() => {
    getCropOptions().then((res: any) => {
      setcropOptions(
        [
          {
            label: "--select--",
            value: "--select--",
          },
        ].concat(generateOptions(res, "cropId", "cropName") as any)
      );
    });
  }, []);

  useEffect(() => {
    setcropVarietyId([]);
    setCropStages([]);
    if (cropId?.length > 0) {
      getCropVarieties(cropId).then((res: any) => {
        setcropVarietyOptions(generateOptions(res, "id", "name") as any);
      });
      getAllLocale().then((res) => {
        setStageOptions(generateOptions(res, "id", "en") as any);
      });
    }
  }, [cropId]);

  useEffect(() => {
    if (res?.varietyId) {
      if (isArrayWithLength(res?.varietyId) && cropVarietyOptions?.length > 0)
        setcropVarietyId(
          cropVarietyOptions?.filter((variety: any) =>
            res?.varietyId.includes(variety.value)
          )
        );
      else
        setcropVarietyId(
          cropVarietyOptions?.filter(
            (variety: any) => res?.varietyId === variety.value
          )
        );
    }
    if (res?.cropStageIds && stageOptions?.length > 0) {
      setCropStages(
        res?.cropStageIds
          ?.map((cropStageId: any) =>
            stageOptions.find((stage: any) => stage.value === cropStageId)
          )
          .filter(Boolean)
      );
    }
    setEnableCopy(true)
  }, [cropVarietyOptions, stageOptions]);

  const addValue = (data: any) => {
    const updatedArray = convertedData.map((item: any) => {
      if (item.name === data.name) {
        const updatedItem = { ...item };
        for (const key in updatedItem) {
          if (key !== "name") {
            if (data.name === "autoIncrementDisabled" || data.name === "stageUpdateNotifDisabled") {
              updatedItem[key] =
                typeof data[key] === "boolean"
                  ? data[key]
                    ? "true"
                    : "false"
                  : data[key];
            } else {
              const numericValue = parseFloat(updatedItem[key]);
              updatedItem[key] = isNaN(numericValue)
                ? updatedItem[key]
                : numericValue;
            }
          }
        }

        return updatedItem;
      } else {
        return item;
      }
    });

    setConvertedData(updatedArray);
  };

  const soilOptions = [
    { label: "--select--", value: "--select--" },
    { label: "Black Soil", value: 1 },
    { label: "Sandy Loam", value: 2 },
    { label: "Clay Loam", value: 3 },
    { label: "Loamy Sand", value: 4 },
    { label: "Fine Sandy Loam", value: 5 },
    { label: "Clay", value: 6 },
  ];

  const onColumnMoved = (event: any) => {
    const newColumnOrder = event.api.columnModel.displayedColumns.map(
      (column: any) => column.getColId()
    );
    const croppedNewColumnOrder = newColumnOrder.filter((colId: string) =>
      cropStages.find((stage: any) => stage.label === colId)
    );
    setCropStages(
      cropStages.slice().sort((a: any, b: any) => {
        return (
          croppedNewColumnOrder.indexOf(a.label) -
          croppedNewColumnOrder.indexOf(b.label)
        );
      })
    );
  };

  const editValue = (e: any) => {
    const nextCell =
      cropStages[
        cropStages?.findIndex((c: any) => c?.label === e?.column?.getColId()) +
          1
      ]?.label;
    const nextRow = e.rowIndex!;
    gridRef?.current?.api?.startEditingCell({
      rowIndex: nextRow,
      colKey: nextCell,
    });
  };

  const navigate = useNavigate();

  return (
    <Grid
      width={"90%"}
      my={2}
      mx={"auto"}
      boxShadow={2}
      borderRadius={4}
      paddingBottom={5}
    >
      <Grid
        p={2}
        borderRadius={20}
        display={"flex"}
        flexDirection={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid display={"flex"} item alignItems={"center"}>
          <IconButton
            onClick={() =>
              props?.setOpenModal
                ? props.setOpenModal(false)
                : navigate("/nutrient")
            }
          >
            <ArrowBack />
          </IconButton>
          <Typography m={1} variant="h5">
            Crop Characteristics
          </Typography>
        </Grid>

        {res?.cropCharId && enableCopy &&(
          <IconButton
            style={{ marginRight: 25 }}
            onClick={() => {
              delete res.cropCharId;
              setOpenToast(true);
            }}
          >
            <ContentCopyIcon />
            <Typography marginLeft={0.8} fontSize={18}>
              Copy
            </Typography>
          </IconButton>
        )}
      </Grid>
      <ToastAlert
        open={openToast}
        setOpen={setOpenToast}
        severity={"info"}
        message={"Content Copied"}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          padding: 30,
        }}
      >
        <form style={{ width: "94%" }}>
          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Crop
          </label>
          <label style={{ fontSize: 20, fontWeight: "bold", color: "red" }}>
            *
          </label>
          <select
            onChange={(e) => {
              setcropId(e.target.value);
            }}
            style={{
              width: "97%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
            value={cropId}
          >
            {cropOptions?.map(({ value, label }: any, index: any) => (
              <option value={value}>{label}</option>
            ))}
          </select>
          <br />

          <h3 style={{ margin: 5 }}>Crop Variety</h3>
          <Select
            options={cropVarietyOptions}
            onChange={(selected: MultiValue<any>) => {
              setcropVarietyId(selected);
            }}
            isMulti
            value={cropVarietyId}
            {...DropdownProps}
          />

          <label
            style={{
              fontSize: 19,
              fontWeight: "bold",
              marginRight: 5,
              margin: 5,
            }}
          >
            Season
          </label>
          <input
            name="fertilizerName"
            onChange={(e) => setseasonId(e.target.value)}
            value={seasonId}
            type="text"
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
          ></input>
          <br />

          <h3 style={{ margin: 5 }}>Soil Type</h3>
          <select
            onChange={(e) => {
              setsoilTypeId(e.target.value);
            }}
            style={{
              width: "97%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
              marginTop: 10,
            }}
            value={soilTypeId}
          >
            {soilOptions?.map(({ value, label }: any, index: any) => (
              <option value={value}>{label}</option>
            ))}
          </select>
          <br />

          <h3 style={{ margin: 5 }}>Crop Period</h3>
          <input
            name="fertilizerAlias"
            onChange={(e) => setCropPeriod(Number(e.target.value))}
            value={cropPeriod}
            type="number"
            min={0}
            style={{
              width: "93%",
              padding: "20px",
              fontSize: 18,
              paddingTop: "15px",
              margin: "3px",
              marginBottom: 11,
            }}
          ></input>
          <br />

          <h3 style={{ margin: 5 }}>Crop Stages</h3>
          <Select
            options={stageOptions}
            onChange={(selected: MultiValue<any>) => {
              setCropStages(selected);
            }}
            isMulti
            value={cropStages}
            {...DropdownProps}
          />

          <div className="ag-theme-alpine">
            {isArrayWithLength(convertedData) && (
              <Grid p={3}>
                <AgGridReact
                  ref={gridRef}
                  rowData={convertedData}
                  columnDefs={columnDefs}
                  onGridReady={onGridReady}
                  defaultColDef={defaultColDef}
                  sideBar={sideBar}
                  animateRows={true}
                  gridOptions={gridOptions}
                  domLayout="autoHeight"
                  onSortChanged={handleColumnSort}
                  onFilterChanged={handleColumnFilter}
                  onCellValueChanged={(e) => addValue(e.data)}
                  onColumnMoved={onColumnMoved}
                  enableCellTextSelection={true}
                  onCellEditingStopped={editValue}
                ></AgGridReact>
              </Grid>
            )}
          </div>

          <Grid
            display={"flex"}
            justifyContent={"space-between"}
            style={{ marginTop: 55 }}
          >
            <input
              type="button"
              value="Submit"
              onClick={onSubmit}
              style={{
                width: "45%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
              }}
            />
            <input
              type="button"
              value="Delete"
              onClick={onDelete}
              style={{
                width: "45%",
                padding: "20px",
                fontSize: 18,
                paddingTop: "15px",
              }}
            />
          </Grid>
        </form>
      </div>
    </Grid>
  );
};

export default CropCharacteristicsDetails;
